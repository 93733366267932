import { AuthGuard, Loading } from '@/components';
import { useFeatureFlags } from '@/core';

import { PersonalDetailsLegacy } from './legacy/PersonalDetailsLegacy';
import { PersonalDetailsPage } from './page';

export const PersonalDetails = () => {
  const { data, isLoading } = useFeatureFlags();

  if (isLoading) {
    return <Loading />;
  }

  // Temporary solution while we are working on introducing identity authentication, this will allow us to backward compatibility until full implementation.
  return data?.identityIntegrationFeature ? (
    <AuthGuard>
      <PersonalDetailsPage />
    </AuthGuard>
  ) : (
    <PersonalDetailsLegacy />
  );
};
