import { GenderType } from '@/store/webApi';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface MinorDetailsState {
  dateOfBirth: string;
  firstName: string;
  gender?: GenderType;
  genderOther?: string;
  lastName: string;
  noLastName: boolean;
}

const initialState: MinorDetailsState = {
  dateOfBirth: '',
  firstName: '',
  lastName: '',
  noLastName: false,
};

const minorDetailsSlice = createSlice({
  name: 'minorDetails',
  initialState,
  reducers: {
    clearMinorDetails: (state): void => {
      state.firstName = '';
      state.dateOfBirth = '';
      delete state.gender;
      delete state.genderOther;
      state.lastName = '';
      state.noLastName = false;
    },
    setMinorDateOfBirth: (state, { payload }: PayloadAction<string>): void => {
      state.dateOfBirth = payload;
    },
    setMinorDetails: (state, { payload }: PayloadAction<MinorDetailsState>): void => {
      state.firstName = payload.firstName;
      state.dateOfBirth = payload.dateOfBirth;
      if (payload.gender) state.gender = payload.gender;
      if (payload.genderOther) state.genderOther = payload.genderOther;
      state.lastName = payload.lastName;
      state.noLastName = payload.noLastName;
    },
    setMinorFirstName: (state, { payload }: PayloadAction<string>): void => {
      state.firstName = payload;
    },
    setMinorGender: (state, { payload }: PayloadAction<GenderType>): void => {
      state.gender = payload;
      if (state.gender !== 0) {
        state.genderOther = undefined;
      }
    },
    setMinorGenderOther: (state, { payload }: PayloadAction<string>): void => {
      state.genderOther = payload;
    },
    setMinorLastName: (state, { payload }: PayloadAction<string>): void => {
      state.lastName = payload;
    },
    setMinorNoLastName: (state, { payload }: PayloadAction<boolean>): void => {
      state.noLastName = payload;
    },
  },
});

const {
  clearMinorDetails,
  setMinorDateOfBirth,
  setMinorDetails,
  setMinorFirstName,
  setMinorGender,
  setMinorGenderOther,
  setMinorLastName,
  setMinorNoLastName,
} = minorDetailsSlice.actions;

const { reducer: minorDetailsReducer } = minorDetailsSlice;

export {
  clearMinorDetails,
  minorDetailsReducer,
  setMinorDateOfBirth,
  setMinorDetails,
  setMinorFirstName,
  setMinorGender,
  setMinorGenderOther,
  setMinorLastName,
  setMinorNoLastName,
};
