import { FC, useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { AlertPopup, Button, ButtonStyle, ButtonsWrapper } from '@/components';
import { useHandleSession } from '@/core';

export const AlertRefreshSessionTimeout: FC = (): JSX.Element => {
  const { sessionOverdue, restartSession } = useHandleSession();
  const [forceHide, setForceHide] = useState(false);
  const delayTime = 60;
  const { t } = useTranslation('');
  const resetSession = useCallback(() => {
    restartSession();
  }, [restartSession]);

  const forceHidingOfAlert = useCallback(() => setForceHide(true), []);
  return (
    <>
      {sessionOverdue > 0 && !forceHide && (
        <AlertPopup alertType="information" show text={''} title={t('session-expiration.title')}>
          <p>{t('session-expiration.subtitle')}</p>
          <p>{t('session-expiration.subtitle2')}</p>

          <p>
            <b>{t('session-expiration.subtitle3')}</b>
            <b>{Math.max(delayTime - sessionOverdue, 0)}</b>
          </p>
          <ButtonsWrapper topBorder wrap>
            <Button buttonStyle={ButtonStyle.Secondary} text={t('session-expiration.button')} onClick={resetSession} />
            <Button buttonStyle={ButtonStyle.Discrete} freeHeight text={t('session-expiration.button-close')} onClick={forceHidingOfAlert} />
          </ButtonsWrapper>
        </AlertPopup>
      )}
    </>
  );
};
