import { PropsWithChildren } from 'react';

import * as S from './ReviewGrid.style';

interface ReviewGridRowProps {
  bold?: boolean;
  id: string;
  label: string;
}

export const ReviewGridRow = ({ bold, children, id, label }: PropsWithChildren<ReviewGridRowProps>) => {
  return (
    <S.ReviewGridRowSection>
      <S.ReviewCell bold={bold} smaller id={id}>
        {label}
      </S.ReviewCell>
      <S.ReviewCell bold role="textbox" aria-labelledby={id} data-sentry-mask>
        {children}
      </S.ReviewCell>
    </S.ReviewGridRowSection>
  );
};
