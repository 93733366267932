import { useEffect } from 'react';

import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import { FlowSteps } from '@/core';
import { Loading } from '@/components';
import { RouterState } from '@/core/hooks/router';
import { useLazyIdentityGetCustomerBoundStatusQuery } from '@/store/webApi';

type NavigateToArgs = {
  productCode?: string;
  boundStatus: number; // 1 = New | 2 = NotBound | 3 = Bound
  correlationId?: string;
  paymentId?: string;
};

const getNavigatePath = ({ productCode, boundStatus, paymentId, correlationId }: NavigateToArgs) => {
  switch (true) {
    //case when we open the booking-complete page in the link from the email.
    case !!productCode && !!paymentId && !!correlationId && boundStatus !== 2: {
      return `/${productCode}/book-test/booking-complete?paymentid=${paymentId}&correlationid=${correlationId}`;
    }

    case !!productCode && boundStatus !== 2: {
      /* TODO:(BRP-604) strange way of getting the step...
      Mobile flow should be refactored and steps should be calculated in a more predictable way, not based on the window width checks in every component or URL segments. */
      const step = window.innerWidth < 1024 ? FlowSteps.flowStepForMobilePath : FlowSteps.flowStepForDesktopPath;
      return `/${productCode}/book-test/personal-details/${step}`;
    }

    case boundStatus === 2:
      return `/account/verify?productCode=${productCode}`;

    default:
      return '/dashboard';
  }
};

export const Callback = () => {
  const navigate = useNavigate();
  const [trigger, query] = useLazyIdentityGetCustomerBoundStatusQuery();
  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated) {
      trigger();
    }
  }, [auth.isAuthenticated, trigger]);

  useEffect(() => {
    if (query.data?.boundStatus) {
      const routeState = auth.user?.state as RouterState;
      navigate(
        getNavigatePath({
          productCode: routeState?.productCode,
          boundStatus: query.data?.boundStatus,
          paymentId: routeState?.paymentId,
          correlationId: routeState?.correlationId,
        }),
        { replace: true },
      );
    }
  }, [auth.user?.state, navigate, query.data?.boundStatus]);

  if (auth.isLoading) {
    return <Loading />;
  }

  if (auth.error) {
    console.error(auth.error);
    throw new Error('Authentication error');
  }

  return <Loading />;
};
