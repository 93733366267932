import { css } from '@emotion/react';

export const themeColors = css`
  :root {
    .base-theme {
      --black: #000;
      --black-text: #333333;

      --core-grey: #898a8d;
      --core-orange: #ff8200;
      --core-red: #ea0034;

      --dark-blue: #005cb9;
      --dark-green: #00953b;
      --dark-grey: #54565a;
      --dark-red: #ab0033;

      --darker-blue: #004a94;
      --darker-grey: #191919;

      --purple: #b25eff;
      --indigo: #23085a;

      --light-blue: #97daff;
      --lighter-blue: #00dcff;
      --light-grey: #c8c8c8;
      --light-red: #ffbab8;
      --light-teal: #99edd1;
      --light-yellow: #ffe49e;

      --lighter-grey: #f4f4f4;
      --lightest-grey: #fcfcfc;

      --white: #fff;

      --rgb-black: 0, 0, 0;

      --rgb-core-grey: 137, 138, 141;
      --rgb-core-red: 234, 0, 52;
      --rgb-core-orange: 255, 130, 80;

      --rgb-dark-blue: 0, 92, 185;
      --rgb-dark-green: 0, 149, 59;
      --rgb-dark-grey: 84, 86, 90;
      --rgb-dark-red: 171, 0, 51;

      --rgb-darker-blue: 0, 74, 148;

      --rgb-indigo: 35, 8, 90;

      --rgb-light-blue: 151, 218, 255;
      --rgb-light-grey: 200, 200, 200;
      --rgb-light-grey-half: 200, 200, 200, 0.5;
      --rgb-light-red: 255, 186, 184;
      --rgb-light-teal: 153, 237, 209;
      --rgb-light-yellow: 255, 228, 158;

      --rgb-lighter-grey: 248, 248, 248;

      --rgb-white: 255, 255, 255;
    }
    .purple-gradient {
      opacity: 0.5;
      background: linear-gradient(99deg, rgba(211, 184, 255, 0.5) 8.68%, rgba(178, 94, 255, 0.5) 49.09%, rgba(211, 184, 255, 0.5) 89.5%);
    }
  }
`;
