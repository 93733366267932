import { ButtonHTMLAttributes, FC } from 'react';

import { FiLoader } from 'react-icons/fi';

import { ChevronLeft } from '@/icons/ChevronLeft';
import { ChevronRight } from '@/icons/ChevronRight';
import { Edit } from '@/icons/Edit';

import * as S from './Button.style';

interface ButtonProps {
  addArrow?: Direction;
  addIcon?: string;
  buttonStyle: ButtonStyle;
  center?: boolean;
  freeHeight?: boolean;
  hideOn?: 'mobile' | 'tablet';
  isLoading?: boolean;
  leftAlignMobile?: boolean;
  noMargin?: boolean;
  noPadding?: boolean;
  text: string;
}
type Direction = 'left' | 'right';

export const Button: FC<ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>> = ({
  addArrow,
  addIcon,
  'aria-label': ariaLabel = '',
  buttonStyle,
  center = false,
  freeHeight,
  hideOn,
  isLoading,
  leftAlignMobile = false,
  noMargin = false,
  noPadding = false,
  onClick,
  text,
  type = 'button',
  ...props
}): JSX.Element => {
  return (
    <S.Button
      addArrow={isLoading ? '' : addArrow}
      aria-label={ariaLabel}
      buttonStyle={buttonStyle}
      center={center}
      freeHeight={freeHeight}
      hideOn={hideOn}
      leftAlignMobile={leftAlignMobile}
      noMargin={noMargin}
      noPadding={noPadding}
      onClick={onClick}
      type={type}
      {...props}
    >
      {addArrow === 'left' && <ChevronLeft />}
      {addIcon === 'edit' && (
        <div className="custom-icon">
          <Edit />
        </div>
      )}
      {text}
      {isLoading && <FiLoader className="text-[20px] relative ml-[10px] animate-spin-slow inline-block rotate"></FiLoader>}

      {addArrow === 'right' && !isLoading && <ChevronRight />}
    </S.Button>
  );
};

export enum ButtonStyle {
  Discrete = 'Discrete',
  DiscreteRed = 'DiscreteRed',
  Primary = 'Primary',
  Secondary = 'Secondary',
}
