import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { SelectField } from '@britishcouncil/react-registration-platform';

import { GoogleTagManager, useAppParams } from '@/core';
import { searchDetailsSlice } from '@/store';
import { useAppDispatch, useAppSelector } from '@/core/hooks';
import { useSessionGetAllCountriesQuery } from '@/store/webApi';

export const ChooseSessionCountry = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { productCode = '' } = useAppParams();

  const { country } = useAppSelector((s) => s.searchDetails);
  const { items } = useAppSelector((s) => s.basket);

  const { data = [], error, isLoading } = useSessionGetAllCountriesQuery({ productCode });

  const options = data.map((country) => ({
    value: country.id ?? -1,
    label: country.name ?? '',
    isoCode: country.isoCode ?? '',
  }));

  const handleCountryChange = useCallback(
    (countryOption: (typeof options)[0] | null) => {
      if (items.length > 0) {
        return;
      }

      if (countryOption && countryOption.value)
        dispatch(
          searchDetailsSlice.actions.setCountry({
            id: countryOption.value,
            isoCode: countryOption.isoCode,
            name: countryOption.label,
          }),
        );

      GoogleTagManager.addEvent({
        action: 'CountrySelection',
        Country: countryOption?.value,
        event: 'VirtualPageview',
        virtualPageTitle: 'British Council - Book a new test - Choose country',
        virtualPageTitleLocal: document.title,
        virtualPageURL: '/book-test',
      });
    },
    [dispatch, items.length],
  );

  // Need to use any as Select from reg. platform is using incorrect types and not allow to use null.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value: any = options.find((el) => el.value === country?.id) || null;

  return (
    <SelectField
      errorMessage={error ? 'Cannot load countries' : undefined}
      headerMessage={t('book-test.choose')}
      testId="select-countries"
      selectProps={{
        value,
        options,
        isMulti: false,
        onChange: handleCountryChange,
        placeholder: t('book-test.search-by'),
        isDisabled: isLoading || items.length > 0,
      }}
      className="mb-[16px]"
    />
  );
};
