export const FlowSteps = {
  totalStepsForFormInDesktopAndNoChild: 4,
  totalStepsForFormInDesktopWithChild: 5,
  totalStepsForFormInMobileAndNoChild: 6,
  totalStepsForFormInMobileWithChild: 8,
  totalStepsForSessionSelection: 1,
  sessionSelectionFinished: 2,
  flowStepForDesktopPath: '0',
  flowStepForMobilePath: '1',
  flowStepForMobilePersonalDetailsPath: '2',
  flowStepForMobileEmailPath: '3',
  flowStepForMobileAddressPath: '4',
  flowForPersonalDetailsFinished: '4',
};
