import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { addMonths } from 'date-fns';

import { cn } from '@/core/helpers/cn';
import { getMonthsArray } from '@/utils/getMonthsNames';

import { LeftArrow, RightArrow } from './Arrows';
import { MonthBtn } from './MonthBtn';
import { NoScrollbar } from './NoScrollbar';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { compareMonthYear, dateFromMonthYear, extractYearFromDate } from '@/utils';

export const MONTHS_CHUNKS = 6;
const MAX_YEARS = 5;

const monthNames = getMonthsArray();
const monthsLeftInCurrentYear = monthNames.slice(new Date().getMonth());

const initMonths = monthsLeftInCurrentYear.map((month) => ({ id: new Date(`${month}-${new Date().getFullYear().toString()}`) }));
const addNextYearMonths = (year: string) => monthNames.map((month) => ({ id: new Date(`${month}-${year}`) }));

type Props = {
  availableDates: string[];
  setDatesRange: Dispatch<SetStateAction<{ from: Date; to: Date }>>;
  setCurrentMonth: Dispatch<SetStateAction<Date>>;
};

export const MonthsScroll = ({ availableDates, setDatesRange, setCurrentMonth }: Props) => {
  const [months, setMonths] = useState(initMonths);
  const [activeMonths, setActiveMonths] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>();
  const [selectedYear, setSelectedYear] = useState<number | undefined>();
  type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;
  const apiRef = useRef({} as scrollVisibilityApiType);

  const availableYears =  availableDates.map(item => extractYearFromDate(new Date(item)))
  const years = useMemo(() => [...new Set(availableYears.map((item) => item))], [months]);

  useEffect(() => {
    const active = availableDates.map((date) => new Date(date).toLocaleDateString('en-GB', { month: 'long', year: 'numeric' }).replace(/\s/g, '-'));
    setActiveMonths(active);

    /* Autoselect first available month when in changed city prev selected month is not available. */
    setSelected((s) => (s && active.includes(s) ? s : active[0]));
  }, [availableDates]);

  useEffect(() => {
    if(selected){
      setSelectedYear(dateFromMonthYear(selected).getFullYear())
    }
  }, [selected])

  const isItemSelected = (id: Date) => selected === `${monthNames[id.getMonth()]}-${id.getFullYear()}`;

  const handleClick = (id: Date) => () => {
    setSelected(monthNames[id.getMonth()]);
    setCurrentMonth(id);
  };

  const updateRange = useCallback(() => {
    if (years.length >= MAX_YEARS) return;
  
    setDatesRange((prev) => {
      const newToRange = addMonths(prev.to, MONTHS_CHUNKS);
  
      updateMonths(newToRange);
  
      return {
        from: prev.to,
        to: newToRange,
      };
    });
  }, [setDatesRange, years.length]);

  const updateMonths = (newToRange: Date) => {
    setMonths((prev) => {
      const uniqueYears = getUniqueYearsFromMonths(prev);
      if (uniqueYears.includes(newToRange.getFullYear())) return prev;

      return [...prev, ...addNextYearMonths(newToRange.getFullYear().toString())];
    });
  };

  const getUniqueYearsFromMonths = (months: { id: Date }[]) => {
    return Array.from(new Set(months.map(({ id }) => id.getFullYear())));
  };

  const scrollToSelectedYear = async (year: number) => {
    const firstMonth = months.find(({ id }) => id.getFullYear() === year);
    apiRef.current?.scrollToItem(apiRef.current?.getItemById(firstMonth?.id.getMonth().toString() ?? ''), 'smooth', 'start');
    setSelectedYear(year)
    const formatDate = availableDates.find(item => dateFromMonthYear(item).getFullYear() === year)
    if(formatDate){
      const date = new Date(formatDate)
      setCurrentMonth(date);
      setSelected(`${date.toLocaleString('default', { month: 'long' })}-${year}`)
    }
    if(Number(year) > new Date().getFullYear()){
      setMonths(monthNames.map((month) => ({ id: new Date(`${month}-${new Date().getFullYear() + 1}`) })));
    }
    else{
      setMonths(initMonths);
    }
  };

  return (
    <div className="sm:p-[24px] sm:bg-[#f4f4f4] sm:rounded-[16px] my-[32px]">
      <div className="py-[24px] bg-white rounded-lg w-[90vw] lg:w-full">
        <NoScrollbar>
          {/* List of years */}
          <div className="flex mb-[24px] px-[8px]">
            {years.map((year) => (
              <div
                key={year}
                onClick={() => scrollToSelectedYear(year)}
                className={cn('border-b-[3px] grow border-grey text-center text-grey font-normal cursor-pointer', {
                  'border-purple text-purple font-bold': year === selectedYear,
                  'border-purple-dark text-purple-dark font-bold': year === selectedYear,
                })}
              >
                {year}
              </div>
            ))}
          </div>

          {/* List of months */}
          <ScrollMenu
            apiRef={apiRef}
            LeftArrow={LeftArrow}
            RightArrow={<RightArrow updateRange={updateRange} chunkSize={MONTHS_CHUNKS} />}
          >
            {months.map(({ id }) => (
              <MonthBtn
                itemId={monthNames[id.getMonth()]}
                title={monthNames[id.getMonth()]}
                key={monthNames[id.getMonth()]}
                onClick={handleClick(id)}
                selected={isItemSelected(id)}
                active={activeMonths.some(item => compareMonthYear(new Date(item), id))}
              />
            ))}
          </ScrollMenu>
        </NoScrollbar>
      </div>
    </div>
  );
};
