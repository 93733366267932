import { params } from './params';

const basePath = `/${params.productCode}/book-test`;

// When old way of routing will be finally improved, remove these wirdos and define routes using tools like 'react-router-typesafe-routes' or similar

export const mainMenuBookTest = {
  absolutePath: `/${params.productCode}/book-test`,
  path: 'book-test/*',
  translateTextKey: 'book-new-test.title',
};

export const bookTestPersonalDetailsRoute = {
  absolutePath: `${basePath}/personal-details/${params.step}`,
  path: `personal-details/${params.step}`,
  translateTextKey: 'book-new-test.personal-details',
};

export const bookTestMinorDetailsRoute = {
  absolutePath: `${basePath}/minor-details/${params.step}`,
  path: `minor-details/${params.step}`,
  translateTextKey: 'book-new-test.minor-details',
};

export const bookTestIdentificationRoute = {
  absolutePath: `${basePath}/identification/${params.step}`,
  path: `identification/${params.step}`,
  translateTextKey: 'book-new-test.identification',
};

export const bookTestMinorIdentificationRoute = {
  absolutePath: `${basePath}/minor-identification/${params.step}`,
  path: `minor-identification/${params.step}`,
  translateTextKey: 'book-new-test.minor-identification',
};

export const bookTestReviewRoute = {
  absolutePath: `${basePath}/review/${params.step}`,
  path: `review/${params.step}`,
  translateTextKey: 'book-new-test.review',
};
