import { AnchorHTMLAttributes, FC } from 'react';

import { ComponentWrapperProps } from '@/components/component-wrapper';
import { ExternalLink as ExternalLinkIcon } from '@/icons/ExternalLink';

import * as S from './ExternalLink.style';

interface ExternalLinkProps extends ComponentWrapperProps {
  text: string;
}

export const ExternalLink: FC<AnchorHTMLAttributes<HTMLAnchorElement> & ExternalLinkProps> = ({ className, href, id, target, text, ...props }): JSX.Element => {
  return (
    <S.ExternalLink className={className} href={href} target={target} id={id} {...props}>
      <S.LinkText>{text}</S.LinkText>
      <S.IconWrapper>
        <ExternalLinkIcon />
      </S.IconWrapper>
    </S.ExternalLink>
  );
};
