import { NavLink } from 'react-router-dom';

import { cn } from '@/core/helpers/cn';

interface NavigationDeckProps {
  routes: DeckRoute[];
}

export type DeckRoute = {
  name: string;
  value: string;
};

export const NavigationDeck = ({ routes }: NavigationDeckProps) => {
  return (
    <div className="flex w-full bg-indigo animate-slide-y-in">
      <div className="flex justify-end max-w-[1280px] w-full px-3 mx-auto">
        {routes.map((route, key) => (
          <NavLink
            key={key}
            className={({ isActive }) =>
              cn('font-bold text-center mx-3 my-1 text-white no-underline animate-fade', {
                'underline decoration-4 underline-offset-4 decoration-indigo-400': isActive,
              })
            }
            to={route.value}
          >
            {route.name}
          </NavLink>
        ))}
      </div>
    </div>
  );
};
