import { useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Label, RadioGroupItem, WellReg } from '@britishcouncil/react-registration-platform';

import { ClearForm } from '@/components/component-wrapper/ClearForm';
import { FormDob } from '@/components/forms/FormDob';
import { FormInput } from '@/components/forms/FormInput';
import { FormRadioGroup } from '@/components/forms/FormRadioGroup';
import { isAdult } from '@/pages/book-test';
import { personalDetailsSlice } from '@/store';

import { PersonalDetailsFormType } from './usePersonalDetailsForm';

export const CandidatePersonalDetails = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('');

  const {
    register,
    formState: { errors },
    setValue,
    control,
    watch,
    setError,
    clearErrors,
  } = useFormContext<PersonalDetailsFormType>();

  const isOtherGenderChoosen = watch('gender')?.toString() === '0';
  const dob = watch('dateOfBirth');

  useEffect(() => {
    if (dob?.day && dob?.month >= 0 && dob?.year && !isAdult(dob)) {
      setError('dateOfBirth', {
        type: 'minDob',
        message: t('personal-details.about-you.dob-minor'),
      });
    } else clearErrors('dateOfBirth');
  }, [clearErrors, dob, errors, setError, t]);

  const onFormClear = useCallback(() => {
    dispatch(personalDetailsSlice.actions.clearPersonalDetails());
    setValue('firstName', '');
    setValue('lastName', '');
    setValue('gender', undefined);
    setValue('otherGenders', '');
    setValue('dateOfBirth', undefined);
  }, [dispatch, setValue]);

  return (
    <ClearForm onClear={onFormClear}>
      <WellReg>
        <div className="[&>*]:my-3">
          <FormInput
            required
            data-testid="first-name"
            {...register('firstName', { required: t('common.required') })}
            errorInline={errors.firstName?.message}
            label={t('common.name-dob-sex.first-name')}
            helpText={t('common.name-dob-sex.first-name-sublabel')}
          />
          <FormInput
            required
            data-testid="last-name"
            {...register('lastName', { required: t('common.required') })}
            errorInline={errors.lastName?.message}
            label={t('common.name-dob-sex.last-name')}
          />
          <FormDob
            control={control}
            errorInline={
              errors.dateOfBirth
                ? errors.dateOfBirth?.type === 'minDob'
                  ? t('personal-details.about-you.dob-minor')
                  : 'The date you have entered is invalid, please check and try again.'
                : undefined
            }
          />
          <FormRadioGroup
            data-testid="gender-choice"
            name="gender"
            control={control}
            label={t('common.name-dob-sex.sex.title')}
            required
            items={[
              { id: 'gender-2', value: '2', label: t('common.name-dob-sex.sex.female') },
              { id: 'gender-1', value: '1', label: t('common.name-dob-sex.sex.male') },
              {
                id: 'gender-0',
                value: '0',
                owerideItem: (
                  <label key="0" className={isOtherGenderChoosen ? 'flex items-center' : ''}>
                    <div className="flex items-center space-x-3 sm:border-none sm:px-0 sm:py-0 sm:my-0 border border-grey-dark px-4 py-2 rounded-lg my-1">
                      <RadioGroupItem id="0" placeholder="" value="0" />
                      <Label placeholder="" className="[&>*]:font-light" htmlFor="0">
                        Other
                      </Label>
                    </div>
                    {isOtherGenderChoosen && (
                      <div className="w-8/12  ml-auto">
                        <FormInput className="w-full " data-testid="other-genders" {...register('otherGenders')} />
                      </div>
                    )}
                  </label>
                ),
              },
            ]}
          />
        </div>
      </WellReg>
    </ClearForm>
  );
};
