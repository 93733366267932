import { useParams } from 'react-router-dom';

/** URL params used across the app.
 * IMPORTANT: Make sure that 'value' is the same string as 'key' and starts with ':'.
 */
export const params = {
  productCode: ':productCode',
  reservationId: ':reservationId',
  step: ':step',
} as const;

export const useAppParams = () => useParams() as Record<keyof typeof params, string | undefined>;
