import { useNavigate } from 'react-router-dom';

import { CreateOrUpdateCustomerIdentificationDocumentRequest } from '@/store/webApi';
import { FlowSteps, bookTestReviewRoute, params, useAppParams, useAppSelector } from '@/core';
import { webApi } from '@/store/webApi/enhancedWebApi';

import { IdentificationFormTypes, IdentificationType } from './useIdentificationForm';

export const useIdentificationMutation = () => {
  const navigate = useNavigate();
  const { productCode, step } = useAppParams();

  const { minorId, isChild } = useAppSelector((s) => s.journey);

  const [updateIdentityDetails, { isLoading: isProcessCustomerIdentification, error: errorProcessCustomerIdentification }] =
    webApi.useCustomerCreateOrUpdateCustomerIdentificationDocumentMutation();
  const [updateMinorIdentityDetails, { isLoading: isProcessMinorIdentification, error: errorProcessMinorIdentification }] =
    webApi.useCustomerCreateOrUpdateMinorIdentificationDocumentMutation();

  const goToNextStep = () =>
    navigate(
      bookTestReviewRoute.absolutePath
        .replace(params.productCode, productCode || '')
        .replace(params.step, step !== FlowSteps.flowStepForDesktopPath ? FlowSteps.flowStepForMobilePath : FlowSteps.flowStepForDesktopPath),
    );

  const createOrUpdate = async (data: IdentificationFormTypes) => {
    const expiryDate =
      data?.typeId?.toString() === IdentificationType.IdCard && data.idCardNoExpiry
        ? null
        : new Date(Date.UTC(data?.expiryDate?.year ?? 0, data?.expiryDate?.month, data?.expiryDate?.day)).toISOString().split('T')[0];

    const request = {
      typeId: Number(data?.typeId),
      documentNumber: data?.documentNumber,
      expiryDate: expiryDate,
      issuingAuthority: data.issuingAuthority,
      issuingCountryId: data.issuingCountryId,
    } as CreateOrUpdateCustomerIdentificationDocumentRequest;

    if (isChild) {
      await updateMinorIdentityDetails({
        id: minorId ?? '',
        createOrUpdateCustomerIdentificationDocumentRequest: request,
      }).then((response) => {
        if (!('error' in response)) {
          goToNextStep();
        }
      });
    } else {
      await updateIdentityDetails({
        createOrUpdateCustomerIdentificationDocumentRequest: request,
      }).then((response) => {
        if (!('error' in response)) {
          goToNextStep();
        }
      });
    }
  };

  return {
    createOrUpdate,
    isLoading: isProcessCustomerIdentification || isProcessMinorIdentification,
    error: errorProcessCustomerIdentification || errorProcessMinorIdentification,
  };
};
