/* eslint-disable @typescript-eslint/no-explicit-any */
/*  Handle personally identifiable information (PII) sends to Sentry */

const keysToMask = [
  'addressLine1',
  'addressLine2',
  'city',
  'postCode',
  'residenceCountryId',
  'dateOfBirth',
  'email',
  'firstName',
  'lastName',
  'middleName',
  'mobileNumber',
  'expiryDate',
  'identificationNumber',
  'issuingAuthority',
  'nationalityCountryId',
];

export const maskSensitiveData = (obj: any): any => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (keysToMask.includes(key) && typeof value === 'string') {
      acc[key] = '*'.repeat(value.length);
    } else if (typeof value === 'object') {
      acc[key] = maskSensitiveData(value);
    } else {
      acc[key] = value;
    }

    return acc;
  }, {} as any);
};
