import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ReviewNameDob, ReviewPickup } from '@/components';
import { getDateStringLong, useAppSelector } from '@/core';

export const AppReviewChildNameDob: FC = (): JSX.Element => {
  const { dateOfBirth, firstName, lastName } = useAppSelector((state) => state.minorDetails);
  const dateOfBirthText = dateOfBirth ? getDateStringLong(new Date(dateOfBirth)) : '';
  const name = `${firstName} ${lastName}`;
  const { t } = useTranslation();
  return (
    <>
      <ReviewNameDob
        dateOfBirth={dateOfBirthText}
        dateOfBirthLabel={t('review.personal-details.dob')}
        name={name}
        nameLabel={t('review.personal-details.name')}
      />
      <ReviewPickup />
    </>
  );
};
