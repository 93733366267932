import { DateWithTimeZone } from '@/components/presentation/calendar/UtcWithLocalDate';
import { City } from '@/core';
import { Country, ProductVariantDto, TestPackageDto } from '@/store/webApi';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface SearchDetailsState {
  lastVisitedFlow?: string;
  accessibilityNeeds: boolean;
  city?: City;
  country?: Country;
  productVariant?: ProductVariantDto;
  testPackage?: TestPackageDto;
  selectedDate?: DateWithTimeZone;
}

const initialState: SearchDetailsState = {
  accessibilityNeeds: false,
  selectedDate: undefined,
  city: undefined,
};

export const searchDetailsSlice = createSlice({
  name: 'searchDetails',
  initialState,
  reducers: {
    /** Reset slice when user enters new flow */
    setLastVisitedFlow: (state, { payload }: PayloadAction<string | undefined>): SearchDetailsState => {
      if (state.lastVisitedFlow?.toLocaleLowerCase() !== payload?.toLocaleLowerCase()) {
        return { ...initialState, lastVisitedFlow: payload };
      }
      return state;
    },
    setAccessibilityNeeds: (state, { payload }: PayloadAction<boolean>): void => {
      state.accessibilityNeeds = payload;
    },
    setCountry: (state, { payload: { id, isoCode, name } }: PayloadAction<Country>): void => {
      state.country = { id, isoCode, name };
      state.productVariant = undefined;
      state.testPackage = undefined;
      state.city = undefined;
      state.selectedDate = undefined;
    },
    setProductVariant: (state, { payload }: PayloadAction<ProductVariantDto>): void => {
      state.productVariant = payload;
      state.testPackage = undefined;
      state.city = undefined;
      state.selectedDate = undefined;
    },
    setTestPackage: (state, { payload }: PayloadAction<TestPackageDto | undefined>): void => {
      state.testPackage = payload;
      state.city = undefined;
      state.selectedDate = undefined;
    },
    setCity: (state, { payload }: PayloadAction<City>): void => {
      state.city = payload;
      state.selectedDate = undefined;
    },
    setSelectedDate: (state, { payload }: PayloadAction<DateWithTimeZone | undefined>): void => {
      state.selectedDate = payload;
    },
    setSearchDetails: (state, { payload }: PayloadAction<SearchDetailsState>): void => {
      state.city = payload.city;
      state.country = payload.country;
      state.productVariant = payload.productVariant;
      state.selectedDate = payload.selectedDate;
      state.testPackage = payload.testPackage;
    },
    /** Preserve values for keys passed in payload array and set all others to initial values. */
    partialReset: (state, { payload }: PayloadAction<(keyof SearchDetailsState)[]>): SearchDetailsState => {
      return payload.reduce((acc, key) => ({ ...acc, [key]: state[key] }), initialState);
    },
    reset: (): SearchDetailsState => initialState,
  },
});
