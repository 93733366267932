import { FC } from 'react';

import { ComponentWrapper, RadioGroupSelectionChange } from '@/components';
import { MinorPickUp } from '@/store/webApi';
import { RadioGroup } from '@/components/html-elements/form-specific/radio-group/RadioGroup';

interface MinorPickupProps {
  minorPickupLabel: string;
  minorPickupType: MinorPickUp;
  noPickupLabel: string;
  parentPickupLabel: string;
  otherPickupLabel: string;
  onChange: RadioGroupSelectionChange;
}

export const MinorPickup: FC<MinorPickupProps> = ({
  minorPickupLabel,
  minorPickupType,
  noPickupLabel,
  parentPickupLabel,
  otherPickupLabel,
  onChange,
}): JSX.Element => {
  return (
    <ComponentWrapper id="pickup-choice">
      <RadioGroup
        addBorder
        id="minor-pickup-choice"
        label={minorPickupLabel}
        name="minor-pickup-choice"
        onChoiceChange={onChange}
        radios={[
          { id: '1', label: noPickupLabel, required: true },
          { id: '2', label: parentPickupLabel, required: true },
          { id: '3', label: otherPickupLabel, required: true },
        ]}
        value={minorPickupType.toString()}
      />
    </ComponentWrapper>
  );
};
