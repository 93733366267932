import styled from '@emotion/styled';

export const SkipLink = styled.a`
  &:focus {
    background-color: var(--skip-background);
    border: 2px solid var(--skip-color);
    border-radius: var(--button-border-radius);
    clip: unset;
    color: var(--skip-color);
    height: unset;
    left: 50%;
    margin: 5px auto;
    overflow: unset;
    padding: var(--whitespace-basic-12);
    transform: translateX(-50%);
    width: auto;
    z-index: 1000;
  }
`;
