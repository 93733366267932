import { FC } from 'react';

import * as S from './Copy.style';

interface CopyProps {
  copy: Array<string>;
}

export const Copy: FC<CopyProps> = ({ copy }): JSX.Element => {
  return (
    <S.Copy>
      {copy.map((copyItem, index) => (
        <li key={`p-${index}`}>
          <p>{copyItem}</p>
        </li>
      ))}
    </S.Copy>
  );
};
