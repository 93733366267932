import { FC } from 'react';

import { IconProps } from '@/icons/IconProps';
import { noAction } from '@/core';

export const EyeOff: FC<IconProps> = ({ index = 0, onClick = noAction }) => {
  return (
    <svg
      aria-hidden="true"
      aria-labelledby={`eye-off-icon-title-${index}`}
      className="eye eye-off-icon"
      data-testid={`eye-off-icon-${index}`}
      fill="none"
      focusable="false"
      onClick={onClick}
      role="img"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`eye-off-icon-title-${index}`}></title>
      <path
        d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <line x1="1" y1="1" x2="23" y2="23" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
    </svg>
  );
};
