import { PropsWithChildren } from 'react';

import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';

import { useAppSettingsContext } from '@/core';

export const OidcProvider = ({ children }: PropsWithChildren) => {
  const { oidc } = useAppSettingsContext();

  const oidcConfig: AuthProviderProps = {
    ...oidc,
    redirect_uri: window.location.origin + '/auth-callback',
    onSigninCallback: () => {
      window.history.replaceState({}, document.title, window.location.pathname);
    },
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    scope: 'openid profile email bc.registration-platform-b2c.api',
  };

  return <AuthProvider {...oidcConfig}>{children}</AuthProvider>;
};
