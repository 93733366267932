import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Label, SelectBaseOptionReg, SelectPropsReg, SelectReg } from '@britishcouncil/react-registration-platform';

import { CustomerMinor } from '@/store/webApi';

type Props = {
  minors?: CustomerMinor[];
  onMinorChange: (id: string) => void;
};

export const ChooseMinor = ({ minors = [], onMinorChange }: Props) => {
  const { t } = useTranslation('');

  const options = useMemo(() => {
    const addNew = {
      value: 'new',
      label: t('minor-details.choose-minor.add-new'),
    };

    const opts = minors.map((minor) => ({
      value: minor.customerId ?? -1,
      label: `${minor.firstName} ${minor.middleName} ${minor.lastName}`.replace('null', ''),
    }));

    return [...(opts ?? []), addNew];
  }, [minors, t]);

  const onChange: Pick<SelectPropsReg<SelectBaseOptionReg>, 'onChange'>['onChange'] = (option) => {
    if (option && 'value' in option) {
      onMinorChange(String(option.value));
    }
  };

  if (!minors.length) {
    return null;
  }

  return (
    <div>
      <Label placeholder="">{t('minor-details.choose-minor.label')}</Label>
      <SelectReg options={options} placeholder={t('minor-details.choose-minor.placeholder')} onChange={onChange} />
    </div>
  );
};
