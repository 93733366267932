import { useAppSelector } from '@/core';
import { useProductConfig } from '@/core/hooks/useProductConfig';
import { useSessionGetSessionsForCityTestPackageQuery } from '@/store/webApi';

export const useSearchAvailableSessions = () => {
  const searchDetails = useAppSelector((s) => s.searchDetails);
  const { productConfig } = useProductConfig();

  const result = useSessionGetSessionsForCityTestPackageQuery(
    {
      cityId: searchDetails.city?.id ?? -1,
      countryId: searchDetails.country?.id ?? -1,
      dateFrom: searchDetails.selectedDate ? getUtcStartDayDateFromLocal(searchDetails.selectedDate.localDate, searchDetails.selectedDate.dateOffset) : '',
      dateTo: searchDetails.selectedDate ? getUtcEndDayDateFromLocal(searchDetails.selectedDate.localDate, searchDetails.selectedDate.dateOffset) : '',
      productCode: productConfig?.productCode ?? '',
      productVariantId: searchDetails.productVariant?.id ?? '',
      testPackageId: searchDetails.testPackage?.id ?? '',
    },
    { skip: !searchDetails.city || !searchDetails.selectedDate },
  );

  return result;
};

const getUtcStartDayDateFromLocal = (date: Date, offset: number) => {
  const localDate = new Date(date);
  const utcStartDate = new Date(Date.UTC(localDate?.getFullYear(), localDate.getMonth(), localDate.getDate()));
  utcStartDate.setHours(utcStartDate.getHours() - offset);
  return utcStartDate.toISOString();
};

const getUtcEndDayDateFromLocal = (date: Date, offset: number) => {
  const localDate = new Date(date);
  const utcEndDate = new Date(Date.UTC(localDate?.getFullYear(), localDate.getMonth(), localDate.getDate(), 23, 59, 59, 59));
  utcEndDate.setHours(utcEndDate.getHours() - offset);
  return utcEndDate.toISOString();
};
