import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { GUID, createGuid } from '@/core';

import { ErrorMessage, ErrorMessageType } from './ErrorMessageList';

interface CreateFormErrorParams {
  icon?: EmotionJSX.Element;
  id?: GUID;
  isFormError?: boolean;
  isNotError?: boolean;
  isSuccess?: boolean;
  text: string;
  type: ErrorMessageType;
}

export const createErrorMessage = ({ text, type, icon, id, isFormError, isNotError, isSuccess }: CreateFormErrorParams): ErrorMessage => {
  const _id = id || createGuid();
  return { icon, id: _id, isFormError, isNotError, isSuccess, text, type };
};

export const dateErrorId = '1d88f235-cd23-4571-8d45-bba2e6c0c1d7' as GUID;
export const pasteErrorId = '475fd9c0-18c4-4360-9d2e-15471b2817a3' as GUID;
export const requiredId = '5b2b4c06-91f0-4320-ab4c-34e11465a795' as GUID;
export const telephoneErrorID = '166aed8d-8987-4786-98cc-89b1e220d84e' as GUID;
