import { useEffect } from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Alert, Footer, Loading, Menu, ScrollToTop } from '@/components';
import { searchDetailsSlice, setSessionTime } from '@/store';
import { useAppDispatch, useAppParams } from '@/core';
import { useProductConfig } from '@/core/hooks/useProductConfig';

import { AlertRefreshSessionTimeout } from '../app-specific';

export const RootLayout = () => {
  const { t } = useTranslation();
  const { data: allProductsConfigs, isLoading, isError } = useProductConfig();
  const dispatch = useAppDispatch();
  const { productCode } = useAppParams();
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    productCode && dispatch(searchDetailsSlice.actions.setLastVisitedFlow(productCode));
  }, [dispatch, productCode]);

  useEffect(() => {
    dispatch(setSessionTime(new Date().getTime()));
  }, [dispatch, location]);

  if (allProductsConfigs?.length) {
    const productConfig = allProductsConfigs.find((product: TODO) => product.productCode?.toLowerCase() === productCode?.toLowerCase());

    if (productCode && !productConfig) {
      navigate('/');
      return null;
    }
  }

  if (isError) {
    return (
      <div className="max-w-fit mx-auto mt-[50px]">
        <Alert show alertType="error">
          {t('book-test.product-config-error')}
        </Alert>
      </div>
    );
  }

  return (
    <div className="grid min-h-screen grid-rows-[auto_1fr_auto;]">
      <ScrollToTop />
      <AlertRefreshSessionTimeout />
      {!isError && (
        <>
          <Menu />
          <main>
            <div id="basket-mobile-opener" className="sticky top-0 w-full bg-white z-20" />
            {isLoading ? <Loading /> : <Outlet />}
          </main>
          <Footer
            copy={[t('copy.copyright'), t('copy.description'), t('copy.registration')]}
            skipId="footer-skip"
            skipText={t('skip-links.footer')}
            skipTo="navigation-skip"
          />
        </>
      )}
    </div>
  );
};
