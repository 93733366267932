import { useCallback, useEffect, useState } from 'react';

import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AddressDetails } from '@/pages/book-test/step-1-personal-details/legacy/AddressDetails';
import { AlertReg } from '@/components/presentation/alerts/AlertReg';
import { Button, ButtonStyle, ButtonsWrapper, FlowIndicator, Form, FormSection, FormSectionTitle, PageFormWrapper, PageTitle, Step } from '@/components';
import { FlowSteps, getTotalSteps, isMobileTrack, useAppDispatch, useAppParams, useAppSelector } from '@/core';
import { contactDetailsSlice, getContactDetails, getPersonalDetails, getReturnToReviewFrom, personalDetailsSlice } from '@/store';

import { BookingForDetails } from '../components/BookingForDetails';

import { CandidatePersonalDetails } from './CandidatePersonalDetails';
import { ContactDetails } from './ContactDetails';
import { MarketingPreferencesDetails } from './MarketingPreferencesDetails';
import { PersonalDetailsFormType, usePersonalDetailsForm } from './usePersonalDetailsForm';
import { useAnalytics } from './useAnalytics';
import { usePageNavigation } from './usePageNavigation';

//To delete after identity release
export const PersonalDetailsLegacy = () => {
  const { t } = useTranslation('');
  const { t: tRoutes } = useTranslation('routes');

  const { step } = useAppParams();
  const [currentStep, setCurrentStep] = useState(step);
  const [displayCurrentStep, setDisplayCurrentStep] = useState(0);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { submitEvents } = useAnalytics();
  const contactDetails = useSelector(getContactDetails);
  const personalDetails = useSelector(getPersonalDetails);
  const { isChild } = useAppSelector((s) => s.journey);

  const methods = usePersonalDetailsForm();
  const goToNextStep = usePageNavigation({ currentStep, isChild: isChild });

  useEffect(() => {
    setCurrentStep(step);
    setDisplayCurrentStep(!isMobileTrack(window.innerWidth) || currentStep === FlowSteps.flowStepForDesktopPath ? 1 : parseInt(currentStep ?? '0') - 1);
  }, [currentStep, step]);

  const returnToReviewFrom = useSelector(getReturnToReviewFrom);

  const onSubmit: SubmitHandler<PersonalDetailsFormType> = (data) => {
    submitEvents(personalDetails, contactDetails);
    const mobileNumber = (data.mobileNumber?.startsWith('+') ? data.mobileNumber : `+${data.mobileDialCode}${data.mobileNumber}`).replaceAll(' ', '');

    dispatch(
      contactDetailsSlice.actions.setContactDetails({
        mobileNumber,
        postalLine01: data.address.addressLine1,
        postalLine02: data.address.addressLine2,
        postalTownCity: data.address.city,
        postalZipCode: data.address.postCode,
        countryId: data.address.countryId,
      }),
    );

    const dateOfBirth =
      data?.dateOfBirth?.day && data?.dateOfBirth?.month >= 0 && data?.dateOfBirth?.year
        ? new Date(Date.UTC(data?.dateOfBirth?.year ?? 0, data?.dateOfBirth?.month, data?.dateOfBirth?.day)).toISOString().split('T')[0]
        : '';

    dispatch(
      personalDetailsSlice.actions.setPersonalDetails({
        firstName: data.firstName,
        lastName: data.lastName,
        dateOfBirth: dateOfBirth,
        gender: data.gender,
        genderOther: data.gender !== 0 ? data.otherGenders : undefined,
        marketingPreference: data.marketingPreference,
      }),
    );

    goToNextStep();
  };

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const totalSteps = getTotalSteps(window.innerWidth, isChild);

  return (
    <div className="personal-details">
      <header className="header">
        <FlowIndicator
          currentFormStep={displayCurrentStep}
          currentStep={FlowSteps.sessionSelectionFinished}
          formSteps={totalSteps}
          steps={FlowSteps.totalStepsForSessionSelection}
        ></FlowIndicator>

        {isMobileTrack(window.innerWidth) && currentStep !== '1' && (
          <Step bottomMargin={'var(--whitespace-basic-08)'}>
            {t('general-step', {
              stepNumber: displayCurrentStep.toString(),
              stepsTotal: totalSteps,
            })}
          </Step>
        )}
        {(currentStep === FlowSteps.flowStepForDesktopPath || currentStep === '1') && (
          <>
            <PageTitle marginBottom={'var(--whitespace-basic-32)'} hideOn="mobile" text={tRoutes('book-new-test.personal-details')} />
            <PageTitle marginBottom={'var(--whitespace-basic-32)'} hideOn="tablet" text={tRoutes('book-new-test.personal-details-mobile')} />
          </>
        )}
        {!isMobileTrack(window.innerWidth) && currentStep === '0' && (
          <Step bottomMargin={'var(--whitespace-32-to-72)'}>
            {t('general-step', {
              stepNumber: displayCurrentStep.toString(),
              stepsTotal: totalSteps,
            })}
          </Step>
        )}
      </header>
      <FormProvider {...methods}>
        <PageFormWrapper>
          <Form noValidate id="personal-details" onSubmit={methods.handleSubmit(onSubmit)}>
            {(currentStep === FlowSteps.flowStepForDesktopPath || currentStep === FlowSteps.flowStepForMobilePath) && (
              <FormSection gap="var(--whitespace-basic-32)">
                <AlertReg
                  variant="information"
                  title={t('common.please-note')}
                  content={
                    <div className="flex flex-col gap-y-3">
                      <div>{t('personal-details.country-requirements')}</div>
                      <div>
                        <ul className="list-disc list-inside">
                          <li>{t('personal-details.country-requirements-one')}</li>
                        </ul>
                      </div>
                    </div>
                  }
                />
                <BookingForDetails />
              </FormSection>
            )}
            {(currentStep === FlowSteps.flowStepForDesktopPath || currentStep === '2') && (
              <FormSection>
                <FormSectionTitle>{isChild ? t('personal-details.about-you.title-child') : t('personal-details.about-you.title')}</FormSectionTitle>
                <AlertReg variant="information" title={t('common.please-note')} content={t('personal-details.about-you.note-self')} visible={!isChild} />
                <CandidatePersonalDetails />
              </FormSection>
            )}
            {(currentStep === FlowSteps.flowStepForDesktopPath || currentStep === '3') && (
              <FormSection>
                <FormSectionTitle>{t('personal-details.contact-details.title')}</FormSectionTitle>
                <ContactDetails />
              </FormSection>
            )}
            {(currentStep === FlowSteps.flowStepForDesktopPath || currentStep === '4') && (
              <FormSection>
                <FormSectionTitle>{t('personal-details.address-details.title')}</FormSectionTitle>
                <AddressDetails />
              </FormSection>
            )}

            {(currentStep === FlowSteps.flowStepForDesktopPath || currentStep === '3') && (
              <FormSection>
                <FormSectionTitle>{t('personal-details.marketing-preferences.title')}</FormSectionTitle>
                <MarketingPreferencesDetails />
              </FormSection>
            )}
            <ButtonsWrapper>
              <Button addArrow="left" buttonStyle={ButtonStyle.Secondary} onClick={goBack} text={t('components.back-button')} />

              <Button
                addArrow="right"
                buttonStyle={ButtonStyle.Primary}
                text={t(returnToReviewFrom ? 'tests-and-results.upcoming.save-and-review' : 'personal-details.cta')}
                type="submit"
              />
            </ButtonsWrapper>
          </Form>
        </PageFormWrapper>
      </FormProvider>
    </div>
  );
};
