import { useContext, useRef } from 'react';

import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { addMonths, lastDayOfMonth, startOfMonth } from 'date-fns';

import { cn } from '@/core/helpers/cn';

export const LeftArrow = () => {
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);
  return <FiChevronLeft size={24} onClick={() => scrollPrev()} className={cn('text-blue-dark cursor-pointer', { 'text-grey-light': isFirstItemVisible })} />;
};

type Props = {
  updateRange: () => void;
  chunkSize: number;
};

export const RightArrow = ({ updateRange, chunkSize }: Props) => {
  const nextUpdate = useRef(startOfMonth(addMonths(new Date(), chunkSize)));

  const { isLastItemVisible, scrollNext, visibleElements } = useContext(VisibilityContext);

  if (visibleElements.some((el) => lastDayOfMonth(new Date(el)) > nextUpdate.current)) {
    nextUpdate.current = startOfMonth(addMonths(nextUpdate.current, chunkSize));
    updateRange();
  }

  return <FiChevronRight size={24} onClick={() => scrollNext()} className={cn('text-blue-dark cursor-pointer', { 'text-grey-light': isLastItemVisible })} />;
};
