import { FC, useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Alert,
  AppExamAndCancellationPolicy,
  AppOrderAcknowledgement,
  AppReviewChildNameDob,
  AppReviewContactDetails,
  AppReviewIdentification,
  AppReviewNameDob,
  AppReviewPayment,
  Button,
  ButtonStyle,
  ButtonsWrapper,
  ComponentWrapper,
  FlowIndicator,
  FormSection,
  H5Wrapper,
  PageFormWrapper,
  PageTitle,
  ReviewGridWrapper,
  ReviewTitle,
  Step,
} from '@/components';
import {
  FlowSteps,
  bookTestIdentificationRoute,
  bookTestMinorDetailsRoute,
  bookTestMinorIdentificationRoute,
  bookTestPersonalDetailsRoute,
  isMobileOrTabletTrack,
  isMobileTrack,
  params,
  useAppParams,
  useAppSelector,
  useDocumentTitle,
} from '@/core';
import { PayBtnLegacy } from '@/components/basket/Actions/legacy/PayBtnLegacy';
import { setReturnToReviewFrom } from '@/store';
import { useProductConfig } from '@/core/hooks/useProductConfig';

import { useUtils } from '../useUtils';

export const ReviewBooking: FC = (): JSX.Element => {
  const { t } = useTranslation('');
  const { t: tRoutes } = useTranslation('routes');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productCode, step } = useAppParams();
  const { productConfig } = useProductConfig();

  const { activeFlowStep, totalSteps } = useUtils();

  useDocumentTitle(`${tRoutes('book-new-test.title')} - ${tRoutes('book-new-test.review')}`);

  const { contactDetails, personalDetails, orderAcknowledgementDetails, journey } = useAppSelector((s) => s);

  const [currentStep, setCurrentStep] = useState(step);
  useEffect(() => {
    setCurrentStep(step);
  }, [step]);

  const handleChangeIdentificationDetails = useCallback(() => {
    const route = journey.isChild ? bookTestMinorIdentificationRoute : bookTestIdentificationRoute;
    dispatch(setReturnToReviewFrom(route.absolutePath));
    navigate(
      route.absolutePath
        .replace(params.productCode, productCode || '')
        .replace(params.step, currentStep !== FlowSteps.flowStepForDesktopPath ? FlowSteps.flowStepForMobilePath : FlowSteps.flowStepForDesktopPath),
    );
  }, [currentStep, dispatch, navigate, journey.isChild, productCode]);

  const handleChangeChildDetails = useCallback(() => {
    dispatch(setReturnToReviewFrom(bookTestMinorDetailsRoute.absolutePath));
    navigate(
      bookTestMinorDetailsRoute.absolutePath
        .replace(params.productCode, productCode || '')
        .replace(params.step, currentStep !== FlowSteps.flowStepForDesktopPath ? FlowSteps.flowStepForMobilePath : FlowSteps.flowStepForDesktopPath),
    );
  }, [currentStep, dispatch, navigate, productCode]);

  const handleChangePersonalDetails = useCallback(
    (target?: string) => {
      dispatch(setReturnToReviewFrom(bookTestPersonalDetailsRoute.absolutePath));
      navigate(
        bookTestPersonalDetailsRoute.absolutePath
          .replace(params.productCode, productCode || '')
          .replace(
            params.step,
            currentStep !== FlowSteps.flowStepForDesktopPath
              ? target
                ? target
                : FlowSteps.flowStepForMobilePersonalDetailsPath
              : FlowSteps.flowStepForDesktopPath,
          ),
      );
    },
    [currentStep, dispatch, navigate, productCode],
  );

  return (
    <section className="identification">
      <header className="header">
        <FlowIndicator
          currentFormStep={activeFlowStep}
          currentStep={FlowSteps.sessionSelectionFinished}
          formSteps={totalSteps}
          steps={FlowSteps.totalStepsForSessionSelection}
        ></FlowIndicator>
        {isMobileTrack(window.innerWidth) && (
          <Step>
            {t('general-step', {
              stepNumber: `${activeFlowStep}`,
              stepsTotal: `${totalSteps}`,
            })}
          </Step>
        )}
        <PageTitle marginBottom={'var(--whitespace-32-to-08)'} text={tRoutes('book-new-test.review')} />
        {!isMobileTrack(window.innerWidth) && (
          <Step bottomMargin={'var(--whitespace-32-to-72)'}>
            {t('general-step', {
              stepNumber: `${activeFlowStep}`,
              stepsTotal: `${totalSteps}`,
            })}
          </Step>
        )}
      </header>
      <PageFormWrapper>
        <div id="identification" className="flex flex-col gap-[24px]">
          <Alert alertType="information" show text={t('review.alert.not-booked-yet')} title={t('common.please-note')}>
            <p>{t('review.alert.please-check')}</p>
          </Alert>
          <FormSection hideOn="mobile">
            <ReviewTitle>
              <H5Wrapper className="section-title">
                <h5>{t('review.your-details')}</h5>
              </H5Wrapper>
              <Button
                addIcon="edit"
                buttonStyle={ButtonStyle.Discrete}
                className="change-button"
                noPadding
                text={t('common.edit')}
                onClick={() => handleChangePersonalDetails()}
              />
            </ReviewTitle>
            <ComponentWrapper id="personal-details" addBackground rowSpacing="0">
              <ReviewGridWrapper>
                <AppReviewNameDob dateOfBirth={personalDetails.dateOfBirth} name={`${personalDetails.firstName} ${personalDetails.lastName}`} />
                <AppReviewContactDetails
                  countryId={contactDetails.countryId}
                  email={personalDetails.email}
                  mobileNumber={contactDetails.mobileNumber}
                  postalLine01={contactDetails.postalLine01}
                  postalLine02={contactDetails.postalLine02}
                  postalTownCity={contactDetails.postalTownCity}
                  postalZipCode={contactDetails.postalZipCode}
                />
              </ReviewGridWrapper>
            </ComponentWrapper>
          </FormSection>

          <FormSection hideOn="tablet">
            <ReviewTitle>
              <H5Wrapper className="section-title">
                <h5>{t('review.your-personal')}</h5>
              </H5Wrapper>
              <Button
                addIcon="edit"
                buttonStyle={ButtonStyle.Discrete}
                className="change-button"
                noPadding
                text={t('common.edit')}
                onClick={() => handleChangePersonalDetails()}
              />
            </ReviewTitle>
            <ComponentWrapper id="personal-details" addBackground>
              <ReviewGridWrapper>
                <AppReviewNameDob dateOfBirth={personalDetails.dateOfBirth} name={`${personalDetails.firstName} ${personalDetails.lastName}`} />
              </ReviewGridWrapper>
            </ComponentWrapper>
          </FormSection>

          <FormSection hideOn="tablet">
            <ReviewTitle>
              <H5Wrapper className="section-title">
                <h5>{t('review.your-contact')}</h5>
              </H5Wrapper>
              <Button
                addIcon="edit"
                buttonStyle={ButtonStyle.Discrete}
                className="change-button"
                noPadding
                text={t('common.edit')}
                onClick={() => handleChangePersonalDetails(FlowSteps.flowStepForMobileEmailPath)}
              />
            </ReviewTitle>
            <ComponentWrapper id="personal-details" addBackground rowSpacing="0">
              <ReviewGridWrapper>
                <AppReviewContactDetails
                  countryId={contactDetails.countryId}
                  email={personalDetails.email}
                  hideAddress
                  mobileNumber={contactDetails.mobileNumber}
                  postalLine01={contactDetails.postalLine01}
                  postalLine02={contactDetails.postalLine02}
                  postalTownCity={contactDetails.postalTownCity}
                  postalZipCode={contactDetails.postalZipCode}
                />
              </ReviewGridWrapper>
            </ComponentWrapper>
          </FormSection>

          <FormSection hideOn="tablet">
            <ReviewTitle>
              <H5Wrapper className="section-title">
                <h5>{t('review.your-address')}</h5>
              </H5Wrapper>
              <Button
                addIcon="edit"
                buttonStyle={ButtonStyle.Discrete}
                className="change-button"
                noPadding
                text={t('common.edit')}
                onClick={() => handleChangePersonalDetails(FlowSteps.flowStepForMobileAddressPath)}
              />
            </ReviewTitle>
            <ComponentWrapper id="personal-details" addBackground rowSpacing="0">
              <ReviewGridWrapper>
                <AppReviewContactDetails
                  countryId={contactDetails.countryId}
                  email={personalDetails.email}
                  hideEmail
                  hideTelephone
                  mobileNumber={contactDetails.mobileNumber}
                  postalLine01={contactDetails.postalLine01}
                  postalLine02={contactDetails.postalLine02}
                  postalTownCity={contactDetails.postalTownCity}
                  postalZipCode={contactDetails.postalZipCode}
                />
              </ReviewGridWrapper>
            </ComponentWrapper>
          </FormSection>

          {journey.isChild && (
            <FormSection>
              <ReviewTitle>
                <H5Wrapper className="section-title">
                  <h5>{tRoutes('book-new-test.minor-details')}</h5>
                </H5Wrapper>
                <Button
                  addIcon="edit"
                  buttonStyle={ButtonStyle.Discrete}
                  className="change-button"
                  noPadding
                  text={t('common.edit')}
                  onClick={handleChangeChildDetails}
                />
              </ReviewTitle>
              <ComponentWrapper id="child-identification-details" addBackground rowSpacing="0">
                <ReviewGridWrapper>
                  <AppReviewChildNameDob />
                </ReviewGridWrapper>
              </ComponentWrapper>
            </FormSection>
          )}
          <FormSection>
            <ReviewTitle>
              <H5Wrapper className="section-title">
                <h5>{journey.isChild ? t('review.identification.title-minor') : t('review.identification.title')}</h5>
              </H5Wrapper>
              <Button
                addIcon="edit"
                buttonStyle={ButtonStyle.Discrete}
                className="change-button"
                noPadding
                text={t('common.edit')}
                onClick={handleChangeIdentificationDetails}
              />
            </ReviewTitle>
            <ComponentWrapper id="identification-details" addBackground>
              <AppReviewIdentification isChild={journey.isChild} />
            </ComponentWrapper>
          </FormSection>
          <FormSection>
            <H5Wrapper className="section-title">
              <h5>{t('review.payment.title')}</h5>
            </H5Wrapper>
            <ComponentWrapper id="payment-details" addBackground>
              <AppReviewPayment />
            </ComponentWrapper>
            {productConfig?.supportedFeatures?.includes('OrderAcknowledgement') && (
              <AppOrderAcknowledgement orderAcknowledgement={orderAcknowledgementDetails.orderAcknowledgement} />
            )}
          </FormSection>
          <FormSection>
            <H5Wrapper className="section-title">
              <h5>{t('review.consent.title')}</h5>
            </H5Wrapper>
            <AppExamAndCancellationPolicy />
          </FormSection>

          <ButtonsWrapper>
            <Button addArrow="left" buttonStyle={ButtonStyle.Secondary} onClick={() => navigate(-1)} text={t('components.back-button')} />
            {isMobileOrTabletTrack(window.innerWidth) && <PayBtnLegacy />}
          </ButtonsWrapper>
        </div>
      </PageFormWrapper>
    </section>
  );
};
