import axios, { AxiosRequestConfig } from 'axios';
import { User } from 'oidc-client-ts';

import { loadAppSettings } from '@/core';

export const webApiClient = axios.create({
  baseURL: `/`,
});

webApiClient.interceptors.request.use(async function (config) {
  return authInterceptor(config);
});

const authInterceptor = async (config: AxiosRequestConfig) => {
  if (!config.headers) {
    config.headers = {};
  }

  const { oidc } = await loadAppSettings();
  const oidcStorage = localStorage.getItem(`oidc.user:${oidc.authority}:${oidc.client_id}`);

  if (!oidcStorage) {
    return config;
  }

  const user = User.fromStorageString(oidcStorage);
  config.headers.Authorization = 'Bearer ' + user?.access_token;

  return config;
};
