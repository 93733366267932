import { FC } from 'react';

import { IconProps } from '@/icons/IconProps';

export const CheckPlain: FC<IconProps> = ({ index = 0 }) => {
  return (
    <svg
      aria-hidden="true"
      aria-labelledby={`check-plain-icon-title-${index}`}
      className="feather feather-check"
      height="24"
      fill="none"
      focusable="false"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
    >
      <polyline points="20 6 9 17 4 12"></polyline>
    </svg>
  );
};
