import { useMemo, useState } from 'react';

import { Navigate, useOutlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AuthGuard, CustomerSelect, CustomerSelectPayload, Loading, NavigationDeck, PageTitle } from '@/components';
import { useFeatureFlags } from '@/core';

import { AlertReg } from '../presentation/alerts/AlertReg';

export const DashboardLayout = () => {
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerSelectPayload | null>();
  const { t } = useTranslation();
  const { data: featureFlags, isLoading: areFeatureFlagsLoading } = useFeatureFlags();
  const outlet = useOutlet(selectedCustomer);

  const routes = useMemo(
    () => [
      {
        name: t('dashboard.tabs.your-tests'),
        value: '/dashboard/tests',
      },
      {
        name: t('dashboard.tabs.your-data'),
        value: '/dashboard/data',
      },
    ],
    [t],
  );

  if (areFeatureFlagsLoading) {
    return <Loading />;
  }

  if (!featureFlags?.customerPortalFeature) {
    return <Navigate to="/account" />;
  }

  return (
    <AuthGuard>
      <NavigationDeck routes={routes} />
      <div className="flex w-full h-full py-8 max-w-7xl mx-auto p-2">
        <div className="w-full mx-auto">
          <PageTitle main text={t('dashboard.title')} marginBottom="32px" />
          <div className="grid lg:grid-cols-3 grid-cols-1 pb-4">
            <AlertReg variant="information" visible={false} title={t('common.please-note')} content={t('dashboard.alerts.resit')} />
          </div>
          <CustomerSelect title={t('dashboard.select-tests-for-person.label')} customerChange={setSelectedCustomer}  selectCustomer={selectedCustomer}/>

          {outlet || <Navigate to="/dashboard/tests" />}
        </div>
      </div>
    </AuthGuard>
  );
};
