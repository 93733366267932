import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const getDateStringLong = (date: Date) => {
  return `${format(date, 'dd')} ${format(date, 'MMMM')} ${format(date, 'yyyy')}`;
};

export const getZonedTime = (date?: string, timeZone?: string | null): string => {
  if (!date || !timeZone) {
    return '-';
  }

  const zonedTime = utcToZonedTime(date, timeZone);
  return new Intl.DateTimeFormat('en-GB', { hour: '2-digit', minute: '2-digit', hour12: true }).format(zonedTime).toUpperCase();
};

export const getDateInTimezone = (date?: string, timeZone?: string | null): string => {
  if (!date || !timeZone) {
    return '-';
  }

  const zonedTime = utcToZonedTime(date, timeZone);
  return new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: 'long', day: 'numeric' }).format(zonedTime);
};
