import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ContactDetailsState {
  mobileNumber: string;
  countryId?: number;
  postalLine01: string;
  postalLine02: string;
  postalTownCity: string;
  postalZipCode: string;
}

const initialState: ContactDetailsState = {
  mobileNumber: '',
  postalLine01: '',
  postalLine02: '',
  postalTownCity: '',
  postalZipCode: '',
};

export const contactDetailsSlice = createSlice({
  name: 'contactDetails',
  initialState,
  reducers: {
    clearContactDetails: (state): void => {
      delete state.countryId;
      state.postalLine01 = '';
      state.postalLine02 = '';
      state.postalTownCity = '';
      state.postalZipCode = '';
    },
    setContactCountryId: (state, { payload }: PayloadAction<number>): void => {
      state.countryId = payload;
    },
    setContactDetails: (state, { payload }: PayloadAction<Partial<ContactDetailsState>>): ContactDetailsState => {
      return { ...state, ...payload };
    },
  },
});
