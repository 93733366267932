import styled from '@emotion/styled';

// Reg. theme wrapper. TODO: Remove all old styles (based on "themed" classes and other strange things) and use current reg. theme from shared-components.
// After that - remove this file.
export const RegTheme = styled.div`
  h1 {
    font-family: BCSans, Arial, Helvetica, sans-serif;
    font-weight: 600;
    line-height: 130%;
    font-size: 48px;
    color: #23085a;
  }

  h2 {
    font-family: BCSans, Arial, Helvetica, sans-serif;
    font-weight: 600;
    line-height: 130%;
    font-size: 40px;
    color: #23085a;
  }

  h3 {
    font-family: Arial, BCSans, Helvetica, sans-serif;
    font-weight: 700;
    line-height: 130%;
    font-size: 32px;
    color: #23085a;
  }

  h4 {
    font-family: Arial, BCSans, Helvetica, sans-serif;
    font-weight: 700;
    line-height: 130%;
    font-size: 24px;
    color: #23085a;
  }

  h5 {
    font-family: Arial, BCSans, Helvetica, sans-serif;
    font-weight: 700;
    line-height: 130%;
    font-size: 20px;
    color: #23085a;
  }

  h6 {
    font-family: Arial, BCSans, Helvetica, sans-serif;
    font-weight: 700;
    line-height: 130%;
    font-size: 16px;
    color: #23085a;
  }

  a {
    font-weight: 700;
    line-height: 150%;
    font-size: 18px;
    textdecoration: underline;
    color: colors.blue_dark;
    cursor: pointer;
  }

  small {
    font-weight: 700;
    line-height: 150%;
    font-size: 14px;
  }

  strong {
    font-weight: 700;
  }
`;
