import { AxiosResponse } from 'axios';

const getFileName = (contentDispositionHeader: string) => {
  const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = regex.exec(contentDispositionHeader);
  const fileName = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'unknown';
  return fileName;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const downloadFile = (result: AxiosResponse<any, any>) => {
  /* Show "Save as..." prompt to the user */
  const type = result.headers['content-type'];
  const blob = new Blob([result.data], { type });

  const virtualDownloadLink = document.createElement('a');
  virtualDownloadLink.href = window.URL.createObjectURL(blob);

  virtualDownloadLink.download = getFileName(result.headers['content-disposition']);

  virtualDownloadLink.click();
  virtualDownloadLink.remove();
};
