import { css } from '@emotion/react';

export const dateInputStyles = css`
  .day {
    flex-grow: 1;
  }
  .disable-choice {
    flex-grow: 1;
  }
  .month {
    flex-grow: 2;
  }
  .year {
    flex-grow: 1;
  }
`;
