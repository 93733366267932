import { FiMapPin, FiTrash2 } from 'react-icons/fi';
import { matchPath, useLocation } from 'react-router-dom';

import { BasketItem } from '@/store';
import { formatPrice, useBasket } from '@/core';

import { Format } from './Format';
import { FormatDate } from './FormatDate';

export type ExamProps = {
  exam: BasketItem;
  i: number;
};

export const Exam = ({ exam, i }: ExamProps) => {
  const { removeSession } = useBasket();
  const location = useLocation();

  const handleDelete = (id: string) => {
    id && removeSession(id);
  };

  const isAtBookingCompleteRoute = !!matchPath('/:productCode/book-test/booking-complete', location.pathname);

  const dateGroups = [...new Set(exam.formats?.map((el) => `${el.startDate}${el.endDate}`))];
  return (
    <li key={exam.id} className="flex flex-col gap-[16px] mb-[32px]">
      <div className="text-[20px] font-bold text-indigo hidden md:block">{i + 1}.</div>
      <h5 className="justify-between flex items-center">
        <div className="pr-[12px] !text-[16px] md:!text-[20px]">
          <span className="pr-[5px] lg:hidden">{i + 1}.</span>
          {exam.name}
        </div>
        <div className="flex">
          <h5 className="block lg:hidden">{formatPrice(exam.price, exam.currency)}</h5>
          <div className="border-r border-grey-light ml-[32px]" />
          {!isAtBookingCompleteRoute && (
            <div className="px-[12px] relative w-[48px] h-[24px] " onClick={() => handleDelete(exam.id ?? '-1')}>
              <div className="h-[72px] absolute cursor-pointer left-0 top-[-24px] w-[100%] flex items-center justify-end">
                <FiTrash2 className="w-[24px] h-[24px] text-blue-dark cursor-pointer" />
              </div>
            </div>
          )}
        </div>
      </h5>
      <div className="flex items-start">
        <FiMapPin className="w-[24px] h-[24px] mr-[12px] text-grey-dark" />
        <div>
          <div className="!text-black-text font-bold text-[16px]">{exam.venuName}</div>
          <div className="!text-black-text font-bold text-[16px]">{exam.address}</div>
        </div>
      </div>
      <div className="pl-[36px]">
        {dateGroups.map((group) => {
          const exampleFormat = exam.formats?.find((el) => group === `${el.startDate}${el.endDate}`);
          return (
            <div key={group}>
              {exam.formats?.filter((el) => group === `${el.startDate}${el.endDate}`).map((el) => <Format format={el} key={el.type} />)}
              {exampleFormat && <FormatDate format={exampleFormat} timeZone={exam?.timeZone} />}
            </div>
          );
        })}
      </div>
      <div className="text-right border-t border-grey-light hidden lg:block">
        <p className="text-[32px] font-bold text-black mt-4">{formatPrice(exam.price, exam.currency)}</p>
      </div>
    </li>
  );
};
