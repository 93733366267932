import styled from '@emotion/styled';
import { breakpoints } from '@/core';

import { AlertType, alertTypeBackgroundColor, alertTypeIconColor } from './Alert';

export const Alert = styled.section<{ alertType: AlertType }>`
  background-color: var(${({ alertType }) => alertTypeBackgroundColor[alertType]});
  border-radius: var(--wrapped-border-radius);
  display: none;
  padding: var(--whitespace-basic-16) var(--whitespace-16-to-24);

  ul {
    list-style: disc;
    margin: 0 20px;
    li:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  svg {
    color: var(${({ alertType }) => alertTypeIconColor[alertType]});
    flex-shrink: 0;
    height: 20px;
    width: 20px;
    margin-top: 2px;
  }
`;

export const AlertDetail = styled.section`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: var(--whitespace-basic-8);

  ${() => breakpoints.isTablet.mediaMinWidth} {
    gap: var(--whitespace-basic-16);
  }
`;

export const AlertDetailRows = styled.section`
  display: flex;
  flex-direction: column;
  gap: var(--whitespace-basic-16);
`;

export const AlertHeading = styled.span`
  color: var(--heading-color);
  font-size: var(--h6-font-size);
  font-weight: 600;
  line-height: 26px;
`;

export const AlertNarrative = styled.section`
  display: flex;
  flex-direction: column;
  gap: var(--whitespace-12-to-20);
`;

export const AlertTitleLine = styled.section`
  display: flex;
  flex-direction: row;
  gap: var(--whitespace-basic-12);
`;
