import { css } from '@emotion/react';

import { breakpoints } from '../utils';

export const attributeGrid = css`
  .themed {
    --grid-display: revert;
    --grid-template-columns: none;

    ${breakpoints.isTablet.mediaMinWidth} {
      --grid-display: grid;
      --grid-template-columns: 1fr var(--main-content-width) 1fr;
    }
  }
`;
