import styled from '@emotion/styled';
import { Columns, PlaceholderMixin } from '@/components';
import { breakpoints } from '@/core/styles';

export const TextInput = styled.input`
  background-color: var(--component-background);
  border: solid 1px var(--border-color);
  border-radius: var(--component-border-radius);
  height: 48px;
  padding: 0 var(--whitespace-basic-16);
  ${PlaceholderMixin}
  width: 100%;

  &.error {
    border: solid 2px var(--border-color-error);
  }

  &:disabled {
    background-color: var(--background-color-disabled);
  }

  &:focus {
    box-shadow: none;
    border: solid 2px var(--dark-blue);
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${() => breakpoints.isTablet.mediaMinWidth} {
    height: 48px;
  }
`;

export const TextInputContainer = styled(Columns)`
  position: relative;
  width: 100%;

  @media (pointer: coarse), (pointer: none) {
    svg.chevron {
      display: none;
    }
  }

  svg.chevron {
    color: var(--component-icon);
    position: absolute;
    right: var(--whitespace-08-to-16);
    user-select: none;
    width: 25px;

    &.chevron-down-icon {
      bottom: 0;
    }

    &.chevron-up-icon {
      top: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }

  svg.eye {
    color: var(--component-icon);
    height: 100%;
    padding: 10px 0;
    position: absolute;
    right: 16px;
    top: 0;
    width: 48px;

    &:hover {
      cursor: pointer;
    }

    &.eye-icon {
      display: none;
    }
  }

  &.hide-default-icon {
    svg {
      &.eye-icon {
        display: block;
      }

      &.eye-off-icon {
        display: none;
      }
    }
  }

  ${() => breakpoints.isTablet.mediaMinWidth} {
    svg.chevron {
      position: absolute;
      width: 30px;
    }

    &.open {
      svg {
        &.eye-icon {
          width: 48px;
        }
        &.eye-off-icon {
          width: 48px;
        }
      }
    }
  }
`;
