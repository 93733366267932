import { useFeaturesGetAllFeaturesQuery } from '@/store/webApi';

/* Active feature flags must be defined manually as API type is too generic */
type FeatureFlags = {
  customerPortalFeature?: boolean;
  identityIntegrationFeature?: boolean;
  productConfigurationsFeature?: boolean;
};

export const useFeatureFlags = () => {
  const { data, ...rest } = useFeaturesGetAllFeaturesQuery();
  return { data: data as FeatureFlags | undefined, ...rest };
};
