import { FC, KeyboardEvent, useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Alert, ExternalLink, H6 } from '@/components';

import { FullMessageShowHide, FullMessageWrapper } from '../full-message-wrapper';

export const AlertExamAndCancellationPolicy: FC = (): JSX.Element => {
  const [hideFullNotice, setHideFullNotice] = useState(true);
  const { t } = useTranslation('');

  const handleShowHideKeyDown = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      if ([' ', 'Enter', 'SpaceBar'].includes(event.key)) setHideFullNotice(!hideFullNotice);
    },
    [hideFullNotice],
  );

  const toggleHideFullNotice = () => setHideFullNotice(!hideFullNotice);

  return (
    <Alert alertType="information" show text={t('review.consent.note')}>
      <FullMessageShowHide
        className="show-hide"
        data-testid="show-hide-full-message"
        onClick={toggleHideFullNotice}
        tabIndex={0}
        onKeyDown={handleShowHideKeyDown}
        role="button"
      >
        {hideFullNotice ? t('common.notices.show-full-notice') : t('common.notices.hide-full-notice')}
      </FullMessageShowHide>
      <FullMessageWrapper data-testid="full-notice" id="full-notice" className={hideFullNotice ? 'hide' : 'animated slideInDown'} role="alert">
        <H6>{t('review.consent.data-protection.title')}</H6>
        <p>{t('review.consent.data-protection.para-01')}</p>
        <p>{t('review.consent.data-protection.para-02')}</p>
        <p>
          {t('review.consent.data-protection.para-03-01')}{' '}
          <ExternalLink href="https://www.britishcouncil.org/privacy" id="privacy-link" target="_blank" text="www.britishcouncil.org/privacy"></ExternalLink>
          {t('review.consent.data-protection.para-03-02')}
        </p>
        <ExternalLink
          id="contact-us-external"
          href="https://www.britishcouncil.org/"
          {...(!hideFullNotice && { tabIndex: 0 })}
          target="_blank"
          text={t('personal-details.marketing-preferences.contact-us')}
        />
        <p>{t('personal-details.marketing-preferences.how-we-process')}</p>
      </FullMessageWrapper>
    </Alert>
  );
};
