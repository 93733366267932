import { useEffect } from 'react';

import { useAppParams } from '@/core';
import { useLazyProductsGetConfigurationsQuery } from '@/store/webApi';

/**
 * Custom hook to fetch products configurations data from the server. It uses the product code from the URL to pick proper config object.
 * @returns An object containing the product configuration, error and loading statuses.
 */
export const useProductConfig = () => {
  const { productCode } = useAppParams();
  const [trigger, result] = useLazyProductsGetConfigurationsQuery();

  useEffect(() => {
    if (productCode) {
      trigger();
    }
  }, [productCode, trigger]);

  const productConfig = result.data?.find((el) => el.productCode?.toLowerCase() === productCode?.toLowerCase());

  return { productConfig, ...result };
};
