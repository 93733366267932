import { FC, HTMLAttributes, useMemo } from 'react';

import { FiAlertCircle, FiCheckCircle, FiInfo } from 'react-icons/fi';

import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { createGuid } from '@/core';

import * as S from './AlertPopup.style';

interface AlertPopupProps {
  alertType: AlertPopupType;
  show: boolean;
  text: string;
  title?: string;
}

export const AlertPopup: FC<AlertPopupProps & HTMLAttributes<HTMLElement>> = ({ alertType, children, show, text, title, ...props }): JSX.Element => {
  return (
    <S.AlertPopup alertType={alertType} {...(show && { className: 'animated slideInDown' })} {...props}>
      <S.AlertPopupDetail>
        <AlertPopupTypeIcon alertType={alertType} index={createGuid()} />
        <S.AlertPopupNarrative>
          {title && <S.AlertPopupHeading>{title}</S.AlertPopupHeading>}
          {text}
          {children}
        </S.AlertPopupNarrative>
      </S.AlertPopupDetail>
    </S.AlertPopup>
  );
};

export type AlertPopupType = 'error' | 'information' | 'success' | 'warning';

type AlertPopupTypeToIcon = Record<AlertPopupType, EmotionJSX.Element>;

interface AlertPopupTypeIconProps {
  alertType?: AlertPopupType;
  index: string;
}

const AlertPopupTypeIcon: FC<AlertPopupTypeIconProps> = ({ alertType }): JSX.Element => {
  const alertTypeToIcon: AlertPopupTypeToIcon = useMemo(
    () => ({
      error: <FiAlertCircle />,
      information: <FiInfo />,
      success: <FiCheckCircle />,
      warning: <FiAlertCircle />,
    }),
    [],
  );

  return alertType ? alertTypeToIcon[alertType] : <></>;
};

type AlertPopupTypeBackgroundColor = Record<AlertPopupType, string>;

export const alertPopupTypeBackgroundColor: AlertPopupTypeBackgroundColor = {
  information: '--alert-popup-information',
  error: '--alert-popup-error',
  success: '--alert-popup-success',
  warning: '--alert-popup-warning',
};
