import { ReactNode, useCallback, useMemo, useState } from 'react';

import { cn } from '@/core/helpers/cn';

export interface TabsProps {
  defaultKey: number;
  tabs: Array<TabItem>;
}

export interface TabItem {
  index: number;
  title: string;
  component: ReactNode;
  disabled?: boolean;
}

export const Tabs = ({ defaultKey, tabs }: TabsProps) => {
  const [currentTabKey, setCurrentTabKey] = useState(defaultKey);

  const currentTab: ReactNode = useMemo(() => {
    const tab = tabs.find((x) => x.index === currentTabKey);

    if (tab) {
      return tab.component;
    } else {
      return tabs[defaultKey].component;
    }
  }, [currentTabKey, tabs, defaultKey]);

  const changeTab = useCallback(
    (tab: TabItem) => {
      if (!tab.disabled) {
        setCurrentTabKey(tab.index);
      }
    },
    [setCurrentTabKey],
  );

  const isActive = useCallback((key: number): boolean => currentTabKey === key, [currentTabKey]);

  return (
    <div className="flex flex-col border rounded-3xl border-grey-300 overflow-hidden w-full">
      <div className="flex border-b border-grey-300">
        {tabs.map((tab, id) => (
          <button
            key={id}
            onClick={() => changeTab(tab)}
            disabled={tab.disabled}
            className={cn('flex items-center justify-center px-7 py-4 border-grey-300 bg-white enabled:hover:bg-purple-700 enabled:hover:text-white', {
              'bg-purple-dark text-white': isActive(tab.index),
              'bg-grey-400': tab.disabled,
            })}
          >
            <p className={cn('text-xl', { 'text-grey-500': tab.disabled })}>{tab.title}</p>
          </button>
        ))}
      </div>

      <div className="p-12">{currentTab}</div>
    </div>
  );
};
