import { FormEvent, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Alert,
  Button,
  ButtonStyle,
  ButtonsWrapper,
  FlowIndicator,
  Form,
  FormSection,
  FormSectionTitle,
  MinorNameDOBGender,
  PageFormWrapper,
  PageTitle,
  Step,
} from '@/components';
import { FlowSteps, isMobileTrack, useAppSelector } from '@/core';

import { MinorPickupForm } from '../components/MinorPickupForm';
import { useNextStep } from '../components/useNextStep';

export const MinorDetailsLegacy = () => {
  const { t } = useTranslation('');
  const { t: tRoutes } = useTranslation('routes');

  const country = useAppSelector((s) => s.searchDetails.country);
  const minorDetails = useAppSelector((s) => s.minorDetails);

  const navigate = useNavigate();

  const { displayCurrentStep, goToNextStep, currentStep, returnToReviewFrom, totalSteps } = useNextStep();

  const saveAndContinue = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      goToNextStep({
        countryIso: country?.isoCode ?? '',
        minorDetails,
      });
    },
    [country?.isoCode, goToNextStep, minorDetails],
  );

  return (
    <div className="minor-details">
      <header className="header">
        <FlowIndicator
          currentFormStep={displayCurrentStep}
          currentStep={FlowSteps.sessionSelectionFinished}
          formSteps={totalSteps}
          steps={FlowSteps.totalStepsForSessionSelection}
        ></FlowIndicator>
        {isMobileTrack(window.innerWidth) && (
          <Step bottomMargin={'var(--whitespace-basic-08)'}>
            {t('general-step', {
              stepNumber: `${displayCurrentStep}`,
              stepsTotal: `${totalSteps}`,
            })}
          </Step>
        )}
        <PageTitle hideOn="mobile" marginBottom={'var(--whitespace-32-to-08)'} text={tRoutes('book-new-test.minor-details')} />
        {!isMobileTrack(window.innerWidth) && currentStep === '0' && (
          <Step bottomMargin={'var(--whitespace-32-to-72)'}>
            {t('general-step', {
              stepNumber: `${displayCurrentStep}`,
              stepsTotal: `${totalSteps}`,
            })}
          </Step>
        )}
      </header>
      <PageFormWrapper>
        <Form id="minor-details" onSubmit={saveAndContinue}>
          {(currentStep === FlowSteps.flowStepForDesktopPath || currentStep === FlowSteps.flowStepForMobilePath) && (
            <FormSection>
              <FormSectionTitle>{t('minor-details.about-your-child.title')}</FormSectionTitle>

              <Alert alertType="information" show text={t('minor-details.about-your-child.note')} title={t('minor-details.about-your-child.note-clarify')} />

              <MinorNameDOBGender />
            </FormSection>
          )}
          {(currentStep === FlowSteps.flowStepForDesktopPath || currentStep === '2') && (
            <FormSection>
              <FormSectionTitle>{t('minor-details.pickup.title')}</FormSectionTitle>

              <Alert alertType="information" show text={t('minor-details.pickup.warning')} title={t('minor-details.pickup.warning-title')} />
              <MinorPickupForm />
            </FormSection>
          )}
          <ButtonsWrapper>
            <Button addArrow="left" buttonStyle={ButtonStyle.Secondary} onClick={() => navigate(-1)} text={t('components.back-button')} />
            <Button
              addArrow="right"
              buttonStyle={ButtonStyle.Primary}
              text={t(returnToReviewFrom ? 'tests-and-results.upcoming.save-and-review' : 'minor-details.cta')}
              type="submit"
            />
          </ButtonsWrapper>
        </Form>
      </PageFormWrapper>
    </div>
  );
};
