/* istanbul ignore file */
import styled from '@emotion/styled';
import { breakpoints } from '@/core';

export const H2 = styled.h2`
  color: var(--heading-color);
  font-family: BCSans;
  font-size: var(--h2-font-size);
  font-weight: bold;
`;

export const H3 = styled.h3`
  color: var(--heading-color);
  font-size: var(--h3-font-size);
  font-weight: bold;
  &.mobileSection {
    ${() => breakpoints.isTablet.mediaMaxWidth} {
      padding-bottom: var(--whitespace-16-to-24);
      position: relative;
      &:after {
        background-color: var(--heading-color);
        border-radius: var(--line-round-end-border-radius);
        bottom: 0;
        content: '';
        height: 7px;
        left: 0;
        position: absolute;
        width: 38px;
      }
    }
  }
`;

// TODO: No idea why h5 element needs wrapper with font size set to h6? Remove it and do proper styling.
export const H5Wrapper = styled.div`
  h5 {
    color: var(--heading-color);
    font-size: var(--h6-font-size);
    ${() => breakpoints.isTablet.mediaMinWidth} {
      font-size: var(--h5-font-size);
    }
    font-weight: bold;
  }
`;

export const H6 = styled.h6`
  color: var(--heading-color);
  font-size: var(--h6-font-size);
  font-weight: bold;
`;
