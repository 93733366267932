import { FC } from 'react';

import { ExternalMenuLink } from '@/components';

import * as S from './Links.style';

interface LinksProps {
  routes: any;
}

export const Links: FC<LinksProps> = ({ routes }): JSX.Element => {
  return (
    <S.Links>
      {routes.map(({ text, path }: any) => (
        <li key={path}>
          <ExternalMenuLink href={path} id="{text}-link" target="_blank" text={text}></ExternalMenuLink>
        </li>
      ))}
    </S.Links>
  );
};
