import { ReactNode, memo, useCallback, useEffect, useRef } from 'react';

import intlTelInput, { Options, Plugin } from 'intl-tel-input';

import { InputReg, InputRegProps, Label, LabelProps } from '@britishcouncil/react-registration-platform';
import 'intl-tel-input/build/css/intlTelInput.css';

export type PhoneInputProps = Omit<InputRegProps, 'value'> &
  Pick<LabelProps, 'helpText'> & {
    label?: ReactNode;
    initOptions?: Options;
    errorInline?: string;
    onChangeNumber?: (nr?: string) => void;
    onChangeValidity?: (isValid: boolean) => void;
    onChangeErrorCode?: (code?: number) => void;
  };

export const PhoneInput = ({
  defaultValue = '',
  onChangeNumber = () => {},
  onChangeValidity = () => {},
  onChangeErrorCode = () => {},
  initOptions,
  label,
  helpText,
  ...inputProps
}: PhoneInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const itiRef = useRef<Plugin | null>(null);

  const update = useCallback(() => {
    const num = itiRef.current?.getNumber() ?? '';
    onChangeNumber(num);

    // @ts-expect-error - the type definition is wrong, "isValidNumberPrecise" does exist and works fine
    if (itiRef.current?.isValidNumberPrecise()) {
      onChangeValidity(true);
      onChangeErrorCode(undefined);
    } else {
      const errorCode = itiRef.current?.getValidationError();
      onChangeValidity(false);
      onChangeErrorCode(errorCode);
    }
  }, [onChangeErrorCode, onChangeNumber, onChangeValidity]);

  useEffect(() => {
    // store a reference to the current input ref, which otherwise is already lost in the cleanup function
    const inputRefCurrent = inputRef?.current;

    if (inputRef?.current) {
      itiRef.current = intlTelInput(inputRef?.current, {
        utilsScript: 'https://bccdn.britishcouncil.org/scripts/3rd-party/intl-tel-input/utils_19_2_19.js',
        autoPlaceholder: 'off',
        // @ts-expect-error - the type definition is wrong, "showSelectedDialCode" does exist and works fine
        showSelectedDialCode: true,
        ...initOptions,
      });

      inputRefCurrent?.addEventListener('countrychange', update);

      return () => {
        inputRefCurrent?.removeEventListener('countrychange', update);
        itiRef.current?.destroy();
      };
    }
  }, [initOptions, update]);

  /** NOTE: Please note that we cannot use <ValidatedField /> here as "intl-tel-input" manipulates DOM directly,
   * which leads to errors when React tries to rerender children in <ValidatedField /> component ("intl-tel-input" cannot find proper ref).
   * For now I simply copy/pasted inline error HTML from common UI library,
   * but when this component (<PhoneInput />) will be moved to common UI library, it will be better to find
   * a more generic way to use <ValidatedField /> here as well. */
  return (
    <div className="flex flex-col">
      <Label placeholder="" helpText={helpText} htmlFor={inputProps.name || inputProps.id}>
        {label}
      </Label>
      {/* Class reg-react-select__input can be delete after refactor all inputs and remove box-shadow styles form global-css  */}
      <InputReg
        defaultValue={defaultValue}
        className="reg-react-select__input focus-visible:shadow-none mt-1"
        type="tel"
        ref={inputRef}
        onInput={update}
        {...inputProps}
      />
    </div>
  );
};

export const MemoPhoneInput = memo(PhoneInput, (prev, current) => !(prev.defaultValue == null && current.defaultValue != null));
