import { useCallback } from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { WellReg } from '@britishcouncil/react-registration-platform';

import { ClearForm } from '@/components/component-wrapper/ClearForm';
import { FormInput } from '@/components/forms/FormInput';
import { FormSelect } from '@/components/forms/FormSelect';
import { getFormError, getServerError } from '@/core/errors/FormError';
import { useCountryGetAllQuery } from '@/store/webApi';

import { PersonalDetailsFormType } from '../hooks/usePersonalDetailsForm';

export const AddressDetails = () => {
  const { t } = useTranslation('');

  const {
    register,
    formState: { errors },
    setValue,
    control,
  } = useFormContext<PersonalDetailsFormType>();

  const { data: countries = [], error: countriesError, isLoading: isLoadingCountries } = useCountryGetAllQuery();

  const options = countries.map((country) => ({
    value: country.id ?? -1,
    label: country.name ?? '',
  }));

  const onFormClear = useCallback(() => {
    setValue('address.addressLine1', '');
    setValue('address.addressLine2', '');
    setValue('address.city', '');
    setValue('address.postCode', '');
    setValue('address.residenceCountryId', 0);
  }, [setValue]);

  return (
    <ClearForm onClear={onFormClear}>
      <WellReg>
        <div className="[&>*]:my-3">
          <FormSelect
            data-testid="country-of-residence"
            name={'address.residenceCountryId'}
            control={control}
            required
            options={options}
            placeholder={t('personal-details.contact-details.search-by-country')}
            label={t('personal-details.contact-details.country-of-residence')}
            errorSubmittion={typeof countriesError === 'string' ? countriesError : getServerError(errors.address?.residenceCountryId)}
            errorInline={getFormError(errors.address?.residenceCountryId)}
            disabled={isLoadingCountries}
          />
          <FormInput
            required
            data-testid="postal-address-one"
            {...register('address.addressLine1', { required: t('common.required') })}
            errorInline={getFormError(errors.address?.addressLine1)}
            errorSubmittion={getServerError(errors.address?.addressLine1)}
            label={t('personal-details.contact-details.postal-address')}
          />
          <FormInput data-testid="postal-address-two" {...register('address.addressLine2')} errorInline={errors.address?.addressLine2?.message} />
          <FormInput
            required
            data-testid="town-city"
            {...register('address.city', { required: t('common.required') })}
            errorInline={getFormError(errors.address?.city)}
            errorSubmittion={getServerError(errors.address?.city)}
            label={t('personal-details.contact-details.town-city')}
          />
          <FormInput
            required
            data-testid="zip-code"
            {...register('address.postCode', { required: t('common.required') })}
            errorInline={getFormError(errors.address?.postCode)}
            errorSubmittion={getServerError(errors.address?.postCode)}
            label={t('personal-details.contact-details.zip-code')}
          />
        </div>
      </WellReg>
    </ClearForm>
  );
};
