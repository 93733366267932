import styled from '@emotion/styled';
import { breakpoints } from '@/core';

export const FormSection = styled.section<{ gap?: string; hideOn?: 'mobile' | 'tablet' }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  row-gap: ${({ gap }) => (gap ? gap : 'var(--whitespace-basic-24)')};
  ${() => breakpoints.isTablet.mediaMinWidth} {
    width: 100%;
    ${({ hideOn }) => (hideOn === 'mobile' ? 'display: flex;' : '')};
    ${({ hideOn }) => (hideOn === 'tablet' ? 'display: none;' : '')};
  }
  ${({ hideOn }) => (hideOn === 'mobile' ? 'display: none;' : '')};
`;

export const FormSectionTitle = styled.h2<{ hideOn?: 'mobile' | 'tablet' }>`
  color: var(--heading-color);
  font-size: var(--h5-font-size);
  font-weight: bold;

  ${() => breakpoints.isTablet.mediaMaxWidth} {
    position: relative;
    padding-bottom: 8px;
    &:after {
      background-color: var(--heading-color);
      border-radius: var(--line-round-end-border-radius);
      bottom: 0;
      content: '';
      height: 2.67px;
      left: 0;
      position: absolute;
      width: 38px;
    }
    ${() => breakpoints.isTablet.mediaMinWidth} {
      width: 100%;
      ${({ hideOn }) => (hideOn === 'mobile' ? 'display: block;' : '')};
      ${({ hideOn }) => (hideOn === 'tablet' ? 'display: none;' : '')};
    }
    ${({ hideOn }) => (hideOn === 'mobile' ? 'display: none;' : '')};
  }
`;
