import { FC, useCallback, useEffect, useRef } from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Country } from '@/store/webApi';
import { Option, OrderAcknowledgement, useAppSelector } from '@/core';
import { OrderAcknowledgementDetails } from '@/components';
import {
  OrderAcknowledgementDetailsState,
  setAcknowledgeCompanyName,
  setAcknowledgeCountryId,
  setAcknowledgeEmail,
  setAcknowledgeFirstName,
  setAcknowledgeLastName,
  setAcknowledgeNoLastName,
  setAcknowledgePostalLineOne,
  setAcknowledgePostalLineTwo,
  setAcknowledgePostalTownCity,
  setAcknowledgePostalZipCode,
  setAcknowledgeTelephoneNumber,
  setAcknowledgeVatNipNumber,
  setOrderAcknowledgement,
} from '@/store';
import { useCountryOptions } from '@/core/hooks/useCountryOptions';

interface AppOrderAcknowledgementProps {
  orderAcknowledgement: OrderAcknowledgement;
}

export const AppOrderAcknowledgement: FC<AppOrderAcknowledgementProps> = ({ orderAcknowledgement }): JSX.Element => {
  const { countries, countriesError, isLoadingCountries } = useCountryOptions();
  const preSelectedOrderAcknowledgementDetails = useRef<OrderAcknowledgementDetailsState>();
  const orderAcknowledgementDetails = useAppSelector((s) => s.orderAcknowledgementDetails);

  useEffect(() => {
    if (!preSelectedOrderAcknowledgementDetails.current) preSelectedOrderAcknowledgementDetails.current = orderAcknowledgementDetails;
  }, [orderAcknowledgementDetails]);

  const dispatch = useDispatch();
  const { t } = useTranslation('');

  const handleCompanyNameChange = useCallback(
    (newValue: string) => {
      dispatch(setAcknowledgeCompanyName(newValue));
    },
    [dispatch],
  );

  const handleCountryChange = useCallback(
    (country: Option<Country>) => {
      dispatch(setAcknowledgeCountryId(+country.value));
    },
    [dispatch],
  );

  const handleEmailChange = useCallback(
    (newValue: string) => {
      dispatch(setAcknowledgeEmail(newValue));
    },
    [dispatch],
  );

  const handleFirstNameChange = useCallback(
    (newValue: string) => {
      dispatch(setAcknowledgeFirstName(newValue));
    },
    [dispatch],
  );

  const handleLastNameChange = useCallback(
    (newValue: string) => {
      dispatch(setAcknowledgeLastName(newValue));
    },
    [dispatch],
  );

  const handleNoLastNameChange = useCallback(
    (newValue: boolean) => {
      dispatch(setAcknowledgeNoLastName(newValue));
    },
    [dispatch],
  );

  const handlePostalOneChange = useCallback(
    (line01: string) => {
      dispatch(setAcknowledgePostalLineOne(line01));
    },
    [dispatch],
  );

  const handlePostalTwoChange = useCallback(
    (line02: string) => {
      dispatch(setAcknowledgePostalLineTwo(line02));
    },
    [dispatch],
  );

  const handleTelephoneNumberChange = useCallback(
    (telephoneNumber?: string) => {
      dispatch(setAcknowledgeTelephoneNumber(telephoneNumber ?? ''));
    },
    [dispatch],
  );

  const handleTownCityChange = useCallback(
    (townCity: string) => {
      dispatch(setAcknowledgePostalTownCity(townCity));
    },
    [dispatch],
  );

  const handleVatNipNumberChange = useCallback(
    (newValue: string) => {
      dispatch(setAcknowledgeVatNipNumber(newValue));
    },
    [dispatch],
  );

  const handleZipChange = useCallback(
    (zipCode: string) => {
      dispatch(setAcknowledgePostalZipCode(zipCode));
    },
    [dispatch],
  );

  const onTypeChange = useCallback(
    (newValue: string) => {
      dispatch(setOrderAcknowledgement(newValue as OrderAcknowledgement));
    },
    [dispatch],
  );

  return (
    <OrderAcknowledgementDetails
      acknowledgement={orderAcknowledgement}
      anotherPersonChoiceLabel={t('review.order-acknowledgement.choices.another-person')}
      companyChoiceLabel={t('review.order-acknowledgement.choices.company')}
      companyNameLabel={t('review.order-acknowledgement.company.name')}
      countries={countries}
      countriesError={countriesError}
      countriesIsLoading={isLoadingCountries}
      countryLabel={t('review.order-acknowledgement.common.country')}
      defaultCountryId={preSelectedOrderAcknowledgementDetails.current?.countryId}
      defaultCompanyName={preSelectedOrderAcknowledgementDetails.current?.companyName}
      defaultEmail={preSelectedOrderAcknowledgementDetails.current?.email}
      defaultFirstName={preSelectedOrderAcknowledgementDetails.current?.firstName}
      defaultLastName={preSelectedOrderAcknowledgementDetails.current?.lastName}
      defaultPostalOne={preSelectedOrderAcknowledgementDetails.current?.postalLine01}
      defaultPostalTwo={preSelectedOrderAcknowledgementDetails.current?.postalLine02}
      defaultTelephoneNumber={preSelectedOrderAcknowledgementDetails.current?.telephoneNumber}
      defaultTownCity={preSelectedOrderAcknowledgementDetails.current?.postalTownCity}
      defaultVatNipNumber={preSelectedOrderAcknowledgementDetails.current?.vatNipNumber}
      defaultZip={preSelectedOrderAcknowledgementDetails.current?.postalZipCode}
      descriptiveLabel={t('review.order-acknowledgement.message')}
      emailLabel={t('review.order-acknowledgement.common.email')}
      firstNameLabel={t('review.order-acknowledgement.another-person.first-name')}
      invalidEmailMessage={t('components.text-input.errors.invalid-email')}
      lastNameLabel={t('review.order-acknowledgement.another-person.last-name')}
      myselfChoiceLabel={t('review.order-acknowledgement.choices.myself')}
      noLastName={orderAcknowledgementDetails?.noLastName}
      noLastNameText={t('review.order-acknowledgement.another-person.no-last-name')}
      onCompanyNameChange={handleCompanyNameChange}
      onCountryChange={handleCountryChange}
      onEmailChange={handleEmailChange}
      onFirstNameChange={handleFirstNameChange}
      onLastNameChange={handleLastNameChange}
      onNoLastNameChange={handleNoLastNameChange}
      onTelephoneNumberChange={handleTelephoneNumberChange}
      onPostalOneChange={handlePostalOneChange}
      onPostalTwoChange={handlePostalTwoChange}
      onTownCityChange={handleTownCityChange}
      onVatNipNumberChange={handleVatNipNumberChange}
      onZipChange={handleZipChange}
      onTypeChange={onTypeChange}
      postalOneLabel={t('review.order-acknowledgement.common.postal-address')}
      postalTwoAriaLabel={t('review.order-acknowledgement.common.postal-address-two')}
      requiredText={t('common.required')}
      telephoneNumberLabel={t('review.order-acknowledgement.common.telephone-number')}
      townCityLabel={t('review.order-acknowledgement.common.town-city')}
      vatNipNumberLabel={t('review.order-acknowledgement.company.vat-nip-number')}
      zipCodeLabel={t('review.order-acknowledgement.common.zipcode')}
    />
  );
};
