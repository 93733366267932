import React, { Suspense } from 'react';

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

import { AppSettingsProvider, globalStyles } from '@/core';
import { Global } from '@emotion/react';
import { Loading } from '@/components';
import { OidcProvider } from '@/components/auth';
import { SentryWrapper } from '@/core/sentry/SentryWrapper';
import { persistor } from '@/store/store';
import { store } from '@/store';

import * as serviceWorker from './serviceWorker';
import { AppRoutes } from './routes/router';
import './index.css';
import '@/core/i18n';

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<Loading />}>
            <AppSettingsProvider>
              <SentryWrapper>
                <Global styles={globalStyles} />
                <OidcProvider>
                  <AppRoutes />
                </OidcProvider>
              </SentryWrapper>
            </AppSettingsProvider>
          </Suspense>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}
