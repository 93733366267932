import { FC, HTMLAttributes, useMemo } from 'react';

import { FiAlertCircle, FiCheckCircle, FiInfo } from 'react-icons/fi';

import { Card } from '@/icons/Card';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { createGuid } from '@/core';

import * as S from './Alert.style';

interface AlertProps {
  alertType: AlertType;
  show: boolean;
  text?: string;
  title?: string;
  showIcon?: boolean;
}

export const Alert: FC<AlertProps & HTMLAttributes<HTMLElement>> = ({ alertType, children, show, text, title, showIcon = true, ...props }): JSX.Element => {
  return (
    <S.Alert alertType={alertType} {...(show && { className: 'animated slideInDown' })} {...props}>
      {title && (
        <S.AlertDetailRows>
          <S.AlertTitleLine>
            {showIcon && <AlertTypeIcon alertType={alertType} index={createGuid()} />}
            {title && <S.AlertHeading>{title}</S.AlertHeading>}
          </S.AlertTitleLine>
          <S.AlertNarrative>
            {text}
            {children}
          </S.AlertNarrative>
        </S.AlertDetailRows>
      )}

      {!title && (
        <S.AlertDetail>
          {showIcon && <AlertTypeIcon alertType={alertType} index={createGuid()} />}

          <S.AlertNarrative>
            {text}
            {children}
          </S.AlertNarrative>
        </S.AlertDetail>
      )}
    </S.Alert>
  );
};

export type AlertType = 'error' | 'information' | 'payment' | 'success' | 'warning';

type AlertTypeToIcon = Record<AlertType, EmotionJSX.Element>;

interface AlertTypeIconProps {
  alertType?: AlertType;
  index: string;
}

export const AlertTypeIcon: FC<AlertTypeIconProps> = ({ alertType, index }): JSX.Element => {
  const alertTypeToIcon: AlertTypeToIcon = useMemo(
    () => ({
      error: <FiAlertCircle />,
      information: <FiInfo />,
      payment: <Card index={index} />,
      success: <FiCheckCircle />,
      warning: <FiAlertCircle />,
    }),
    [index],
  );

  return alertType ? alertTypeToIcon[alertType] : <></>;
};

type AlertTypeBackgroundColor = Record<AlertType, string>;

export const alertTypeBackgroundColor: AlertTypeBackgroundColor = {
  error: '--alert-error',
  information: '--alert-information',
  payment: '--alert-information',
  success: '--alert-success',
  warning: '--alert-warning',
};

export const alertTypeIconColor: AlertTypeBackgroundColor = {
  error: '--alert-color',
  information: '--indigo',
  payment: '--indigo',
  success: '--indigo',
  warning: '--alert-warning',
};
