import { FC } from 'react';

import { IconProps } from '@/icons/IconProps';

export const ChevronRight: FC<IconProps> = ({ index = 0 }) => {
  return (
    <svg
      aria-hidden="true"
      aria-labelledby={`chevron-right-icon-title-${index}`}
      className="chevron-right-icon feather feather-chevron-right"
      data-testid={`chevron-right-icon-${index}`}
      fill="none"
      focusable="false"
      height="1.2em"
      role="img"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="0 0 17 28"
      width="1.2em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`chevron-right-icon-title-${index}`}></title>
      <polyline points="9 18 15 12 9 6"></polyline>{' '}
    </svg>
  );
};
