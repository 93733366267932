import { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AlertReg } from '@/components/presentation/alerts/AlertReg';
import { RadioGroupReg } from '@/components/forms/RadioGroupReg';
import { journeySlice } from '@/store';
import { useAppSelector, useTranslationOrDefault } from '@/core';
import { useProductConfig } from '@/core/hooks/useProductConfig';

export const BookingForDetails = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const { isChild } = useAppSelector((s) => s.journey);
  const { getTranslation } = useTranslationOrDefault();
  const { productConfig } = useProductConfig();

  const handleValueChange = useCallback(
    (value: string) => {
      dispatch(journeySlice.actions.setIsChild(value === 'child'));
      dispatch(journeySlice.actions.setReturnToReviewFrom(undefined));
    },
    [dispatch],
  );
  return (
    <>
      <RadioGroupReg
        defaultValue={isChild ? 'child' : 'self'}
        label={t('personal-details.create-account.test-taker.who-for')}
        items={[
          { id: 'self', value: 'self', label: t('personal-details.create-account.test-taker.myself') },
          { id: 'child', value: 'child', label: t('personal-details.create-account.test-taker.my-child') },
        ]}
        onValueChange={handleValueChange}
      />
      <AlertReg
        className="mt-2"
        variant="warning"
        title={t('common.please-note')}
        content={getTranslation(
          'personal-details.create-account.test-taker.warning.' + productConfig?.productCode?.toLowerCase(),
          t('personal-details.create-account.test-taker.warning.default'),
        )}
        visible={isChild}
      />
    </>
  );
};
