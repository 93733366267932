import { FlowSteps, useAppSelector } from '@/core';
import {
  useCustomerCreateCustomerMutation,
  useCustomerUpdateCustomerPersonalDataMutation,
  useIdentityBindCustomerToIdentityIdMutation,
  useIdentityGetCustomerBoundStatusQuery,
} from '@/store/webApi';

import { usePageNavigation } from '../legacy/usePageNavigation';

import { PersonalDetailsFormType } from './usePersonalDetailsForm';

export const useCustomerDatailsMutation = (isMobile: boolean) => {
  const { data: boundStatusData, isLoading: isLoadingBoundStatus } = useIdentityGetCustomerBoundStatusQuery();
  const [createCustomerDetails, { isLoading: isCreateCustomerDetailsLoading, error: createCustomerError }] = useCustomerCreateCustomerMutation();
  const [updateCustomerDetails, { isLoading: isUpdateCustomerDetailsLoading, error: updateCustomerError }] = useCustomerUpdateCustomerPersonalDataMutation();
  const [bindCustomer, { isLoading: isBindCustomerLoading, error: bindCustomerError }] = useIdentityBindCustomerToIdentityIdMutation();

  const { isChild } = useAppSelector((s) => s.journey);
  const goToNextStep = usePageNavigation({ currentStep: isMobile ? FlowSteps.flowForPersonalDetailsFinished : FlowSteps.flowStepForDesktopPath, isChild });

  const createOrUpdate = async ({ data }: { data: PersonalDetailsFormType }) => {
    const dateOfBirth = new Date(Date.UTC(data?.dateOfBirth?.year ?? 0, data?.dateOfBirth?.month, data?.dateOfBirth?.day)).toISOString().split('T')[0];
    const mobileNumber = (data.mobileNumber?.startsWith('+') ? data.mobileNumber : `+${data.mobileDialCode}${data.mobileNumber}`).replaceAll(' ', '');

    if (boundStatusData?.boundStatus === 1) {
      // 1 = bound
      const createCustomerRequest = await createCustomerDetails({
        createCustomerRequest: {
          firstName: data.firstName,
          lastName: data.lastName,
          dateOfBirth: dateOfBirth,
          gender: data?.gender,
          otherGenders: data?.gender == 0 ? data.otherGenders : undefined,
          address: {
            residenceCountryId: data.address.residenceCountryId,
            addressLine1: data.address.addressLine1,
            addressLine2: data.address.addressLine2,
            city: data.address.city,
            postCode: data.address.postCode,
          },
          mobileNumber: mobileNumber,
          email: data.email,
          marketingPreference: data.marketingPreference,
          isGDPRConsentProvided: false,
        },
      });
      if (createCustomerRequest && !('error' in createCustomerRequest)) {
        await bindCustomer({ bindCustomerToIdentityRequest: { dateOfBirth } }).then((response) => {
          if (!('error' in response)) goToNextStep();
        });
      }
    } else if (boundStatusData?.boundStatus === 3 || boundStatusData?.boundStatus === 2) {
      /* 2 = not bound, 3= new
       If the customer's status is Not Bonded, it must be bound before update the data 
       This may happen if the customer is not bound when it is created. */

      if (boundStatusData?.boundStatus === 2) await bindCustomer({ bindCustomerToIdentityRequest: { dateOfBirth } });

      await updateCustomerDetails({
        updateCustomerPersonalDetailsRequest: {
          firstName: data.firstName,
          lastName: data.lastName,
          dateOfBirth: dateOfBirth,
          gender: data?.gender,
          otherGenders: data?.gender == 0 ? data.otherGenders : undefined,
          address: {
            residenceCountryId: data.address.residenceCountryId,
            addressLine1: data.address.addressLine1,
            addressLine2: data.address.addressLine2,
            city: data.address.city,
            postCode: data.address.postCode,
          },
          mobileNumber: mobileNumber,
          email: data.email,
          marketingPreference: data.marketingPreference,
          isGDPRConsentProvided: false,
        },
      }).then((response) => {
        if (!('error' in response)) goToNextStep();
      });
    }
  };

  return {
    createOrUpdate,
    isLoading: isLoadingBoundStatus || isCreateCustomerDetailsLoading || isUpdateCustomerDetailsLoading || isBindCustomerLoading,
    error: createCustomerError || updateCustomerError || bindCustomerError,
  };
};
