import { utcToZonedTime } from 'date-fns-tz';

import { ExamFormat } from './Format';

const getZonedTimeRange = (startDateIso?: string, endDateIso?: string, timeZone?: string | null): string => {
  if (!startDateIso || !timeZone) {
    return '-';
  }

  const startDate = utcToZonedTime(startDateIso, timeZone);
  const endDate = endDateIso ? utcToZonedTime(endDateIso, timeZone) : undefined;

  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const formattedDate = startDate.toLocaleString('en-GB', { ...dateOptions });

  const startTime = startDate.toLocaleString('en-GB', { ...timeOptions }).toUpperCase();
  const endTime = endDate?.toLocaleString('en-GB', { ...timeOptions }).toUpperCase();

  return `${formattedDate}, ${startTime}${endTime ? ` - ${endTime}` : ''}`;
};

type Props = ExamFormat & {
  timeZone?: string | null;
};

export const FormatDate = ({ format, timeZone }: Props) => {
  return (
    <div className="flex flex-col gap-[4px] mb-[16px]">
      <h6 className="!text-black-text">{getZonedTimeRange(format.startDate, format.endDate, timeZone)}</h6>
    </div>
  );
};
