import { ErrorResponse, useAppSelector } from '@/core';
import { useProductConfig } from '@/core/hooks/useProductConfig';
import { UtcWithLocalDateResponse, useSessionGetSessionDatesForCityTestPackageQuery } from '@/store/webApi';

export type AvailableDatesHydration = {
  availableDates: Array<UtcWithLocalDateResponse>;
  availableDatesError: ErrorResponse;
  isLoadingAvailableDates: boolean;
};

export const useSearchAvailableDates = (dateFrom: string, dateTo: string): AvailableDatesHydration => {
  const { productConfig } = useProductConfig();
  const searchDetails = useAppSelector((s) => s.searchDetails);

  const {
    data: availableDates = [],
    error: availableDatesError,
    isLoading: isLoadingAvailableDates,
  } = useSessionGetSessionDatesForCityTestPackageQuery(
    {
      dateFrom,
      dateTo,
      cityId: searchDetails.city?.id ?? -1,
      countryId: searchDetails.country?.id ?? -1,
      productCode: productConfig?.productCode ?? '',
      productVariantId: searchDetails.productVariant?.id ?? '',
      testPackageId: searchDetails.testPackage?.id ?? '',
    },
    { skip: !searchDetails.city },
  );

  return {
    availableDates,
    availableDatesError: availableDatesError as ErrorResponse,
    isLoadingAvailableDates,
  };
};
