import { useEffect } from 'react';

import { SetState } from '@/core';

interface ControlScrollIntoViewParams {
  alignToTop: boolean;
  activeElementForScroll?: React.RefObject<HTMLElement>;
  controlToScrollIntoView?: React.RefObject<HTMLElement>;
  resetScrollIntoView?: SetState;
  scrollIntoView?: boolean;
}

export const useControlScrollIntoView = ({
  alignToTop,
  activeElementForScroll,
  controlToScrollIntoView,
  resetScrollIntoView,
  scrollIntoView,
}: ControlScrollIntoViewParams): void => {
  useEffect(() => {
    const scrollIntoView = () => document.activeElement === activeElementForScroll?.current && controlToScrollIntoView?.current?.scrollIntoView(alignToTop);

    if (controlToScrollIntoView && matchMedia('(pointer:coarse)').matches) window.addEventListener('resize', scrollIntoView);
    else document.removeEventListener('resize', scrollIntoView);

    return () => document.removeEventListener('resize', scrollIntoView);
  }, [activeElementForScroll, alignToTop, controlToScrollIntoView]);

  useEffect(() => {
    if (scrollIntoView) {
      controlToScrollIntoView?.current?.scrollIntoView(alignToTop);
      activeElementForScroll?.current?.focus();
    }
    if (resetScrollIntoView) resetScrollIntoView();
  }, [activeElementForScroll, alignToTop, controlToScrollIntoView, resetScrollIntoView, scrollIntoView]);

  return;
};
