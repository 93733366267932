import { useEffect, useState } from 'react';

import { FiChevronDown, FiTrash2 } from 'react-icons/fi';
import { createPortal } from 'react-dom';

import { BasketItem } from '@/store';
import { formatPrice, useBasket } from '@/core';

type Props = {
  openFn: () => void;
  exams: BasketItem[];
  mobileType?: 'mobile' | 'tablet';
};

const splitDate = (exam: BasketItem) => {
  const date = exam.formats?.find((el) => el.type === 'writing')?.startDate ?? exam.formats?.[0]?.startDate;

  if (!date) return { day: '', month: '', year: '' };

  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = dateObj.toLocaleDateString('en-GB', { month: 'short' });

  const year = dateObj.getFullYear();
  return { day, month, year };
};

const Opener = ({ openFn, exams: allExams, mobileType }: Props) => {
  const exams = allExams.slice(mobileType === 'tablet' ? -2 : -1);
  const { removeSession } = useBasket();

  const handleDelete = (id: string) => {
    id && removeSession(id);
  };

  return (
    <>
      <div className={allExams.length > 0 ? 'py-[24px] px-[24px] sm:pr-[42px] shadow-md' : 'purple-gradient py-[24px] px-[24px] sm:pr-[42px] shadow-md'}>
        <h3 className={allExams.length > 0 ? 'mb-[32px] !text-[16px] md:!text-[32px]' : '!text-[16px] md:!text-[32px]'}>
          Order Summary <span>{`(${allExams.length} item${allExams.length !== 1 ? 's' : ''})`}</span>
        </h3>
        <ul className="flex flex-col  gap-[16px]">
          {exams.map((exam, i) => (
            <li key={exam.id} className="flex items-center justify-between">
              <div className="border border-grey-light rounded-[4px] text-purple-dark mr-[16px]">
                <div className="text-center text-[18px]">{splitDate(exam).day}</div>
                <div className="flex p-[4px] bg-grey-light text-[12px] gap-[4px]">
                  <span className="font-bold">{splitDate(exam).month}</span>
                  <span>{splitDate(exam).year}</span>
                </div>
              </div>
              <div className="font-bold text-indigo pr-[32px] flex-grow">
                {i + 1}. {exam.name}
              </div>
              <div className="font-bold flex items-center">
                <div>{formatPrice(exam.price, exam.currency)}</div>
                <div className=" block ml-[8px]  sm:ml-[32px] border-l border-grey-light h-[26px]" />
                <div
                  className="text-blue-dark cursor-pointer px-[8px] sm:px-[32px] relative w-[24px] sm:w-[48px] h-[16px]"
                  onClick={() => handleDelete(exam.id ?? '-1')}
                >
                  <div className="h-[48px] absolute cursor-pointer left-0 top-[-16px] w-[100%] flex items-center justify-end">
                    <FiTrash2 className=" sm:block" />
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
        {allExams.length > 0 && (
          <div className="text-center text-blue-dark font-bold sm:block">
            <button onClick={openFn} className="inline-flex items-center gap-[4px] mt-[12px]">
              <span>Show more</span>
              <FiChevronDown />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export const MobileOpener = ({ openFn, exams }: Omit<Props, 'mobileType'>) => {
  const opener = document.getElementById('basket-mobile-opener');
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isMobileDevice = screenWidth < 1024;
  const mobileType = screenWidth <= 640 ? 'mobile' : 'tablet';

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return opener && isMobileDevice ? createPortal(<Opener openFn={openFn} exams={exams} mobileType={mobileType} />, opener) : null;
};
