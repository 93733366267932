import { css } from '@emotion/react';

import { breakpoints } from '../utils';

export const attributeDimensions = css`
  .themed {
    --line-round-end-border-radius: 0px;

    --round-border-radius: 50%;
    --semi-circle-left-border-radius: 50% 0 0 50%;
    --semi-circle-right-border-radius: 0 50% 50% 0;

    --focus-spread: 4px;

    --whitespace-basic-04: 4px;
    --whitespace-basic-06: 6px;
    --whitespace-basic-08: 8px;
    --whitespace-basic-12: 12px;
    --whitespace-basic-16: 16px;
    --whitespace-basic-20: 20px;
    --whitespace-basic-24: 24px;
    --whitespace-basic-32: 32px;
    --whitespace-basic-40: 40px;
    --whitespace-basic-48: 48px;
    --whitespace-basic-72: 72px;

    --whitespace-00-to-24: 0px;
    --whitespace-04-to-08: var(--whitespace-basic-04);
    --whitespace-06-to-12: var(--whitespace-basic-06);
    --whitespace-08-to-16: var(--whitespace-basic-08);
    --whitespace-08-to-24: var(--whitespace-basic-08);

    --whitespace-12-to-20: var(--whitespace-basic-12);
    --whitespace-16-to-24: var(--whitespace-basic-16);
    --whitespace-24-to-40: var(--whitespace-basic-24);

    --whitespace-32-to-24: var(--whitespace-basic-32);
    --whitespace-32-to-40: var(--whitespace-basic-32);
    --whitespace-40-to-72: var(--whitespace-basic-40);

    --whitespace-32-to-72: var(--whitespace-basic-32);
    --whitespace-32-to-08: var(--whitespace-basic-32);

    ${breakpoints.isTablet.mediaMinWidth} {
      --whitespace-04-to-08: var(--whitespace-basic-08);
      --whitespace-06-to-12: var(--whitespace-basic-12);
      --whitespace-08-to-16: var(--whitespace-basic-16);
      --whitespace-00-to-24: var(--whitespace-basic-24);
      --whitespace-08-to-24: var(--whitespace-basic-24);
      --whitespace-12-to-20: var(--whitespace-basic-20);
      --whitespace-16-to-24: var(--whitespace-basic-24);
      --whitespace-24-to-40: var(--whitespace-basic-40);
      --whitespace-32-to-24: var(--whitespace-basic-24);
      --whitespace-32-to-40: var(--whitespace-basic-40);
      --whitespace-32-to-72: var(--whitespace-basic-72);
      --whitespace-40-to-72: var(--whitespace-basic-72);
      --whitespace-32-to-08: var(--whitespace-basic-08);
    }
  }
`;
