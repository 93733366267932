import { FC, useMemo } from 'react';

import { startOfDay, subYears } from 'date-fns';

import {
  ComponentWrapper,
  DateInput,
  DateInputSelectionChange,
  DateInputValidated,
  DisabledChangeEvent,
  NoPadding,
  RadioGroupSelectionChange,
  TextInput,
  TextInputChangeEvent,
} from '@/components';
import { GenderType } from '@/store/webApi';
import { RadioGroup } from '@/components/html-elements/form-specific/radio-group/RadioGroup';
import { noAction } from '@/core';

interface NameDobGenderProps {
  addBackground?: boolean;
  dateOfBirthTooOldMessage?: string;
  dateOfBirthTooYoungMessage?: string;
  dateOfBirthLabel?: string;
  defaultDateOfBirth?: Date;
  defaultFirstName?: string;
  defaultGenderOther?: string;
  defaultLastName?: string;
  femaleText?: string;
  firstNameLabel: string;
  firstNameRequiredText: string;
  firstNameSubLabel?: string;
  gender?: GenderType;
  genderLabel?: string;
  genderRequiredText?: string;
  id: string;
  invalidDateMessage?: string;
  isMinor?: boolean;
  lastNameDisabled: boolean;
  lastNameLabel: string;
  lastNameRequiredText: string;
  maleText?: string;
  noLastNameText: string;
  noPadding?: NoPadding;
  onDateOfBirthChange?: DateInputSelectionChange;
  onDateOfBirthValidate?: DateInputValidated;
  onGenderOtherChange?: TextInputChangeEvent;
  onFirstNameChange: TextInputChangeEvent;
  onGenderChange?: RadioGroupSelectionChange;
  onLastNameChange: TextInputChangeEvent;
  onNoLastNameChange: DisabledChangeEvent;
  placeholderDay?: string;
  placeholderMonth?: string;
  placeholderYear?: string;
  showDob: boolean;
  showGender: boolean;
  whiteBackground?: boolean;
}

export const NameDobGender: FC<NameDobGenderProps> = ({
  addBackground = true,
  dateOfBirthTooOldMessage = '',
  dateOfBirthTooYoungMessage = '',
  dateOfBirthLabel = '',
  defaultDateOfBirth,
  defaultFirstName = '',
  defaultGenderOther = '',
  defaultLastName = '',
  femaleText = '',
  firstNameLabel,
  firstNameRequiredText,
  firstNameSubLabel,
  gender,
  genderLabel = '',
  genderRequiredText = '',
  invalidDateMessage = '',
  id,
  isMinor,
  lastNameDisabled,
  lastNameLabel,
  lastNameRequiredText,
  maleText = '',
  noPadding,
  onDateOfBirthChange = noAction,
  onDateOfBirthValidate = noAction,
  onGenderOtherChange = noAction,
  onFirstNameChange,
  onGenderChange = noAction,
  onLastNameChange,
  placeholderDay = '',
  placeholderMonth = '',
  placeholderYear = '',
  showDob,
  showGender,
  whiteBackground,
}): JSX.Element => {
  const startOfToday = startOfDay(new Date());
  const earliestDate = useMemo(() => (isMinor ? subYears(startOfToday, 18) : new Date(1900, 0, 1)), [isMinor, startOfToday]);
  const latestDate = useMemo(() => (isMinor ? subYears(startOfToday, 16) : subYears(startOfToday, 18)), [isMinor, startOfToday]);

  return (
    <ComponentWrapper addBackground={addBackground} whiteBackground={whiteBackground} id={`${id}-about-you`} {...(noPadding && { noPadding: noPadding })}>
      <TextInput
        data-testid="first-name"
        defaultText={defaultFirstName}
        id={`${id}-first-name`}
        inputType="text"
        label={firstNameLabel}
        onValueChange={onFirstNameChange}
        required
        requiredText={firstNameRequiredText}
        sublabel={firstNameSubLabel}
      ></TextInput>
      <TextInput
        data-testid="last-name"
        disabled={lastNameDisabled}
        // disabledChoiceText={noLastNameText} TODO In a very likely scenario of reapplying disable lastname feature, uncomment this code, otherwise delete
        defaultText={defaultLastName}
        id={`${id}-last-name`}
        inputType="text"
        label={lastNameLabel}
        // onDisabledChange={onNoLastNameChange} TODO In a very likely scenario of reapplying disable lastname feature, uncomment this code, otherwise delete
        onValueChange={onLastNameChange}
        required
        requiredText={lastNameRequiredText}
        // showDisabledChoice TODO In a very likely scenario of reapplying disable lastname feature, uncomment this code, otherwise delete
      ></TextInput>
      {showDob && (
        <DateInput
          data-testid="date-of-birth"
          afterLatestDateMessage={dateOfBirthTooYoungMessage}
          beforeEarliestDateMessage={dateOfBirthTooOldMessage}
          defaultDate={defaultDateOfBirth}
          earliestDate={earliestDate}
          id={`${id}-date-of-birth`}
          invalidDateMessage={invalidDateMessage}
          label={dateOfBirthLabel}
          latestDate={latestDate}
          onDateChange={onDateOfBirthChange}
          onValidate={onDateOfBirthValidate}
          placeholderDay={placeholderDay}
          placeholderMonth={placeholderMonth}
          placeholderYear={placeholderYear}
          required
          requiredText=""
        />
      )}
      {showGender && (
        <RadioGroup
          id="sex-choice"
          label={genderLabel}
          name="sex-choice"
          onChoiceChange={onGenderChange}
          radios={[
            { id: '2', label: femaleText, required: true },
            { id: '1', label: maleText, required: true },
            {
              id: '0',
              label: 'Other',
              onRadioTextChange: onGenderOtherChange,
              radioTextDefaultValue: defaultGenderOther,
              required: true,
            },
          ]}
          required
          requiredText={genderRequiredText}
          value={gender?.toString() ?? ''}
        />
      )}
    </ComponentWrapper>
  );
};
