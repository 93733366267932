import { FC, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { ReviewIdentification } from '@/components';
import { getDateStringLong, useAppSelector } from '@/core';
import { useCountryOptions } from '@/core/hooks/useCountryOptions';

interface AppReviewIdentificationProps {
  isChild?: boolean;
}

export const AppReviewIdentification: FC<AppReviewIdentificationProps> = ({ isChild = false }): JSX.Element => {
  const { countries } = useCountryOptions();
  const countryName = useRef('');

  const id = useAppSelector((s) => s.identificationDetails);
  const mid = useAppSelector((s) => s.minorIdentificationDetails);

  const identificationDetails = isChild ? mid : id;
  const expiryDate = identificationDetails.expiryDate && new Date(identificationDetails.expiryDate);
  const expiryDateText = expiryDate ? getDateStringLong(expiryDate) : '';
  const identificationType = identificationDetails.identificationType;
  const { t } = useTranslation();

  useEffect(() => {
    if (!countryName.current && countries && countries.length) {
      const country = countries.find((country) => +country.value === identificationDetails.nationalityCountryId);
      countryName.current = country ? country.text : '';
    }
  }, [identificationDetails.nationalityCountryId, countries]);

  return (
    <ReviewIdentification
      countryOfNationality={countryName.current}
      countryOfNationalityLabel={t('review.identification.nationality')}
      expiryDate={expiryDateText}
      expiryDateLabel={t(`${identificationType === 1 ? 'identification.details.id-card.expiry-date' : 'identification.details.passport.expiry-date'}`)}
      idNumber={identificationDetails.identificationNumber}
      idNumberLabel={t(`${identificationType === 1 ? 'identification.details.id-card.number' : 'identification.details.passport.number'}`)}
      issuingAuthority={identificationDetails.issuingAuthority}
      issuingAuthorityLabel={t('identification.details.issuing-authority')}
    />
  );
};
