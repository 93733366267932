/* istanbul ignore file */
import { SessionReservationDTO, SessionStatus, SessionsDTO, Venue } from '@/core';
import { SessionResponse } from '@/store/webApi';

import { venues } from './venues';

const today = new Date(new Date().setHours(0, 0, 0, 0));
const day = today.getDay();
const sunday = new Date(new Date(today).setDate(today.getDate() - day));

const getSessionDateTime = (daysInFuture: number, hours: number, minutes: number, from: Date): Date => {
  const newDate = new Date(new Date(new Date(from).setHours(hours, minutes)).setDate(from.getDate() + daysInFuture));
  return newDate;
};

export const getSessionMock = (sessionId: string): SessionResponse => {
  let session = mockUpcomingSessions.find((session) => session.id === sessionId);
  if (!session) session = mockUpcomingSessions[0];
  return session;
};

export const getSessionMockOptional = (include: boolean, sessionId: string): SessionResponse | undefined => {
  if (!include) return;
  return getSessionMock(sessionId);
};

export const getSessionReservationDTO = (include: boolean, sessionId: string): SessionReservationDTO | undefined => {
  if (!include) return;
  return {
    sessionId,
    reservationId: `${sessionId}-res-id`,
  };
};

const newSession = (
  id: number,
  cityId: number,
  daysInFuture: number,
  startHour: number,
  startMinute: number,
  duration: number,
  status: SessionStatus,
  venue: Venue,
): SessionResponse => {
  return {
    id: id.toString(),
    endDateTimeUtc: new Date(getSessionDateTime(daysInFuture, startHour, startMinute, sunday).setMinutes(duration)).toISOString(),
    startDateTimeUtc: getSessionDateTime(daysInFuture, startHour, startMinute, sunday).toISOString(),
    venue,
  };
};

const mockUpcomingSessions: SessionsDTO = [
  newSession(1, 1, 4, 10, 0, 160, 'In Progress', venues.barcelona01),
  newSession(2, 1, 5, 14, 30, 160, 'In Progress', venues.barcelona02),
  newSession(3, 1, 8, 9, 15, 160, 'In Progress', venues.barcelona01),
  newSession(4, 2, 9, 11, 45, 160, 'In Progress', venues.madrid01),
  newSession(5, 2, 10, 15, 0, 160, 'In Progress', venues.madrid01),
  newSession(6, 2, 16, 10, 30, 160, 'In Progress', venues.madrid01),
  newSession(7, 2, 18, 17, 0, 160, 'In Progress', venues.madrid02),
  newSession(8, 3, 22, 12, 0, 160, 'In Progress', venues.seville01),
  newSession(9, 3, 23, 9, 0, 160, 'In Progress', venues.seville02),
  newSession(10, 3, 24, 14, 30, 160, 'In Progress', venues.seville03),
  newSession(11, 3, 26, 16, 0, 160, 'In Progress', venues.seville03),
  newSession(12, 3, 29, 10, 30, 160, 'In Progress', venues.seville02),
  newSession(13, 3, 30, 12, 45, 160, 'In Progress', venues.seville02),
  newSession(14, 4, 33, 9, 30, 160, 'In Progress', venues.valencia01),
  newSession(15, 11, 5, 9, 30, 160, 'In Progress', venues.acoruna01),
  newSession(16, 11, 8, 15, 45, 160, 'In Progress', venues.acoruna01),
  newSession(17, 11, 12, 12, 0, 160, 'In Progress', venues.acoruna01),

  newSession(18, 1, -4, 10, 0, 160, 'Passed', venues.barcelona01),
  newSession(19, 1, -5, 14, 30, 160, 'Passed', venues.barcelona02),
  newSession(20, 1, -8, 9, 15, 160, 'Passed', venues.barcelona01),
  newSession(21, 2, -9, 11, 45, 160, 'Passed', venues.madrid01),
  newSession(22, 2, -10, 15, 0, 160, 'Passed', venues.madrid01),
  newSession(23, 2, -16, 10, 30, 160, 'Passed', venues.madrid01),
  newSession(24, 2, -18, 17, 0, 160, 'Passed', venues.madrid02),
  newSession(25, 3, -22, 12, 0, 160, 'Passed', venues.seville01),
  newSession(26, 3, -23, 9, 0, 160, 'Passed', venues.seville02),
  newSession(27, 3, -24, 14, 30, 160, 'Passed', venues.seville03),
  newSession(28, 3, -26, 16, 0, 160, 'Passed', venues.seville03),
  newSession(29, 3, -29, 10, 30, 160, 'Passed', venues.seville02),
  newSession(30, 3, -30, 12, 45, 160, 'Passed', venues.seville02),
  newSession(31, 4, -33, 9, 30, 160, 'Passed', venues.valencia01),
  newSession(32, 11, -5, 9, 30, 160, 'Passed', venues.acoruna01),
  newSession(33, 11, -8, 15, 45, 160, 'Passed', venues.acoruna01),
  newSession(34, 11, -12, 12, 0, 160, 'Passed', venues.acoruna01),
];
