import { useForm } from 'react-hook-form';

import { DobInputs } from '@/components/forms/FormDob';
import { GenderType } from '@/store/webApi';
import { MarketingPreference, useAppSelector } from '@/core';
import { PhoneFormInput } from '@/components/forms/FormPhoneInput';

/** When extending this type, try to be as close as possible to API contracts */
export type PersonalDetailsFormType = PhoneFormInput & {
  firstName?: string;
  lastName?: string;
  gender?: GenderType;
  otherGenders?: string;
  address: {
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    postCode?: string;
    countryId: number;
  };
  mobileNumber?: string;
  mobileNumberPrefixCountry?: string;
  mobileDialCode?: string;
  email?: string;
  marketingPreference?: MarketingPreference;
} & DobInputs;

export const usePersonalDetailsForm = () => {
  const contactDetailsState = useAppSelector((s) => s.contactDetails);
  const personalDetailsState = useAppSelector((s) => s.personalDetails);
  const dateOfBirth = new Date(personalDetailsState?.dateOfBirth ?? '');

  const methods = useForm<PersonalDetailsFormType>({
    mode: 'onTouched',
    criteriaMode: 'all',
    defaultValues: {
      firstName: personalDetailsState.firstName,
      lastName: personalDetailsState.lastName,
      gender: personalDetailsState.gender,
      otherGenders: personalDetailsState.genderOther,
      mobileNumber: contactDetailsState.mobileNumber,
      address: {
        addressLine1: contactDetailsState.postalLine01,
        addressLine2: contactDetailsState.postalLine02,
        city: contactDetailsState.postalTownCity,
        postCode: contactDetailsState.postalZipCode,
        countryId: contactDetailsState.countryId,
      },
      email: personalDetailsState.email,
      marketingPreference: personalDetailsState.marketingPreference,
      dateOfBirth: dateOfBirth ? { day: dateOfBirth.getDate(), month: dateOfBirth.getMonth(), year: dateOfBirth.getFullYear() } : undefined,
    },
  });

  return methods;
};
