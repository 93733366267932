import { useTranslation } from 'react-i18next';

export const useTranslationOrDefault = () => {
  const { t, i18n } = useTranslation();

  const getTranslation = (key: string, defaultValue?: string) => {
    return i18n.exists(key) ? t(key) : defaultValue;
  };

  return { getTranslation };
};
