import { FC, FormEvent, FormEventHandler, FormHTMLAttributes, useState } from 'react';

import * as S from './Form.style';
import { FormContextClass, FormContextProvider, useFormContext } from './form-context';

interface FormProps {
  onSubmit: FormEventHandler<HTMLFormElement>;
}

const ChildForm: FC<FormProps & FormHTMLAttributes<HTMLFormElement>> = ({ children, className, id, onSubmit, ...props }): JSX.Element => {
  const { checkForm } = useFormContext();
  const handleSubmit: FormEventHandler<HTMLFormElement> = (event: FormEvent<HTMLFormElement>) => {
    !checkForm() ? event.preventDefault() : onSubmit(event);
  };

  return (
    <S.Form className={className} id={id} onSubmit={handleSubmit} {...props}>
      {children}
    </S.Form>
  );
};

export const Form: FC<FormProps & FormHTMLAttributes<HTMLFormElement>> = ({ children, className, id, onSubmit, ...props }): JSX.Element => {
  const [formContextValue] = useState(new FormContextClass());

  return (
    <FormContextProvider value={formContextValue}>
      <ChildForm className={className} id={id} onSubmit={onSubmit} {...props}>
        {children}
      </ChildForm>
    </FormContextProvider>
  );
};
