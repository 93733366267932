import { useContext } from 'react';

import { VisibilityContext } from 'react-horizontal-scrolling-menu';

import { cn } from '@/core/helpers/cn';

import { ActiveDot } from './ActiveDot';

type Props = {
  onClick: (a: React.ContextType<typeof VisibilityContext>) => void;
  selected: boolean;
  title: string;
  itemId: string;
  active: boolean;
};

export const MonthBtn = ({ onClick, selected, title, active }: Props) => {
  const visibility = useContext(VisibilityContext);

  return (
    <button disabled={!active} onClick={() => onClick(visibility)} tabIndex={0} className={cn('relative flex', { 'cursor-pointer': active })}>
      {active && <ActiveDot />}
      <span
        className={cn('relative inline-flex rounded-md bg-grey-light px-[16px] py-[4px] mx-[8px] opacity-40', {
          'bg-white shadow-md opacity-100': active,
          'bg-purple-dark text-white': selected,
        })}
      >
        {title}
      </span>
    </button>
  );
};
