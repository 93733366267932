import { useTranslation } from 'react-i18next';

import { ReviewGrid, ReviewGridRow, Rows } from '@/components';
import { useAppSelector } from '@/core';
import { useDictionariesGetAllMinorPickupPersonsQuery } from '@/store/webApi';

export const ReviewPickup = () => {
  const { guardian, pickupType } = useAppSelector((state) => state.minorPickup);

  const { data = [] } = useDictionariesGetAllMinorPickupPersonsQuery();
  const relationShipMatched = data.filter((relation) => relation.id === Number(guardian?.relationshipId));
  const { t } = useTranslation();

  const relationTranslation = t('review.child-details.pickup-relationship');
  const pickupStatementTranslation = t('review.child-details.pickup-statement');
  const telephoneTranslation = t('review.child-details.pickup-telephone-information');
  const parentPickUpStatementTranslation = t('review.child-details.parent-pickup-statement');
  const noPickupStatementTranslation = t('review.child-details.no-pickup-statement');
  const pickupLabelTranslation = t('review.child-details.pickup-label');

  return (
    <ReviewGrid>
      <ReviewGridRow id="dob-label" label={pickupLabelTranslation}>
        {pickupType === 3 && (
          <Rows>
            <div>
              <div>
                {guardian?.firstName} {guardian?.lastName} ({relationTranslation} {relationShipMatched[0] ? relationShipMatched[0].name : ''}){' '}
                {pickupStatementTranslation}
              </div>
              <div>
                {telephoneTranslation} {guardian?.telephone}
              </div>
            </div>
          </Rows>
        )}
        {pickupType === 2 && <> {parentPickUpStatementTranslation} </>}
        {pickupType === 1 && <> {noPickupStatementTranslation} </>}
      </ReviewGridRow>
    </ReviewGrid>
  );
};
