import { useEffect } from 'react';

import { GoogleTagManager, useAppSelector } from '@/core';

export const useAnalytics = () => {
  const country = useAppSelector((s) => s.searchDetails.country);

  const basket = useAppSelector((s) => s.basket);
  const session = basket?.items ? basket?.items[0] : undefined;

  useEffect(() => {
    GoogleTagManager.addEvent({
      Country: country?.isoCode,
      event: 'SessionSelection',
      sessionId: session?.id,
      virtualPageTitle: 'British Council - Book a new test - Personal details',
      virtualPageTitleLocal: document.title,
      virtualPageURL: '/book-test/personal-details/',
    });

    GoogleTagManager.addEvent({
      event: 'PersonalDetailsStep',
      Country: country?.isoCode,
      virtualPageTitle: 'British Council - Book a new test - Personal details',
      virtualPageTitleLocal: document.title,
      virtualPageURL: '/book-test/personal-details/',
    });
  }, [country?.isoCode, session?.id]);

  const submitEvents = (personalDetails: TODO, contactDetails: TODO) => {
    GoogleTagManager.addEvent({
      event: 'PersonalDetailsStepCompletion',
      Country: country?.isoCode,
      data: personalDetails,
      virtualPageTitle: 'British Council - Book a new test - Personal details',
      virtualPageTitleLocal: document.title,
      virtualPageURL: '/book-test/personal-details/',
    });
    GoogleTagManager.addEvent({
      event: 'ContactDetailsStepCompletion',
      Country: country?.isoCode,
      data: contactDetails,
      virtualPageTitle: 'British Council - Book a new test - Personal details',
      virtualPageTitleLocal: document.title,
      virtualPageURL: '/book-test/personal-details/',
    });
  };

  return { submitEvents };
};
