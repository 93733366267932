import { FC } from 'react';

import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { GUID } from '@/core';

import * as S from './ErrorMessageList.style';

interface ErrorMessageListProps {
  errorMessages: ErrorMessages;
}

export type ErrorMessage = {
  icon?: EmotionJSX.Element;
  id: GUID;
  isFormError?: boolean;
  isNotError?: boolean;
  isSuccess?: boolean;
  text: string;
  type: ErrorMessageType;
};

export const ErrorMessageList: FC<ErrorMessageListProps> = ({ errorMessages }): JSX.Element | null => {
  if (!errorMessages.length) return null;
  return (
    <S.ErrorMessageList data-testid="error-message-list">
      {errorMessages.map(({ icon, id, isFormError, isNotError, isSuccess, text }) => (
        <S.ErrorMessage key={id} isNotError={isNotError} isSuccess={isSuccess}>
          {icon}
          {isFormError ? <label dangerouslySetInnerHTML={{ __html: text }} /> : text}
        </S.ErrorMessage>
      ))}
    </S.ErrorMessageList>
  );
};

export type ErrorMessageType = 'form' | 'inline' | 'internal' | 'required';

export type ErrorMessages = Array<ErrorMessage>;
