import { useCallback, useMemo } from 'react';

import { BasketDetailsResponse, BasketItemDto, SessionResponse, useSessionCreateReservationMutation } from '@/store/webApi';
import { basketAdapter, basketSlice } from '@/store';
import { useAppSettingsContext } from '@/core/app-settings';
import { useProductConfig } from '@/core/hooks/useProductConfig';

import { useAppDispatch, useAppSelector } from './store';

const MAX_SESSIONS_IN_BASKET = 6;

export const useBasket = () => {
  const basket = useAppSelector((s) => s.basket);
  const testPackage = useAppSelector((s) => s.searchDetails.testPackage);
  const dispatch = useAppDispatch();
  const appSettings = useAppSettingsContext();
  const { productConfig } = useProductConfig();
  const limit = productConfig?.enrollmentQuantityLimitPerOrder ?? MAX_SESSIONS_IN_BASKET;
  const basketLimitReached = useMemo(() => {
    return basket.items.length + 1 > limit;
  }, [basket.items.length, limit]);
  const [reserveSession, { isLoading: isUpdating }] = useSessionCreateReservationMutation();

  const findSession = useCallback((id: string, sessions: SessionResponse[]) => {
    return sessions.find((session: SessionResponse) => session.id === id);
  }, []);

  const removeSession = useCallback(
    (sessionId: string) => {
      if (basket.items.length === 0) return;

      dispatch(basketSlice.actions.removeItem(sessionId));
    },
    [basket.items, dispatch],
  );

  const filterReservationsForActiveBasketSessions = useCallback(() => {
    if (basket.reservations && basket.reservations.length > 0) {
      const sessionIds = basket.items?.map((el) => el.id);
      const reservationIds = basket.reservations.filter((el) => sessionIds?.includes(el.sessionId)).map((el) => el.reservationId);
      if (sessionIds && sessionIds.length === reservationIds.length) {
        return reservationIds;
      }
      return [];
    }
  }, [basket.reservations, basket.items]);

  const reserveItemsInBasket = useCallback(async () => {
    const sessionsIds = basket.items.map((el) => el.id);

    for (const sessionId of sessionsIds) {
      try {
        const sessionReservation = await reserveSession({
          sessionId: sessionId ?? '',
        }).unwrap();

        dispatch(basketSlice.actions.addReservation(sessionReservation));
      } catch (err) {
        return { isSuccess: false };
      }
    }

    return { isSuccess: true };
  }, [appSettings.api.url, basket.items, dispatch, reserveSession]);

  const addSession = (sessionObject: SessionResponse) => {
    if (!sessionObject || !sessionObject.id) {
      return { error: 'No session provided', promise: null };
    }

    const isSessionAlreadyAdded = !!findSession(sessionObject.id, basket.items);
    const isSessionFromPackageAlreadyAdded = !!basket.items.find((session) => session.packageId === sessionObject.packageId);

    const limitReached = limit > 1 && basket.items.length + 1 > limit;
    const shouldReplace = limit === 1 || isSessionFromPackageAlreadyAdded;

    if (limitReached) {
      return { error: 'Session Limit Reached', promise: null };
    }

    if (!isSessionAlreadyAdded) {
      if (shouldReplace) {
        dispatch(
          basketSlice.actions.setItems([
            ...basket.items.slice(0, -1),
            {
              ...basketAdapter.fromSession(sessionObject),
              price: testPackage?.totalFee ?? 0,
              tax: testPackage?.tax ?? 0,
              currency: testPackage?.currency ?? '',
            },
          ]),
        );
      } else {
        dispatch(
          basketSlice.actions.addItem({
            ...basketAdapter.fromSession(sessionObject),
            price: testPackage?.totalFee ?? 0,
            tax: testPackage?.tax ?? 0,
            currency: testPackage?.currency ?? '',
          }),
        );
      }
    }

    return { error: null };
  };

  const clearBasket = () => {
    dispatch(basketSlice.actions.setItems([]));
    dispatch(basketSlice.actions.setReservations([]));
  };

  const getEarliestExpiryDate = () => {
    if (!basket || !basket.items || basket.items.length === 0) return;
    const itemsToSort = [...basket.items];
    const sorted = itemsToSort.sort((a, b) => ((a.startDateTimeUtc ?? '') < (b.startDateTimeUtc ?? '') ? 1 : -1));
    return new Date(sorted[0].startDateTimeUtc ?? '');
  };

  const getTotal = () => {
    const total = basket.items
      .filter((el) => el.price)
      .map((el) => el.price)
      .reduce((p = 0, n = 0) => p + n, 0);
    const totalTax = basket.items
      .filter((el) => el.tax)
      .map((el) => el.tax)
      .reduce((p = 0, n = 0) => p + n, 0);
    return { fee: total && totalTax ? total - totalTax : total, total: total, tax: totalTax, currency: basket.items[0]?.currency };
  };

  const recoverBasketItemsLegacy = (basketItems: BasketItemDto[]) => {
    if (basketItems && basketItems.length > 0)
      basketItems.map((i) =>
        dispatch(
          basketSlice.actions.addItem({
            ...basketAdapter.fromBasketItemDto(i),
          }),
        ),
      );
  };

  const recoverBasketItems = (basket: BasketDetailsResponse | never[]) => {
    if (basket?.basketItems && basket.basketItems.length > 0)
      basket.basketItems.map((i) =>
        dispatch(
          basketSlice.actions.addItem({
            ...basketAdapter.fromBasketItemDto(i),
          }),
        ),
      );
  };

  return {
    basket,
    basketLimitReached,
    addSession,
    clearBasket,
    removeSession,
    getTotal,
    getEarliestExpiryDate,
    filterReservationsForActiveBasketSessions,
    reserveItemsInBasket,
    recoverBasketItemsLegacy,
    recoverBasketItems,
    isUpdating,
  };
};
