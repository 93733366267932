import { FC, useContext, useEffect } from 'react';

import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useTranslation } from 'react-i18next';

import { Alert, ComponentWrapperProps, FormElementProps, LoadingProps } from '@/components';
import { GoogleTagManager, GroupedSessions } from '@/core';
import { NoScrollbar } from '@/components/presentation/calendar/NoScrollbar';
import { TestPackageDto } from '@/store/webApi/webApi';
import { cn } from '@/core/helpers/cn';

import { SessionItem } from './SessionItem';
import 'react-horizontal-scrolling-menu/dist/styles.css';

interface SessionListProps extends ComponentWrapperProps {
  groupedSessions: GroupedSessions;
  handleClick?: (id?: string) => void;
  testPackage?: TestPackageDto;
}

export const SessionsList: FC<SessionListProps & FormElementProps & LoadingProps> = ({
  formErrorMessages,
  handleClick,
  id,
  isLoading,
  groupedSessions,
  testPackage,
}): JSX.Element => {
  const { t } = useTranslation();

  useEffect(() => {
    GoogleTagManager.addEvent({
      action: 'SessionsList',
      event: 'VirtualPageview',
      virtualPageTitle: 'British Council - Book a new test - Choose location and time',
      virtualPageTitleLocal: document.title,
      virtualPageURL: '/book-test/choose-session/',
    });
  }, []);

  return (
    <ul className="flex flex-col gap-[16px]" data-testid={id}>
      {isLoading && <p className="text-center">Loading...</p>}
      {!isLoading && !groupedSessions.length && (
        <Alert alertType="information" show text={t('choose-session.no-sessions-found')} title={t('common.no-results-found')} />
      )}
      {formErrorMessages?.[0] ? <Alert alertType="warning" show text={t('choose-session.sessions-fetch-error')} title={t('common.no-results-found')} /> : null}
      {groupedSessions.map((group) => (
        <li key={`${group[0].id}${group[0].startDateTimeUtc}`} data-testid={`venue-${group[0].venue?.id}`}>
          <NoScrollbar>
            <ul id={`${id}-${group[0].id}${group[0].startDateTimeUtc}`}>
              {group[0].venue && <h5 className="font-bold break-all">{group[0].venue.name}</h5>}
              <ScrollMenu LeftArrow={<Arrow direction="left" />} RightArrow={<Arrow direction="right" />}>
                {group.map((session) => (
                  <SessionItem
                    delivery={session?.deliveryMode}
                    handleClick={handleClick}
                    itemId={session.id ?? ''}
                    key={session.id}
                    session={session}
                    testPackage={testPackage}
                  />
                ))}
              </ScrollMenu>
            </ul>
          </NoScrollbar>
        </li>
      ))}
    </ul>
  );
};

const Arrow = ({ direction }: { direction: 'left' | 'right' }) => {
  const { isFirstItemVisible, scrollPrev, isLastItemVisible, scrollNext, initComplete } = useContext(VisibilityContext);
  const className = (active: boolean) => cn('text-blue-dark cursor-pointer relative top-[40%]', { 'text-grey-light': active });

  if ((isFirstItemVisible && isLastItemVisible) || !initComplete) {
    return null;
  }

  return direction === 'left' ? (
    <FiChevronLeft size={24} onClick={() => scrollPrev()} className={className(isFirstItemVisible)} />
  ) : (
    <FiChevronRight size={24} onClick={() => scrollNext()} className={className(isLastItemVisible)} />
  );
};
