/* istanbul ignore file */
import { Countries, Country } from '@/core';

export const getCountryMockById = (include: boolean, countryId: number): Country | undefined => {
  if (!include) return;
  const result = mockCountries.find((country) => country.id === countryId);
  if (result) return result;
  return mockCountries[1];
};

export const getCountryMockByIso = (include: boolean, countryIso: string): Country | undefined => {
  if (!include) return;
  const result = mockCountries.find((country) => country.isoCode === countryIso);
  if (result) return result;
  return mockCountries[1];
};

export const mockGermany: Country = { id: 125, currency: 'EUR', isoCode: 'de', name: 'Germany' };
export const mockPoland: Country = { id: 100, currency: 'PLN', isoCode: 'pl', name: 'Poland' };
//TODO: mockSpain is used as the default country in the running app.
//       Spain's id of 1 currently matches the API expectation but this may change
//       when it does this id will need to be updated to match the API expectation
//        There shouldn't be any hard-coded id's for Spain anywhere,
//        this object should be referenced so this is the only place that will require updating
export const mockSpain: Country = { id: 1, currency: 'EUR', isoCode: 'es', name: 'Spain' };
export const mockUK: Country = { id: 250, currency: 'GBP', isoCode: 'gb', name: 'UK' };

export const mockCountries: Countries = [mockSpain, mockPoland, mockGermany, mockUK];
