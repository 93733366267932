import { useAuth } from 'react-oidc-context';
import { useForm } from 'react-hook-form';

import { DateInputs } from '@/components/forms/FormDate';
import { Gender, MarketingPreference, useLazyIdentityGetCustomerBoundStatusQuery } from '@/store/webApi';
import { webApi } from '@/store/webApi/enhancedWebApi';

export const MarketingPreferenceType = {
  NotSpecified: 0 as MarketingPreference,
  DoNotSend: 1 as MarketingPreference,
  BcOnly: 2 as MarketingPreference,
  BcAndThirdParties: 3 as MarketingPreference,
};

/** When extending this type, try to be as close as possible to API contracts */
export type PersonalDetailsFormType = {
  firstName?: string;
  lastName?: string;
  gender?: Gender;
  otherGenders?: string;
  address: {
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    postCode?: string;
    residenceCountryId?: number;
  };
  mobileNumber?: string;
  mobileDialCode?: string;
  mobileNumberPrefixCountry?: string;
  email?: string;
  marketingPreference?: MarketingPreference;
  isGDPRConsentProvided: boolean;
} & DateInputs<'dateOfBirth'>;

export const usePersonalDetailsForm = () => {
  const auth = useAuth();
  const [triggerGetBoundStatus, getBoundStatusQuery] = useLazyIdentityGetCustomerBoundStatusQuery();
  const [triggerGetCustomerDetails, getCustomerDetailsQuery] = webApi.useLazyCustomerGetCustomerDetailsQuery();

  const methods = useForm<PersonalDetailsFormType>({
    mode: 'onTouched',
    criteriaMode: 'all',
    defaultValues: async () => {
      let defaultValue = { email: auth.user?.profile?.email, mobileNumber: '' } as PersonalDetailsFormType;
      const boundStatusResponse = await triggerGetBoundStatus();

      if (boundStatusResponse.data?.boundStatus === 3) {
        const customerData = await triggerGetCustomerDetails();
        const dateOfBirth = new Date(customerData?.data?.dateOfBirth ?? '');

        defaultValue = {
          firstName: customerData.data?.firstName ?? '',
          lastName: customerData.data?.lastName ?? '',
          gender: customerData.data?.gender ?? undefined,
          otherGenders: customerData.data?.gender === 0 ? customerData.data?.otherGenders ?? '' : undefined,
          address: {
            addressLine1: customerData.data?.address?.addressLine1 ?? '',
            addressLine2: customerData.data?.address?.addressLine2 ?? '',
            city: customerData.data?.address?.city ?? '',
            postCode: customerData.data?.address?.postCode ?? '',
            residenceCountryId: customerData.data?.address?.residenceCountryId ?? 0,
          },
          mobileNumber: customerData.data?.mobileNumber ?? '',
          email: customerData.data?.email ?? '',
          //During creation personal details for the customer,  we set the default value for marketing preferences to "DoNotSend"
          marketingPreference:
            customerData.data?.marketingPreference && customerData.data?.marketingPreference !== MarketingPreferenceType.NotSpecified
              ? customerData.data?.marketingPreference
              : MarketingPreferenceType.DoNotSend,
          isGDPRConsentProvided: customerData.data?.isGDPRConsentProvided ?? false,
          dateOfBirth: dateOfBirth ? { day: dateOfBirth.getDate(), month: dateOfBirth.getMonth(), year: dateOfBirth.getFullYear() } : undefined,
        };
      }

      return defaultValue;
    },
  });

  return {
    methods: methods,
    isLoading:
      getBoundStatusQuery.isLoading ||
      getBoundStatusQuery.isUninitialized ||
      getCustomerDetailsQuery.isLoading ||
      (getBoundStatusQuery.data?.boundStatus === 3 && getCustomerDetailsQuery.isUninitialized),
  };
};
