import { FC } from 'react';

import { IconProps } from '@/icons/IconProps';
import { noAction } from '@/core';

export const ChevronDown: FC<IconProps> = ({ index = 0, onClick = noAction, onMouseDown = noAction, onMouseOut = noAction, onMouseUp = noAction }) => {
  return (
    <svg
      aria-hidden="true"
      aria-labelledby={`chevron-down-icon-title-${index}`}
      className="chevron chevron-down-icon"
      data-testid={`chevron-down-icon-${index}`}
      fill="none"
      focusable="true"
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseOut={onMouseOut}
      role="img"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`chevron-down-icon-title-${index}`}></title>
      <polyline points="6 9 12 15 18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></polyline>
    </svg>
  );
};
