import { EnrolCustomerResponse } from '@/store/webApi';
import { useAppSelector, useBasket } from '@/core';
import { webApi } from '@/store/webApi/enhancedWebApi';

export const useEnrolMutation = () => {
  const { journey, minorPickup } = useAppSelector((s) => s);
  const { filterReservationsForActiveBasketSessions } = useBasket();

  const [enrolCustomer, { isLoading: isProcessEnrolCustomer, error: errorProcessEnrolCustomer, data: enrolCustomerData }] =
    webApi.useEnrolmentEnrolCustomerMutation();
  const [enrolMinor, { isLoading: isProcessEnrolMinor, error: errorProcessEnrolMinor, data: enrolMinorData }] = webApi.useEnrolmentEnrolMinorMutation();

  const enrol = async (): Promise<EnrolCustomerResponse> => {
    const reservationIds = filterReservationsForActiveBasketSessions();
    let responseData = {} as EnrolCustomerResponse;
    if (journey.isChild) {
      await enrolMinor({
        minorId: journey.minorId ?? '',
        enrolMinorRequest: {
          reservationIds: reservationIds as string[],
          minorPickUpOption: minorPickup.pickupType,
          guardianInfo:
            minorPickup.pickupType === 3
              ? {
                  fullName: `${minorPickup?.guardian?.firstName} ${minorPickup?.guardian?.lastName}`,
                  telephone: minorPickup?.guardian?.telephone,
                  relationshipId: minorPickup?.guardian?.relationshipId,
                  ageDeclaration: minorPickup?.guardian?.ageDeclaration,
                }
              : null,
        },
      }).then((response) => {
        if ('data' in response) responseData = response.data as EnrolCustomerResponse;
      });
    } else {
      await enrolCustomer({
        enrolCustomerRequest: {
          reservationIds: reservationIds as string[],
        },
      }).then((response) => {
        if ('data' in response) responseData = response.data as EnrolCustomerResponse;
      });
    }

    return responseData;
  };

  return {
    enrol,
    isLoading: isProcessEnrolCustomer || isProcessEnrolMinor,
    error: errorProcessEnrolCustomer || errorProcessEnrolMinor,
    data: enrolCustomerData || enrolMinorData,
  };
};
