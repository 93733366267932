import { ReactNode, forwardRef } from 'react';

import { InputReg, InputRegProps, Label, LabelProps, ValidatedField, ValidatedFieldProps } from '@britishcouncil/react-registration-platform';

import { cn } from '@/core/helpers/cn';

export type FormInputProps = InputRegProps &
  Pick<ValidatedFieldProps, 'errorInline' | 'errorSubmittion'> &
  Pick<LabelProps, 'helpText'> & {
    label?: ReactNode;
  };

export const FormInput = forwardRef<HTMLInputElement, FormInputProps>(({ errorInline, className, errorSubmittion, label, helpText, ...props }, ref) => {
  return (
    <div>
      {label ? (
        <Label placeholder="" helpText={helpText} htmlFor={props.name || props.id}>
          {label}
        </Label>
      ) : null}
      {/* Class reg-react-select__input can be delete after refacor all inputs and remove box-shadow styles form global-css  */}
      <ValidatedField errorInline={errorInline} errorSubmittion={errorSubmittion}>
        <InputReg className={cn('reg-react-select__input mt-1', className)} ref={ref} {...props} />
      </ValidatedField>
    </div>
  );
});

FormInput.displayName = 'FormInput';
