import { useCallback, useEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Alert,
  Button,
  ButtonStyle,
  ButtonsWrapper,
  ComponentWrapper,
  FlowIndicator,
  Form,
  FormSection,
  FormSectionTitle,
  OuterWrapper,
  PageFormWrapper,
  PageTitle,
  Select,
  Step,
  createErrorMessage,
} from '@/components';
import { Country } from '@/store/webApi';
import {
  FlowSteps,
  GoogleTagManager,
  Option,
  bookTestIdentificationRoute,
  bookTestReviewRoute,
  getTotalSteps,
  isMobileTrack,
  params,
  useAppDispatch,
  useAppParams,
  useAppSelector,
  useBasket,
  useDocumentTitle,
} from '@/core';
import { FormInput } from '@/components/forms/FormInput';
import { clearIdentificationDetails, getReturnToReviewFrom, identificationDetailsSlice, minorIdentificationDetailsSlice } from '@/store';
import { useCountryOptions } from '@/core/hooks/useCountryOptions';

import { ExpiryDate } from './ExpiryDate';
import { IdentificationTypeChoice } from './IdentificationType';

type Inputs = {
  identificationNumber: string;
  issuingAuthority: string;
};

export const IdentificationLegacy = () => {
  const { countries, countriesError, isLoadingCountries } = useCountryOptions();
  const country = useAppSelector((s) => s.searchDetails.country);
  const { isChild } = useAppSelector((s) => s.journey);
  const identificationDetails = useAppSelector((s) => (isChild ? s.minorIdentificationDetails : s.identificationDetails));
  const slice = isChild ? minorIdentificationDetailsSlice : identificationDetailsSlice;

  const { productCode, step } = useAppParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Inputs>({
    mode: 'onTouched',
    criteriaMode: 'all',
    defaultValues: {
      identificationNumber: identificationDetails.identificationNumber,
      issuingAuthority: identificationDetails.issuingAuthority,
    },
  });

  const { getEarliestExpiryDate } = useBasket();

  const dispatch = useAppDispatch();

  const { t } = useTranslation('');
  const { t: tRoutes } = useTranslation('routes');
  const navigate = useNavigate();

  useDocumentTitle(`${tRoutes('book-new-test.title')} - ${tRoutes('book-new-test.identification')}`);

  useEffect(() => {
    GoogleTagManager.addEvent({
      Country: country?.isoCode,
      event: 'CustomerIdentificationStep',
      virtualPageTitle: 'British Council - Book a new test - Your identification',
      virtualPageTitleLocal: document.title,
      virtualPageURL: '/book-test/identification/',
    });
  }, [country?.isoCode]);

  const handleCountryChange = useCallback(
    (country: Option<Country>) => {
      dispatch(slice.actions.setIdentificationNationalityCountryId(Number(country.value)));
    },
    [dispatch],
  );

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    GoogleTagManager.addEvent({
      country: country?.isoCode,
      data: identificationDetails,
      event: 'CustomerIdentificationStepCompletion',
      virtualPageTitle: document.title,
      virtualPageURL: window.location.pathname,
    });

    dispatch(
      slice.actions.setIdentificationDetails({
        identificationNumber: data.identificationNumber,
        issuingAuthority: data.issuingAuthority,
      }),
    );

    navigate(
      bookTestReviewRoute.absolutePath
        .replace(params.productCode, productCode || '')
        .replace(params.step, step !== FlowSteps.flowStepForDesktopPath ? FlowSteps.flowStepForMobilePath : FlowSteps.flowStepForDesktopPath),
    );
  };

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onFormClear = useCallback(() => {
    dispatch(clearIdentificationDetails());
    reset();
  }, [dispatch, identificationDetails]);

  const totalSteps = getTotalSteps(window.innerWidth, isChild);
  const activeFlowStep = isMobileTrack(window.innerWidth) ? 5 : 2;
  const mobileTrack = isMobileTrack(window.innerWidth);

  const returnToReviewFrom = useSelector(getReturnToReviewFrom);

  return (
    <div>
      <header className="header">
        <FlowIndicator
          currentFormStep={activeFlowStep}
          currentStep={FlowSteps.sessionSelectionFinished}
          formSteps={totalSteps}
          steps={FlowSteps.totalStepsForSessionSelection}
        />
        {mobileTrack && (
          <Step bottomMargin={'var(--whitespace-basic-08)'}>
            {t('general-step', {
              stepNumber: `${activeFlowStep}`,
              stepsTotal: `${totalSteps}`,
            })}
          </Step>
        )}
        <PageTitle
          hideOn="mobile"
          marginBottom={'var(--whitespace-32-to-08)'}
          text={tRoutes(isChild ? 'book-new-test.minor-identification' : 'book-new-test.identification')}
        />
        {!mobileTrack && (
          <Step bottomMargin={'var(--whitespace-32-to-72)'}>
            {t('general-step', {
              stepNumber: `${activeFlowStep}`,
              stepsTotal: `${totalSteps}`,
            })}
          </Step>
        )}
      </header>
      <PageFormWrapper>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormSection>
            <FormSectionTitle>{t('identification.type.title')}</FormSectionTitle>

            <Alert alertType="information" show text={t(isChild ? 'minor-identification.note' : 'identification.note')} title={t('common.please-note')}>
              {isChild ? <p>{t('minor-identification.note2')}</p> : null}
            </Alert>
            <OuterWrapper>
              <ComponentWrapper addBackground whiteBackground id="identification-form">
                <IdentificationTypeChoice identificationType={identificationDetails.identificationType} isChild={isChild} />
                <FormInput
                  data-testid="id-number"
                  {...register('identificationNumber', { required: t('common.required') })}
                  errorInline={errors.identificationNumber?.message}
                  label={
                    identificationDetails.identificationType === 1 ? t('identification.details.id-card.number') : t('identification.details.passport.number')
                  }
                />
                <ExpiryDate
                  identificationType={identificationDetails.identificationType}
                  defaultExpiryDate={identificationDetails?.expiryDate}
                  earliestExpiryDate={getEarliestExpiryDate()}
                  noIdCardExpiryDate={identificationDetails.noExpiryDate}
                  isChild={isChild}
                />
                <FormInput
                  data-testid="issuing-authority"
                  {...register('issuingAuthority', { required: t('common.required') })}
                  errorInline={errors.issuingAuthority?.message}
                  label={t('identification.details.issuing-authority')}
                />
                <Select<Country>
                  defaultSelectedValue={identificationDetails?.nationalityCountryId?.toString()}
                  formErrorMessages={countriesError && [createErrorMessage({ isFormError: true, text: countriesError.response, type: 'form' })]}
                  id="country-of-nationality"
                  isLoading={isLoadingCountries}
                  label={t('identification.nationality')}
                  onSelectionChange={handleCountryChange}
                  options={countries}
                  required
                  requiredText={t('common.required')}
                />
                <ButtonsWrapper>
                  <Button onClick={onFormClear} buttonStyle={ButtonStyle.DiscreteRed} text={t('common.clear-form')} />
                </ButtonsWrapper>
              </ComponentWrapper>
            </OuterWrapper>
          </FormSection>

          <ButtonsWrapper>
            <Button addArrow="left" buttonStyle={ButtonStyle.Secondary} onClick={goBack} text={t('components.back-button')} />
            <Button
              addArrow="right"
              buttonStyle={ButtonStyle.Primary}
              text={t(returnToReviewFrom === bookTestIdentificationRoute.absolutePath ? 'tests-and-results.upcoming.save-and-review' : 'identification.cta')}
              type="submit"
            />
          </ButtonsWrapper>
        </Form>
      </PageFormWrapper>
    </div>
  );
};
