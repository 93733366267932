import { ErrorMessages, TextInputType, createErrorMessage } from '@/components';
import { validateEmail } from '@/core';

import { ValidateMethod, ValidateMethods, ValidateOptions } from '..';

export const addEmailValidation = (inputType: TextInputType, validationMethods: ValidateMethods) => {
  const validateText: ValidateMethod = (text: string, validationErrors: ErrorMessages, options: ValidateOptions) => {
    if (!validateEmail(text)) return [...validationErrors, createErrorMessage({ text: options.errorMessages?.invalidEmailMessage || '', type: 'internal' })];
    return validationErrors;
  };

  if (inputType === 'email') validationMethods.push(validateText);
};
