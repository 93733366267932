type Props = {
  onClick?: () => void;
};
export const Empty = ({ onClick }: Props) => {
  return (
    <div>
      <h5>Your summary is empty</h5>
      <p onClick={onClick} className={onClick ? 'cursor-pointer text-purple-dark' : 'text-purple-dark'}>
        Please select the country, exam, test city and dates.
      </p>
    </div>
  );
};
