export interface CreateOrderResponse {
  domainId: string;
}

export interface GetOrderResponse {
  domainId: string;
  status: OrderStatus;
  correlationId: string;
  countryIsoCode: string;
  currencyIsoCode: string;
  orderItems: OrderItemDto[];
}

export enum OrderStatus {
  Created = 1,
  AwaitingPayment = 2,
  PaymentFailed = 3,
  Completed = 4,
}

export interface OrderItemDto {
  description: string;
  quantity: number;
  gross: number;
  net: number;
  tax: number;
}
