import { HTMLAttributes } from 'react';

import { FiChevronLeft, FiChevronRight, FiLoader } from 'react-icons/fi';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, ButtonProps } from '@britishcouncil/react-registration-platform';

import { cn } from '@/core/helpers/cn';
import { getReturnToReviewFrom } from '@/store';

type Props = HTMLAttributes<HTMLDivElement> & {
  submitButton: Omit<ButtonProps, 'type' | 'disabled'> & { label?: string; isLoading?: boolean };
  backButton: ButtonProps & { label?: string };
};

export const StepButtonsForm = ({
  submitButton: { label: submitButtonLabel, isLoading, ...submitButtonRest },
  backButton: { label: backButtonLabel, ...backButtonRest },
  className,
  ...rest
}: Props) => {
  const { t } = useTranslation('');
  const returnToReviewFrom = useSelector(getReturnToReviewFrom);
  const [searchParams] = useSearchParams();
  const editMode = searchParams.get('edit');

  return (
    <div className={cn('flex justify-start gap-6 ', className)} {...rest}>
      <Button type="button" variant="secondary" {...backButtonRest}>
        <FiChevronLeft className="text-[20px] relative top-[-1px] mr-[10px] hidden md:block" /> {backButtonLabel ? backButtonLabel : t('common.back-button')}
      </Button>
      <Button type="submit" disabled={isLoading} className="w-auto" {...submitButtonRest}>
        {submitButtonLabel ? submitButtonLabel : t(returnToReviewFrom || editMode ? 'common.save-and-review' : 'common.next-button')}
        {isLoading ? (
          <FiLoader className="text-[20px] relative top-[-2px] ml-[10px] animate-spin-slow inline-block"></FiLoader>
        ) : (
          <FiChevronRight className="text-[20px] relative top-[-1px] ml-[10px] hidden md:block" />
        )}
      </Button>
    </div>
  );
};
