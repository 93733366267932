import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from '@britishcouncil/react-registration-platform';

import { Loading } from '@/components';

import { useDownloadCertificate } from '../hooks';

export interface ResultsAndCertificateProps {
  enrolmentId: string;
  minorId?: string;
}

export const ResultsAndCertificates = ({ enrolmentId, minorId }: ResultsAndCertificateProps) => {
  const { t } = useTranslation();
  const { getCertificate, isCertificateAvailable, isCertificateLoading, isCertificateAvailabilityLoading } = useDownloadCertificate(enrolmentId, minorId);

  const certificateTitle = useMemo(
    () => (minorId ? t('dashboard.results-and-certificates.your-childs-certificate') : t('dashboard.results-and-certificates.your-certificate')),
    [minorId, t],
  );

  if (isCertificateAvailabilityLoading) return <Loading />;

  return (
    <>
      {isCertificateAvailable ? (
        <div className="flex flex-col gap-4">
          <p className="font-bold">{certificateTitle}</p>
          <Button className="max-w-md" onClick={() => getCertificate()} disabled={isCertificateLoading}>
            {t('dashboard.results-and-certificates.get-certificate')}
          </Button>
        </div>
      ) : (
        <p className="font-bold">{t('dashboard.results-and-certificates.no-certificate')}</p>
      )}
    </>
  );
};
