import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { Account, Create, Verify } from '@/pages/account';
import { AccountLayout } from '@/components/layouts/AccountLayout';
import { BasketLayout } from '@/components';
import { BookTest, BookingComplete, Identification, MinorDetails, PersonalDetails, Review } from '@/pages/book-test';
import { Callback } from '@/pages/auth/Callback';
import { CustomerData, CustomerTests } from '@/pages/dashboard';
import { DashboardLayout } from '@/components/dashboard/DashboardLayout';
import { ErrorPage } from '@/pages/errors/ErrorPage';
import { Home } from '@/pages/home';
import { RootLayout } from '@/components/layouts/RootLayout';
import { TermsConditions } from '@/pages/terms/TermsConditions';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          { index: true, element: <Home /> },
          { path: 'auth-callback', element: <Callback /> },

          /* Account pages */
          {
            path: 'account',
            element: <AccountLayout />,
            children: [
              {
                index: true,
                element: <Account />,
              },
              {
                path: 'create',
                element: <Create />,
              },
              {
                path: 'verify',
                element: <Verify />,
              },
            ],
          },
          {
            path: 'dashboard',
            element: <DashboardLayout />,
            children: [
              {
                path: 'tests',
                element: <CustomerTests />,
              },
              {
                path: 'data',
                element: <CustomerData />,
              },
            ],
          },
          /* Book test flow pages */
          {
            path: ':productCode/book-test',
            element: <BasketLayout />,
            children: [
              {
                index: true,
                element: <BookTest />,
              },
              {
                path: 'personal-details/:step',
                element: <PersonalDetails />,
              },
              {
                path: 'minor-details/:step',
                element: <MinorDetails />,
              },
              {
                path: 'identification/:step',
                element: <Identification />,
              },
              {
                path: 'minor-identification/:step',
                element: <Identification />,
              },
              {
                path: 'review/:step',
                element: <Review />,
              },
              {
                path: 'booking-complete',
                element: <BookingComplete />,
              },
            ],
          },
          {
            path: 'terms-and-conditions',
            element: <TermsConditions />,
          },
        ],
      },
    ],
  },
]);

export const AppRoutes = () => <RouterProvider router={router} />;
