import styled from '@emotion/styled';
import { breakpoints } from '@/core';

export const ReviewCell = styled.div<{ bold?: boolean; smaller?: boolean }>`
  display: block;
  ${({ bold }) => bold && 'font-weight: bold;'};
  font-size: var(--h6-font-size);

  ${() => breakpoints.isTablet.mediaMinWidth} {
    ${({ smaller }) => !smaller && 'font-size: var(--base-font-size);'};
  }
`;

export const ReviewGrid = styled.section`
  display: grid;
  row-gap: 32px;
  margin-bottom: var(--review-grid);

  ${() => breakpoints.isTablet.mediaMinWidth} {
    background: var(--white);
  }
`;

export const ReviewGridRowSection = styled.section`
  display: grid;
  row-gap: 8px;
`;

export const ReviewGridWrapper = styled.section`
  padding: var(--whitespace-16-to-24);
  display: grid;
  row-gap: 32px;
  margin-bottom: var(--review-grid);
  border-radius: var(--wrapped-border-radius);
  box-shadow: var(--box-shadow-responsive);

  ${() => breakpoints.isTablet.mediaMinWidth} {
    background: var(--white);
  }
`;
