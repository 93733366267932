import { useCallback } from 'react';

import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MemoPhoneInput, PhoneInputProps } from './PhoneInput';

export type PhoneFormInput = {
  mobileNumber?: string;
};

type Props<T extends PhoneFormInput> = PhoneInputProps & { formContext: UseFormReturn<T> };

export const FormPhoneInput = <T extends PhoneFormInput = PhoneFormInput>({ formContext, ...inputProps }: Props<T>) => {
  const { t } = useTranslation('');

  const typedFormContext = formContext as unknown as UseFormReturn<PhoneFormInput>;

  const onChangeNumber = useCallback(
    (nr?: string) => {
      typedFormContext.setValue('mobileNumber', nr);
    },
    [typedFormContext],
  );

  const onChangeValidity = useCallback(
    (isValid: boolean) => {
      isValid
        ? typedFormContext.clearErrors('mobileNumber')
        : typedFormContext.setError('mobileNumber', { message: t('personal-details.contact-details.mobile-number-err') });
    },
    [t, typedFormContext],
  );

  const errorMsg = typedFormContext.formState.errors.mobileNumber?.message;

  return (
    <>
      <MemoPhoneInput
        {...inputProps}
        label={t('personal-details.contact-details.mobile-number')}
        defaultValue={typedFormContext.getValues('mobileNumber')}
        onChangeNumber={onChangeNumber}
        onChangeValidity={onChangeValidity}
      />
      {errorMsg ? (
        <div className="flex gap-3 mt-2">
          <div className="w-[5px] bg-red-dark rounded-full" />
          <p className="text-red-dark font-bold text-base py-2">{errorMsg}</p>
        </div>
      ) : null}
    </>
  );
};
