import { ReactNode } from 'react';

import {
  Label,
  LabelProps,
  RadioGroup,
  RadioGroupItem,
  RadioGroupItemProps,
  RadioGroupProps,
  ValidatedField,
  ValidatedFieldProps,
} from '@britishcouncil/react-registration-platform';

export type RadioGroupRegProps = RadioGroupProps &
  Pick<ValidatedFieldProps, 'errorInline' | 'errorSubmittion'> &
  Pick<LabelProps, 'helpText'> & { label?: ReactNode; items: (RadioGroupItemProps & { label?: ReactNode; owerideItem?: ReactNode })[] };

export const RadioGroupReg = ({ id, name, label, helpText, items, errorInline, errorSubmittion, ...props }: RadioGroupRegProps) => {
  return (
    <div>
      {label ? (
        <Label placeholder="" helpText={helpText} htmlFor={name || id}>
          {label}
        </Label>
      ) : null}
      <ValidatedField errorInline={errorInline} errorSubmittion={errorSubmittion}>
        <RadioGroup className="mt-2" id={id} name={name} placeholder="" {...props}>
          {items.map(({ owerideItem, id: itemId, value, label, ...props }) =>
            owerideItem ? (
              owerideItem
            ) : (
              <div key={value}>
                <label
                  htmlFor={itemId}
                  className="flex items-center space-x-2.5 sm:border-none sm:px-0 sm:py-0 sm:my-0 border border-grey-dark px-4 py-2 rounded-lg my-1"
                >
                  <RadioGroupItem id={itemId} value={value} placeholder="" {...props} />
                  <Label placeholder="" className="[&>*]:font-light" htmlFor={itemId}>
                    {label}
                  </Label>
                </label>
              </div>
            ),
          )}
        </RadioGroup>
      </ValidatedField>
    </div>
  );
};
