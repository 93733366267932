import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ReviewNameDob } from '@/components';
import { getDateStringLong } from '@/core';

interface AppReviewNameDobProps {
  dateOfBirth: string;
  name: string;
}

export const AppReviewNameDob: FC<AppReviewNameDobProps> = ({ dateOfBirth, name }): JSX.Element => {
  const dateOfBirthText = dateOfBirth ? getDateStringLong(new Date(dateOfBirth)) : '';
  const { t } = useTranslation();
  return (
    <ReviewNameDob
      dateOfBirth={dateOfBirthText}
      dateOfBirthLabel={t('review.personal-details.dob')}
      name={name}
      nameLabel={t('review.personal-details.name')}
    />
  );
};
