import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface SessionTimeState {
  lastUpdated: number;
}

const initialState: SessionTimeState = {
  lastUpdated: new Date().getTime(),
};

const sessionTimeSlice = createSlice({
  name: 'sessionTime',
  initialState,
  reducers: {
    setSessionTime: (state, { payload }: PayloadAction<number>): void => {
      state.lastUpdated = payload;
    },
  },
});

const { setSessionTime } = sessionTimeSlice.actions;

const { reducer: sessionTimeReducer } = sessionTimeSlice;

export { sessionTimeReducer, setSessionTime };
