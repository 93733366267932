import { FC } from 'react';

import * as S from './SkipLink.style';

interface SkipLinkProps {
  id: string;
  skipText: string;
  skipTo: string;
}

export const SkipLink: FC<SkipLinkProps> = ({ id, skipText, skipTo }): JSX.Element => {
  return (
    <S.SkipLink className="visually-hidden" id={id} tabIndex={0} href={`#${skipTo}`}>
      {skipText}
    </S.SkipLink>
  );
};
