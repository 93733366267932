import { emptyWebApi as api } from './_emptyWebApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    sessionGetByCountryForSessions: build.query<SessionGetByCountryForSessionsApiResponse, SessionGetByCountryForSessionsApiArg>({
      query: (queryArg) => ({
        url: `/api/sessions/countries/${queryArg.countryId}/products/${queryArg.productCode}/variants/${queryArg.productVariantId}/test-packages/${queryArg.testPackageId}/cities`,
      }),
    }),
    sessionGetAllCountries: build.query<SessionGetAllCountriesApiResponse, SessionGetAllCountriesApiArg>({
      query: (queryArg) => ({ url: `/api/sessions/products/${queryArg.productCode}/countries` }),
    }),
    sessionGetAllVariants: build.query<SessionGetAllVariantsApiResponse, SessionGetAllVariantsApiArg>({
      query: (queryArg) => ({ url: `/api/sessions/countries/${queryArg.countryId}/products/${queryArg.productCode}/variants` }),
    }),
    sessionGetAllTestPackages: build.query<SessionGetAllTestPackagesApiResponse, SessionGetAllTestPackagesApiArg>({
      query: (queryArg) => ({
        url: `/api/sessions/countries/${queryArg.countryId}/products/${queryArg.productCode}/variants/${queryArg.productVariantId}/test-packages`,
      }),
    }),
    sessionGetSessionDatesForCityTestPackage: build.query<SessionGetSessionDatesForCityTestPackageApiResponse, SessionGetSessionDatesForCityTestPackageApiArg>({
      query: (queryArg) => ({
        url: `/api/sessions/countries/${queryArg.countryId}/products/${queryArg.productCode}/variants/${queryArg.productVariantId}/test-packages/${queryArg.testPackageId}/cities/${queryArg.cityId}/session-dates`,
        params: { dateFrom: queryArg.dateFrom, dateTo: queryArg.dateTo },
      }),
    }),
    sessionGetSessionsForCityTestPackage: build.query<SessionGetSessionsForCityTestPackageApiResponse, SessionGetSessionsForCityTestPackageApiArg>({
      query: (queryArg) => ({
        url: `/api/sessions/countries/${queryArg.countryId}/products/${queryArg.productCode}/variants/${queryArg.productVariantId}/test-packages/${queryArg.testPackageId}/cities/${queryArg.cityId}`,
        params: { dateFrom: queryArg.dateFrom, dateTo: queryArg.dateTo },
      }),
    }),
    sessionCreateReservation: build.mutation<SessionCreateReservationApiResponse, SessionCreateReservationApiArg>({
      query: (queryArg) => ({ url: `/api/sessions/${queryArg.sessionId}/reservations`, method: 'POST' }),
    }),
    sessionGetCustomerSessionWithVenue: build.mutation<SessionGetCustomerSessionWithVenueApiResponse, SessionGetCustomerSessionWithVenueApiArg>({
      query: (queryArg) => ({ url: `/api/sessions/me`, method: 'POST', body: queryArg.paginationAndSortingParameters }),
    }),
    sessionGetMinorSessionWithVenue: build.mutation<SessionGetMinorSessionWithVenueApiResponse, SessionGetMinorSessionWithVenueApiArg>({
      query: (queryArg) => ({ url: `/api/sessions/me/minors/${queryArg.minorId}`, method: 'POST', body: queryArg.paginationAndSortingParameters }),
    }),
    productsGetConfigurations: build.query<ProductsGetConfigurationsApiResponse, ProductsGetConfigurationsApiArg>({
      query: () => ({ url: `/api/products/configurations` }),
    }),
    paymentsCreate: build.mutation<PaymentsCreateApiResponse, PaymentsCreateApiArg>({
      query: (queryArg) => ({ url: `/api/orders/${queryArg.orderId}/payments`, method: 'POST', headers: { correlationId: queryArg.correlationId } }),
    }),
    orderCreate: build.mutation<OrderCreateApiResponse, OrderCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/orders`,
        method: 'POST',
        body: queryArg.createOrderInSessionRequest,
        headers: { 'Basket-Id': queryArg['Basket-Id'] },
      }),
    }),
    orderGetOrderById: build.query<OrderGetOrderByIdApiResponse, OrderGetOrderByIdApiArg>({
      query: (queryArg) => ({ url: `/api/orders/${queryArg.orderId}/${queryArg.paymentExternalId}` }),
    }),
    orderCreateOrderAcknowledgement: build.mutation<OrderCreateOrderAcknowledgementApiResponse, OrderCreateOrderAcknowledgementApiArg>({
      query: (queryArg) => ({ url: `/api/orders/${queryArg.orderDomainId}/acknowledgement`, method: 'POST', body: queryArg.createOrderAcknowledgementRequest }),
    }),
    identityGetLoggedInCustomerDetails: build.query<IdentityGetLoggedInCustomerDetailsApiResponse, IdentityGetLoggedInCustomerDetailsApiArg>({
      query: () => ({ url: `/api/identity/me` }),
    }),
    identityGetCustomerBoundStatus: build.query<IdentityGetCustomerBoundStatusApiResponse, IdentityGetCustomerBoundStatusApiArg>({
      query: () => ({ url: `/api/identity` }),
    }),
    identityBindCustomerToIdentityId: build.mutation<IdentityBindCustomerToIdentityIdApiResponse, IdentityBindCustomerToIdentityIdApiArg>({
      query: (queryArg) => ({ url: `/api/identity/customer`, method: 'POST', body: queryArg.bindCustomerToIdentityRequest }),
    }),
    enrolmentEnrolCustomer: build.mutation<EnrolmentEnrolCustomerApiResponse, EnrolmentEnrolCustomerApiArg>({
      query: (queryArg) => ({ url: `/api/enrolments`, method: 'POST', body: queryArg.enrolCustomerRequest }),
    }),
    enrolmentEnrolMinor: build.mutation<EnrolmentEnrolMinorApiResponse, EnrolmentEnrolMinorApiArg>({
      query: (queryArg) => ({ url: `/api/enrolments/minors/${queryArg.minorId}`, method: 'POST', body: queryArg.enrolMinorRequest }),
    }),
    enrolmentGetEnrolmentPayments: build.query<EnrolmentGetEnrolmentPaymentsApiResponse, EnrolmentGetEnrolmentPaymentsApiArg>({
      query: (queryArg) => ({ url: `/api/enrolments/${queryArg.enrolmentId}/payments` }),
    }),
    customerCreateCustomer: build.mutation<CustomerCreateCustomerApiResponse, CustomerCreateCustomerApiArg>({
      query: (queryArg) => ({ url: `/api/customers`, method: 'POST', body: queryArg.createCustomerRequest }),
    }),
    customerGetCustomerDetails: build.query<CustomerGetCustomerDetailsApiResponse, CustomerGetCustomerDetailsApiArg>({
      query: () => ({ url: `/api/customers/me` }),
    }),
    customerUpdateCustomerPersonalData: build.mutation<CustomerUpdateCustomerPersonalDataApiResponse, CustomerUpdateCustomerPersonalDataApiArg>({
      query: (queryArg) => ({ url: `/api/customers/me/personal-data`, method: 'PUT', body: queryArg.updateCustomerPersonalDetailsRequest }),
    }),
    customerCreateOrUpdateCustomerIdentificationDocument: build.mutation<
      CustomerCreateOrUpdateCustomerIdentificationDocumentApiResponse,
      CustomerCreateOrUpdateCustomerIdentificationDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/customers/me/identification-document`,
        method: 'PUT',
        body: queryArg.createOrUpdateCustomerIdentificationDocumentRequest,
      }),
    }),
    customerGetCustomerMinors: build.query<CustomerGetCustomerMinorsApiResponse, CustomerGetCustomerMinorsApiArg>({
      query: () => ({ url: `/api/customers/me/minors` }),
    }),
    customerCreateMinor: build.mutation<CustomerCreateMinorApiResponse, CustomerCreateMinorApiArg>({
      query: (queryArg) => ({ url: `/api/customers/me/minors`, method: 'POST', body: queryArg.createMinorRequest }),
    }),
    customerGetMinorDetails: build.query<CustomerGetMinorDetailsApiResponse, CustomerGetMinorDetailsApiArg>({
      query: (queryArg) => ({ url: `/api/customers/me/minors/${queryArg.id}` }),
    }),
    customerUpdateMinorPersonalData: build.mutation<CustomerUpdateMinorPersonalDataApiResponse, CustomerUpdateMinorPersonalDataApiArg>({
      query: (queryArg) => ({ url: `/api/customers/me/minors/${queryArg.id}/personal-data`, method: 'PUT', body: queryArg.updateMinorPersonalDetailsRequest }),
    }),
    customerCreateOrUpdateMinorIdentificationDocument: build.mutation<
      CustomerCreateOrUpdateMinorIdentificationDocumentApiResponse,
      CustomerCreateOrUpdateMinorIdentificationDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/customers/me/minors/${queryArg.id}/identification-document`,
        method: 'PUT',
        body: queryArg.createOrUpdateCustomerIdentificationDocumentRequest,
      }),
    }),
    customerCreateAndEnrol: build.mutation<CustomerCreateAndEnrolApiResponse, CustomerCreateAndEnrolApiArg>({
      query: (queryArg) => ({ url: `/api/customers/create`, method: 'POST', body: queryArg.createAndEnrolRequest }),
    }),
    countryGetAll: build.query<CountryGetAllApiResponse, CountryGetAllApiArg>({
      query: () => ({ url: `/api/countries` }),
    }),
    dictionariesGetAllSpecialRequirements: build.query<DictionariesGetAllSpecialRequirementsApiResponse, DictionariesGetAllSpecialRequirementsApiArg>({
      query: () => ({ url: `/api/dictionaries/special-requirements` }),
    }),
    dictionariesGetAllIdentificationDocumentTypes: build.query<
      DictionariesGetAllIdentificationDocumentTypesApiResponse,
      DictionariesGetAllIdentificationDocumentTypesApiArg
    >({
      query: () => ({ url: `/api/dictionaries/document-types` }),
    }),
    dictionariesGetAllReasonsForTakingTest: build.query<DictionariesGetAllReasonsForTakingTestApiResponse, DictionariesGetAllReasonsForTakingTestApiArg>({
      query: () => ({ url: `/api/dictionaries/test-reasons` }),
    }),
    dictionariesGetAllMinorPickupPersons: build.query<DictionariesGetAllMinorPickupPersonsApiResponse, DictionariesGetAllMinorPickupPersonsApiArg>({
      query: () => ({ url: `/api/dictionaries/minor-pickup-persons` }),
    }),
    featuresGetAllFeatures: build.query<FeaturesGetAllFeaturesApiResponse, FeaturesGetAllFeaturesApiArg>({
      query: () => ({ url: `/api/features` }),
    }),
    certificateIsCertificateAvailable: build.query<CertificateIsCertificateAvailableApiResponse, CertificateIsCertificateAvailableApiArg>({
      query: (queryArg) => ({ url: `/api/certificates/me/enrolments/${queryArg.enrolmentId}/certificate-availability` }),
    }),
    certificateGetCertificate: build.query<CertificateGetCertificateApiResponse, CertificateGetCertificateApiArg>({
      query: (queryArg) => ({ url: `/api/certificates/me/enrolments/${queryArg.enrolmentId}/certificate` }),
    }),
    certificateIsCertificateAvailableForMinor: build.query<
      CertificateIsCertificateAvailableForMinorApiResponse,
      CertificateIsCertificateAvailableForMinorApiArg
    >({
      query: (queryArg) => ({ url: `/api/certificates/me/minors/${queryArg.minorId}/enrolments/${queryArg.enrolmentId}/certificate-availability` }),
    }),
    certificateGetCertificateForMinor: build.query<CertificateGetCertificateForMinorApiResponse, CertificateGetCertificateForMinorApiArg>({
      query: (queryArg) => ({ url: `/api/certificates/me/minors/${queryArg.minorId}/enrolments/${queryArg.enrolmentId}/certificate` }),
    }),
    basketGet: build.query<BasketGetApiResponse, BasketGetApiArg>({
      query: (queryArg) => ({ url: `/api/basket/${queryArg.basketId}` }),
    }),
    basketGetBasket: build.query<BasketGetBasketApiResponse, BasketGetBasketApiArg>({
      query: (queryArg) => ({ url: `/api/baskets/${queryArg.basketId}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type SessionGetByCountryForSessionsApiResponse = /** status 200  */ CityResponse[];
export type SessionGetByCountryForSessionsApiArg = {
  countryId: number;
  productCode: string | null;
  productVariantId: string;
  testPackageId: string | null;
};
export type SessionGetAllCountriesApiResponse = /** status 200  */ Country[];
export type SessionGetAllCountriesApiArg = {
  productCode: string | null;
};
export type SessionGetAllVariantsApiResponse = /** status 200  */ ProductVariantDto[];
export type SessionGetAllVariantsApiArg = {
  countryId: number;
  productCode: string | null;
};
export type SessionGetAllTestPackagesApiResponse = /** status 200  */ TestPackageDto[];
export type SessionGetAllTestPackagesApiArg = {
  countryId: number;
  productCode: string | null;
  productVariantId: string;
};
export type SessionGetSessionDatesForCityTestPackageApiResponse = /** status 200  */ UtcWithLocalDateResponse[];
export type SessionGetSessionDatesForCityTestPackageApiArg = {
  cityId: number;
  countryId: number;
  dateFrom?: string;
  dateTo?: string;
  productCode: string | null;
  productVariantId: string;
  testPackageId: string | null;
};
export type SessionGetSessionsForCityTestPackageApiResponse = /** status 200  */ SessionResponse[];
export type SessionGetSessionsForCityTestPackageApiArg = {
  cityId: number;
  countryId: number;
  dateFrom?: string;
  dateTo?: string;
  productCode: string | null;
  productVariantId: string;
  testPackageId: string | null;
};
export type SessionCreateReservationApiResponse = /** status 200  */ CreateReservationResponse;
export type SessionCreateReservationApiArg = {
  sessionId: string | null;
};
export type SessionGetCustomerSessionWithVenueApiResponse = /** status 200  */ PagedResultOfSessionWithVenueResponse;
export type SessionGetCustomerSessionWithVenueApiArg = {
  paginationAndSortingParameters: PaginationAndSortingParameters;
};
export type SessionGetMinorSessionWithVenueApiResponse = /** status 200  */ PagedResultOfSessionWithVenueResponse;
export type SessionGetMinorSessionWithVenueApiArg = {
  minorId: string | null;
  paginationAndSortingParameters: PaginationAndSortingParameters;
};
export type ProductsGetConfigurationsApiResponse = /** status 200  */ ProductConfigurationDto[];
export type ProductsGetConfigurationsApiArg = void;
export type PaymentsCreateApiResponse = /** status 200  */ CreatePaymentResponse;
export type PaymentsCreateApiArg = {
  orderId: string | null;
  correlationId?: string | null;
};
export type OrderCreateApiResponse = /** status 200  */ CreateOrderResponse;
export type OrderCreateApiArg = {
  'Basket-Id'?: string;
  createOrderInSessionRequest: CreateOrderInSessionRequest;
};
export type OrderGetOrderByIdApiResponse = /** status 200  */ GetOrderResponse;
export type OrderGetOrderByIdApiArg = {
  orderId: string | null;
  paymentExternalId: string | null;
};
export type OrderCreateOrderAcknowledgementApiResponse = /** status 200  */ CreateOrderResponse2;
export type OrderCreateOrderAcknowledgementApiArg = {
  orderDomainId: string | null;
  createOrderAcknowledgementRequest: CreateOrderAcknowledgementRequest;
};
export type IdentityGetLoggedInCustomerDetailsApiResponse = /** status 200  */ IdentityCustomerInfoResponse;
export type IdentityGetLoggedInCustomerDetailsApiArg = void;
export type IdentityGetCustomerBoundStatusApiResponse = /** status 200  */ IdentityCustomerStatusResponse;
export type IdentityGetCustomerBoundStatusApiArg = void;
export type IdentityBindCustomerToIdentityIdApiResponse = /** status 200  */ IdentityCustomerInfoResponse;
export type IdentityBindCustomerToIdentityIdApiArg = {
  bindCustomerToIdentityRequest: BindCustomerToIdentityRequest;
};
export type EnrolmentEnrolCustomerApiResponse = /** status 201  */ EnrolCustomerResponse;
export type EnrolmentEnrolCustomerApiArg = {
  enrolCustomerRequest: EnrolCustomerRequest;
};
export type EnrolmentEnrolMinorApiResponse = /** status 201  */ EnrolCustomerResponse;
export type EnrolmentEnrolMinorApiArg = {
  minorId: string;
  enrolMinorRequest: EnrolMinorRequest;
};
export type EnrolmentGetEnrolmentPaymentsApiResponse = /** status 200  */ EnrolmentPaymentsResponse;
export type EnrolmentGetEnrolmentPaymentsApiArg = {
  enrolmentId: string | null;
};
export type CustomerCreateCustomerApiResponse = unknown;
export type CustomerCreateCustomerApiArg = {
  createCustomerRequest: CreateCustomerRequest;
};
export type CustomerGetCustomerDetailsApiResponse = /** status 200  */ CustomerDataResponse;
export type CustomerGetCustomerDetailsApiArg = void;
export type CustomerUpdateCustomerPersonalDataApiResponse = unknown;
export type CustomerUpdateCustomerPersonalDataApiArg = {
  updateCustomerPersonalDetailsRequest: UpdateCustomerPersonalDetailsRequest;
};
export type CustomerCreateOrUpdateCustomerIdentificationDocumentApiResponse = unknown;
export type CustomerCreateOrUpdateCustomerIdentificationDocumentApiArg = {
  createOrUpdateCustomerIdentificationDocumentRequest: CreateOrUpdateCustomerIdentificationDocumentRequest;
};
export type CustomerGetCustomerMinorsApiResponse = /** status 200  */ CustomerMinorsResponse;
export type CustomerGetCustomerMinorsApiArg = void;
export type CustomerCreateMinorApiResponse = /** status 201  */ string;
export type CustomerCreateMinorApiArg = {
  createMinorRequest: CreateMinorRequest;
};
export type CustomerGetMinorDetailsApiResponse = /** status 200  */ MinorDataResponse;
export type CustomerGetMinorDetailsApiArg = {
  id: string;
};
export type CustomerUpdateMinorPersonalDataApiResponse = unknown;
export type CustomerUpdateMinorPersonalDataApiArg = {
  id: string;
  updateMinorPersonalDetailsRequest: UpdateMinorPersonalDetailsRequest;
};
export type CustomerCreateOrUpdateMinorIdentificationDocumentApiResponse = unknown;
export type CustomerCreateOrUpdateMinorIdentificationDocumentApiArg = {
  id: string;
  createOrUpdateCustomerIdentificationDocumentRequest: CreateOrUpdateCustomerIdentificationDocumentRequest;
};
export type CustomerCreateAndEnrolApiResponse = /** status 200  */ CustomerCreateResponse;
export type CustomerCreateAndEnrolApiArg = {
  createAndEnrolRequest: CreateAndEnrolRequest;
};
export type CountryGetAllApiResponse = /** status 200  */ Country[];
export type CountryGetAllApiArg = void;
export type DictionariesGetAllSpecialRequirementsApiResponse = /** status 200  */ DictionaryDto[];
export type DictionariesGetAllSpecialRequirementsApiArg = void;
export type DictionariesGetAllIdentificationDocumentTypesApiResponse = /** status 200  */ DictionaryDto[];
export type DictionariesGetAllIdentificationDocumentTypesApiArg = void;
export type DictionariesGetAllReasonsForTakingTestApiResponse = /** status 200  */ DictionaryDto[];
export type DictionariesGetAllReasonsForTakingTestApiArg = void;
export type DictionariesGetAllMinorPickupPersonsApiResponse = /** status 200  */ DictionaryDto[];
export type DictionariesGetAllMinorPickupPersonsApiArg = void;
export type FeaturesGetAllFeaturesApiResponse = /** status 200  */ {
  [key: string]: boolean;
};
export type FeaturesGetAllFeaturesApiArg = void;
export type CertificateIsCertificateAvailableApiResponse = unknown;
export type CertificateIsCertificateAvailableApiArg = {
  enrolmentId: string | null;
};
export type CertificateGetCertificateApiResponse = unknown;
export type CertificateGetCertificateApiArg = {
  enrolmentId: string | null;
};
export type CertificateIsCertificateAvailableForMinorApiResponse = unknown;
export type CertificateIsCertificateAvailableForMinorApiArg = {
  minorId: string;
  enrolmentId: string | null;
};
export type CertificateGetCertificateForMinorApiResponse = unknown;
export type CertificateGetCertificateForMinorApiArg = {
  minorId: string;
  enrolmentId: string | null;
};
export type BasketGetApiResponse = /** status 200  */ BasketItemDto[];
export type BasketGetApiArg = {
  basketId: string;
};
export type BasketGetBasketApiResponse = /** status 200  */ BasketDetailsResponse;
export type BasketGetBasketApiArg = {
  basketId: string;
};
export type CityResponse = {
  id?: number;
  countryId?: number;
  name?: string;
};
export type Country = {
  id?: number;
  isoCode?: string | null;
  name?: string | null;
};
export type ProductVariantDto = {
  id?: string;
  countryId?: number;
  description?: string | null;
  productId?: string;
  title?: string | null;
  type?: string | null;
  fromFee?: number | null;
  fromTax?: number | null;
  currency?: string | null;
};
export type TestPackageDto = {
  id?: string | null;
  name?: string | null;
  fee?: number | null;
  totalFee?: number | null;
  description?: string | null;
  lengthMinutes?: number;
  productVariantId?: string;
  tax?: number | null;
  currency?: string | null;
};
export type UtcWithLocalDateResponse = {
  utcDate?: string;
  localDate?: string;
  dateOffset?: number;
};
export type SectionDto = {
  id?: string | null;
  description?: string | null;
};
export type SessionStatus = 1 | 2 | 3 | 4;
export type VenueDto = {
  id?: string | null;
  name?: string | null;
  timezone?: string | null;
  capacity?: number;
  city?: string | null;
  countryId?: number;
  address?: string | null;
  specialRequirements?: string[] | null;
  cityId?: number;
  organisationId?: string | null;
  country?: string | null;
  countryCode?: string | null;
};
export type PackageDto = {
  packageId?: string | null;
  windowId?: string | null;
  skills?: SectionDto[] | null;
};
export type SessionResponse = {
  id?: string;
  name?: string;
  startDateTimeUtc?: string;
  endDateTimeUtc?: string;
  timeSlotDescription?: string;
  seatsAllocation?: number;
  seatsAvailable?: number;
  seatsReservation?: number;
  productId?: string;
  packageId?: string;
  windowId?: string;
  sessionComponents?: string[];
  skills?: SectionDto[];
  sessionStatus?: string;
  sessionStatusEnum?: SessionStatus;
  sessionStatusId?: string;
  isB2B?: boolean;
  venue?: VenueDto;
  productName?: string;
  productVariantName?: string;
  productCode?: string;
  packages?: PackageDto[];
  deliveryMode?: string;
};
export type CreateReservationResponse = {
  sessionId?: string | null;
  reservationId?: string;
  expiryTimeUtc?: string;
};
export type SessionWithVenueResponse = {
  enrolmentId?: string | null;
  sessionName?: string | null;
  startDateUtc?: string;
  endDateUtc?: string;
  durationInMinutes?: number;
  sessionStats?: string | null;
  price?: number | null;
  currency?: string | null;
  venueName?: string | null;
  postCode?: string | null;
  buildingStreet?: string | null;
  city?: string | null;
  country?: string | null;
  timezone?: string | null;
  deliveryMode?: string | null;
  productCode?: string | null;
  skills?: string[] | null;
};
export type PagedResultOfSessionWithVenueResponse = {
  results?: SessionWithVenueResponse[] | null;
  totalCount?: number;
};
export type PaginationParameters = {
  page?: number;
  pageSize?: number;
};
export type SortDirection = 0 | 1 | 2;
export type SortingParameters = {
  sortField?: string | null;
  sortDir?: SortDirection;
};
export type PaginationAndSortingParameters = {
  pagination?: PaginationParameters | null;
  sorting?: SortingParameters | null;
  isSortingSpecified?: boolean;
  isPaginationSpecified?: boolean;
};
export type ProductConfigurationDto = {
  productCode?: string;
  enrollmentQuantityLimitPerOrder?: number | null;
  supportedFeatures?: string[];
};
export type CreatePaymentResponse = {
  paymentExternalId?: string | null;
  paymentRedirectUrl?: string | null;
};
export type CreateOrderResponse = {
  domainId?: string | null;
  correlationId?: string | null;
};
export type CreateOrderInSessionRequest = {
  customerId?: string;
};
export type OrderStatus = 1 | 2 | 3 | 4;
export type OrderItemDto = {
  description?: string | null;
  quantity?: number;
  gross?: number;
  net?: number | null;
  tax?: number | null;
};
export type GetOrderResponse = {
  domainId?: string | null;
  status?: OrderStatus;
  correlationId?: string | null;
  countryIsoCode?: string | null;
  currencyIsoCode?: string | null;
  orderItems?: OrderItemDto[] | null;
};
export type CreateOrderResponse2 = {
  domainId?: string | null;
};
export type AddressRequest = {
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  postCode?: string | null;
  residenceCountryId?: number | null;
};
export type CreateOrderAcknowledgementRequest = {
  referenceId?: string | null;
  acknowledgementReceiverType?: number;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  email?: string | null;
  contactNumber?: string | null;
  address?: AddressRequest | null;
  taxNumber?: string | null;
  vatNumber?: string | null;
  companyName?: string | null;
};
export type IdentityCustomerInfoResponse = {
  name?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string | null;
  email?: string | null;
};
export type BoundStatus = 1 | 2 | 3;
export type IdentityCustomerStatusResponse = {
  boundStatus?: BoundStatus;
};
export type BindCustomerToIdentityRequest = {
  dateOfBirth?: string;
};
export type EnrolCustomerResponse = {
  customerId?: string;
  basketId?: string;
};
export type ReasonForTakingTest = {
  id?: number;
  otherReason?: string | null;
};
export type EnrolCustomerRequest = {
  reservationIds?: string[] | null;
  specialRequirementId?: number | null;
  reasonForTakingTest?: ReasonForTakingTest | null;
};
export type MinorPickUpOption = 1 | 2 | 3;
export type GuardianInformation = {
  fullName?: string | null;
  telephone?: string | null;
  relationshipId?: number;
  ageDeclaration?: boolean | null;
};
export type EnrolMinorRequest = {
  reservationIds?: string[] | null;
  specialRequirementId?: number | null;
  reasonForTakingTest?: ReasonForTakingTest | null;
  minorPickUpOption?: MinorPickUpOption;
  guardianInfo?: GuardianInformation | null;
};
export type PaymentDto = {
  statusCode?: string | null;
  statusName?: string | null;
  createdOn?: string;
};
export type EnrolmentPaymentsResponse = {
  payments?: PaymentDto[] | null;
};
export type Gender = 0 | 1 | 2;
export type Address = {
  residenceCountryId?: number | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  postCode?: string | null;
};
export type MarketingPreference = 0 | 1 | 2 | 3;
export type CreateCustomerRequest = {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string;
  gender?: Gender;
  otherGenders?: string | null;
  address?: Address | null;
  mobileNumber?: string | null;
  email?: string | null;
  marketingPreference?: MarketingPreference;
  isGDPRConsentProvided?: boolean;
};
export type IdentityDocument = {
  typeId?: number | null;
  documentNumber?: string | null;
  expiryDate?: string | null;
  issuingAuthority?: string | null;
  issuingCountryId?: number | null;
};
export type CustomerDataResponse = {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string;
  gender?: Gender;
  otherGenders?: string | null;
  address?: Address | null;
  mobileNumber?: string | null;
  email?: string | null;
  marketingPreference?: MarketingPreference;
  isGDPRConsentProvided?: boolean;
  identityDocument?: IdentityDocument | null;
};
export type UpdateCustomerPersonalDetailsRequest = {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string;
  gender?: Gender;
  otherGenders?: string | null;
  address?: Address | null;
  mobileNumber?: string | null;
  email?: string | null;
  marketingPreference?: MarketingPreference;
  isGDPRConsentProvided?: boolean;
};
export type CreateOrUpdateCustomerIdentificationDocumentRequest = {
  typeId?: number | null;
  documentNumber?: string | null;
  expiryDate?: string | null;
  issuingAuthority?: string | null;
  issuingCountryId?: number | null;
};
export type CustomerMinor = {
  customerId?: string;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
};
export type CustomerMinorsResponse = {
  customerId?: string;
  minors?: CustomerMinor[] | null;
};
export type CreateMinorRequest = {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string;
  gender?: Gender;
  otherGenders?: string | null;
};
export type MinorDataResponse = {
  customerId?: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string;
  gender?: Gender;
  otherGenders?: string | null;
  identityDocument?: IdentityDocument | null;
};
export type UpdateMinorPersonalDetailsRequest = {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string;
  gender?: Gender;
  otherGenders?: string | null;
};
export type CustomerCreateResponse = {
  customerId?: string | null;
  registrationIds?: string[] | null;
  basketId?: string | null;
};
export type GenderType = 0 | 1 | 2;
export type AddressDto = {
  residenceCountryId?: number | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  postCode?: string | null;
};
export type IdentificationDocumentDto = {
  typeId?: number | null;
  documentNumber?: string | null;
  expiryDate?: string | null;
  issuingAuthority?: string | null;
  issuingCountryId?: number | null;
};
export type MinorPickUp = 1 | 2 | 3;
export type GuardianInfo = {
  fullName?: string | null;
  telephone?: string | null;
  relationshipId?: number;
  ageDeclaration?: boolean | null;
};
export type ParentDto = {
  parentFirstName?: string | null;
  parentLastName?: string | null;
  parentGender?: GenderType | null;
  parentDateOfBirth?: string | null;
};
export type Origin = 'B2B' | 'B2C';
export type MarketingPreferenceType = 0 | 1 | 2 | 3;
export type ProviderStatus = 1 | 2 | 3;
export type BaseCustomerDto = {
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  dateOfBirth?: string | null;
  gender?: GenderType | null;
  otherGenders?: string | null;
  isGDPRConsentProvided?: boolean | null;
  email?: string | null;
  address?: AddressDto | null;
  mobileNumber?: string | null;
  identificationDocument?: IdentificationDocumentDto | null;
  parentId?: string | null;
  parentEmail?: string | null;
  minorPickUpOption?: MinorPickUp | null;
  guardianInfo?: GuardianInfo | null;
  parentData?: ParentDto | null;
  origin?: Origin | null;
  marketingPreference?: MarketingPreferenceType | null;
  rowNumber?: number | null;
  providerStatus?: ProviderStatus | null;
  providerErrorMessages?: string | null;
  businessId?: string | null;
};
export type CreateAndEnrolRequest = {
  reservationIds?: string[] | null;
  customer?: BaseCustomerDto | null;
  minor?: BaseCustomerDto | null;
  hasSpecialRequirements?: boolean | null;
  specialRequirementId?: number | null;
  reasonForTakingTestId?: number | null;
  reasonForTakingTestOtherReason?: string | null;
};
export type DictionaryDto = {
  id?: number;
  name?: string | null;
};
export type BasketItemDto = {
  id?: number;
  name?: string | null;
  address?: string | null;
  totalFee?: number | null;
  tax?: number | null;
  currency?: string | null;
  startDate?: string;
  endDate?: string;
  productCode?: string | null;
  skills?: string[] | null;
  venueName?: string | null;
  timeZone?: string | null;
  enrolmentId?: string | null;
};
export type BasketItem = {
  id?: number;
  enrolmentId?: string | null;
  name?: string | null;
  address?: string | null;
  totalFee?: number | null;
  tax?: number | null;
  currency?: string | null;
  startDate?: string;
  endDate?: string;
  productCode?: string | null;
  skills?: string[] | null;
  venueName?: string | null;
  timeZone?: string | null;
};
export type BasketDetailsResponse = {
  basketId?: string;
  basketItems?: BasketItem[] | null;
};
export const {
  useSessionGetByCountryForSessionsQuery,
  useLazySessionGetByCountryForSessionsQuery,
  useSessionGetAllCountriesQuery,
  useLazySessionGetAllCountriesQuery,
  useSessionGetAllVariantsQuery,
  useLazySessionGetAllVariantsQuery,
  useSessionGetAllTestPackagesQuery,
  useLazySessionGetAllTestPackagesQuery,
  useSessionGetSessionDatesForCityTestPackageQuery,
  useLazySessionGetSessionDatesForCityTestPackageQuery,
  useSessionGetSessionsForCityTestPackageQuery,
  useLazySessionGetSessionsForCityTestPackageQuery,
  useSessionCreateReservationMutation,
  useSessionGetCustomerSessionWithVenueMutation,
  useSessionGetMinorSessionWithVenueMutation,
  useProductsGetConfigurationsQuery,
  useLazyProductsGetConfigurationsQuery,
  usePaymentsCreateMutation,
  useOrderCreateMutation,
  useOrderGetOrderByIdQuery,
  useLazyOrderGetOrderByIdQuery,
  useOrderCreateOrderAcknowledgementMutation,
  useIdentityGetLoggedInCustomerDetailsQuery,
  useLazyIdentityGetLoggedInCustomerDetailsQuery,
  useIdentityGetCustomerBoundStatusQuery,
  useLazyIdentityGetCustomerBoundStatusQuery,
  useIdentityBindCustomerToIdentityIdMutation,
  useEnrolmentEnrolCustomerMutation,
  useEnrolmentEnrolMinorMutation,
  useEnrolmentGetEnrolmentPaymentsQuery,
  useLazyEnrolmentGetEnrolmentPaymentsQuery,
  useCustomerCreateCustomerMutation,
  useCustomerGetCustomerDetailsQuery,
  useLazyCustomerGetCustomerDetailsQuery,
  useCustomerUpdateCustomerPersonalDataMutation,
  useCustomerCreateOrUpdateCustomerIdentificationDocumentMutation,
  useCustomerGetCustomerMinorsQuery,
  useLazyCustomerGetCustomerMinorsQuery,
  useCustomerCreateMinorMutation,
  useCustomerGetMinorDetailsQuery,
  useLazyCustomerGetMinorDetailsQuery,
  useCustomerUpdateMinorPersonalDataMutation,
  useCustomerCreateOrUpdateMinorIdentificationDocumentMutation,
  useCustomerCreateAndEnrolMutation,
  useCountryGetAllQuery,
  useLazyCountryGetAllQuery,
  useDictionariesGetAllSpecialRequirementsQuery,
  useLazyDictionariesGetAllSpecialRequirementsQuery,
  useDictionariesGetAllIdentificationDocumentTypesQuery,
  useLazyDictionariesGetAllIdentificationDocumentTypesQuery,
  useDictionariesGetAllReasonsForTakingTestQuery,
  useLazyDictionariesGetAllReasonsForTakingTestQuery,
  useDictionariesGetAllMinorPickupPersonsQuery,
  useLazyDictionariesGetAllMinorPickupPersonsQuery,
  useFeaturesGetAllFeaturesQuery,
  useLazyFeaturesGetAllFeaturesQuery,
  useCertificateIsCertificateAvailableQuery,
  useLazyCertificateIsCertificateAvailableQuery,
  useCertificateGetCertificateQuery,
  useLazyCertificateGetCertificateQuery,
  useCertificateIsCertificateAvailableForMinorQuery,
  useLazyCertificateIsCertificateAvailableForMinorQuery,
  useCertificateGetCertificateForMinorQuery,
  useLazyCertificateGetCertificateForMinorQuery,
  useBasketGetQuery,
  useLazyBasketGetQuery,
  useBasketGetBasketQuery,
  useLazyBasketGetBasketQuery,
} = injectedRtkApi;
