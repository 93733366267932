import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ReviewPayment } from '@/components';
import { formatPrice, useBasket } from '@/core';

export const AppReviewPayment: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { getTotal } = useBasket();

  return (
    <ReviewPayment
      fee={formatPrice(getTotal()?.fee, getTotal()?.currency)}
      feeLabel={t('review.payment.fee')}
      tax={formatPrice(getTotal()?.tax, getTotal()?.currency)}
      taxLabel={t('review.payment.tax')}
      total={formatPrice(getTotal()?.total, getTotal()?.currency)}
      totalLabel={t('review.payment.total')}
    />
  );
};
