import { useState } from 'react';

import { FiExternalLink } from 'react-icons/fi';
import { useSearchParams } from 'react-router-dom';

import { SelectField, Well } from '@britishcouncil/react-registration-platform';

/** Temporary (hopefully) page to display terms and conditions as a links to PDF files from BC CDN */
export const TermsConditions = () => {
  const [search] = useSearchParams();

  const prod = search.get('product') || '';

  const init = links[prod] ? { value: prod, label: links[prod]?.text } : undefined;

  const options = Object.keys(links).map((key) => ({
    value: key,
    label: links[key].text,
  }));

  const [product, setProduct] = useState<(typeof options)[0] | undefined>(init);

  const handleCountryChange = (countryOption: (typeof options)[0] | null) => {
    countryOption && setProduct(countryOption);
  };

  return (
    <>
      <div className="flex my-8 mx-auto max-w-7xl px-2">
        <div className="flex flex-col w-full gap-8">
          <h1>Terms and conditions</h1>
          <Well>
            <SelectField
              headerMessage="Select product:"
              selectProps={{
                value: product,
                options,
                onChange: handleCountryChange,
                placeholder: 'Select product',
                isMulti: false,
              }}
            />
          </Well>
          {product && <p className="pl-6">Select country:</p>}
          {links[product?.value || '']?.countries &&
            Object.keys(links[product?.value || ''].countries).map((key) => (
              <Well key={key}>
                <div className="flex justify-between items-center p-4 bg-white">
                  <span className="capitalize">{key}</span>
                  <a href={links[product?.value || ''].countries[key]} target="_blank" rel="noreferrer" className="flex gap-1 items-center text-blue-dark">
                    <span>Show terms and conditions</span>
                    <FiExternalLink />
                  </a>
                </div>
              </Well>
            ))}
        </div>
      </div>
    </>
  );
};

const links: {
  [key: string]: {
    text: string;
    countries: { [key: string]: string };
  };
} = {
  aptis: {
    text: 'Aptis',
    countries: {
      albania: 'https://bccdn.britishcouncil.org/apps/registration/terms/aptis-albania.pdf',
      romania: 'https://bccdn.britishcouncil.org/apps/registration/terms/aptis-romania.pdf',
      thailand: 'https://bccdn.britishcouncil.org/apps/registration/terms/aptis-thailand.pdf'
    },
  },
  esol: {
    text: 'Aptis ESOL',
    countries: {
      bulgaria: 'https://bccdn.britishcouncil.org/apps/registration/terms/aptis-esol-bulgaria.pdf',
    },
  },
  uol: {
    text: 'University of London',
    countries: {
      cyprus: 'https://bccdn.britishcouncil.org/apps/registration/terms/uol-cyprus.pdf',
      india: 'https://bccdn.britishcouncil.org/apps/registration/terms/uol-india.pdf',
      nigeria: 'https://bccdn.britishcouncil.org/apps/registration/terms/uol-nigeria.pdf',
      turkey: 'https://bccdn.britishcouncil.org/apps/registration/terms/uol-turkey.pdf',
      vietnam: 'https://bccdn.britishcouncil.org/apps/registration/terms/uol-vietnam.pdf',
    },
  },
};
