import { useContext } from 'react';

import { FiFileText, FiMapPin, FiMonitor } from 'react-icons/fi';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';

import { SessionResponse, TestPackageDto } from '@/store/webApi';
import { cn } from '@/core/helpers/cn';
import { formatPrice, getZonedTime, noAction, useAppSelector } from '@/core';

interface SessionListCardProps {
  delivery?: string;
  handleClick?: (id?: string) => void;
  session: SessionResponse;
  testPackage?: TestPackageDto;
  itemId: string;
}

const deliveryMapper = (delivery: string) => {
  switch (delivery) {
    case 'computer-based exam':
      return { text: 'Computer', Ico: FiMonitor };
    case 'writing exam':
      return { text: 'Computer', Ico: FiFileText };
    default:
      return { text: delivery, Ico: FiMonitor };
  }
};

export const SessionItem = ({ delivery = '', handleClick = noAction, session, testPackage, itemId }: SessionListCardProps) => {
  const basketSessions = useAppSelector((s) => s.basket.items);
  const isActive = basketSessions.some((s) => s.id === session.id);
  const visibility = useContext(VisibilityContext);

  const { text, Ico } = deliveryMapper(delivery.toLowerCase());

  return (
    <li
      className={cn(
        'flex flex-col border border-grey-light rounded-xl p-[16px] opacity-30 cursor-pointer hover:bg-[#F2EAFF] min-w-[258px] max-w-[258px] mr-[12px] h-full',
        {
          'bg-purple-dark text-white hover:bg-purple-dark': isActive,
          'opacity-100': visibility.isItemVisible(itemId),
          'pointer-events-none': !visibility.isItemVisible(itemId),
        },
      )}
      onClick={() => handleClick(session.id)}
      data-testid={`session-${session.id}`}
    >
      {session.venue?.name ? (
        /* UI when venue data is present */
        <>
          <div className="flex justify-between font-bold">
            <div className="">{getZonedTime(session.startDateTimeUtc ?? '', session.venue?.timezone)}</div>
            <div className="">{formatPrice(testPackage?.totalFee ?? 0, testPackage?.currency ?? '')}</div>
          </div>
          <div className="flex gap-[8px] items-start border-t border-grey py-[16px] grow">
            <div className="w-[24px]">
              <FiMapPin size={24} />
            </div>
            <div className="text-[16px] leading-[130%] break-all">
              <div>{session.venue?.name},</div>
              <div>{session.venue?.city}</div>
            </div>
          </div>
        </>
      ) : (
        /* UI when venue data is not present */
        <>
          <div className="font-bold text-[18px]">{getZonedTime(session.startDateTimeUtc ?? '', session.venue?.timezone)}</div>
          <div className="text-[16px] mt-[8px] mb-[16px]">{formatPrice(testPackage?.totalFee ?? 0, testPackage?.currency ?? '')}</div>
        </>
      )}
      <div className={cn('text-[14px] text-body border-t border-grey pt-[4px] capitalize', { 'text-white border-white': isActive })}>
        <Ico className="w-[16px] h-[16px] mr-[4px] float-left mt-[2px]" />
        {text}
      </div>
    </li>
  );
};
