import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

import { Loading } from '@/components';
import { useFeatureFlags } from '@/core';

export const AccountLayout = () => {
  const auth = useAuth();
  const { data, isSuccess, isError } = useFeatureFlags();

  if ((isSuccess && !data?.identityIntegrationFeature) || isError) {
    return <Navigate to="/" />;
  }

  if (auth.activeNavigator || auth.isLoading) {
    return <Loading />;
  }

  if (data?.identityIntegrationFeature) {
    return (
      <div className="p-3 mx-auto mt-12 max-w-screen-xl">
        <Outlet />
      </div>
    );
  }

  return <Loading />;
};
