import styled from '@emotion/styled';
import { LinkMixin } from '@/core/styles/mixins';

export const FullMessageShowHide = styled.span`
  ${LinkMixin}

  align-self: flex-start;
  color: var(--a-link-color);
  order: 2;
`;

export const FullMessageWrapper = styled.section`
  --slide-down-display: flex;
  flex-direction: column;
  order: 1;
  row-gap: var(--whitespace-12-to-20);

  a {
    color: var(--a-link-color);
    text-decoration: var(--link-text-decoration);
  }

  &.hide {
    display: none;
  }
`;
