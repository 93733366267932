import { PropsWithChildren } from 'react';

import * as S from './PageFormWrapper.style';

interface PageFormWrapperProps {
  className?: string;
}

export const PageFormWrapper = ({ children, className }: PropsWithChildren<PageFormWrapperProps>) => {
  return (
    <S.PageFormOuterWrapper>
      <S.PageFormWrapper className={className}>{children}</S.PageFormWrapper>
    </S.PageFormOuterWrapper>
  );
};
