import { OrderAcknowledgement } from '@/core';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface OrderAcknowledgementDetailsState {
  companyName: string;
  countryId?: number;
  email: string;
  firstName: string;
  lastName: string;
  noLastName: boolean;
  orderAcknowledgement: OrderAcknowledgement;
  postalLine01: string;
  postalLine02: string;
  postalTownCity: string;
  postalZipCode: string;
  telephoneNumber: string;
  vatNipNumber: string;
}

const initialState: OrderAcknowledgementDetailsState = {
  companyName: '',
  email: '',
  firstName: '',
  lastName: '',
  noLastName: false,
  orderAcknowledgement: 'self',
  postalLine01: '',
  postalLine02: '',
  postalTownCity: '',
  postalZipCode: '',
  telephoneNumber: '',
  vatNipNumber: '',
};

const orderAcknowledgementDetailsSlice = createSlice({
  name: 'orderAcknowledgementDetails',
  initialState,
  reducers: {
    setAcknowledge: (state, { payload }: PayloadAction<OrderAcknowledgementDetailsState>): void => {
      state.companyName = payload.companyName;
      if (payload.countryId) state.countryId = payload.countryId;
      state.email = payload.email;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.noLastName = payload.noLastName;
      state.orderAcknowledgement = payload.orderAcknowledgement;
      state.postalLine01 = payload.postalLine01;
      state.postalLine02 = payload.postalLine02;
      state.postalTownCity = payload.postalTownCity;
      state.postalZipCode = payload.postalZipCode;
      state.telephoneNumber = payload.telephoneNumber;
      state.vatNipNumber = payload.vatNipNumber;
    },
    setAcknowledgeCompanyName: (state, { payload }: PayloadAction<string>): void => {
      state.companyName = payload;
    },
    setAcknowledgeCountryId: (state, { payload }: PayloadAction<number>): void => {
      state.countryId = payload;
    },
    setAcknowledgeEmail: (state, { payload }: PayloadAction<string>): void => {
      state.email = payload;
    },
    setAcknowledgeFirstName: (state, { payload }: PayloadAction<string>): void => {
      state.firstName = payload;
    },
    setAcknowledgeLastName: (state, { payload }: PayloadAction<string>): void => {
      state.lastName = payload;
    },
    setAcknowledgeNoLastName: (state, { payload }: PayloadAction<boolean>): void => {
      state.noLastName = payload;
    },
    setAcknowledgePostalLineOne: (state, { payload }: PayloadAction<string>): void => {
      state.postalLine01 = payload;
    },
    setAcknowledgePostalLineTwo: (state, { payload }: PayloadAction<string>): void => {
      state.postalLine02 = payload;
    },
    setAcknowledgePostalTownCity: (state, { payload }: PayloadAction<string>): void => {
      state.postalTownCity = payload;
    },
    setAcknowledgePostalZipCode: (state, { payload }: PayloadAction<string>): void => {
      state.postalZipCode = payload;
    },
    setAcknowledgeTelephoneNumber: (state, { payload }: PayloadAction<string>): void => {
      state.telephoneNumber = payload;
    },
    setAcknowledgeVatNipNumber: (state, { payload }: PayloadAction<string>): void => {
      state.vatNipNumber = payload;
    },
    setOrderAcknowledgement: (state, { payload }: PayloadAction<OrderAcknowledgement>): void => {
      state.orderAcknowledgement = payload;
    },
  },
});

const {
  setAcknowledge,
  setAcknowledgeCompanyName,
  setAcknowledgeCountryId,
  setAcknowledgeEmail,
  setAcknowledgeFirstName,
  setAcknowledgeLastName,
  setAcknowledgeNoLastName,
  setAcknowledgePostalLineOne,
  setAcknowledgePostalLineTwo,
  setAcknowledgePostalTownCity,
  setAcknowledgePostalZipCode,
  setAcknowledgeTelephoneNumber,
  setAcknowledgeVatNipNumber,
  setOrderAcknowledgement,
} = orderAcknowledgementDetailsSlice.actions;

const { reducer: orderAcknowledgementDetailsReducer } = orderAcknowledgementDetailsSlice;

export {
  orderAcknowledgementDetailsReducer,
  setAcknowledge,
  setAcknowledgeCompanyName,
  setAcknowledgeCountryId,
  setAcknowledgeEmail,
  setAcknowledgeFirstName,
  setAcknowledgeLastName,
  setAcknowledgeNoLastName,
  setAcknowledgePostalLineOne,
  setAcknowledgePostalLineTwo,
  setAcknowledgePostalTownCity,
  setAcknowledgePostalZipCode,
  setAcknowledgeTelephoneNumber,
  setAcknowledgeVatNipNumber,
  setOrderAcknowledgement,
};
