import { PropsWithChildren } from 'react';

import { Navigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

import { Loading } from '@/components';

export const AuthGuard = ({ children }: PropsWithChildren) => {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/account/create" />;
  }

  return <>{children}</>;
};
