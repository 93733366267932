import { ErrorMessages, TextInputType, createErrorMessage } from '@/components';

import { ValidateMethod, ValidateMethods, ValidateOptions } from '..';

export const addConfirmEmailValidator = (inputType: TextInputType, validationMethods: ValidateMethods) => {
  const validateText: ValidateMethod = (text: string, validationErrors: ErrorMessages, options: ValidateOptions) => {
    if (text !== options.emailToMatch)
      return [...validationErrors, createErrorMessage({ text: options.errorMessages?.invalidConfirmEmailMessage || '', type: 'internal' })];
    return validationErrors;
  };

  if (inputType === 'confirm-email') validationMethods.push(validateText);
};
