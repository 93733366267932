import { Navigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

import { Button } from '@britishcouncil/react-registration-platform';

export const Account = () => {
  const { user, removeUser, signoutRedirect, isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/account/create" />;
  }

  const signOut = () => {
    removeUser();
    signoutRedirect({ post_logout_redirect_uri: window.location.origin });
  };

  return (
    <div className="text-center space-y-3 mt-4">
      <h4>Hello, {user?.profile?.email}</h4>
      <div>
        <Button onClick={signOut}>Logout</Button>
      </div>
    </div>
  );
};
