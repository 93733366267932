import { Navigate, useSearchParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@britishcouncil/react-registration-platform';

import { DobInputs, FormDob } from '@/components/forms/FormDob';
import { Loading } from '@/components';
import { useIdentityBindCustomerToIdentityIdMutation, useIdentityGetCustomerBoundStatusQuery } from '@/store/webApi';

export const Verify = () => {
  const { t } = useTranslation();

  const [trigger, mutation] = useIdentityBindCustomerToIdentityIdMutation();
  const { data, isLoading } = useIdentityGetCustomerBoundStatusQuery();

  const [searchParams] = useSearchParams();
  const productCode = searchParams.get('productCode');

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<DobInputs>({
    mode: 'onTouched',
    criteriaMode: 'all',
  });

  const onSubmit: SubmitHandler<DobInputs> = async ({ dateOfBirth: dob }) => {
    const dateOfBirth = new Date(Date.UTC(dob?.year ?? 0, dob?.month, dob?.day)).toISOString().split('T')[0];
    trigger({ bindCustomerToIdentityRequest: { dateOfBirth } });
  };

  if (data?.boundStatus !== 2 || mutation.isSuccess) {
    // 2 = not bound
    return <Navigate to={productCode ? `/${productCode}/book-test/personal-details/0` : '/account'} />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="max-w-[600px] mx-auto">
      <h4 className="mb-4">{t('account.verify.title')}</h4>
      <p className="my-2 font-bold">{t('account.verify.dob')}</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormDob
          control={control}
          errorInline={Object.keys(errors).length ? 'The date you have entered is invalid, please check and try again.' : undefined}
          errorSubmittion={
            mutation.error
              ? 'status' in mutation.error && mutation.error.status === 400
                ? t('account.verify.errors.invalid-dob')
                : t('account.verify.errors.generic')
              : undefined
          }
        />
        <div>
          <Button type="submit" className="mt-6" disabled={mutation.isLoading}>
            {t('account.verify.cta')}
          </Button>
        </div>
      </form>
    </div>
  );
};
