import { useEffect } from 'react';

export const useCloseOnFocusChangeHandler = (
  isOpen: boolean,
  listRef: React.RefObject<HTMLUListElement>,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  callbackWhenOpen?: () => void,
  callbackToExtraGuardCheck?: (event: FocusEvent) => void,
): void => {
  useEffect(() => {
    const focusChangeHandler = (event: FocusEvent) => {
      if ((event.target instanceof Node && listRef.current?.contains(event.target)) || (callbackToExtraGuardCheck && callbackToExtraGuardCheck(event))) return;
      setIsOpen(false);
    };

    if (isOpen) {
      document.addEventListener('mouseup', focusChangeHandler);
      document.addEventListener('focusin', focusChangeHandler);
      callbackWhenOpen && callbackWhenOpen();
    } else {
      document.removeEventListener('mouseup', focusChangeHandler);
      document.removeEventListener('focusin', focusChangeHandler);
    }
    return () => {
      document.removeEventListener('mouseup', focusChangeHandler);
      document.removeEventListener('focusin', focusChangeHandler);
    };
  }, [callbackToExtraGuardCheck, callbackWhenOpen, isOpen, listRef, setIsOpen]);

  return;
};
