import { useCallback } from 'react';

import { ErrorMessages, TextInputType } from '@/components';

import { PasswordRequirementTexts, addConfirmEmailValidator, addEmailValidation, addPasswordValidation } from './validators';

type ValidateErrorMessages = {
  invalidConfirmEmailMessage?: string;
  invalidEmailMessage?: string;
};

export type ValidateMethod = (text: string, validationErrors: ErrorMessages, options: ValidateOptions) => ErrorMessages;
export type ValidateMethods = Array<ValidateMethod>;
export type ValidateOptions = {
  emailToMatch?: string;
  errorMessages?: ValidateErrorMessages;
  passwordRequirementTexts?: PasswordRequirementTexts;
};
export type ValidateText = (text: string) => ErrorMessages;

export const useTextInputValidation = (inputType: TextInputType, options: ValidateOptions): ValidateText => {
  const validateText: ValidateText = useCallback(
    (text: string): ErrorMessages => {
      const validationMethods = new Array<ValidateMethod>();
      addEmailValidation(inputType, validationMethods);
      addConfirmEmailValidator(inputType, validationMethods);
      addPasswordValidation(inputType, validationMethods);
      return validationMethods.reduce<ErrorMessages>((errorMessages, validate) => {
        return validate(text, errorMessages, options);
      }, []);
    },
    [inputType, options],
  );

  return validateText;
};
