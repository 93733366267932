import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  FlowSteps,
  GoogleTagManager,
  bookTestMinorDetailsRoute,
  bookTestMinorIdentificationRoute,
  bookTestReviewRoute,
  getTotalSteps,
  isMobileTrack,
  params,
  useAppParams,
} from '@/core';
import { getReturnToReviewFrom } from '@/store';

export const useNextStep = () => {
  const returnToReviewFrom = useSelector(getReturnToReviewFrom);
  const navigate = useNavigate();
  const { productCode, step } = useAppParams();
  const [currentStep, setCurrentStep] = useState(step);
  const [displayCurrentStep, setDisplayCurrentStep] = useState(0);

  useEffect(() => {
    GoogleTagManager.addEvent({
      event: 'MinorDetailsStep',
      virtualPageTitle: `British Council - Book a new test - Your child's details`,
      virtualPageURL: document.title,
    });
  }, []);

  useEffect(() => {
    setCurrentStep(step);
    setDisplayCurrentStep(isMobileTrack(window.innerWidth) ? 3 + parseInt(currentStep ?? '') : 3);
  }, [currentStep, step]);

  const goToNextStep = ({ countryIso, minorDetails }: { countryIso?: string; minorDetails?: any }) => {
    GoogleTagManager.addEvent({
      event: 'MinorDetailsStepCompletion',
      country: countryIso,
      data: minorDetails,
      virtualPageTitle: document.title,
      virtualPageURL: document.title,
    });

    if (returnToReviewFrom === bookTestMinorDetailsRoute.absolutePath) {
      navigate(
        bookTestReviewRoute.absolutePath
          .replace(params.productCode, productCode || '')
          .replace(params.step, currentStep !== FlowSteps.flowStepForDesktopPath ? FlowSteps.flowStepForMobilePath : FlowSteps.flowStepForDesktopPath),
      );
    } else if (currentStep === FlowSteps.flowStepForMobilePath) {
      navigate(
        bookTestMinorDetailsRoute.absolutePath.replace(params.productCode, productCode || '').replace(params.step, (parseInt(currentStep) + 1).toString()),
      );
    } else
      navigate(
        bookTestMinorIdentificationRoute.absolutePath
          .replace(params.productCode, productCode || '')
          .replace(params.step, currentStep !== FlowSteps.flowStepForDesktopPath ? FlowSteps.flowStepForMobilePath : FlowSteps.flowStepForDesktopPath),
      );
  };

  const totalSteps = getTotalSteps(window.innerWidth, true);

  return { displayCurrentStep, goToNextStep, currentStep, returnToReviewFrom, totalSteps };
};
