import { useEffect } from 'react';

import { GoogleTagManager, useAppSelector } from '@/core';

import { IdentificationFormTypes } from './useIdentificationForm';

export const useAnalytics = () => {
  const country = useAppSelector((s) => s.searchDetails.country);

  useEffect(() => {
    GoogleTagManager.addEvent({
      Country: country?.isoCode,
      event: 'CustomerIdentificationStep',
      virtualPageTitle: 'British Council - Book a new test - Your identification',
      virtualPageTitleLocal: document.title,
      virtualPageURL: '/book-test/identification/',
    });
  }, [country?.isoCode]);

  const submitEvents = (identyficationDetails: IdentificationFormTypes) => {
    GoogleTagManager.addEvent({
      country: country?.isoCode,
      data: identyficationDetails,
      event: 'CustomerIdentificationStepCompletion',
      virtualPageTitle: document.title,
      virtualPageURL: window.location.pathname,
    });
  };

  return { submitEvents };
};
