import { FC } from 'react';

import { ComponentWrapper, Select, SelectSelectionChangeEvent, TextInput, TextInputChangeEvent, createErrorMessage } from '@/components';
import { Country } from '@/store/webApi';
import { ErrorResponse, Options } from '@/core';
import { PhoneInput } from '@/components/forms/PhoneInput';

interface ContactDetailsProps {
  addBackground?: boolean;
  countryLabel: string;
  countries: Options<Country>;
  countriesError?: ErrorResponse;
  countriesIsLoading: boolean;
  defaultCountryId?: number;
  defaultPostalOne?: string;
  defaultPostalTwo?: string;
  defaultTelephoneNumber?: string;
  defaultTownCity?: string;
  defaultZip?: string;
  hidePhoneNumber?: boolean;
  onCountryChange: SelectSelectionChangeEvent<Country>;
  onPostalOneChange: TextInputChangeEvent;
  onPostalTwoChange: TextInputChangeEvent;
  onTelephoneNumberChange: (s?: string) => void;
  onTownCityChange: TextInputChangeEvent;
  onZipChange: TextInputChangeEvent;
  postalOneLabel: string;
  postalTwoAriaLabel: string;
  requiredText: string;
  telephoneNumberLabel: string;
  townCityLabel: string;
  whiteBackground?: boolean;
  zipLabel: string;
}

export const ContactDetails: FC<ContactDetailsProps> = ({
  addBackground = true,
  countryLabel,
  countries,
  countriesError,
  countriesIsLoading,
  defaultCountryId,
  defaultTelephoneNumber,
  defaultPostalOne,
  defaultPostalTwo,
  defaultTownCity,
  defaultZip,
  hidePhoneNumber = false,
  onCountryChange,
  onPostalOneChange,
  onPostalTwoChange,
  onTelephoneNumberChange,
  onTownCityChange,
  onZipChange,
  postalOneLabel,
  postalTwoAriaLabel,
  requiredText,
  telephoneNumberLabel,
  townCityLabel,
  whiteBackground,
  zipLabel,
}): JSX.Element => {
  return (
    <ComponentWrapper addBackground={addBackground} whiteBackground={whiteBackground} id="contact-details">
      {!hidePhoneNumber && (
        <PhoneInput
          id="telephone-number"
          label={telephoneNumberLabel}
          defaultValue={defaultTelephoneNumber}
          onChangeNumber={onTelephoneNumberChange}
          required
        />
      )}
      <Select<Country>
        defaultSelectedValue={defaultCountryId?.toString()}
        formErrorMessages={countriesError && [createErrorMessage({ isFormError: true, text: countriesError.response, type: 'form' })]}
        id="country-of-residence"
        isLoading={countriesIsLoading}
        label={countryLabel}
        onSelectionChange={onCountryChange}
        options={countries}
        required
        requiredText={requiredText}
      ></Select>
      <TextInput
        defaultText={defaultPostalOne}
        id="postal-address-one"
        inputType="text"
        label={postalOneLabel}
        onValueChange={onPostalOneChange}
        required
        requiredText={requiredText}
      ></TextInput>
      <TextInput
        aria-label={postalTwoAriaLabel}
        defaultText={defaultPostalTwo}
        formPreventControlRegistration
        id="postal-address-two"
        inputType="text"
        onValueChange={onPostalTwoChange}
      ></TextInput>
      <TextInput
        defaultText={defaultTownCity}
        id="town-city"
        inputType="text"
        label={townCityLabel}
        onValueChange={onTownCityChange}
        required
        requiredText={requiredText}
      ></TextInput>
      <TextInput
        defaultText={defaultZip}
        id="zip-code"
        inputType="text"
        label={zipLabel}
        onValueChange={onZipChange}
        required
        requiredText={requiredText}
        debounceWait={0}
      ></TextInput>
    </ComponentWrapper>
  );
};
