import { ReactNode } from 'react';

import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Label, LabelProps, SelectBaseOptionReg, ValidatedField, ValidatedFieldProps } from '@britishcouncil/react-registration-platform';

import { getMonthsArray } from '@/utils/getMonthsNames';

import { FormInput } from './FormInput';
import { FormSelect } from './FormSelect';

export type DateInputs<PropertyName extends string> = {
  [P in PropertyName]?: {
    day: number;
    month: number;
    year: number;
  };
};

export type FormDateProps<TFieldName extends string, T extends DateInputs<TFieldName>> = Pick<ValidatedFieldProps, 'errorInline' | 'errorSubmittion'> &
  Pick<LabelProps, 'helpText'> & {
    control: Control<T>;
    // I have no idea how to pass the property name to the FormDate method in a different way. At this point, for proper operation, you must define
    // the property name in TFieldName, and rootPropertyName.
    rootPropertyName: TFieldName;
    label?: ReactNode;
    required?: boolean;
    disabled?: boolean;
    onChange?: ((event: Event | SelectBaseOptionReg, component: string) => void) | undefined;
  };

export const FormDate = <TFieldName extends string, T extends DateInputs<TFieldName> = DateInputs<TFieldName>>({
  label,
  errorInline,
  errorSubmittion,
  required,
  disabled,
  onChange,
  rootPropertyName,
  ...rest
}: FormDateProps<TFieldName, T>) => {
  const { t } = useTranslation();
  const dayRange = { min: 1, max: 31 };
  const yearRange = { min: 1900, max: 9999 };
  const options = getMonthsArray().map((month, index) => ({ value: index, label: month }));

  const control = rest.control as unknown as Control<DateInputs<string>>;
  return (
    <div className="grow">
      {label ? <Label placeholder="">{label}</Label> : null}
      <ValidatedField errorInline={errorInline} errorSubmittion={errorSubmittion}>
        <div className="flex gap-2 items-center">
          <FormInput
            placeholder="Day"
            type="number"
            required={required}
            disabled={disabled}
            className="max-w-[100px]"
            {...dayRange}
            {...control.register(rootPropertyName.concat('.day'), {
              required: required ? t('common.required') : false,
              min: {
                value: dayRange.min,
                message: t('components.date-input.invalid-day'),
              },
              max: {
                value: dayRange.max,
                message: t('components.date-input.invalid-day'),
              },
              onChange: (event: Event) => onChange && onChange(event, 'Day'),
            })}
          />
          <div className="grow">
            <FormSelect
              placeholder="Month"
              required={required}
              disabled={disabled}
              options={options}
              name={rootPropertyName.concat('.month')}
              control={control}
              onChange={(value: SelectBaseOptionReg | null) => onChange && onChange(value as SelectBaseOptionReg, 'Month')}
            />
          </div>
          <FormInput
            placeholder="Year"
            required={required}
            disabled={disabled}
            type="number"
            {...yearRange}
            className="max-w-[100px]"
            {...control.register(rootPropertyName.concat('.year'), {
              required: required ? t('common.required') : false,
              min: {
                value: yearRange.min,
                message: t('components.date-input.invalid-year'),
              },
              max: {
                value: yearRange.max,
                message: t('components.date-input.invalid-year'),
              },
              onChange: (event: Event) => onChange && onChange(event, 'Year'),
            })}
          />
        </div>
      </ValidatedField>
    </div>
  );
};
