import { AuthGuard, Loading } from '@/components';
import { useFeatureFlags } from '@/core';

import { MinorDetailsLegacy } from './legacy';
import { MinorDetailsPage } from './page';

export const MinorDetails = () => {
  const { data, isLoading } = useFeatureFlags();

  if (isLoading) {
    return <Loading />;
  }

  return data?.identityIntegrationFeature ? (
    <AuthGuard>
      <MinorDetailsPage />
    </AuthGuard>
  ) : (
    <MinorDetailsLegacy />
  );
};
