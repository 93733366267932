import styled from '@emotion/styled';
import { breakpoints } from '@/core';

export const Links = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: var(--small-font-size);
  row-gap: var(--whitespace-basic-12);
  margin-bottom: 40px;

  li {
    position: relative;
    a {
      text-decoration: none;
      color: var(--color-inverted);
      font-weight: 400;
      font-size: 16px;
      &:focus-visible {
        box-shadow:
          0 0 0 var(--focus-spread) var(--focus-color),
          0 0 0 var(--focus-spread) var(--focus-color);
      }
    }
  }

  ${() => breakpoints.isTablet.mediaMinWidth} {
    flex-direction: row;
    gap: var(--whitespace-basic-32);

    li:not(:last-child):after {
      background-color: rgba(var(--rgb-white), 0.6);
      content: '';
      height: var(--whitespace-basic-12);
      position: absolute;
      right: calc(var(--whitespace-basic-16) - var(--whitespace-basic-16) * 2);
      top: var(--whitespace-basic-04);
      width: 1px;
    }
  }
`;
