import { AuthGuard, Loading } from '@/components';
import { useFeatureFlags } from '@/core';

import { ReviewBooking } from './legacy';
import { ReviewPage } from './page';

export const Review = () => {
  const { data, isLoading } = useFeatureFlags();

  if (isLoading) {
    return <Loading />;
  }

  return data?.identityIntegrationFeature ? (
    <AuthGuard>
      <ReviewPage />
    </AuthGuard>
  ) : (
    <ReviewBooking />
  );
};
