import styled from '@emotion/styled';

export const ExternalLink = styled.a`
  color: var(--a-link-color);
  align-self: flex-start;
`;

export const IconWrapper = styled.span`
  padding-left: var(--whitespace-basic-06);

  svg {
    color: var(--a-link-color);
    height: 18px;
    display: inline-block;
  }
`;

export const LinkText = styled.span`
  text-decoration: var(--link-text-decoration);
`;
