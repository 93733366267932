import { FC, HTMLAttributes } from 'react';

import { SpacingProps } from '@/components/component-wrapper';

import * as S from './OuterWrapper.style';

export const OuterWrapper: FC<HTMLAttributes<HTMLElement> & SpacingProps> = ({ children, rowSpacing, ...props }): JSX.Element => {
  return (
    <S.OuterWrapper rowSpacing={rowSpacing} {...props}>
      {children}
    </S.OuterWrapper>
  );
};
