import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getSessionTime, setSessionTime } from '@/store';

import { useSessionStorage } from './useSessionStorage';

export const useHandleSession = () => {
  const { lastUpdated } = useSelector(getSessionTime);
  const { clearSessionStorage } = useSessionStorage<{ value: string }>('any', { value: '' });
  const [sessionTime, setLocalSessionTime] = useState(lastUpdated);
  const [sessionOverdue, setSessionOverdue] = useState(0);
  const maxDelay = 900;
  const maxOvertime = 60;
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      const delay = Math.floor((new Date().getTime() - sessionTime) / 1000);
      if (delay > maxDelay) {
        setSessionOverdue(delay - maxDelay);
      } else if (sessionOverdue !== 0) {
        setSessionOverdue(0);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [sessionOverdue, sessionTime]);

  useEffect(() => {
    if (sessionOverdue > maxOvertime) {
      clearSessionStorage();
      window.location.pathname = '/';
    }
  }, [sessionOverdue, maxOvertime, clearSessionStorage]);

  useEffect(() => {
    if (lastUpdated && lastUpdated !== sessionTime) {
      setLocalSessionTime(lastUpdated);
    }
  }, [lastUpdated, sessionTime]);

  const restartSession = () => {
    dispatch(setSessionTime(new Date().getTime()));
    setSessionOverdue(0);
  };

  return {
    restartSession,
    sessionOverdue,
  };
};
