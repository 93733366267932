import { minimumPasswordLength } from '..';

import { or } from './common';

export const eightOrMoreCharacters = `(?=.{${minimumPasswordLength},})`;
export const oneDigit = '(?=.*\\d)';
export const oneLowercase = '(?=.*[a-z])';
export const oneSpecial = '(?=.*[\\W_])';
export const oneUppercase = '(?=.*[A-Z])';

const threeOfOneDigitOneLowercaseOneSpecial = `${oneDigit}${oneLowercase}${oneSpecial}`;
const threeOfOneLowercaseOneSpecialOneUppercase = `${oneLowercase}${oneSpecial}${oneUppercase}`;
const threeOfOneSpecialOneUppercaseOneDigit = `${oneSpecial}${oneUppercase}${oneDigit}`;
const threeOfOneUppercaseOneDigitOneLowercase = `${oneUppercase}${oneDigit}${oneLowercase}`;

export const passwordRegEx = new RegExp(
  `${eightOrMoreCharacters}(${threeOfOneDigitOneLowercaseOneSpecial}${or}${threeOfOneLowercaseOneSpecialOneUppercase}${or}${threeOfOneSpecialOneUppercaseOneDigit}${or}${threeOfOneUppercaseOneDigitOneLowercase}).*`,
);
