import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

export const useDocumentTitle = (title = ''): string => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = `${t('common.british-council')} - ${title ? `${title}` : `${t('common.app-name')}`}`;
  }, [t, title]);

  return document.title;
};
