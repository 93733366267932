import { css } from '@emotion/react';

import { breakpoints } from '../utils';

export const componentDimensions = css`
  .themed {
    //--border
    //  --radii
    --button-border-radius: 6px;
    --component-border-radius: 4px;
    --component-left-rounded-border-radius: var(--component-border-radius) 0 0 var(--component-border-radius);
    --component-bottom-rounded-border-radius: 0 0 var(--component-border-radius) var(--component-border-radius);
    --component-right-rounded-border-radius: 0 var(--component-border-radius) var(--component-border-radius) 0;
    --component-top-rounded-border-radius: var(--component-border-radius) var(--component-border-radius) 0 0;
    --component-top-right-rounded-border-radius: 0 var(--component-border-radius) 0 0;
    --wrapped-border-radius: 16px;
    --wrapper-border-radius: 16px;
    //  --spacing
    --calendar-border-spacing: var(--whitespace-basic-04);

    //--padding
    --a-link-padding: var(--whitespace-04-to-08) 3px;

    //--widths
    --calendar-max-width: 10px;

    ${breakpoints.isMobile.mediaMinWidth} {
      --calendar-max-width: 412px;
    }

    ${breakpoints.isTablet.mediaMinWidth} {
      --wrapped-border-radius: 8px;

      --main-content-width: 720px;
    }

    ${breakpoints.isLaptop.mediaMinWidth} {
      --calendar-max-width: 942px;
      --main-content-width: 960px;
    }
  }
`;
