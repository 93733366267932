/* istanbul ignore file */
import styled from '@emotion/styled';
import { breakpoints } from '@/core';

enum ButtonStyle {
  Discrete = 'Discrete',
  DiscreteRed = 'DiscreteRed',
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export const Button = styled.button<{
  addArrow?: string;
  buttonStyle: ButtonStyle;
  center: boolean;
  disabled?: boolean;
  freeHeight?: boolean;
  hideOn?: 'mobile' | 'tablet';
  leftAlignMobile: boolean;
  noMargin: boolean;
  noPadding?: boolean;
}>`
  text-align: center;
  background-color: ${({ buttonStyle }) => (buttonStyle === ButtonStyle.Primary ? 'var(--button-primary-background)' : 'var(--button-secondary-background)')};
  ${({ buttonStyle }) => buttonStyle === ButtonStyle.DiscreteRed && 'background: none;'};
  border: ${({ buttonStyle }) => ([ButtonStyle.Primary, ButtonStyle.Secondary].includes(buttonStyle) ? 'solid 2px var(--button-primary-background)' : 'none')};
  border-radius: var(--button-border-radius);
  color: ${({ buttonStyle }) =>
    buttonStyle === ButtonStyle.Primary
      ? 'var(--button-primary-color)'
      : buttonStyle === ButtonStyle.DiscreteRed
        ? 'var(--dark-red)'
        : 'var(--button-secondary-color)'};
  cursor: pointer;
  font-size: var(--h5-h6-base-font-size);
  line-height: var(--whitespace-basic-16);
  font-weight: ${({ buttonStyle }) => ([ButtonStyle.Primary, ButtonStyle.DiscreteRed, ButtonStyle.Discrete].includes(buttonStyle) ? 'bold' : 'normal')};
  padding: ${({ noPadding }) => (noPadding ? '0' : '0 var(--whitespace-basic-40)')};
  position: relative;
  ${({ freeHeight }) => !freeHeight && 'height: var(--whitespace-basic-48)'};
  margin: ${({ center }) => (center ? 'auto' : '0')};
  ${({ leftAlignMobile }) => leftAlignMobile && 'margin: auto auto auto 0;'};
  ${({ noMargin }) => noMargin && 'margin: 0;'};

  ${() => breakpoints.isTablet.mediaMinWidth} {
    ${({ addArrow }) => addArrow === 'left' && 'padding-left: 72px;'};
    ${({ addArrow }) => addArrow === 'right' && 'padding-right: 72px;'};
  }
  &.change-button {
    min-width: 95px;
    text-align: right;
    height: auto;
  }
  &:hover {
    background-color: ${({ buttonStyle }) =>
      buttonStyle === ButtonStyle.Primary
        ? 'var(--button-primary-background-hover)'
        : buttonStyle === ButtonStyle.Secondary
          ? 'var(--button-secondary-background-hover)'
          : 'none'};
  }

  ${({ buttonStyle }) =>
    buttonStyle === ButtonStyle.DiscreteRed &&
    `
  padding-left: 0;
  margin-top: var(--whitespace-basic-08);`}

  ${() => breakpoints.isTablet.mediaMinWidth} {
    margin: ${({ center }) => (center ? 'auto' : 'auto auto auto 0')};
    ${({ noMargin }) => noMargin && 'margin: 0;'};
  }
  ${() => breakpoints.isTablet.mediaMaxWidth} {
    flex-grow: ${({ buttonStyle }) => (buttonStyle === ButtonStyle.Primary ? '1' : '')};
  }

  .custom-icon {
    display: inline-block;
    margin-right: 12px;
    top: -6px;
    position: relative;
    svg {
      top: 9px;
    }
  }
  .rotate {
    top: 0;
  }
  svg {
    top: 14px;
    position: relative;
    width: 1.2em;
    height: 1.2em;

    &.chevron-left-icon {
      display: none;
      left: var(--whitespace-basic-40);
      position: absolute;
    }

    &.chevron-right-icon {
      display: none;
      right: var(--whitespace-basic-40);
      position: absolute;
    }

    ${() => breakpoints.isTablet.mediaMinWidth} {
      &.chevron-right-icon,
      &.chevron-left-icon {
        display: inline-block;
      }
      ${({ disabled }) => disabled && 'color: var(--dark-grey);'}
    }
  }

  ${({ disabled }) =>
    disabled &&
    `
  background: var(--light-grey);
  border:solid 2px var(--light-grey);
  border-radius: var(--button-border-radius);
  color: #FFFFFF
  &:hover{
    box-shadow:none;
    background: var(--light-grey);
  }
  `}

  ${() => breakpoints.isTablet.mediaMinWidth} {
    ${({ hideOn }) => (hideOn === 'mobile' ? 'display: block;' : '')};
    ${({ hideOn }) => (hideOn === 'tablet' ? 'display: none;' : '')};
  }
  ${({ hideOn }) => (hideOn === 'mobile' ? 'display: none;' : '')};
`;
