import styled from '@emotion/styled';
import { breakpoints } from '@/core';

export const ErrorMessage = styled.li<{ isNotError?: boolean; isSuccess?: boolean }>`
  align-items: center;
  color: ${({ isNotError, isSuccess }) => (isSuccess ? 'var(--color-success)' : isNotError ? 'var(--color-not-error)' : 'inherit')};
  column-gap: 8px;
  display: flex;
  font-size: var(--component-wrapper-label-font-size);

  svg {
    width: 22px;
  }

  ${() => breakpoints.isTablet.mediaMinWidth} {
    column-gap: 10px;

    svg {
      width: 24px;
    }
  }
`;

export const ErrorMessageList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: var(--whitespace-06-to-12);
`;
