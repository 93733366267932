import { Country, useCountryGetAllQuery } from '@/store/webApi';
import { ErrorResponse, Options } from '@/core';

export const useCountryOptions = () => {
  const { data: countries = [], error: countriesError, isLoading: isLoadingCountries } = useCountryGetAllQuery();

  const options = countries
    .map((country) => ({ data: country, text: country.name ?? '', value: country.id?.toString() }))
    .sort((a, b) => a.text.localeCompare(b.text)) as Options<Country>;

  return {
    isLoadingCountries,
    countries: options,
    countriesError: countriesError as ErrorResponse,
  };
};
