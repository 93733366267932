const charCodesSpecialOne = [...Array('/'.charCodeAt(0) - '!'.charCodeAt(0) + 1).keys()].map((i) => i + '!'.charCodeAt(0));
const charCodesNumber = [...Array('9'.charCodeAt(0) - '0'.charCodeAt(0) + 1).keys()].map((i) => i + '0'.charCodeAt(0));
const charCodesSpecialTwo = [...Array('@'.charCodeAt(0) - ':'.charCodeAt(0) + 1).keys()].map((i) => i + ':'.charCodeAt(0));
const charCodesUppercaseLetter = [...Array('Z'.charCodeAt(0) - 'A'.charCodeAt(0) + 1).keys()].map((i) => i + 'A'.charCodeAt(0));
const charCodesSpecialThree = [...Array('`'.charCodeAt(0) - '['.charCodeAt(0) + 1).keys()].map((i) => i + '['.charCodeAt(0));
const charCodesLowercaseLetter = [...Array('z'.charCodeAt(0) - 'a'.charCodeAt(0) + 1).keys()].map((i) => i + 'a'.charCodeAt(0));
const charCodesSpecialFour = [...Array('~'.charCodeAt(0) - '{'.charCodeAt(0) + 1).keys()].map((i) => i + '{'.charCodeAt(0));
const charCodesSpecialFive = [...Array('■'.charCodeAt(0) - 'Ç'.charCodeAt(0) + 1).keys()].map((i) => i + 'Ç'.charCodeAt(0));

export const characterCharCodesAll = [
  ...charCodesSpecialOne,
  ...charCodesNumber,
  ...charCodesSpecialTwo,
  ...charCodesUppercaseLetter,
  ...charCodesSpecialThree,
  ...charCodesLowercaseLetter,
  ...charCodesSpecialFour,
  ...charCodesSpecialFive,
];
