import { ReactNode } from 'react';

import { FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Label,
  LabelProps,
  SelectBaseOptionReg,
  SelectPropsReg,
  SelectReg,
  SelectRegActionMeta,
  ValidatedField,
  ValidatedFieldProps,
} from '@britishcouncil/react-registration-platform';

type FormSelectProps<T extends FieldValues> = SelectPropsReg<SelectBaseOptionReg> &
  Pick<ValidatedFieldProps, 'errorInline' | 'errorSubmittion'> &
  Pick<LabelProps, 'helpText'> &
  UseControllerProps<T> & {
    label?: ReactNode;
  };

export const FormSelect = <T extends FieldValues = FieldValues>({
  id,
  errorInline,
  errorSubmittion,
  label,
  helpText,
  control,
  rules,
  defaultValue,
  shouldUnregister,
  disabled,
  name,
  options,
  required,
  onChange,
  ...props
}: FormSelectProps<T>) => {
  const { t } = useTranslation('');

  const { field } = useController({
    name,
    control,
    rules: { required: required ? t('common.required') : false, ...rules },
    defaultValue,
    shouldUnregister,
  });

  return (
    <div>
      {label ? (
        <Label placeholder="" helpText={helpText} htmlFor={name || id}>
          {label}
        </Label>
      ) : null}
      <div className="mt-1">
        <ValidatedField errorInline={errorInline} errorSubmittion={errorSubmittion}>
          <SelectReg
            {...props}
            id={id}
            options={options}
            name={field.name}
            isDisabled={disabled}
            value={options?.find((c) => 'value' in c && c.value === field.value) as SelectBaseOptionReg}
            onChange={(newValue: SelectBaseOptionReg | null, actionMeta: SelectRegActionMeta) => {
              field.onChange(newValue?.value);
              onChange && onChange(newValue, actionMeta);
            }}
            onBlur={field.onBlur}
          />
        </ValidatedField>
      </div>
    </div>
  );
};
