import { FC } from 'react';

import { IconProps } from '@/icons/IconProps';

export const ExternalLink: FC<IconProps> = ({ index = 0 }) => {
  return (
    <svg
      aria-hidden="true"
      aria-labelledby={`external-link-icon-title-${index}`}
      className="external-link-icon"
      data-testid={`external-link-icon-${index}`}
      fill="none"
      focusable="false"
      height="1em"
      role="img"
      tabIndex={-1}
      viewBox="0 0 1063 912.8"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`external-link-icon-title-${index}`}></title>
      <path
        fill="currentColor"
        d="M825.1,536.3c-3.5-3.5-8-5.2-13.4-5.2h-37.4c-5.5,0-9.9,1.8-13.4,5.2s-5.2,8-5.2,13.4v186.8c0,25.7-9.2,47.7-27.4,66
          s-40.3,27.4-66,27.4H176.5c-25.7,0-47.7-9.2-66-27.4s-27.4-40.3-27.4-66V250.9c0-25.7,9.2-47.7,27.4-66s40.3-27.4,66-27.4h411
          c5.5,0,9.9-1.8,13.4-5.2s5.2-8,5.2-13.4v-37.5c0-5.4-1.8-9.9-5.2-13.4s-8-5.2-13.4-5.2h-411c-46.3,0-85.9,16.4-118.8,49.3
          S8.4,204.5,8.4,250.9v485.7c0,46.3,16.4,85.9,49.3,118.8s72.5,49.3,118.8,49.3h485.7c46.3,0,85.9-16.4,118.8-49.3
          c32.9-32.9,49.3-72.5,49.3-118.8V549.8C830.4,544.3,828.6,539.8,825.1,536.3L825.1,536.3z M1043.5,19.1
          c-7.4-7.4-16.2-11.1-26.3-11.1H718.3c-10.1,0-18.9,3.7-26.3,11.1s-11.1,16.1-11.1,26.3s3.7,18.9,11.1,26.3l102.8,102.8L414.1,555
          c-3.9,3.9-5.8,8.4-5.8,13.4s2,9.5,5.8,13.4l66.5,66.5c3.9,3.9,8.4,5.8,13.4,5.8s9.5-2,13.4-5.8L888,267.7l102.8,102.8
          c7.4,7.4,16.2,11.1,26.3,11.1s18.9-3.7,26.3-11.1s11.1-16.1,11.1-26.3V45.4C1054.5,35.2,1050.8,26.5,1043.5,19.1L1043.5,19.1z"
      />
    </svg>
  );
};
