import { eightOrMoreCharacters, oneDigit, oneLowercase, oneSpecial, oneUppercase, passwordRegEx } from '@/core';

type PasswordRequirement = { id: string; valid: boolean };
type PasswordRequirements = Array<PasswordRequirement>;

export type PasswordValidation = {
  isMinimumLength: boolean;
  isValid: boolean;
  passwordRequirements: PasswordRequirements;
};

export const oneDigitId = '9788664f-ce02-4bc1-962a-865f5ecc758a';
export const oneLowercaseId = 'f656d3c1-05bd-4e44-994d-9ea02241c83f';
export const oneSpecialId = '300baeb8-db89-4281-b6ae-3ea1c0457aec';
export const oneUppercaseId = '4ef9747f-2d79-4871-80c3-4feb92cd850a';

export const validatePassword = (password: string): PasswordValidation => {
  return {
    isMinimumLength: !!password.match(eightOrMoreCharacters),
    isValid: !!password.match(passwordRegEx),
    passwordRequirements: [
      { id: oneLowercaseId, valid: !!password.match(oneLowercase) },
      { id: oneUppercaseId, valid: !!password.match(oneUppercase) },
      { id: oneDigitId, valid: !!password.match(oneDigit) },
      { id: oneSpecialId, valid: !!password.match(oneSpecial) },
    ],
  };
};
