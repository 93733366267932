import styled from '@emotion/styled';
import { breakpoints } from '@/core';

export const Footer = styled.footer`
  --skip-background: var(--skip-background-inverted);
  --skip-color: var(--skip-color-inverted);

  background-color: var(--background-color-inverted);
  color: var(--color-inverted);
  display: var(--grid-display);
  flex-direction: column;
  font-size: var(--smaller-font-size);
  gap: var(--whitespace-basic-06);
  grid-template-columns: var(--grid-template-columns);
  margin-top: auto;
  z-index: 0;

  ${() => breakpoints.isTablet.mediaMinWidth} {
    padding: var(--whitespace-basic-32) 0;
  }
`;

export const InnerMainContent = styled.div`
  padding: var(--whitespace-basic-24);

  ${() => breakpoints.isTablet.mediaMinWidth} {
    grid-column: 2;
    padding: var(--whitespace-basic-40) 0px;
  }
`;
