import { useEffect, useState } from 'react';

import { FiLoader } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from '@britishcouncil/react-registration-platform';

import { useAppParams, useBasket, useFeatureFlags } from '@/core';

export const BookBtn = () => {
  const navigate = useNavigate();
  const { basket, reserveItemsInBasket, isUpdating } = useBasket();
  const [reservationError, setReservationError] = useState(false);
  const { productCode } = useAppParams();
  const { data, isLoading } = useFeatureFlags();
  const { t } = useTranslation();

  const handleBook = async () => {
    setReservationError(false);
    const { isSuccess } = await reserveItemsInBasket();

    if (!isSuccess) {
      setReservationError(true);
      return;
    }

    data?.identityIntegrationFeature ? navigate('/account/create', { state: { productCode } }) : navigate(`personal-details/0`);
  };

  useEffect(() => {
    setReservationError(false);
  }, [basket.items]);

  return (
    <>
      <div className="hidden md:block mt-12 text-right w-full p-[24px] md:p-0 ">
        <Button disabled={isUpdating || isLoading} onClick={handleBook}>
          {t('book-test.cta')}
          {isUpdating && <FiLoader className="text-[20px] relative top-[-2px] ml-[10px] animate-spin-slow inline-block"></FiLoader>}
        </Button>
      </div>
      {reservationError ? <div className="p-[12px] text-red text-right">Ooops, cannot create reservation!</div> : null}
    </>
  );
};
