import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface MinorIdentificationDetailsState {
  expiryDate?: string;
  identificationType: number;
  identificationNumber: string;
  issuingAuthority: string;
  nationalityCountryId?: number;
  noExpiryDate: boolean;
}

const initialState: MinorIdentificationDetailsState = {
  identificationNumber: '',
  identificationType: 0,
  issuingAuthority: '',
  noExpiryDate: false,
};

export const minorIdentificationDetailsSlice = createSlice({
  name: 'minorIdentificationDetails',
  initialState,
  reducers: {
    clearMinorIdentificationDetails: (state): void => {
      delete state.expiryDate;
      state.identificationNumber = '';
      state.identificationType = 0;
      delete state.nationalityCountryId;
      state.issuingAuthority = '';
      state.noExpiryDate = false;
    },
    setIdentificationDetails: (state, { payload }: PayloadAction<Partial<MinorIdentificationDetailsState>>): MinorIdentificationDetailsState => {
      return { ...state, ...payload };
    },
    setIdentificationExpiryDate: (state, { payload }: PayloadAction<string>): void => {
      state.expiryDate = payload;
    },
    setIdentificationNumber: (state, { payload }: PayloadAction<string>): void => {
      state.identificationNumber = payload;
    },
    setIdentificationType: (state, { payload }: PayloadAction<number>): void => {
      state.identificationType = payload;
    },
    setIdentificationIssuingAuthority: (state, { payload }: PayloadAction<string>): void => {
      state.issuingAuthority = payload;
    },
    setIdentificationNationalityCountryId: (state, { payload }: PayloadAction<number>): void => {
      state.nationalityCountryId = payload;
    },
    setNoIdentificationExpiryDate: (state, { payload }: PayloadAction<boolean>): void => {
      state.noExpiryDate = payload;
    },
  },
});

const { clearMinorIdentificationDetails } = minorIdentificationDetailsSlice.actions;
const { reducer: minorIdentificationDetailsReducer } = minorIdentificationDetailsSlice;
export { clearMinorIdentificationDetails, minorIdentificationDetailsReducer };
