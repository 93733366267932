import { UseControllerProps, get, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getFormError, getServerError } from '@/core/errors/FormError';

import { RadioGroupReg, RadioGroupRegProps } from './RadioGroupReg';

type Props = RadioGroupRegProps & UseControllerProps<TODO, TODO>;

export const FormRadioGroup = ({ name, control, defaultValue, shouldUnregister, rules, ...props }: Props) => {
  const { t } = useTranslation('');

  const { field, formState } = useController({
    name,
    control,
    rules: { required: t('common.required'), ...rules },
    defaultValue,
    shouldUnregister,
  });

  return (
    <RadioGroupReg
      name={name}
      value={field.value?.toString()}
      errorInline={getFormError(get(formState.errors, field.name))}
      errorSubmittion={getServerError(get(formState.errors, field.name))}
      onValueChange={(value: string) => {
        return field.onChange(value);
      }}
      onBlur={field.onBlur}
      {...props}
    />
  );
};
