import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { ComponentWrapper, DateInput, DateInputValidated } from '@/components';
import { identificationDetailsSlice, minorIdentificationDetailsSlice } from '@/store';
import { useAppDispatch } from '@/core';

interface ExpiryDatesProps {
  defaultExpiryDate?: string;
  earliestExpiryDate?: Date;
  identificationType: number;
  isChild?: boolean;
  noIdCardExpiryDate: boolean;
}

export const ExpiryDate = ({ defaultExpiryDate, earliestExpiryDate, identificationType, isChild, noIdCardExpiryDate }: ExpiryDatesProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('');
  const slice = isChild ? minorIdentificationDetailsSlice : identificationDetailsSlice;

  const onExpiryDateChange = useCallback(
    (newValue: Date) => {
      const expiryDate = `${newValue.getFullYear()}-${(newValue.getMonth() + 1).toString().padStart(2, '0')}-${newValue.getDate().toString().padStart(2, '0')}`;
      dispatch(slice.actions.setIdentificationExpiryDate(expiryDate));
    },
    [dispatch, isChild],
  );

  const onExpiryDateValidate: DateInputValidated = useCallback(
    ({ valid }) => {
      if (!valid) dispatch(slice.actions.setIdentificationExpiryDate(''));
    },
    [dispatch, isChild],
  );

  const onNoExpiryDateChange = useCallback(
    (newValue: boolean) => {
      dispatch(slice.actions.setNoIdentificationExpiryDate(newValue));
      dispatch(slice.actions.setIdentificationExpiryDate(''));
    },
    [dispatch, isChild],
  );

  const earliestYear = useMemo(() => (earliestExpiryDate ? earliestExpiryDate.getFullYear() : undefined), [earliestExpiryDate]);

  return (
    <ComponentWrapper id="identification-details">
      <DateInput
        beforeEarliestDateMessage={t('identification.expiry-date-too-early')}
        defaultDate={defaultExpiryDate ? new Date(defaultExpiryDate) : undefined}
        disabled={identificationType === 1 && noIdCardExpiryDate}
        disabledChoiceText={t('identification.details.id-card.no-expiry')}
        earliestDate={earliestExpiryDate}
        earliestYear={earliestYear}
        id="expiry-date"
        invalidDateMessage={t('components.date-input.invalid-date-message')}
        label={identificationType === 1 ? t('identification.details.id-card.expiry-date') : t('identification.details.passport.expiry-date')}
        onDateChange={onExpiryDateChange}
        onDisabledChange={onNoExpiryDateChange}
        onValidate={onExpiryDateValidate}
        placeholderDay={t('components.date-input.day-placeholder')}
        placeholderMonth={t('components.date-input.month-placeholder')}
        placeholderYear={t('components.date-input.year-placeholder')}
        required
        requiredText={t('common.required')}
        showDisabledChoice={identificationType === 1}
      />
    </ComponentWrapper>
  );
};
