import { FC } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// This page will be rebuild with proper UI/UX, which is now in progress. For now done as simple as possible.
export const Home: FC = (): JSX.Element => {
  const { t } = useTranslation('');

  const products = [
    { name: 'APTIS', code: 'aptis' },
    { name: 'APTIS ESOL', code: 'esol' },
    { name: 'University of London', code: 'uol' },
  ];

  return (
    <div className="flex flex-col justify-center items-center gap-[20px] mt-[20px]">
      <h1 className="font-bold text-[24px] md:text-[48px] text-indigo">{t('product-not-found.title')}</h1>
      <div className="text-center">
        <p>{t('product-not-found.continue-with')}</p>
        <ul>
          {products.map((product) => (
            <li key={product.code}>
              <Link className="underline" to={`/${product.code}/book-test/`}>
                {product.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
