import { FC } from 'react';

import { IconProps } from '@/icons/IconProps';
import { noAction } from '@/core';

export const ChevronUp: FC<IconProps> = ({ index = 0, onClick = noAction, onMouseDown = noAction, onMouseOut = noAction, onMouseUp = noAction }) => {
  return (
    <svg
      aria-hidden="true"
      aria-labelledby={`chevron-up-icon-title-${index}`}
      className="chevron chevron-up-icon"
      data-testid={`chevron-up-icon-${index}`}
      fill="none"
      focusable="true"
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseOut={onMouseOut}
      onMouseUp={onMouseUp}
      role="img"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`chevron-up-icon-title-${index}`}></title>
      <polyline points="18 15 12 9 6 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></polyline>
    </svg>
  );
};
