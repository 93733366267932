/* istanbul ignore file */
import { CustomerDTO, CustomersDTO } from '@/core';

import { getSessionMock } from './sessions';
import { mockPoland, mockSpain, mockUK } from './countries';

export const lewkowiczKarolMinor: CustomerDTO = {
  id: '7',
  dateOfBirth: '2005-06-15',
  firstName: 'Karol',
  sessions: [getSessionMock('4'), getSessionMock('26')],
  lastName: 'Lewkowicz',
  parentId: '6',
};
export const lewkowiczNadiaMinor: CustomerDTO = {
  id: '5',
  dateOfBirth: '2005-01-01',
  firstName: 'Nadia',
  sessions: [getSessionMock('6')],
  lastName: 'Lewkowicz',
  parentId: '6',
};
export const lewkowiczPiotrParentWithSessions: CustomerDTO = {
  id: '6',
  address: {
    addressLine1: 'Address Line One',
    addressLine2: '',
    city: 'Warsaw',
    postCode: '02-411',
    residenceCountryId: mockPoland.id,
  },
  dateOfBirth: '1985-01-01',
  emailAddress: 'user03@fake.fk',
  firstName: 'Piotr',
  lastName: 'Lewkowicz',
  marketingPreference: 'all',
  mobileNumber: '0048 123456789',
  sessions: [getSessionMock('1'), getSessionMock('19'), getSessionMock('34')],
};

export const smithAliceMinor: CustomerDTO = {
  id: '2',
  dateOfBirth: '2005-06-15',
  firstName: 'Alice',
  lastName: 'Smith',
  parentId: '4',
  sessions: [getSessionMock('14', '12'), getSessionMock('22', '16')],
};
export const smithJaneMinor: CustomerDTO = {
  id: '3',
  dateOfBirth: '2005-01-01',
  firstName: 'Jane',
  lastName: 'Smith',
  parentId: '4',
  sessions: [getSessionMock('1', '11')],
};
export const smithJohnParentNoSessions: CustomerDTO = {
  id: '4',
  address: {
    addressLine1: 'Address Line One',
    addressLine2: '',
    city: 'London',
    postCode: 'NW1 1XX',
    residenceCountryId: mockUK.id,
  },
  dateOfBirth: '1985-01-01',
  emailAddress: 'user02@fake.fk',
  firstName: 'John',
  lastName: 'Smith',
  marketingPreference: 'none',
  mobileNumber: '07777 123456',
  sessions: [],
};

export const velarXavierNoMinors: CustomerDTO = {
  id: '1',
  address: {
    addressLine1: 'Address Line One',
    addressLine2: '',
    city: 'Donostia-San Sebastian',
    postCode: '20013',
    residenceCountryId: mockSpain.id,
  },
  dateOfBirth: '1999-01-01',
  emailAddress: 'user01@fake.fk',
  firstName: 'Xavier',
  lastName: 'Velar',
  marketingPreference: 'british-council',
  mobileNumber: '654 123 456',
  sessions: [getSessionMock('2', '13')],
  termsAndConditions: true,
};

export const mockCustomersDTOs: CustomersDTO = [
  lewkowiczNadiaMinor,
  lewkowiczPiotrParentWithSessions,
  lewkowiczKarolMinor,
  smithAliceMinor,
  smithJaneMinor,
  smithJohnParentNoSessions,
  velarXavierNoMinors,
];

export const getCustomerDTOsMock = (include: boolean, customerId: string) => {
  if (!include) return undefined;
  else return mockCustomersDTOs.filter((customer) => customer.id === customerId || customer.parentId === customerId);
};
