import { useEffect } from 'react';

import { journeySlice } from '@/store';
import { useAppDispatch } from '@/core';

import { BookingCompleteLegacyPage } from './legacy/BookingCompleteLegacyPage';

export const BookingComplete = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(journeySlice.actions.setFarthestJourneyStep('BookingComplete'));
  }, [dispatch]);

  // TODO: [REG-1934] Switch to authorized booking complete page
  // Temporary solution while we are working on introducing identity authentication, this will allow us to backward compatibility until full implementation.
  return <BookingCompleteLegacyPage />;
};
