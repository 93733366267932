import { useEffect, useState } from 'react';

import { FiLoader } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { WellReg } from '@britishcouncil/react-registration-platform';

import { FlowIndicator, PageTitle } from '@/components';
import { FlowSteps, GoogleTagManager, bookTestReviewRoute, params, useAppDispatch, useAppSelector, useBasket, useFeatureFlags } from '@/core';
import { basketSlice, getReturnToReviewFrom, journeySlice, searchDetailsSlice } from '@/store';
import { useProductConfig } from '@/core/hooks/useProductConfig';
import { useTranslationOrDefault } from '@/core/hooks/useTranslationOrDefault';

import { ChooseDates, ChooseSession, ChooseSessionCity, ChooseSessionCountry, ChooseSessionTestPackage, ChooseVariant } from './components';

export * from './booking-complete';
export * from './components/ChooseSessionCountry';
export * from './step-1-1-minor-details';
export * from './step-1-personal-details';
export * from './step-2-identification';
export * from './step-3-review';
export * from './helpers';

export const BookTest = () => {
  const steps = window.location.pathname.split('/');
  const stepName = steps[3];
  const isStepWithMobileFlow = ['personal-details', 'identification', 'minor-identification', 'minor-details', 'review'].includes(stepName);
  const numberOfParams = isStepWithMobileFlow && steps[4] !== '0' ? 5 : 4;
  GoogleTagManager.addEvent({
    action: 'Page entered',
    event: 'VirtualPageview',
    virtualPageTitle: 'British Council - Book a new test - Choose country',
    virtualPageTitleLocal: document.title,
    virtualPageURL: steps.slice(0, numberOfParams).join('/'),
  });

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { data, isLoading } = useFeatureFlags();
  const { getTranslation } = useTranslationOrDefault();

  const returnToReviewFrom = useSelector(getReturnToReviewFrom);
  const navigate = useNavigate();
  const { productConfig } = useProductConfig();
  const { basket, reserveItemsInBasket, isUpdating } = useBasket();
  const [reservationError, setReservationError] = useState(false);
  const { farthestJourneyStep } = useAppSelector((s) => s.journey);

  useEffect(() => {
    setReservationError(false);
  }, [basket.items]);

  useEffect(() => {
    if (farthestJourneyStep == 'BookingComplete') {
      dispatch(basketSlice.actions.reset());
      dispatch(searchDetailsSlice.actions.reset());
      dispatch(journeySlice.actions.reset());
    }
  }, [farthestJourneyStep]);

  const handleBook = async () => {
    setReservationError(false);
    const { isSuccess } = await reserveItemsInBasket();
    if (!isSuccess) {
      setReservationError(true);
      return;
    }

    const step = window.innerWidth < 1024 ? FlowSteps.flowStepForMobilePath : FlowSteps.flowStepForDesktopPath;

    if (returnToReviewFrom) navigate(bookTestReviewRoute.absolutePath.replace(params.productCode, productConfig?.productCode || '').replace(params.step, step));
    else
      data?.identityIntegrationFeature
        ? navigate('/account/create', { state: { productCode: productConfig?.productCode } })
        : navigate(`personal-details/${step}`);
  };

  return (
    <>
      <FlowIndicator currentFormStep={0} currentStep={1} formSteps={6} steps={FlowSteps.totalStepsForSessionSelection}></FlowIndicator>
      <PageTitle main text={t('book-test.title')} />
      <p className="font-bold text-indigo text-[24px]">
        {getTranslation('book-test.product-title.' + productConfig?.productCode?.toLowerCase(), productConfig?.productCode)}
      </p>
      <section data-testid="book-test">
        <WellReg className="my-[32px]">
          <ChooseSessionCountry />
          {/** Variant select is hidden and auto-selected when there is only one option */}
          <ChooseVariant />
          <ChooseSessionTestPackage />
          <ChooseSessionCity />
        </WellReg>
        <ChooseDates />
        <ChooseSession />
        <div className="mt-[16px] lg:hidden">
          <button
            disabled={basket.items.length === 0 || isUpdating || isLoading}
            onClick={handleBook}
            className="w-full py-4 bg-purple-dark text-white rounded-[8px] font-bold text-[16px] disabled:opacity-30"
          >
            {t(returnToReviewFrom ? 'tests-and-results.upcoming.save-and-review' : 'book-test.cta')}
            {isUpdating && <FiLoader className="text-[20px] relative top-[-2px] ml-[10px] animate-spin-slow inline-block"></FiLoader>}
          </button>
          {reservationError ? <div className="p-[12px] text-red text-left">Ooops, cannot create reservation!</div> : null}
        </div>
      </section>
    </>
  );
};
