import { ContactDetailsState, OrderAcknowledgementDetailsState, PersonalDetailsState } from '@/store';

export const convertOrderAcknowledgeBodyLegacy = (content: {
  other: OrderAcknowledgementDetailsState;
  person: PersonalDetailsState;
  personContact: ContactDetailsState;
}) => {
  const acknowledgementType = { not_specified: 0, self: 1, person: 2, company: 3 }[content.other.orderAcknowledgement ?? 'not_specified'];
  const usePersonal = acknowledgementType === 1;

  const includeAddress = {
    addressLine1: usePersonal ? content.personContact.postalLine01 : content.other.postalLine01,
    addressLine2: usePersonal ? content.personContact.postalLine02 : content.other.postalLine02,
    city: usePersonal ? content.personContact.postalTownCity : content.other.postalTownCity,
    postCode: usePersonal ? content.personContact.postalZipCode : content.other.postalZipCode,
    residenceCountryId: usePersonal ? content.personContact.countryId : content.other.countryId,
  };
  const personalDetailsToPost = {
    address: includeAddress,
    contactNumber: content.personContact.mobileNumber,
    email: content.person.email,
    firstName: content.person.firstName,
    lastName: content.person.lastName,
  };

  const otherPersonDetails = {
    address: includeAddress,
    contactNumber: content.other.telephoneNumber,
    email: content.other.email,
    firstName: content.other.firstName,
    lastName: content.other.lastName,
  };

  const companyDetails = {
    address: includeAddress,
    companyName: content.other.companyName,
    contactNumber: content.other.telephoneNumber,
    email: content.other.email,
    taxNumber: content.other.vatNipNumber,
  };

  const bodyTypes = [
    { acknowledgementReceiverType: acknowledgementType, ...personalDetailsToPost },
    { acknowledgementReceiverType: acknowledgementType, ...personalDetailsToPost },
    { acknowledgementReceiverType: acknowledgementType, ...otherPersonDetails },
    { acknowledgementReceiverType: acknowledgementType, ...companyDetails },
  ];
  return bodyTypes[acknowledgementType];
};
