import React, { FC, HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { getDaysInMonth, startOfDay } from 'date-fns';

import {
  Choice,
  Columns,
  ComponentWrapper,
  ComponentWrapperProps,
  ConditionalRequiredProps,
  DisabledChangeEvent,
  FormElementProps,
  LoadingProps,
  Select,
  SelectSelectionChangeEvent,
  TextInput,
  TextInputChangeEvent,
  createErrorMessage,
  dateErrorId,
  useErrorMessages,
  useRegisterFormControl,
} from '@/components';
import { Global } from '@emotion/react';
import { Option, Options, maxJSDateInMilliseconds, noAction } from '@/core';
import { getMonthsObject } from '@/utils/getMonthsNames';

import { dateInputStyles } from './DateInput.style';

interface Month {
  dayCount: number;
  monthIndex: string;
}

interface DateInputProps extends ComponentWrapperProps {
  autoFocus?: boolean;
  afterLatestDateMessage?: string;
  beforeEarliestDateMessage?: string;
  'data-testid'?: string;
  defaultDate?: Date;
  disabled?: boolean;
  disabledChoiceText?: string;
  earliestDate?: Date;
  earliestYear?: number;
  invalidDateMessage: string;
  latestDate?: Date;
  latestYear?: number;
  onDateChange?: DateInputSelectionChange;
  onDisabledChange?: DisabledChangeEvent;
  onValidate?: DateInputValidated;
  placeholderDay?: string;
  placeholderMonth?: string;
  placeholderYear?: string;
  showDisabledChoice?: boolean;
}

const monthNames = getMonthsObject();

export const DateInput: FC<ConditionalRequiredProps & DateInputProps & FormElementProps & HTMLAttributes<HTMLElement> & LoadingProps> = ({
  afterLatestDateMessage,
  'aria-label': ariaLabel = '',
  autoFocus,
  beforeEarliestDateMessage,
  className,
  'data-testid': dataTestId,
  defaultDate,
  disabled,
  disabledChoiceText = '',
  earliestDate,
  earliestYear = 0,
  formErrorMessages,
  formErrorSimpleMessage,
  formPreventControlRegistration,
  id,
  inlineErrorMessages,
  isLoading,
  invalidDateMessage,
  label,
  labelIsHeading = false,
  latestDate,
  latestYear = new Date(maxJSDateInMilliseconds).getFullYear(),
  loadingText,
  onDateChange = noAction,
  onDisabledChange = noAction,
  onValidate = noAction,
  placeholderDay = '',
  placeholderMonth = '',
  placeholderYear = '',
  showDisabledChoice,
  required = false,
  requiredText = '',
  ...props
}): JSX.Element => {
  const [clearDay, setClearDay] = useState(false);
  const [clearYear, setClearYear] = useState(false);
  const columnsRef = useRef<HTMLElement>(null);
  const [daysInFebruary, setDaysInFebruary] = useState(29);
  const [daysInMonth, setDaysInMonth] = useState(31);
  const dateError = useMemo(() => createErrorMessage({ id: dateErrorId, text: invalidDateMessage, type: 'internal' }), [invalidDateMessage]);
  const afterLatestDateError = useMemo(
    () => createErrorMessage({ id: dateErrorId, text: afterLatestDateMessage ?? '', type: 'internal' }),
    [afterLatestDateMessage],
  );
  const beforeEarliestDateError = useMemo(
    () => createErrorMessage({ id: dateErrorId, text: beforeEarliestDateMessage ?? '', type: 'internal' }),
    [beforeEarliestDateMessage],
  );
  const { internalErrorMessages, setInternalErrorMessages } = useErrorMessages(required, requiredText, inlineErrorMessages);
  const [isDisabled, setIsDisabled] = useState(!!disabled);
  const [isRequired, setIsRequired] = useState(!!required);
  const preSelectedDay = useRef<number>();
  const preSelectedMonth = useRef<Option<Month>>();
  const preSelectedYear = useRef<number>();
  const [selectedDay, setSelectedDay] = useState<number>();
  const [selectedMonth, setSelectedMonth] = useState<Option<Month>>();
  const [selectedYear, setSelectedYear] = useState<number>();
  const [throttleDay, setThrottleDay] = useState('');
  const [throttleYear, setThrottleYear] = useState('');
  const testIdValue = dataTestId ?? id;

  const months: Options<Month> = useMemo(
    () => [
      { data: { dayCount: 31, monthIndex: '01' }, text: monthNames.january, value: 'jan' },
      { data: { dayCount: 28, monthIndex: '02' }, text: monthNames.february, value: 'feb' },
      { data: { dayCount: 31, monthIndex: '03' }, text: monthNames.march, value: 'mar' },
      { data: { dayCount: 30, monthIndex: '04' }, text: monthNames.april, value: 'apr' },
      { data: { dayCount: 31, monthIndex: '05' }, text: monthNames.may, value: 'may' },
      { data: { dayCount: 30, monthIndex: '06' }, text: monthNames.june, value: 'jun' },
      { data: { dayCount: 31, monthIndex: '07' }, text: monthNames.july, value: 'jul' },
      { data: { dayCount: 31, monthIndex: '08' }, text: monthNames.august, value: 'aug' },
      { data: { dayCount: 30, monthIndex: '09' }, text: monthNames.september, value: 'sep' },
      { data: { dayCount: 31, monthIndex: '10' }, text: monthNames.october, value: 'oct' },
      { data: { dayCount: 30, monthIndex: '11' }, text: monthNames.november, value: 'nov' },
      { data: { dayCount: 31, monthIndex: '12' }, text: monthNames.december, value: 'dec' },
    ],
    [],
  );

  useEffect(() => {
    if (defaultDate) {
      if (!preSelectedYear.current) {
        preSelectedYear.current = defaultDate.getFullYear();
        setSelectedYear(preSelectedYear.current);
      }
      if (!preSelectedMonth.current) {
        preSelectedMonth.current = months[defaultDate.getMonth()];
        setSelectedMonth(preSelectedMonth.current);
      }
      if (!preSelectedDay.current) {
        preSelectedDay.current = defaultDate.getDate();
        setSelectedDay(preSelectedDay.current);
      }
    }
  }, [defaultDate, months]);

  useEffect(() => {
    setClearDay(false);
  }, [clearDay]);

  useEffect(() => {
    setClearYear(false);
  }, [clearYear]);

  useEffect(() => {
    if (selectedMonth?.data) setDaysInMonth(selectedMonth.value === 'feb' ? daysInFebruary : selectedMonth.data.dayCount);
  }, [daysInFebruary, selectedMonth]);

  useEffect(() => {
    setIsDisabled(!!disabled);
  }, [disabled]);

  useEffect(() => {
    if (required) setIsRequired(!isDisabled);
  }, [isDisabled, required]);

  useEffect(() => {
    setIsRequired(!!required);
  }, [required]);

  useEffect(() => {
    setDaysInFebruary(getDaysInMonth(new Date(`${selectedYear}-02-01`)));
  }, [selectedYear]);

  useEffect(() => {
    setSelectedDay(+throttleDay);
    setThrottleDay('');
  }, [throttleDay]);

  useEffect(() => {
    setSelectedYear(+throttleYear);
    setThrottleYear('');
  }, [throttleYear]);

  const validYear = useCallback(() => selectedYear && selectedYear >= earliestYear && selectedYear <= latestYear, [earliestYear, latestYear, selectedYear]);

  const validateEarliestDate = useCallback(
    (newDate: Date): boolean => {
      if (earliestDate && newDate < earliestDate) {
        setInternalErrorMessages((errorMessages) => [...errorMessages, beforeEarliestDateError]);
        return false;
      }
      return true;
    },
    [beforeEarliestDateError, earliestDate, setInternalErrorMessages],
  );

  const validateLatestDate = useCallback(
    (newDate: Date): boolean => {
      if (latestDate && newDate > latestDate) {
        setInternalErrorMessages((errorMessages) => [...errorMessages, afterLatestDateError]);
        return false;
      }
      return true;
    },
    [afterLatestDateError, latestDate, setInternalErrorMessages],
  );

  const getNewDate = useCallback(() => {
    return startOfDay(new Date(`${selectedYear}-${selectedMonth?.data?.monthIndex}-${selectedDay?.toString().padStart(2, '0')}`));
  }, [selectedDay, selectedMonth?.data?.monthIndex, selectedYear]);

  useEffect(() => {
    if (!selectedDay || !selectedMonth || !validYear()) return;
    const newDate = getNewDate();
    setInternalErrorMessages((errorMessages) => errorMessages.filter((errorMessage) => errorMessage.id !== dateErrorId));
    if (validateLatestDate(newDate) && validateEarliestDate(newDate)) onDateChange(newDate);
  }, [getNewDate, onDateChange, selectedDay, selectedMonth, setInternalErrorMessages, validYear, validateEarliestDate, validateLatestDate]);

  const setDay = useCallback(
    (day?: number, useSelected?: boolean) => {
      const _day = useSelected ? selectedDay : day;
      _day && daysInMonth < _day ? setThrottleDay(daysInMonth.toString()) : _day && _day < 1 ? setThrottleDay('1') : setSelectedDay(_day);
    },
    [daysInMonth, selectedDay],
  );

  useEffect(() => {
    setDay(undefined, true);
  }, [daysInMonth, setDay]);

  const setYear = useCallback(
    (year: number) => {
      if (latestYear && latestYear < year) {
        setThrottleYear(latestYear.toString());
        return;
      }
      setSelectedYear(year);
    },
    [latestYear],
  );

  const validate = useCallback((): boolean => {
    setInternalErrorMessages((errorMessages) => errorMessages.filter((errorMessage) => errorMessage.id !== dateErrorId));
    if (isDisabled) return true;
    if (isRequired && (!selectedDay || !selectedMonth || !selectedYear)) {
      setInternalErrorMessages((errorMessages) => [...errorMessages, dateError]);
      const valid = false;
      onValidate({ day: selectedDay, month: selectedMonth?.data?.monthIndex, newDate: undefined, valid, year: selectedYear });
      return valid;
    } else
      try {
        const newDate = getNewDate();
        let valid = newDate instanceof Date && !isNaN(newDate.getTime());
        if (!valid) {
          setInternalErrorMessages((errorMessages) => [...errorMessages, dateError]);
          onValidate({ day: selectedDay, month: selectedMonth?.data?.monthIndex, newDate, tooLate: false, valid, year: selectedYear });
          return false;
        }
        const tooLate = !validateLatestDate(newDate);
        const tooEarly = !validateEarliestDate(newDate);
        if (!tooEarly && !tooLate) {
          if (!validYear()) {
            setInternalErrorMessages((errorMessages) => [...errorMessages, dateError]);
            valid = false;
          }
        } else valid = !tooLate && !tooEarly;
        onValidate({ day: selectedDay, month: selectedMonth?.data?.monthIndex, newDate, tooEarly, tooLate, valid, year: selectedYear });
        return valid;
      } catch (error) {
        setInternalErrorMessages((errorMessages) => [...errorMessages, dateError]);
        onValidate({ day: selectedDay, month: selectedMonth?.data?.monthIndex, valid: false, year: selectedYear });
        return false;
      }
  }, [
    dateError,
    getNewDate,
    isDisabled,
    isRequired,
    onValidate,
    selectedDay,
    selectedMonth,
    selectedYear,
    setInternalErrorMessages,
    validYear,
    validateEarliestDate,
    validateLatestDate,
  ]);

  const { errorId, scrollIntoView, resetScrollIntoView } = useRegisterFormControl(id, !!formPreventControlRegistration, validate);

  const handleBlur: React.FocusEventHandler<HTMLElement> = useCallback(
    (event: React.FocusEvent<HTMLElement>) => {
      if (!event.currentTarget.contains(event.relatedTarget)) validate();
    },
    [validate],
  );

  const handleDayChange: TextInputChangeEvent = useCallback(
    (newValue) => {
      const day = +newValue;
      setDay(day);
    },
    [setDay],
  );

  const handleDisabledChanged = useCallback(
    (newValue: boolean) => {
      setIsDisabled(newValue);
      if (newValue) {
        setClearDay(true);
        setClearYear(true);
      }
      onDisabledChange(newValue);
    },
    [onDisabledChange],
  );

  const handleMonthChange: SelectSelectionChangeEvent<Month> = useCallback(
    (selectedMonth: Option<Month>) => {
      if (selectedMonth.data && selectedMonth.data.dayCount !== daysInMonth) setDaysInMonth(selectedMonth.data.dayCount);
      setSelectedMonth(selectedMonth);
    },
    [daysInMonth],
  );

  const handleYearChange: TextInputChangeEvent = useCallback(
    (newValue) => {
      const year = +newValue;
      setYear(year);
    },
    [setYear],
  );

  return (
    <ComponentWrapper
      aria-label={ariaLabel}
      className={className}
      {...(errorId && { 'data-errorid': errorId })}
      formErrorMessages={formErrorMessages}
      formErrorSimpleMessage={formErrorSimpleMessage}
      id={`date-input-${id}`}
      inlineErrorMessages={internalErrorMessages}
      isLoading={isLoading}
      label={label}
      labelIsHeading={labelIsHeading}
      labelSpacing="--whitespace-04-to-08"
      loadingText={loadingText}
      htmlFor={`error-wrapper-date-input-${id}`}
      rowSpacing="--whitespace-08-to-16"
      scrollIntoView={scrollIntoView}
      resetScrollIntoView={resetScrollIntoView}
      {...props}
    >
      <Columns onBlur={handleBlur} ref={columnsRef}>
        <Global styles={dateInputStyles} />
        <TextInput
          aria-label={placeholderDay}
          autoFocus={autoFocus}
          className="day"
          clearText={clearDay}
          data-testid={`${testIdValue}-day-of-month`}
          debounceWait={5}
          defaultText={preSelectedDay.current && preSelectedDay.current > 0 ? preSelectedDay.current.toString() : ''}
          disabled={isDisabled}
          formPreventControlRegistration
          id={`${id}-day-of-month`}
          inputType="number"
          max={daysInMonth}
          min={1}
          onValueChange={handleDayChange}
          placeholder={placeholderDay}
          setText={throttleDay}
        />
        <Select<Month>
          aria-label={placeholderMonth}
          className="month"
          data-testid={`${testIdValue}-month`}
          defaultSelectedValue={preSelectedMonth.current?.value}
          disabled={isDisabled}
          droponly
          formPreventControlRegistration
          id={`${id}-month`}
          onSelectionChange={handleMonthChange}
          options={months}
          placeholder={placeholderMonth}
        />
        <TextInput
          aria-label={placeholderYear}
          className="year"
          clearText={clearYear}
          data-testid={`${testIdValue}-year`}
          debounceWait={5}
          defaultText={preSelectedYear.current && preSelectedYear.current > 0 ? preSelectedYear.current.toString() : ''}
          disabled={isDisabled}
          formPreventControlRegistration
          id={`${id}-year`}
          inputType="number"
          min={earliestYear}
          max={latestYear}
          onValueChange={handleYearChange}
          placeholder={placeholderYear}
          setText={throttleYear}
        />
        {showDisabledChoice && (
          <Choice
            className="disable-choice"
            data-testid={`${testIdValue}-disable`}
            defaultChecked={disabled}
            formPreventControlRegistration
            id={`${id}-disable`}
            label={disabledChoiceText}
            name={`${id}-disable`}
            onValueChange={handleDisabledChanged}
            type="checkbox"
          />
        )}
      </Columns>
    </ComponentWrapper>
  );
};

export type DateInputSelectionChange = (newValue: Date) => void;
export type DateInputValidated = (params: {
  day?: number;
  month?: string;
  newDate?: Date;
  tooEarly?: boolean;
  tooLate?: boolean;
  valid: boolean;
  year?: number;
}) => void;
