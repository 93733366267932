import styled from '@emotion/styled';
import { breakpoints } from '@/core';

type NoPadding = 'column' | 'none' | 'row';

export const ComponentLabel = styled.label<{ bolded?: boolean; fontColor?: string; fontSize?: string; labelIsHeading: boolean; labelLineHeight?: string }>`
  color: ${({ fontColor, labelIsHeading }) => (fontColor ? fontColor : labelIsHeading ? 'var(--heading-color);' : 'var(--component-wrapper-color);')};
  font-size: ${({ fontSize, labelIsHeading }) =>
    fontSize ? fontSize : labelIsHeading ? 'var(--component-wrapper-heading-font-size)' : 'var(--component-wrapper-label-font-size)'};
  font-weight: ${({ bolded, labelIsHeading }) => (bolded || labelIsHeading ? '700' : '400')};
  ${({ labelLineHeight }) => (labelLineHeight ? `line-height: ${labelLineHeight}` : '')};
  user-select: none;
`;

export const ComponentWrapper = styled.section<{
  addBackground?: boolean;
  backgroundOn?: 'mobile' | 'tablet';
  customPadding?: string;
  customBackground?: string;
  wrapperDirection?: string;
  hide?: boolean;
  hideOn?: 'mobile' | 'tablet';
  labelSpacing: string;
  noPadding?: NoPadding;
  noPaddingRow?: boolean;
  showValidationBorder?: boolean;
  whiteBackground?: boolean;
}>`
  background-color: ${({ addBackground, backgroundOn, whiteBackground }) =>
    !addBackground || backgroundOn === 'tablet' ? 'transparent' : whiteBackground ? 'white' : 'var(--component-wrapper-background)'};
  border: ${({ showValidationBorder }) => (showValidationBorder ? 'solid 2px var(--border-color-error)' : 'none')};
  border-radius: var(--wrapped-border-radius);
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  flex-direction: ${({ wrapperDirection }) => (wrapperDirection ? wrapperDirection : 'column')};
  gap: ${({ labelSpacing }) => `var(${labelSpacing})`};
  position: relative;
  padding: ${({ addBackground, noPadding, showValidationBorder }) =>
    !noPadding && (addBackground || showValidationBorder) ? 'var(--whitespace-16-to-24)' : '0'};
  ${({ noPadding }) => noPadding === 'column' && 'padding: var(--whitespace-16-to-24) var(--focus-spread)'};
  ${({ noPadding }) => noPadding === 'row' && 'padding: 0 var(--whitespace-16-to-24) var(--focus-spread)'};
  width: 100%;
  box-shadow: ${({ whiteBackground }) => whiteBackground && 'var(--box-shadow-responsive)'};
  ${({ customPadding }) => customPadding && `padding: ${customPadding}`};
  ${({ customBackground }) => customBackground && `background-color: ${customBackground}`};

  &:focus-visible {
    box-shadow: none;
  }

  ${() => breakpoints.isTablet.mediaMinWidth} {
    background-color: ${({ addBackground, backgroundOn, whiteBackground }) =>
      !addBackground || backgroundOn === 'mobile' ? 'transparent' : whiteBackground ? 'white' : 'var(--component-wrapper-background)'};
  }

  ${({ hideOn }) => (hideOn === 'mobile' ? 'display: none;' : '')};

  ${() => breakpoints.isTablet.mediaMinWidth} {
    ${({ hideOn }) => (hideOn === 'mobile' ? 'display: block;' : '')};
    ${({ hideOn }) => (hideOn === 'tablet' ? 'display: none;' : '')};
  }
`;

export const ErrorMessageLabel = styled.span`
  color: var(--error-message-color);
  display: inline-block;
  font-weight: bold;
  position: relative;
  user-select: none;

  &.clickable {
    cursor: pointer;
  }

  &.hide {
    display: none;
  }
`;

export const ErrorMessageLabelInline = styled(ErrorMessageLabel)`
  padding: var(--whitespace-04-to-08) var(--whitespace-08-to-16);

  &:before {
    background-color: var(--error-message-color);
    border-radius: var(--line-round-end-border-radius);
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    width: 2.67px;

    ${() => breakpoints.isTablet.mediaMinWidth} {
      width: 4px;
    }
  }
`;

export const ErrorWrapper = styled.div<{ expandBorder?: boolean; inputDirection?: string; internalBorder?: boolean; rowSpacing: string }>`
  display: flex;
  flex-direction: ${({ inputDirection }) => (inputDirection ? inputDirection : 'column')};
  gap: ${({ rowSpacing }) => `var(${rowSpacing})`};
  position: relative;

  ${() => breakpoints.isTablet.mediaMinWidth} {
    border: ${({ internalBorder }) => (internalBorder ? 'solid 1px var(--light-grey)' : '0')};
    border-radius: var(--wrapper-border-radius);
    margin: ${({ expandBorder, internalBorder }) => (internalBorder && expandBorder ? '0 calc(var(--whitespace-16-to-24)*-1) ' : '0')};
    padding: ${({ internalBorder }) => (internalBorder ? 'var(--whitespace-16-to-24)' : '0')};
  }
  &.isFormErrored {
    padding: var(--whitespace-04-to-08) var(--whitespace-08-to-16);

    &:before {
      background-color: var(--error-message-color);
      border-radius: var(--line-round-end-border-radius);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      top: 0;
      width: 2.67px;

      ${() => breakpoints.isTablet.mediaMinWidth} {
        width: 4px;
      }
    }
  }
`;

export const Sublabel = styled.div`
  font-weight: 400;
  margin-top: var(--whitespace-basic-4);
`;
