import { EnrolmentDetailsDto } from '@/core';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type JourneyStep = 'BookingComplete';

export interface JourneyState {
  minorId?: string;
  isChild: boolean;
  enrolment?: EnrolmentDetailsDto;
  examAndTermsAndConditionsConsent: boolean;
  returnToReviewFrom?: string;
  farthestJourneyStep?: JourneyStep;
}

const initialState: JourneyState = {
  examAndTermsAndConditionsConsent: false,
  returnToReviewFrom: '',
  isChild: false,
  farthestJourneyStep: undefined,
};

export const journeySlice = createSlice({
  name: 'journey',
  initialState,
  reducers: {
    setExamAndTermsAndConditionsConsent: (state, { payload }: PayloadAction<boolean>): void => {
      state.examAndTermsAndConditionsConsent = payload;
    },
    setReturnToReviewFrom: (state, { payload }: PayloadAction<string | undefined>): void => {
      state.returnToReviewFrom = payload;
    },
    setMinor: (state, { payload }: PayloadAction<string | undefined>): void => {
      state.minorId = payload;
    },
    setIsChild: (state, { payload }: PayloadAction<boolean>): void => {
      state.isChild = payload;
    },
    setFarthestJourneyStep: (state, { payload }: PayloadAction<JourneyStep>): void => {
      state.farthestJourneyStep = payload;
    },
    reset: (): JourneyState => initialState,
  },
});

const { setExamAndTermsAndConditionsConsent, setReturnToReviewFrom, setFarthestJourneyStep } = journeySlice.actions;

const { reducer: journeyReducer } = journeySlice;

export { journeyReducer, setExamAndTermsAndConditionsConsent, setReturnToReviewFrom, setFarthestJourneyStep };
