import { Logo } from './Logo';
import { User } from './User';

export const Menu = () => {
  return (
    <nav data-testid="menu-container" className="bg-indigo">
      <div className="flex justify-between items-center max-w-[1280px] mx-auto p-3 relative z-10">
        <Logo />
        <User />
      </div>
    </nav>
  );
};
