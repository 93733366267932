import { FC, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Alert, AlertPopup, Button, ButtonStyle, FlowIndicator, H5Wrapper, Loading, PageTitle } from '@/components';
import { FlowSteps, OrderStatus, useBasket } from '@/core';
import { PaymentStatusFormat, basketSlice } from '@/store';
import { useBasketGetQuery, useOrderGetOrderByIdQuery, usePaymentsCreateMutation } from '@/store/webApi';

import { useAnalytics } from '../useAnalytics';

const getPageData = (processing: boolean, success: boolean, isFetching: boolean): PaymentStatusFormat => {
  switch (true) {
    case isFetching:
      return {
        status: 'processing',
        alertType: 'payment' as const,
        title: 'Pending',
        basketStatus: 'Pending',
        text: 'booking-complete.awaiting-message',
      };

    case processing:
      return {
        status: 'processing',
        alertType: 'payment' as const,
        title: 'Payment in progress',
        basketStatus: 'Payment in progress',
        text: 'booking-complete.awaiting-message',
      };

    case success:
      return {
        status: 'success',
        alertType: 'success' as const,
        title: 'Success',
        basketStatus: 'Paid',
        text: 'booking-complete.congratulations-info',
      };

    case !success:
      return {
        status: 'failure',
        alertType: 'warning' as const,
        title: 'Payment failed',
        basketStatus: 'Payment failed',
        text: 'booking-complete.problem-message',
      };

    default:
      return {
        status: 'error',
        alertType: 'warning' as const,
        title: 'Something went wrong',
        basketStatus: 'Something went wrong',
        text: 'booking-complete.problem-message',
      };
  }
};

export const BookingCompleteLegacyPage: FC = (): JSX.Element => {
  const { t } = useTranslation('');
  const [searchParams] = useSearchParams();
  const { recoverBasketItemsLegacy, basket } = useBasket();

  const dispatch = useDispatch();

  const correlationId = searchParams.get('correlationId') ?? searchParams.get('correlationid') ?? '';
  const paymentId = searchParams.get('paymentId') ?? searchParams.get('paymentid') ?? '';

  const basketItemsCount = basket.items.length;

  const {
    data: orderData,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useOrderGetOrderByIdQuery(
    {
      orderId: correlationId ?? '',
      paymentExternalId: paymentId ?? '',
    },
    { skip: !correlationId || !paymentId },
  );

  const success = orderData?.status === OrderStatus.Completed || orderData?.status === OrderStatus.Created;
  const processing = orderData?.status === OrderStatus.AwaitingPayment;

  const { paymentAttemptFailedAction, paymentRepetitionAction } = useAnalytics({ orderData, basket });

  const [createPayment, { isLoading: isPaymentLoading, error: paymentError }] = usePaymentsCreateMutation();
  const {
    data: recoveredBasketItems = [],
    error: rocoverBasketItemsError,
    isLoading: isLoadingRecoverBasket,
  } = useBasketGetQuery({ basketId: orderData?.correlationId ?? '' }, { skip: !orderData?.correlationId || basket.items.length > 0 });

  const payAgain = async () => {
    if (!orderData?.correlationId || !orderData?.domainId) {
      return;
    }
    paymentRepetitionAction();

    createPayment({
      orderId: orderData?.domainId || '',
      correlationId: orderData?.correlationId,
    })
      .unwrap()
      .then((resp: TODO) => window.location.replace(resp.paymentRedirectUrl))
      .catch(() => paymentAttemptFailedAction());
  };

  useEffect(() => {
    /* Refetch every 10 seconds if still waiting for payment */
    if (processing) {
      const interval = setInterval(() => {
        refetch();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [processing, refetch]);

  useEffect(() => {
    if (recoveredBasketItems && basket.items.length === 0) {
      recoverBasketItemsLegacy(recoveredBasketItems);
    }
  }, [basket.items.length, recoverBasketItemsLegacy, recoveredBasketItems]);

  const pageData = getPageData(processing, success, isFetching);

  useEffect(() => {
    !processing && pageData && pageData.status !== basket.paymentStatus?.status && dispatch(basketSlice.actions.setPaymentStatus(pageData));
  }, [dispatch, pageData, basket.paymentStatus?.status, processing]);

  if (error) {
    return <Alert alertType="error" show text={t('booking-complete.error')} />;
  }

  if (isLoading || !orderData || isLoadingRecoverBasket) {
    return <Loading />;
  }

  return (
    <>
      <AlertPopup alertType="error" show={!!paymentError} text="" title="Online payment failed"></AlertPopup>
      <AlertPopup alertType="error" show={!!rocoverBasketItemsError} text="" title="It is not possible to recreate the basket"></AlertPopup>
      <header className="header">
        <FlowIndicator
          completed
          currentFormStep={6}
          currentStep={FlowSteps.sessionSelectionFinished}
          formSteps={6}
          steps={FlowSteps.totalStepsForSessionSelection}
        ></FlowIndicator>

        <PageTitle main text={t(`booking-complete.page-title.${pageData?.status}`)} />
      </header>
      <div className="flex flex-col gap-[72px] [&_h5]:pb-[24px]">
        <div>
          <H5Wrapper>
            <h5>
              {basketItemsCount > 1 ? t('booking-complete.congratulations-booked-exams-message') : t('booking-complete.congratulations-booked-exam-message')}
            </h5>
          </H5Wrapper>
          {pageData?.alertType && (
            <Alert alertType={pageData.alertType} show title={pageData.title}>
              <p>{t(pageData.text)}</p>
              {orderData?.status === OrderStatus.PaymentFailed && (
                <div className="text-right">
                  <Button
                    disabled={isPaymentLoading}
                    buttonStyle={ButtonStyle.Primary}
                    isLoading={isPaymentLoading}
                    className="cta"
                    text={t('booking-complete.problem-payment-repeat')}
                    onClick={payAgain}
                  />
                </div>
              )}
            </Alert>
          )}
        </div>
        <div>
          <H5Wrapper>
            <h5>{t('booking-complete.test-information')}</h5>
          </H5Wrapper>
          <Alert alertType="information" show text={t('identification.note')} title={t('common.please-note')} />
        </div>
      </div>
    </>
  );
};
