import { SelectHTMLAttributes } from 'react';

type Props = SelectHTMLAttributes<HTMLSelectElement> & {
  label?: string;
  options: { label: string | number; value: string | number }[];
};

export const Select = (props: Props) => {
  return (
    <div className="flex flex-col">
      <label className="text-base font-bold" htmlFor={props.name || props.id}>
        {props.label}
      </label>
      <select {...props} className="border border-grey-light h-11 px-4 rounded-md">
        {props.options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
