import { useEffect, useState } from 'react';

import { FiLoader } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from '@britishcouncil/react-registration-platform';

import { CreatePaymentResponse, useOrderCreateMutation, useOrderCreateOrderAcknowledgementMutation, usePaymentsCreateMutation } from '@/store/webApi';
import { basketSlice } from '@/store';
import { convertOrderAcknowledgeBody } from '@/core/helpers/convertOrderAcknowledgment';
import { useAppSelector, useBasket, useDocumentTitle, useSessionStorage } from '@/core';

import { webApi } from '@/store/webApi/enhancedWebApi';
import { useAnalytics } from './hooks/useAnalytics';
import { useEnrolMutation } from './hooks/useEnrolMutation';

export const PayBtn = () => {
  const { t } = useTranslation('');
  const { t: tRoutes } = useTranslation('routes');
  const dispatch = useDispatch();
  const { setValue } = useSessionStorage('dataLayer');
  const { startPayment: startPaymentEvent } = useAnalytics();
  const { data } = webApi.useCustomerGetCustomerDetailsQuery();

  useDocumentTitle(`${tRoutes('book-new-test.title')} - ${tRoutes('book-new-test.review')}`);

  const { orderAcknowledgementDetails } = useAppSelector((s) => s);

  const { basket, filterReservationsForActiveBasketSessions } = useBasket();
  const { examAndTermsAndConditionsConsent } = useAppSelector((s) => s.journey);

  const { enrol, error: isEnrollError, data: enrollData, isLoading: isProcessEnrol } = useEnrolMutation();
  const [createOrders, { error: isCreatingOrdersError, isLoading: isProcessCreateOrders }] = useOrderCreateMutation();
  const [createPayment, { error: isCreatingPaymentError, isLoading: isProcessCreatePayment }] = usePaymentsCreateMutation();
  const [createAcknowledgement, { error: isCreatingAcknowledgementError, isLoading: isProcessAcknowledgement }] = useOrderCreateOrderAcknowledgementMutation();
  const [savedCustomerId, setCustomerId] = useState('');
  const [savedBasketId, setBasketId] = useState('');
  const [savedDomainId, saveDomainId] = useState('');

  const isProcessing = isProcessEnrol || isProcessCreateOrders || isProcessCreatePayment || isProcessAcknowledgement;
  const errorAccured = isEnrollError || isCreatingOrdersError || isCreatingPaymentError || isCreatingAcknowledgementError;

  const reservationIds = filterReservationsForActiveBasketSessions();

  useEffect(() => {
    if (enrollData) {
      setCustomerId(enrollData.customerId ?? '');
      setBasketId(enrollData.basketId ?? '');
      dispatch(basketSlice.actions.setBasketId(enrollData.basketId ?? ''));
    }
  }, [dispatch, enrollData]);

  const createOrder = async (customerId: string, basketId: string) => {
    const resp = await createOrders({
      createOrderInSessionRequest: {
        customerId: customerId ?? '',
      },
      'Basket-Id': basketId ?? '',
    });
    const domainId = 'data' in resp ? resp.data?.domainId : '';
    saveDomainId(domainId ?? '');
    return domainId;
  };

  const createOrderAcknowledgement = async (domainId: string) => {
    const request = convertOrderAcknowledgeBody({ other: orderAcknowledgementDetails, personDetails: data ?? {} });
    return await createAcknowledgement({
      createOrderAcknowledgementRequest: request,
      orderDomainId: domainId ?? '',
    }).unwrap();
  };

  const startPayment = async (domainId: string, basketId: string) => {
    const { paymentRedirectUrl } = (await createPayment({
      orderId: domainId,
      correlationId: basketId,
    }).unwrap()) as CreatePaymentResponse;

    startPaymentEvent(domainId);

    return paymentRedirectUrl;
  };

  const initPayment = async () => {
    const session = basket.items ? basket.items[0] : undefined;

    if (!session || isProcessing || !reservationIds) {
      return;
    }

    const createdCustomer = savedCustomerId && savedBasketId ? { customerId: savedCustomerId, basketId: savedBasketId } : await enrol();

    if (createdCustomer?.customerId && createdCustomer?.basketId) {
      const domainId = savedDomainId || (await createOrder(createdCustomer.customerId ?? '', createdCustomer.basketId));
      await createOrderAcknowledgement(domainId ?? '');
      const paymentRedirectUrl = (await startPayment(domainId ?? '', createdCustomer.basketId ?? '')) ?? '';

      /** Save dataLayer to session storage to retrieve it on booking complete page */
      setValue(window.dataLayer);

      /** Navigate to Payment's provider paywall */
      paymentRedirectUrl && window.location.replace(paymentRedirectUrl);
    }
  };

  return (
    <div className="text-right w-full">
      <Button disabled={isProcessing || !examAndTermsAndConditionsConsent} onClick={initPayment}>
        {t('review.book-pay-now')}
        {isProcessing && <FiLoader className="text-[20px] relative top-[-2px] ml-[10px] animate-spin-slow inline-block"></FiLoader>}
      </Button>
      {errorAccured && <p className="p-[18px] bg-red-light text-left rounded-lg mt-[24px]">Error when creating payment</p>}
    </div>
  );
};
