import { NavLink } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { useTranslation } from 'react-i18next';

import { useFeatureFlags } from '@/core';

export const User = () => {
  const { t } = useTranslation();
  const { user, removeUser, signoutRedirect, isAuthenticated } = useAuth();
  const { data } = useFeatureFlags();

  const signOut = () => {
    const idToken: string | undefined = user?.id_token;
    removeUser();
    signoutRedirect({ id_token_hint: idToken, post_logout_redirect_uri: window.location.origin });
  };

  if (isAuthenticated) {
    return (
      <div className="flex gap-6">
        <NavLink className="text-white no-underline" to="dashboard">
          <p>Welcome, {user?.profile?.email}</p>
        </NavLink>
        <button onClick={signOut} className="text-white no-underline font-bold mx-auto">
          <p>{t('menu.logout')}</p>
        </button>
      </div>
    );
  } else if (data?.identityIntegrationFeature) {
    return (
      <div className="flex gap-6">
        <NavLink className="text-white no-underline mx-auto" to="account/create">
          <p>{t('menu.login')}</p>
        </NavLink>
      </div>
    );
  } else {
    return null;
  }
};
