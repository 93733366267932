import React, { KeyboardEvent, MouseEvent, SelectHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';

import { ChevronDown } from '@/icons/ChevronDown';
import { ChildComponentProps, ComponentWrapper, ComponentWrapperProps, ConditionalRequiredProps, FormElementProps, LoadingProps } from '@/components';
import { Option, Options, characterCharCodesAll, indexIsSet, indexNotSet, noAction, notFound, useCloseOnFocusChangeHandler, useDebounce } from '@/core';

import * as S from './Select.style';

interface SelectProps<T> extends ComponentWrapperProps {
  bolded?: boolean;
  'data-testid'?: string;
  defaultSelectedValue?: string;
  droponly?: boolean;
  fontColor?: string;
  fontSize?: string;
  onSelectionChange?: SelectSelectionChangeEvent<T>;
  options: Options<T>;
  placeholder?: string;
}

export function Select<T>({
  addBackground,
  autoFocus,
  'aria-label': ariaLabel = '',
  bolded,
  className,
  customBackground,
  customPadding,
  'data-testid': dataTestId,
  debounceWait = 500,
  defaultSelectedValue,
  disabled,
  droponly,
  fontColor,
  fontSize,
  formErrorMessages,
  formErrorSimpleMessage,
  id,
  inlineErrorMessages,
  label = '',
  labelIsHeading,
  onSelectionChange = noAction,
  options,
  placeholder,
  isLoading,
  loadingText = 'Loading, please wait...',
}: ChildComponentProps & ConditionalRequiredProps & FormElementProps & LoadingProps & SelectHTMLAttributes<HTMLSelectElement> & SelectProps<T>): JSX.Element {
  const [dropOnlySearchText, setDropOnlySearchText] = useState('');
  const dropOnlySearchTextTimer = useRef<ReturnType<typeof setTimeout>>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputText, setInputText] = useState('');
  const [inputTextForDebounce, setInputTextForDebounce] = useState('');
  const debounceInputText = useDebounce(inputTextForDebounce, debounceWait);
  const [isOpen, setIsOpen] = useState(false);
  const optionsLIRef = useRef<Array<HTMLLIElement>>([]);
  const optionsListRef = useRef<HTMLUListElement>(null);
  const [optionsState, setOptionsState] = useState<Options<T>>([]);
  const selectContainerRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(placeholder ? indexNotSet : 0);
  const [selectedOption, setSelectedOption] = useState<Option<T>>();
  const testIdValue = dataTestId ?? id;

  useCloseOnFocusChangeHandler(
    isOpen,
    optionsListRef,
    setIsOpen,
    () => {
      droponly && indexIsSet(selectedIndex) && optionsLIRef.current[selectedIndex].focus();
    },
    (e) =>
      e.target instanceof Node &&
      ((inputRef.current && inputRef.current.contains(e.target)) || (selectContainerRef.current && selectContainerRef.current.contains(e.target))),
  );

  useEffect(() => {
    const newOptions = options.filter((option) => !debounceInputText || option.text.toLowerCase().startsWith(debounceInputText.toLowerCase()));
    setOptionsState(newOptions);
  }, [debounceInputText, options]);

  useEffect(() => {
    if (indexIsSet(selectedIndex)) {
      if (optionsState && optionsState.length < selectedIndex) setSelectedIndex(0);
      const currentText = inputRef.current ? inputRef.current.value : '';
      if (
        optionsState?.length &&
        (droponly ||
          document.activeElement !== inputRef.current ||
          (optionsState && optionsState[selectedIndex] && currentText === optionsState[selectedIndex].text))
      )
        setInputText(optionsState[selectedIndex].text);
      if (optionsLIRef.current && optionsLIRef.current[selectedIndex]) {
        optionsLIRef.current[selectedIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
      if (optionsState[selectedIndex] && optionsState[selectedIndex]?.value !== selectedOption?.value) {
        setSelectedOption(optionsState[selectedIndex]);
        onSelectionChange(optionsState[selectedIndex]);
      }
    }
  }, [droponly, onSelectionChange, optionsState, selectedIndex, selectedOption?.value]);

  useEffect(() => {
    if (dropOnlySearchText) {
      clearTimeout(dropOnlySearchTextTimer.current);
      dropOnlySearchTextTimer.current = setTimeout(() => {
        setDropOnlySearchText('');
      }, 500);
      const index = optionsState.findIndex((option) => option.text.toLocaleLowerCase().startsWith(dropOnlySearchText));
      if (indexIsSet(index)) {
        setSelectedIndex(index);
      }
    }
    return () => clearTimeout(dropOnlySearchTextTimer.current);
  }, [dropOnlySearchText, optionsState]);

  useEffect(() => {
    setOptionsState(options.slice());
  }, [options]);

  useEffect(() => {
    if (defaultSelectedValue && optionsState.length) {
      const _selectedIndex = optionsState.findIndex((option) => option.value === defaultSelectedValue);
      if (_selectedIndex !== notFound) setSelectedIndex(_selectedIndex);
    }
  }, [optionsState, defaultSelectedValue]);

  useEffect(() => {
    if (!isOpen && indexIsSet(selectedIndex) && optionsState && optionsState[selectedIndex]) setInputText(optionsState[selectedIndex].text);
  }, [isOpen, optionsState, selectedIndex]);

  const selectAndClose = useCallback(
    (index: number) => (event: MouseEvent<HTMLLIElement> | KeyboardEvent<HTMLElement>) => {
      event.preventDefault();
      inputRef.current?.focus();
      setIsOpen(false);
      setSelectedIndex(index);
      setInputText(optionsState[index].text);
    },
    [optionsState],
  );

  const toggleIsOpen = useCallback(
    (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
      if (disabled) return;
      e.preventDefault();
      setIsOpen(!isOpen);
    },
    [disabled, isOpen],
  );

  const handleListKeyDown = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      const altKeyDown = () => {
        if (event.altKey) {
          if (isOpen) inputRef.current?.focus();
          toggleIsOpen(event);
          return true;
        }
        return false;
      };
      switch (event.key) {
        case ' ':
        case 'Enter':
        case 'SpaceBar':
          if ((indexIsSet(selectedIndex) || droponly) && (event.key === 'Enter' || droponly)) {
            event.preventDefault();
            isOpen ? (indexIsSet(selectedIndex) ? selectAndClose(selectedIndex)(event) : setIsOpen(false)) : setIsOpen(true);
          }
          break;
        case 'Escape':
          event.preventDefault();
          inputRef.current?.focus();
          setIsOpen(false);
          break;
        case 'ArrowUp':
        case 'ArrowDown':
          event.preventDefault();
          if (altKeyDown()) return;
          if (optionsState) {
            if (!isOpen) {
              setIsOpen(true);
              return;
            }
            const newIndex =
              event.key === 'ArrowUp'
                ? selectedIndex - 1 >= 0
                  ? selectedIndex - 1
                  : optionsState.length - 1
                : selectedIndex === optionsState.length - 1
                  ? 0
                  : selectedIndex + 1;
            setSelectedIndex(newIndex);
            setInputText(optionsState[newIndex].text);
          }
          break;
        default:
          if (droponly && event.key.length === 1 && characterCharCodesAll.includes(event.key.charCodeAt(0))) {
            event.preventDefault();
            if (!isOpen) setIsOpen(true);
            setDropOnlySearchText(dropOnlySearchText + event.key.toLocaleLowerCase());
          }
          break;
      }
    },
    [dropOnlySearchText, droponly, isOpen, optionsState, selectAndClose, selectedIndex, toggleIsOpen],
  );

  const getActiveDescendant = useCallback(() => {
    return indexIsSet(selectedIndex) && optionsLIRef.current && optionsLIRef.current.length > selectedIndex
      ? optionsLIRef.current[selectedIndex].id
      : undefined;
  }, [selectedIndex]);

  const handleInputChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      if (!isOpen) setIsOpen(true);
      setInputText(value);
      setInputTextForDebounce(value);
    },
    [isOpen],
  );

  const handleInputFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      !droponly && matchMedia('(pointer:fine)').matches && event.target.select();
    },
    [droponly],
  );

  return (
    <ComponentWrapper
      activeElementForScroll={inputRef}
      addBackground={addBackground}
      customBackground={customBackground}
      customPadding={customPadding}
      bolded={bolded}
      className={className}
      fontColor={fontColor}
      fontSize={fontSize}
      formErrorMessages={formErrorMessages}
      formErrorSimpleMessage={formErrorSimpleMessage}
      id={`wrapper-${id}`}
      inlineErrorMessages={inlineErrorMessages}
      label={label}
      labelIsHeading={labelIsHeading}
      labelSpacing="--whitespace-04-to-08"
      htmlFor={`combobox-${id}`}
    >
      <S.SelectContainer
        aria-haspopup="listbox"
        aria-owns={`list-${id}`}
        {...(isOpen && { className: 'open' })}
        id={`combobox-${id}`}
        onClick={toggleIsOpen}
        ref={selectContainerRef}
      >
        <S.SelectInput
          aria-autocomplete="none"
          aria-controls={`list-${id}`}
          aria-expanded={isOpen}
          {...(ariaLabel && !label && { 'aria-label': ariaLabel })}
          {...(!ariaLabel && label && { 'aria-labelledby': `wrapper-${id}` })}
          autoComplete="off"
          autoFocus={autoFocus}
          id={`select-input-${id}`}
          aria-activedescendant={getActiveDescendant()}
          data-testid={`select-input-${testIdValue}`}
          disabled={disabled}
          droponly={droponly ? true : false}
          {...(isOpen && { className: 'open' })}
          onChange={handleInputChange}
          onClick={toggleIsOpen}
          onFocus={handleInputFocus}
          onKeyDown={handleListKeyDown}
          placeholder={isLoading ? loadingText : placeholder}
          readOnly={droponly}
          ref={inputRef}
          role="combobox"
          type="text"
          value={inputText}
        />
        <ChevronDown index={`${id}-chevron-down`} />
        <S.OptionList
          {...(ariaLabel && !label && { 'aria-label': ariaLabel })}
          {...(!ariaLabel && label && { 'aria-labelledby': `wrapper-${id}` })}
          {...(isOpen && { className: 'animated verticalReveal' })}
          data-testid={`list-${testIdValue}`}
          id={`list-${id}`}
          onKeyDown={handleListKeyDown}
          ref={optionsListRef}
          role="listbox"
          tabIndex={-1}
        >
          {optionsState?.map((option, index) => (
            <S.OptionListItem
              aria-selected={selectedIndex === index}
              id={`option-${option.value}`}
              key={`option-${option.value}`}
              onClick={selectAndClose(index)}
              ref={(el) => {
                if (el) optionsLIRef.current[index] = el;
              }}
              role="option"
            >
              {option.text}
            </S.OptionListItem>
          ))}
        </S.OptionList>
      </S.SelectContainer>
    </ComponentWrapper>
  );
}

export type SelectSelectionChangeEvent<T> = (option: Option<T>) => void;
