import styled from '@emotion/styled';
import { breakpoints } from '@/core';

import { AlertPopupType, alertPopupTypeBackgroundColor } from './AlertPopup';

export const AlertPopup = styled.section<{ alertType: AlertPopupType }>`
  background-color: var(${({ alertType }) => alertPopupTypeBackgroundColor[alertType]});
  border-radius: var(--wrapper-border-radius);
  box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.34);
  display: none;
  padding: var(--whitespace-32-to-40);
  position: fixed;
  right: 10%;
  top: 10%;
  width: 70%;
  max-width: 700px;
  z-index: 1000;

  ul {
    list-style: disc;
    margin: 0 20px;
    li:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  svg {
    color: var(--alert-color);
    flex-shrink: 0;
    height: 30px;
    width: 30px;
  }
  b {
    font-weight: 700;
    margin-right: 10px;
  }
`;

export const AlertPopupDetail = styled.section`
  display: flex;
  flex-direction: column;
  gap: var(--whitespace-16-to-24);

  ${() => breakpoints.isTablet.mediaMinWidth} {
    align-items: flex-start;
    flex-direction: row;
  }
`;

export const AlertPopupHeading = styled.span`
  font-size: var(--h2-font-size);
  font-weight: 700;
  color: var(--heading-color);
`;

export const AlertPopupNarrative = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: var(--whitespace-12-to-20);
`;
