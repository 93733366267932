import { FC } from 'react';

import { ComponentWrapper, TextInput, TextInputChangeEvent } from '@/components';

interface CompanyVatNipProps {
  addBackground?: boolean;
  defaultCompanyName?: string;
  defaultVatNipNumber?: string;
  companyNameLabel: string;
  companyNameRequiredText: string;
  vatNipNumberLabel: string;
  vatNipNumberRequiredText: string;
  onCompanyNameChange: TextInputChangeEvent;
  onVatNipNumberChange: TextInputChangeEvent;
}

export const CompanyVatNip: FC<CompanyVatNipProps> = ({
  addBackground = true,
  defaultCompanyName = '',
  defaultVatNipNumber = '',
  companyNameLabel,
  companyNameRequiredText,
  vatNipNumberLabel,
  vatNipNumberRequiredText,
  onCompanyNameChange,
  onVatNipNumberChange,
}): JSX.Element => {
  return (
    <ComponentWrapper addBackground={addBackground} id="company-vat-nip">
      <TextInput
        defaultText={defaultCompanyName}
        id="company-name"
        inputType="text"
        label={companyNameLabel}
        onValueChange={onCompanyNameChange}
        required
        requiredText={companyNameRequiredText}
      ></TextInput>
      <TextInput
        defaultText={defaultVatNipNumber}
        id="vat-nip-number"
        inputType="text"
        label={vatNipNumberLabel}
        onValueChange={onVatNipNumberChange}
        required
        requiredText={vatNipNumberRequiredText}
      ></TextInput>
    </ComponentWrapper>
  );
};
