import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ErrorMessages, createErrorMessage, requiredId } from '.';

type UseErrorMessages = {
  valuePassesRequiredCheck: (newValue: boolean | string, ignoreRequiredSet?: boolean) => boolean;
  internalErrorMessageCount: number;
  internalErrorMessages: ErrorMessages;
  setInternalErrorMessages: React.Dispatch<React.SetStateAction<ErrorMessages>>;
};

export const useErrorMessages = (required: boolean, requiredText: string, inlineErrorMessages?: ErrorMessages): UseErrorMessages => {
  const internalErrorMessagesRef = useRef<ErrorMessages>([]);
  const [internalErrorMessageCount, setInternalErrorMessageCount] = useState(0);
  const [internalErrorMessages, setInternalErrorMessages] = useState<ErrorMessages>([]);
  const requiredErrorMessage = useMemo(() => createErrorMessage({ id: requiredId, text: requiredText, type: 'required' }), [requiredText]);

  useEffect(() => {
    internalErrorMessagesRef.current = [...internalErrorMessages];
    setInternalErrorMessageCount(internalErrorMessages.length);
  }, [internalErrorMessages]);

  useEffect(() => {
    setInternalErrorMessages((errorMessages) => errorMessages.filter((errorMessage) => errorMessage.type !== 'inline'));
    if (inlineErrorMessages?.length) setInternalErrorMessages((errorMessages) => [...errorMessages, ...inlineErrorMessages]);
  }, [inlineErrorMessages]);

  const valuePassesRequiredCheck = useCallback(
    (newValue: boolean | string, ignoreRequiredSet = true): boolean => {
      const requiredSet = internalErrorMessagesRef.current.find((errorMessage) => errorMessage.type === 'required');
      if (required && !newValue && (!requiredSet || ignoreRequiredSet)) {
        if (!requiredSet) setInternalErrorMessages((errorMessages) => [...errorMessages, requiredErrorMessage]);
        return false;
      }
      if ((required && newValue && requiredSet) || (!required && requiredSet)) {
        setInternalErrorMessages((errorMessages) => errorMessages.filter((errorMessage) => errorMessage.type !== 'required'));
        return true;
      }
      return true;
    },
    [required, requiredErrorMessage],
  );

  return {
    valuePassesRequiredCheck,
    internalErrorMessageCount,
    internalErrorMessages,
    setInternalErrorMessages,
  };
};
