import { useCallback, useEffect, useState } from 'react';

import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AlertReg } from '@/components/presentation/alerts/AlertReg';
import { FlowIndicator, Form, FormSection, FormSectionTitle, Loading, PageFormWrapper, PageTitle, Step } from '@/components';
import { FlowSteps, getTotalSteps, isMobileTrack, useAppParams, useAppSelector } from '@/core';
import { ServerValidationError } from '@/core/errors/FormError';
import { StepButtonsForm } from '@/pages/book-test/StepButtonsForm';
import { getContactDetails, getPersonalDetails } from '@/store';
import { useFormError } from '@/core/errors/useFormError';

import { BookingForDetails } from './components/BookingForDetails';
import { useAnalytics } from './legacy/useAnalytics';
import { usePageNavigation } from './legacy/usePageNavigation';

import { AddressDetails } from './components/AddressDetails';
import { CandidatePersonalDetails } from './components/CandidatePersonalDetails';
import { ContactDetails } from './components/ContactDetails';
import { MarketingPreferencesDetails } from './components/MarketingPreferencesDetails';
import { PersonalDetailsFormType, usePersonalDetailsForm } from './hooks/usePersonalDetailsForm';
import { useCustomerDatailsMutation } from './hooks/useCustomerDatailsMutation';

export const PersonalDetailsPage = () => {
  const { t } = useTranslation('');
  const { t: tRoutes } = useTranslation('routes');

  const { step } = useAppParams();
  const [currentStep, setCurrentStep] = useState(step);
  const [displayCurrentStep, setDisplayCurrentStep] = useState(0);
  const navigate = useNavigate();
  const { submitEvents } = useAnalytics();
  const contactDetails = useSelector(getContactDetails);
  const personalDetails = useSelector(getPersonalDetails);
  const { isChild } = useAppSelector((s) => s.journey);

  const { methods, isLoading: isLoadingForm } = usePersonalDetailsForm();
  const { createOrUpdate, isLoading: isProcessData, error } = useCustomerDatailsMutation(isMobileTrack(window.innerWidth));
  const goToNextStep = usePageNavigation({ currentStep, isChild: isChild });

  useFormError({ error: error as ServerValidationError, setError: methods.setError });

  useEffect(() => {
    setCurrentStep(step);
    setDisplayCurrentStep(!isMobileTrack(window.innerWidth) || currentStep === FlowSteps.flowStepForDesktopPath ? 1 : parseInt(currentStep ?? '0') - 1);
  }, [currentStep, step]);

  const onSubmit: SubmitHandler<PersonalDetailsFormType> = async (data) => {
    if (currentStep == FlowSteps.flowForPersonalDetailsFinished || currentStep === FlowSteps.flowStepForDesktopPath) {
      /* Manually validate if phone input is present */
      if (!methods.getValues('mobileNumber')) {
        methods.setError('mobileNumber', { message: t('common.required') });
        const element = document.getElementById('mobileNumber');
        if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        return;
      }

      submitEvents(personalDetails, contactDetails);
      await createOrUpdate({ data });
    } else {
      goToNextStep();
    }
  };

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const totalSteps = getTotalSteps(window.innerWidth, isChild);

  if (isLoadingForm) return <Loading />;

  return (
    <div className="personal-details">
      <header className="header">
        <FlowIndicator
          currentFormStep={displayCurrentStep}
          currentStep={FlowSteps.sessionSelectionFinished}
          formSteps={totalSteps}
          steps={FlowSteps.totalStepsForSessionSelection}
        ></FlowIndicator>

        {isMobileTrack(window.innerWidth) && currentStep !== '1' && (
          <Step bottomMargin={'var(--whitespace-basic-08)'}>
            {t('general-step', {
              stepNumber: displayCurrentStep.toString(),
              stepsTotal: totalSteps,
            })}
          </Step>
        )}
        {(currentStep === FlowSteps.flowStepForDesktopPath || currentStep === '1') && (
          <>
            <PageTitle marginBottom={'var(--whitespace-basic-32)'} hideOn="mobile" text={tRoutes('book-new-test.personal-details')} />
            <PageTitle marginBottom={'var(--whitespace-basic-32)'} hideOn="tablet" text={tRoutes('book-new-test.personal-details-mobile')} />
          </>
        )}
        {!isMobileTrack(window.innerWidth) && currentStep === '0' && (
          <Step bottomMargin={'var(--whitespace-32-to-72)'}>
            {t('general-step', {
              stepNumber: displayCurrentStep.toString(),
              stepsTotal: totalSteps,
            })}
          </Step>
        )}
      </header>
      <FormProvider {...methods}>
        <PageFormWrapper>
          <Form noValidate id="personal-details" onSubmit={methods.handleSubmit(onSubmit)}>
            {(currentStep === FlowSteps.flowStepForDesktopPath || currentStep === FlowSteps.flowStepForMobilePath) && (
              <FormSection gap="var(--whitespace-basic-32)">
                <AlertReg
                  variant="information"
                  title={t('common.please-note')}
                  content={
                    <div className="flex flex-col gap-y-3">
                      <div>{t('personal-details.country-requirements')}</div>
                      <div>
                        <ul className="list-disc list-inside">
                          <li>{t('personal-details.country-requirements-one')}</li>
                        </ul>
                      </div>
                    </div>
                  }
                />
                <BookingForDetails />
              </FormSection>
            )}
            {(currentStep === FlowSteps.flowStepForDesktopPath || currentStep === '2') && (
              <FormSection>
                <FormSectionTitle>{isChild ? t('personal-details.about-you.title-child') : t('personal-details.about-you.title')}</FormSectionTitle>
                <AlertReg variant="information" title={t('common.please-note')} content={t('personal-details.about-you.note-self')} visible={!isChild} />
                <CandidatePersonalDetails />
              </FormSection>
            )}
            {(currentStep === FlowSteps.flowStepForDesktopPath || currentStep === '3') && (
              <FormSection>
                <FormSectionTitle>{t('personal-details.contact-details.title')}</FormSectionTitle>
                <ContactDetails />
              </FormSection>
            )}
            {(currentStep === FlowSteps.flowStepForDesktopPath || currentStep === '4') && (
              <FormSection>
                <FormSectionTitle>{t('personal-details.address-details.title')}</FormSectionTitle>
                <AddressDetails />
              </FormSection>
            )}

            {(currentStep === FlowSteps.flowStepForDesktopPath || currentStep === '3') && (
              <FormSection>
                <FormSectionTitle>{t('personal-details.marketing-preferences.title')}</FormSectionTitle>
                <MarketingPreferencesDetails />
              </FormSection>
            )}
            <div className="flex flex-col gap-4">
              <StepButtonsForm
                submitButton={{
                  isLoading: isProcessData,
                }}
                backButton={{ onClick: goBack }}
              />
              <AlertReg
                variant="error"
                title={t('personal-details.save-error-title')}
                content={t('personal-details.save-error-message')}
                visible={error !== undefined}
              />
            </div>
          </Form>
        </PageFormWrapper>
      </FormProvider>
    </div>
  );
};
