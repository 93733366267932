import { useCallback, useMemo } from 'react';

import { webApi } from '@/store/webApi/enhancedWebApi';

export const useDownloadCertificate = (enrolmentId: string, minorId?: string) => {
  const [getAdultCertificate, { isFetching: isAdultCertificateFetching }] = webApi.useLazyCertificateGetCertificateQuery();
  const { isSuccess: isAdultCertificateAvailable, isFetching: isAdultCertificateAvailabilityFetching } = webApi.useCertificateIsCertificateAvailableQuery(
    {
      enrolmentId: enrolmentId,
    },
    { skip: !!minorId },
  );

  const [getMinorCertificate, { isFetching: isMinorCertificateFetching }] = webApi.useLazyCertificateGetCertificateForMinorQuery();
  const { isSuccess: isMinorCertificateAvailable, isFetching: isMinorCertificateAvailabilityFetching } =
    webApi.useCertificateIsCertificateAvailableForMinorQuery(
      {
        enrolmentId: enrolmentId,
        minorId: minorId!,
      },
      { skip: !minorId },
    );

  const getCertificate = useCallback(() => {
    if (minorId) {
      getMinorCertificate({ minorId: minorId, enrolmentId: enrolmentId });
    } else {
      getAdultCertificate({ enrolmentId: enrolmentId });
    }
  }, [enrolmentId, minorId, getAdultCertificate, getMinorCertificate]);

  const isCertificateLoading = useMemo(
    () => (minorId ? isMinorCertificateFetching : isAdultCertificateFetching),
    [minorId, isMinorCertificateFetching, isAdultCertificateFetching],
  );

  const isCertificateAvailable = useMemo(
    () => (minorId ? isMinorCertificateAvailable : isAdultCertificateAvailable),
    [minorId, isMinorCertificateAvailable, isAdultCertificateAvailable],
  );

  const isCertificateAvailabilityLoading = useMemo(
    () => (minorId ? isMinorCertificateAvailabilityFetching : isAdultCertificateAvailabilityFetching),
    [minorId, isMinorCertificateAvailabilityFetching, isAdultCertificateAvailabilityFetching],
  );

  return { getCertificate, isCertificateAvailable, isCertificateLoading, isCertificateAvailabilityLoading };
};
