import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storageSession from 'redux-persist/lib/storage/session';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';

import * as Sentry from '@sentry/react';
import { GoogleTagManager } from '@/core'; // defaults to localStorage for web
import { Middleware, PreloadedState, combineReducers, configureStore, isRejectedWithValue } from '@reduxjs/toolkit';

import {
  basketSlice,
  contactDetailsSlice,
  identificationDetailsReducer,
  journeySlice,
  minorDetailsReducer,
  minorIdentificationDetailsReducer,
  minorPickupReducer,
  orderAcknowledgementDetailsReducer,
  personalDetailsSlice,
  searchDetailsSlice,
  sessionTimeReducer,
  setSessionTime,
} from './slices';
import { emptyWebApi } from './webApi/_emptyWebApi';

const rootReducer = combineReducers({
  basket: basketSlice.reducer,
  contactDetails: contactDetailsSlice.reducer,
  identificationDetails: identificationDetailsReducer,
  journey: journeySlice.reducer,
  minorDetails: minorDetailsReducer,
  minorIdentificationDetails: minorIdentificationDetailsReducer,
  minorPickup: minorPickupReducer,
  personalDetails: personalDetailsSlice.reducer,
  orderAcknowledgementDetails: orderAcknowledgementDetailsReducer,
  searchDetails: searchDetailsSlice.reducer,
  sessionTime: sessionTimeReducer,
  [emptyWebApi.reducerPath]: emptyWebApi.reducer,
});

export type AppStore = ReturnType<typeof setupStore>;
export type RootState = ReturnType<typeof rootReducer>;
export { rootReducer };

const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  storage: storageSession,
  whitelist: [
    'basket',
    'contactDetails',
    'identificationDetails',
    'journey',
    'minorDetails',
    'minorIdentificationDetails',
    'minorPickup',
    'personalDetails',
    'orderAcknowledgementDetails',
    'searchDetails',
    'sessionTime',
  ],
  stateReconciler: autoMergeLevel2,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const queryInterceptorErrorHandler: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    GoogleTagManager.addEvent({
      event: 'API',
      action: 'APIfailure',
      endpoint: action?.meta?.arg?.endpointName,
      responseValue: action?.payload,
    });
  }
  return next(action);
};
const anyActionMiddleware: Middleware = (state) => (next) => (action) => {
  const stateTime = state.getState().sessionTime.lastUpdated;
  const actualTime = new Date().getTime();
  if (action.type !== 'sessionTime/setSessionTime' && actualTime - stateTime > 10000) {
    state.dispatch(setSessionTime(new Date().getTime()));
  }
  return next(action);
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // https://docs.sentry.io/platforms/javascript/guides/react/features/redux/#redux-enhancer-options
});

export const setupStore = (preloadedState?: PreloadedState<RootState>, immutableCheck = process.env.NODE_ENV === 'development', serializableCheck = false) => {
  return configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ immutableCheck, serializableCheck }).concat(anyActionMiddleware, queryInterceptorErrorHandler, emptyWebApi.middleware),
    reducer: persistedReducer,
    preloadedState,
    enhancers: (defaultEnhancers) => {
      return [...defaultEnhancers, sentryReduxEnhancer];
    },
  });
};

export const store = setupStore();
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
