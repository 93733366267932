import { FC } from 'react';

import * as S from './PageTitle.style';

interface PageTitleProps {
  hideOn?: 'tablet' | 'mobile';
  main?: boolean;
  marginBottom?: string;
  text?: string;
}

export const PageTitle: FC<PageTitleProps> = ({ hideOn, main, marginBottom, text }): JSX.Element => {
  return (
    <S.PageTitle hideOn={hideOn} main={main} marginBottom={marginBottom}>
      <h1>{text ?? ''}</h1>
    </S.PageTitle>
  );
};
