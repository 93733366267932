import styled from '@emotion/styled';
import { breakpoints } from '@/core';

import { ColumnsMixin } from '../columns';

export const ButtonsWrapper = styled.section<{ hideOn?: 'mobile' | 'tablet'; topBorder?: boolean; wrap?: boolean }>`
  ${ColumnsMixin}
  justify-content: left;
  column-gap: var(--whitespace-basic-24);
  button {
    margin-right: 0;
  }
  ${({ topBorder }) =>
    topBorder &&
    `
    border-top: 1px solid #C4C4C4;
  margin-top: var(--whitespace-basic-16);
  padding-top: var(--whitespace-basic-32);

  `};

  ${() => breakpoints.isTablet.mediaMinWidth} {
    width: 100%;
    ${({ hideOn }) => (hideOn === 'mobile' ? 'display: block;' : '')};
    ${({ hideOn }) => (hideOn === 'tablet' ? 'display: none;' : '')};
  }
  ${() => breakpoints.isTablet.mediaMaxWidth} {
    ${({ wrap }) =>
      wrap &&
      `
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
  `};
  }
  ${({ hideOn }) => (hideOn === 'mobile' ? 'display: none;' : '')};
`;
