import { Link } from 'react-router-dom';

import { searchDetailsSlice } from '@/store';
import { useAppDispatch, useAppParams, useAppSelector } from '@/core';

export type AddItemBtnProps = {
  hideBasket: () => void;
};

export const AddItemBtn = ({ hideBasket }: AddItemBtnProps) => {
  const dispatch = useAppDispatch();
  const { productCode } = useAppParams();
  const basket = useAppSelector((s) => s.basket);
  const handleAddAnotherItem = () => {
    dispatch(searchDetailsSlice.actions.partialReset(['country']));
    hideBasket();
  };

  return (
    <div className="flex justify-center items-center gap-[12px] text-purple-dark">
      <span className="text-[32px]">+</span>
      <Link onClick={handleAddAnotherItem} to={`/${productCode}/book-test`} className="font-bold text-center my-[24px] text-purple-dark no-underline">
        {`Add ${basket.items.length ? 'another' : 'first'} item`}
      </Link>
    </div>
  );
};
