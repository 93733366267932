import { MinorPickUp } from '@/store/webApi';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface MinorPickupState {
  confirmed: boolean;
  pickupType: MinorPickUp; //  { 'no-pickup': 1, parent: 2, other: 3 }
  guardian?: Guardian;
}

export interface Guardian {
  firstName?: string;
  lastName?: string;
  fullName?: string | null;
  telephone?: string | null;
  relationshipId?: number;
  ageDeclaration?: boolean | null;
}

const initialState: MinorPickupState = {
  confirmed: false,
  pickupType: 1,
};

const minorPickupSlice = createSlice({
  name: 'minorPickup',
  initialState,
  reducers: {
    setConfirmed: (state, { payload }: PayloadAction<boolean>): void => {
      state.confirmed = payload;
    },
    setMinorPickup: (state, { payload }: PayloadAction<MinorPickupState>): void => {
      state.confirmed = payload.confirmed;
      state.pickupType = payload.pickupType;
      state.guardian = payload.guardian;
    },
    setMinorPickupType: (state, { payload }: PayloadAction<MinorPickUp>): void => {
      state.pickupType = payload;
    },
    setGuardian: (state, { payload }: PayloadAction<Guardian>): void => {
      state.guardian = { ...state.guardian, ...payload };
    },
    clearGuardian: (state): void => {
      state.guardian = undefined;
    },
  },
});

const { clearGuardian, setConfirmed, setMinorPickup, setMinorPickupType, setGuardian } = minorPickupSlice.actions;

const { reducer: minorPickupReducer } = minorPickupSlice;

export { clearGuardian, minorPickupReducer, setConfirmed, setMinorPickup, setMinorPickupType, setGuardian };
