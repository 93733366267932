import { Outlet, matchPath, useLocation } from 'react-router-dom';

import { Basket } from '@/components/basket';
import { cn } from '@/core/helpers/cn';

export const BasketLayout = () => {
  const location = useLocation();
  const isAtBookingCompleteRoute = !!matchPath('/:productCode/book-test/booking-complete', location.pathname);

  return (
    <div className="flex w-full justify-center h-full">
      <div className=" w-full lg:w-[calc(50%+121px)] flex justify-end py-[50px]">
        <div className="lg:max-w-[565px] xl:max-w-[627px] px-[24px] lg:px-[0px] lg:mx-[24px] lg:mr-[55px] xl:mr-[134px] w-screen">
          <Outlet />
        </div>
      </div>
      <div
        className={cn('bg-[#fafafa] lg:w-[calc(50%-121px)]', {
          'bg-white border-l border-grey-light': isAtBookingCompleteRoute,
        })}
      >
        <div className="lg:sticky lg:top-4">
          <div className="lg:mt-[129px] lg:ml-[108px] lg:mr-3 xl:mr-6">
            <Basket />
          </div>
        </div>
      </div>
    </div>
  );
};
