import { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Alert, AlertDescription } from '@britishcouncil/react-registration-platform';

import { AlertReg } from '@/components/presentation/alerts/AlertReg';
import { Choice, ComponentWrapper, ExternalLink, FormSection, MinorPickup, OuterWrapper, RadioGroupSelectionChange } from '@/components';
import { MinorPickUp } from '@/store/webApi';
import { clearGuardian, setConfirmed, setMinorPickupType } from '@/store';
import { useAppSelector } from '@/core';

import { GuardianForm } from './GuardianForm';

export const MinorPickupForm = () => {
  const minorPickup = useAppSelector((s) => s.minorPickup);

  const dispatch = useDispatch();
  const { t } = useTranslation('');

  const onChangePickupType: RadioGroupSelectionChange = useCallback(
    (newValue: string) => {
      if (minorPickup.pickupType === 1 && Number(newValue) !== 1) dispatch(clearGuardian());
      dispatch(setMinorPickupType(Number(newValue) as MinorPickUp));
    },
    [dispatch, minorPickup.pickupType],
  );

  const onConfirm = useCallback(
    (newValue: boolean) => {
      dispatch(setConfirmed(newValue));
    },
    [dispatch],
  );

  return (
    <FormSection>
      <MinorPickup
        minorPickupLabel={t('minor-details.pickup.question')}
        minorPickupType={minorPickup.pickupType}
        noPickupLabel={t('minor-details.pickup.choices.unaccompanied')}
        parentPickupLabel={t('minor-details.pickup.choices.parent-pickup')}
        otherPickupLabel={t('minor-details.pickup.choices.other-pickup')}
        onChange={onChangePickupType}
      />

      {minorPickup.pickupType === 3 && (
        <>
          <AlertReg
            variant="information"
            title={t('minor-details.pickup.other-pickup.message.line-01')}
            content={
              <>
                <p className="pb-3">{t('minor-details.pickup.other-pickup.message.line-02')}</p>
                <p>{t('minor-details.pickup.other-pickup.message.line-03')}</p>
              </>
            }
          />
          <OuterWrapper>
            <GuardianForm />
          </OuterWrapper>
        </>
      )}

      <AlertReg
        visible={minorPickup.pickupType === 2}
        variant="warning"
        title={t('common.please-note')}
        content={t('minor-details.pickup.other-pickup.collection-alert.line-01')}
      />
      <AlertReg
        visible={minorPickup.pickupType === 3}
        variant="warning"
        title={t('common.please-note')}
        content={t('minor-details.pickup.other-pickup.collection-alert.line-02')}
      />

      {minorPickup.pickupType === 3 && (
        <>
          <ComponentWrapper addBackground whiteBackground id="minor-pickup-confirm">
            <Alert variant="information">
              <AlertDescription>
                <p>{t('minor-details.pickup.other-pickup.confirm.line-01')}</p>
              </AlertDescription>
            </Alert>
            <Choice
              defaultChecked={minorPickup.confirmed}
              id="confirm-third-party-pickup"
              label={t('minor-details.pickup.other-pickup.confirm.yes-confirm')}
              required
              requiredText={t('common.required')}
              type="checkbox"
              onValueChange={onConfirm}
            />
            <ExternalLink
              href="https:www.britishcouncil.org"
              target="_blank"
              text={t('minor-details.pickup.other-pickup.confirm.child-safe-collection-policy')}
              id="child-safe-collection"
            />
          </ComponentWrapper>
        </>
      )}
    </FormSection>
  );
};
