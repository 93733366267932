import { useState } from 'react';

/**
 * Sync state to session storage so that it persists through
 * a page refresh or when navigated to different apps within the same domain.
 * @param key unique identifier
 * @param initialValue initial value for specified key
 * @returns
 */
export const useSessionStorage = <T>(key: string, initialValue?: T) => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });
  const setValue = (valueToStore: T) => {
    try {
      setStoredValue(valueToStore);
      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const removeValue = () => {
    try {
      if (typeof window !== 'undefined') {
        window.sessionStorage.removeItem(key);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const clearSessionStorage = () => {
    try {
      if (typeof window !== 'undefined') {
        sessionStorage.clear();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return { clearSessionStorage, removeValue, storedValue, setValue };
};
