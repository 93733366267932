import { css } from '@emotion/react';

export const animations = css`
  .themed {
    --animation-long-duration: 500s;
    --animation-medium-duration: 250ms;
    --animation-short-duration: 125ms;

    --right-reveal-display: block;

    --slidedown-percentage: -25%;
    --slide-down-display: block;

    --vertical-reveal-display: block;

    .animated {
      animation-duration: var(--animation-medium-duration);
      /* animation-fill-mode: both; */
    }

    @keyframes rightReveal {
      from {
        transform: scaleX(0);
      }

      to {
        transform: scaleX(1);
      }
    }

    .rightReveal {
      animation-name: rightReveal;
      display: var(--vertical-reveal-display);
      transform-origin: right;
    }

    @keyframes slideInDown {
      from {
        opacity: 0;
        transform: translate3d(0, var(--slidedown-percentage), 0);
      }

      to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }

    .slideInDown {
      animation-name: slideInDown;
      display: var(--slide-down-display);
    }

    @keyframes verticalReveal {
      from {
        transform: scaleY(0);
      }

      to {
        transform: scaleY(1);
      }
    }

    .verticalReveal {
      animation-name: verticalReveal;
      display: var(--vertical-reveal-display);
      transform-origin: top;
    }

    @media print, (prefers-reduced-motion: reduce) {
      --animation-long-duration: 1ms;
      --animation-medium-duration: 1ms;
      --animation-short-duration: 1ms;
    }
  }
`;
