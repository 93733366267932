import { css } from '@emotion/react';

import { breakpoints } from '../utils';

export const attributeColors = css`
  .themed {
    --background-color: var(--white);
    --background-color-inverted: var(--indigo);
    --background-color-disabled: linear-gradient(0deg, rgba(200, 200, 200, 0.2), rgba(200, 200, 200, 0.2)), #ffffff;

    --border-color: var(--dark-grey);

    --border-color-error: var(--dark-red);

    --color: var(--dark-grey);
    --color-disabled: var(--core-grey);
    --color-inverted: var(--white);
    --color-not-error: var(--color);
    --color-success: var(--dark-green);

    --component-icon: var(--dark-grey);

    --focus-color: var(--core-orange);
    --focus-input-color: var(--dark-blue);

    --placeholder-color: var(--core-grey);
    ${breakpoints.isTablet.mediaMinWidth} {
      --border-color: var(--light-grey);
    }
  }
`;
