import { FC } from 'react';

import { IconProps } from '@/icons/IconProps';

export const Clock: FC<IconProps> = ({ index = 0 }) => {
  return (
    <svg
      aria-hidden="true"
      aria-labelledby={`clock-icon-title-${index}`}
      className="clock-icon"
      focusable="false"
      role="img"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`clock-icon-title-${index}`}></title>
      <g>
        <path
          d="M11,21c5.52,0,10-4.48,10-10S16.52,1,11,1,1,5.48,1,11s4.48,10,10,10Z"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M11,5v6l4,2" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};
