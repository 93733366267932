import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  FlowSteps,
  bookTestIdentificationRoute,
  bookTestMinorDetailsRoute,
  bookTestPersonalDetailsRoute,
  bookTestReviewRoute,
  params,
  useAppParams,
} from '@/core';
import { getReturnToReviewFrom } from '@/store';

export const usePageNavigation = ({ currentStep, isChild }: { currentStep?: string; isChild: boolean }) => {
  const { productCode } = useAppParams();
  const navigate = useNavigate();
  const returnToReviewFrom = useSelector(getReturnToReviewFrom);

  return () => {
    if (returnToReviewFrom === bookTestPersonalDetailsRoute.absolutePath)
      navigate(
        bookTestReviewRoute.absolutePath
          .replace(params.productCode, productCode || '')
          .replace(params.step, currentStep !== FlowSteps.flowStepForDesktopPath ? FlowSteps.flowStepForMobilePath : FlowSteps.flowStepForDesktopPath),
      );
    else if (currentStep !== FlowSteps.flowStepForDesktopPath && currentStep !== FlowSteps.flowForPersonalDetailsFinished) {
      navigate(
        bookTestPersonalDetailsRoute.absolutePath
          .replace(params.productCode, productCode || '')
          .replace(params.step, (parseInt(currentStep ?? '0') + 1).toString()),
      );
    } else if (isChild)
      navigate(
        bookTestMinorDetailsRoute.absolutePath
          .replace(params.productCode, productCode || '')
          .replace(params.step, currentStep !== FlowSteps.flowStepForDesktopPath ? FlowSteps.flowStepForMobilePath : FlowSteps.flowStepForDesktopPath),
      );
    else
      navigate(
        bookTestIdentificationRoute.absolutePath
          .replace(params.productCode, productCode || '')
          .replace(params.step, currentStep !== FlowSteps.flowStepForDesktopPath ? FlowSteps.flowStepForMobilePath : FlowSteps.flowStepForDesktopPath),
      );
  };
};
