import { GoogleTagManagerUtils } from './GtmManagerUtil';

const init = () => {
  const gtmCode = 'GTM-PHKDBFQ';
  GoogleTagManagerUtils.initialize(gtmCode);
};

const addEvent = (dataLayer: object) => {
  GoogleTagManagerUtils.updateDataLayer(dataLayer);
};

export const GoogleTagManager = { addEvent, init };
