import styled from '@emotion/styled';
import { PlaceholderMixin } from '@/components';
import { breakpoints } from '@/core';

export const OptionList = styled.ul`
  background-color: var(--select-option-list-background);
  border-top: none;
  border-radius: var(--component-border-radius);
  box-shadow: 0px 0px 40px rgba(112, 144, 176, 0.3);
  display: none;
  left: 0;
  max-height: 30px;
  overflow-y: scroll;
  position: absolute;
  top: calc(100% + 8px);
  width: 100%;
  z-index: 1000;
  max-height: 320px;
  &:focus {
    box-shadow: 0px 0px 40px rgba(112, 144, 176, 0.3);
  }
`;

export const OptionListItem = styled.li`
  font-size: var(--h5-base-font-size);
  line-height: 22px;
  padding: var(--whitespace-16-to-24) var(--whitespace-12-to-20);
  position: relative;

  &:not(:last-child) {
    :after {
      background-color: var(--border-color);
      bottom: 0px;
      content: '';
      height: 1px;
      left: var(--whitespace-12-to-20);
      padding: 0 var(--whitespace-12-to-20);
      position: absolute;
      right: var(--whitespace-12-to-20);
    }
  }

  &:active,
  &:focus,
  &:hover,
  &[aria-selected='true'] {
    cursor: pointer;

    :focus-visible {
      :after {
        background-color: transparent;
      }
    }
  }
  &:hover {
    background: linear-gradient(0deg, rgba(151, 218, 255, 0.15), rgba(151, 218, 255, 0.15)), #ffffff;
  }
  &:active,
  &[aria-selected='true'] {
    color: var(--white);
    background: var(--select-option-list-item-active-background);
  }

  &:focus {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), #005cb9;
  }
`;

export const SelectContainer = styled.div`
  position: relative;

  &:hover {
    cursor: pointer;
  }

  svg {
    &.chevron-down-icon {
      color: var(--component-icon);
      height: 100%;
      position: absolute;
      right: 10px;
      top: 0;
      width: 24px;
    }
  }

  &.open {
    svg {
      &.chevron-down-icon {
        transform: rotate(180deg);
      }
    }
  }

  ${() => breakpoints.isTablet.mediaMinWidth} {
    &.open {
      svg {
        &.times-icon {
          width: 48px;
        }
      }
    }
  }
`;

export const SelectInput = styled.input<{ droponly: boolean }>`
  align-items: center;
  background-color: var(--component-background);
  border: solid 2px var(--border-color);
  border-radius: var(--component-border-radius);
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 0 var(--whitespace-basic-16);
  ${PlaceholderMixin}
  position: relative;
  text-align: left;
  user-select: ${({ droponly }) => (droponly ? 'none' : 'auto')};
  width: 100%;

  width: 100%;
  &:focus {
    box-shadow: none;
    border: solid 2px var(--dark-blue);
  }

  &:disabled {
    background-color: var(--background-color-disabled);
    color: var(--color-disabled);
    cursor: not-allowed;
  }

  &.open {
    border-radius: var(--component-border-radius);
    border: 2px solid var(--dark-blue);

    &.focus {
      border: 2px solid var(--dark-blue);
      box-shadow: none;
    }
  }

  @media (pointer: coarse), (pointer: none) {
    user-select: 'none';
  }
`;
