import { FC } from 'react';

import { CheckPlain } from '@/icons/CheckPlain';

import * as S from './FlowIndicator.style';

interface FlowIndicatorProps {
  completed?: boolean;
  currentFormStep: number;
  currentStep: number;
  formSteps: number;
  steps: number;
}

export const FlowIndicator: FC<FlowIndicatorProps> = ({ completed, formSteps, currentFormStep, steps, currentStep }) => {
  const stars = [];
  for (let i = 1; i <= steps; i++) {
    if (i < currentStep) {
      stars.push(
        <S.FlowIndicatorStar key={`star-${i.toString()}`}>
          <CheckPlain index={i.toString()} />
        </S.FlowIndicatorStar>,
      );
    } else {
      stars.push(<S.FlowIndicatorCircle className={i === currentStep ? 'active' : ''} key={`circle-${i.toString()}`}></S.FlowIndicatorCircle>);
    }
  }
  const formStepsLines = [];
  for (let i = 1; i <= formSteps; i++) {
    formStepsLines.push(
      <S.FlowIndicatorLine
        className={i <= currentFormStep ? (i === currentFormStep ? 'active last' : 'active') : ''}
        key={`step-${i.toString()}`}
      ></S.FlowIndicatorLine>,
    );
  }
  return (
    <S.FlowIndicatorAllign>
      <S.FlowIndicatorWrapper>
        <S.FlowIndicatorSection>{stars}</S.FlowIndicatorSection>
        <S.FlowIndicatorLineSection>{formStepsLines}</S.FlowIndicatorLineSection>
        <S.FlowIndicatorSection>
          <S.FlowIndicatorCircle className={completed ? 'active' : ''}></S.FlowIndicatorCircle>
        </S.FlowIndicatorSection>
      </S.FlowIndicatorWrapper>
    </S.FlowIndicatorAllign>
  );
};
