import { AnchorHTMLAttributes, FC } from 'react';

import { ComponentWrapperProps } from '@/components/component-wrapper';

import * as S from './ExternalMenuLink.style';

interface ExternalLinkProps extends ComponentWrapperProps {
  text: string;
}

export const ExternalMenuLink: FC<AnchorHTMLAttributes<HTMLAnchorElement> & ExternalLinkProps> = ({
  className,
  href,
  id,
  target,
  text,
  ...props
}): JSX.Element => {
  return (
    <S.ExternalMenuLink className={className} href={href} target={target} id={id} {...props}>
      {text}
    </S.ExternalMenuLink>
  );
};
