import { FiChevronLeft } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from '@britishcouncil/react-registration-platform';

import { Alert, AppExamAndCancellationPolicy, AppOrderAcknowledgement, ButtonsWrapper, FlowIndicator, PageFormWrapper, PageTitle, Step } from '@/components';
import { FlowSteps, isMobileOrTabletTrack, isMobileTrack, useAppSelector } from '@/core';
import { PayBtn } from '@/components/basket/Actions/PayBtn';
import { useProductConfig } from '@/core/hooks/useProductConfig';

import { ReviewCard } from './components/ReviewCard';
import { useReviewData } from './useReviewData';
import { useUtils } from './useUtils';

export const ReviewPage = () => {
  const { t } = useTranslation('');
  const { t: tRoutes } = useTranslation('routes');
  const navigate = useNavigate();
  const { step } = useParams();

  const { orderAcknowledgementDetails, journey } = useAppSelector((s) => s);

  const { productConfig } = useProductConfig();

  const { isChild } = useAppSelector((s) => s.journey);

  const { activeFlowStep, totalSteps } = useUtils();
  const { rows } = useReviewData();

  return (
    <>
      <header className="header">
        <FlowIndicator
          currentFormStep={activeFlowStep}
          currentStep={FlowSteps.sessionSelectionFinished}
          formSteps={totalSteps}
          steps={FlowSteps.totalStepsForSessionSelection}
        ></FlowIndicator>
        {isMobileTrack(window.innerWidth) && (
          <Step>
            {t('general-step', {
              stepNumber: `${activeFlowStep}`,
              stepsTotal: `${totalSteps}`,
            })}
          </Step>
        )}
        <PageTitle marginBottom={'var(--whitespace-32-to-08)'} text={tRoutes('book-new-test.review')} />
        {!isMobileTrack(window.innerWidth) && (
          <Step bottomMargin={'var(--whitespace-32-to-72)'}>
            {t('general-step', {
              stepNumber: `${activeFlowStep}`,
              stepsTotal: `${totalSteps}`,
            })}
          </Step>
        )}
      </header>
      <PageFormWrapper>
        <div className="flex flex-col gap-[24px]">
          <Alert alertType="information" show text={t('review.alert.not-booked-yet')} title={t('common.please-note')}>
            <p>{t('review.alert.please-check')}</p>
          </Alert>
        </div>
      </PageFormWrapper>
      <div>
        <ReviewCard title={t('review.your-details')} editUrl={`../personal-details/${step}`} rows={rows.personal.details} />
        {journey.isChild && <ReviewCard title={tRoutes('book-new-test.minor-details')} editUrl={`../minor-details/${step}`} rows={rows.minor.details} />}
        <ReviewCard
          title={isChild ? t('review.identification.title-minor') : t('review.identification.title')}
          editUrl={`../identification/${step}`}
          rows={isChild ? rows.minor.identification : rows.personal.identification}
        />
        <ReviewCard title={t('review.payment.title')} rows={rows.price} />
        {productConfig?.supportedFeatures?.includes('OrderAcknowledgement') && (
          <div className="mt-6">
            <AppOrderAcknowledgement orderAcknowledgement={orderAcknowledgementDetails.orderAcknowledgement} />
          </div>
        )}
        <div className="mt-6">
          <AppExamAndCancellationPolicy />
        </div>
        <div className="mt-6">
          <ButtonsWrapper>
            <Button type="button" variant="secondary" onClick={() => navigate(-1)}>
              <FiChevronLeft className="mr-2" />
              {t('components.back-button')}
            </Button>
            {isMobileOrTabletTrack(window.innerWidth) && <PayBtn />}
          </ButtonsWrapper>
        </div>
      </div>
    </>
  );
};
