import { matchPath, useLocation } from 'react-router-dom';

import { formatPrice, useBasket, useFeatureFlags } from '@/core';

import { AddItemBtn, AddItemBtnProps } from './AddItemBtn';
import { BookBtn } from './BookBtn';
import { PayBtn } from './PayBtn';
import { PayBtnLegacy } from './legacy/PayBtnLegacy';
import { PaymentStatus } from './PaymentStatus';

/* Extend props when needed */
type Props = AddItemBtnProps;

/** Visible on desktop screens only  */
export const Actions = ({ hideBasket }: Props) => {
  const { data } = useFeatureFlags();
  const { basketLimitReached, basket } = useBasket();
  const location = useLocation();

  // TODO: do it properly (by not hardcoding path) when routing and route's declarations will be refactored and cleaned up.
  const isAtBookTestRoute = !!matchPath('/:productCode/book-test', location.pathname);
  const isAtBookingCompleteRoute = !!matchPath('/:productCode/book-test/booking-complete', location.pathname);
  const isAtReviewRoute = !!matchPath('/:productCode/book-test/review/0', location.pathname);

  const totalPrice = basket.items.reduce((acc, item) => acc + (item?.price ?? 0), 0);

  const showBookButton = isAtBookTestRoute && basket.items.length !== 0;
  const showAddItemButton = !(basketLimitReached || isAtBookingCompleteRoute);
  const showPayBtn = isAtReviewRoute;
  const showPaymentStatus = basket.paymentStatus && isAtBookingCompleteRoute;

  return (
    <>
      {showAddItemButton && <AddItemBtn hideBasket={hideBasket} />}
      {basket.items.length > 1 && (
        <div className="flex items-center gap-[36px] justify-end mb-12">
          <p className="text-[24px]">Total:</p>
          <p className="text-[32px] font-bold text-black">{formatPrice(totalPrice, basket.items[0]?.currency)}</p>
        </div>
      )}
      {showBookButton && <BookBtn />}
      {showPayBtn && data?.identityIntegrationFeature && <PayBtn />}
      {showPayBtn && !data?.identityIntegrationFeature && <PayBtnLegacy />}
      {showPaymentStatus && <PaymentStatus />}
    </>
  );
};
