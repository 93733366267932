import { Location } from 'react-router';
import { useLocation } from 'react-router';

export type RouterState = {
  productCode?: string;
  correlationId?: string;
  paymentId?: string;
};

interface LocationWithState extends Location {
  state: RouterState;
}

export const useAppLocation = (): LocationWithState => useLocation() as LocationWithState;
