import styled from '@emotion/styled';
import { breakpoints } from '@/core';

type ChoiceType = 'checkbox' | 'radio';

export const Choice = styled.input<{ type: ChoiceType; simplified?: boolean }>`
  --border-radius: ${({ type }) => (type === 'radio' ? 'var(--round-border-radius)' : '3px')};
  --square: 16px;

  appearance: none;
  border-radius: var(--border-radius);
  color: var(--choice-normal-border);
  display: grid;
  flex: 0 0 var(--square);
  height: var(--square);
  margin: 0;
  place-content: center;
  position: relative;
  width: var(--square);
  ${({ simplified }) => (simplified ? 'width: 0; flex:0;' : '')};

  &:before {
    border: 1px solid var(--choice-normal-border);
    border-radius: var(--border-radius);
    box-shadow: inset 1em 1em var(--choice-center-background);
    content: '';
    height: 16px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    width: 16px;
  }
  ${({ simplified }) => (simplified ? '&:before {display: none}' : '')};

  &:after {
    border-radius: var(--border-radius);
    box-shadow: inset 1em 1em var(--choice-checked-color);
    content: '';
    height: 12px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: scale(0) translate(-50%, -50%);
    transform-origin: top left;
    transition: var(--animation-short-duration) transform ease-in-out;
    width: 12px;
  }

  ${({ simplified }) => (simplified ? '&:after {display: none}' : '')};

  &:checked {
    border-color: var(--dark-blue);
    &:after {
      transform: scale(1) translate(-50%, -50%);
      transform-origin: top left;
    }
  }

  ${({ simplified }) =>
    simplified
      ? `&:checked + span span {
    color: var(--choice-checked-color);
    text-decoration: underline;
    font-weight: 600;
  }`
      : ''};

  &.error {
    background-color: var(--choice-error-background);
    border-color: var(--choice-error-border);

    &:before {
      border-color: var(--choice-error-border);
    }
    &:after {
      box-shadow: inset 1em 1em var(--choice-center-error-background);
    }
  }
`;

export const ChoiceLabel = styled.label`
  align-items: center;
  background-color: var(--component-background);
  border: solid 1px var(--border-color);
  border-radius: 12px;
  box-shadow: 0px 1px 4px rgba(84, 86, 90, 0.24);
  cursor: pointer;
  column-gap: var(--whitespace-basic-16);
  display: flex;
  font-size: var(--h5-h6-base-font-size);
  min-height: 54px;
  padding: 8px 16px;
  position: relative;

  ${() => breakpoints.isXMobile.mediaMinWidth} {
    background-color: inherit;
    border: inherit;
    border-radius: inherit;
    box-shadow: inherit;
    min-height: inherit;
    padding: 0;
  }
`;
