import styled from '@emotion/styled';
import { breakpoints } from '@/core';

export const PageFormOuterWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const PageFormWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  row-gap: var(--whitespace-40-to-72);
  ${() => breakpoints.isTablet.mediaMinWidth} {
    width: 100%;
  }
`;
