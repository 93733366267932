import { useEffect, useState } from 'react';

import { FiLoader } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from '@britishcouncil/react-registration-platform';

import {
  CreatePaymentResponse,
  useCustomerCreateAndEnrolMutation,
  useOrderCreateMutation,
  useOrderCreateOrderAcknowledgementMutation,
  usePaymentsCreateMutation,
} from '@/store/webApi';
import { GoogleTagManager, convertOrderAcknowledgeBodyLegacy, useAppSelector, useBasket, useDocumentTitle, useSessionStorage } from '@/core';
import { basketSlice } from '@/store';

export const PayBtnLegacy = () => {
  const { t } = useTranslation('');
  const { t: tRoutes } = useTranslation('routes');
  const dispatch = useDispatch();
  const { setValue } = useSessionStorage('dataLayer');

  useDocumentTitle(`${tRoutes('book-new-test.title')} - ${tRoutes('book-new-test.review')}`);

  const {
    minorDetails,
    identificationDetails,
    minorIdentificationDetails,
    minorPickup,
    contactDetails,
    personalDetails,
    searchDetails,
    orderAcknowledgementDetails,
    journey,
  } = useAppSelector((s) => s);

  const [errorMsg, setErrorMsg] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const { basket, filterReservationsForActiveBasketSessions } = useBasket();
  const { examAndTermsAndConditionsConsent } = useAppSelector((s) => s.journey);

  const [createCustomerCall, { error: isCreatingCustomerError }] = useCustomerCreateAndEnrolMutation();
  const [createOrders, { error: isCreatingOrdersError }] = useOrderCreateMutation();
  const [createPayment, { error: isCreatingPaymentError }] = usePaymentsCreateMutation();
  const [createAcknowledgement, { error: isCreatingAcknowledgementError }] = useOrderCreateOrderAcknowledgementMutation();
  const [savedCustomerId, saveCustomerId] = useState('');
  const [savedBasketId, saveBasketId] = useState('');
  const [savedDomainId, saveDomainId] = useState('');

  const reservationIds = filterReservationsForActiveBasketSessions();

  const showError = () => {
    setIsProcessing(false);
    setErrorMsg(`Error when creating payment`);
  };

  useEffect(() => {
    if (isCreatingCustomerError || isCreatingOrdersError || isCreatingPaymentError || isCreatingAcknowledgementError) showError();
  }, [isCreatingAcknowledgementError, isCreatingCustomerError, isCreatingOrdersError, isCreatingPaymentError]);

  const createCustomer = async () => {
    const createdCustomer = await createCustomerCall({
      createAndEnrolRequest: {
        reservationIds: reservationIds || [],
        customer: {
          origin: 'B2C',
          firstName: personalDetails.firstName,
          lastName: personalDetails.lastName,
          dateOfBirth: personalDetails.dateOfBirth,
          gender: personalDetails.gender,
          otherGenders: personalDetails.genderOther,
          email: personalDetails.email,
          mobileNumber: contactDetails.mobileNumber.replace('+', ''),
          address: {
            residenceCountryId: contactDetails?.countryId || 0,
            addressLine1: contactDetails.postalLine01,
            addressLine2: contactDetails.postalLine02,
            city: contactDetails.postalTownCity,
            postCode: contactDetails.postalZipCode,
          },
          isGDPRConsentProvided: true,

          identificationDocument: journey?.isChild
            ? undefined
            : {
                // typeId: Number(identificationDetails.identificationType), // kill me but have no idea why API works when it is not provided and fails when it is provided
                documentNumber: identificationDetails.identificationNumber,
                expiryDate: identificationDetails.expiryDate,
                issuingAuthority: identificationDetails.issuingAuthority,
                issuingCountryId: identificationDetails.nationalityCountryId,
              },
        },
        minor: journey?.isChild
          ? {
              firstName: minorDetails.firstName,
              lastName: minorDetails.lastName,
              dateOfBirth: minorDetails.dateOfBirth,
              gender: minorDetails.gender,
              otherGenders: minorDetails.genderOther,

              identificationDocument: {
                // typeId: Number(minorIdentificationDetails.identificationType), // kill me but have no idea why API works when it is not provided and fails when it is provided
                documentNumber: minorIdentificationDetails.identificationNumber,
                expiryDate: minorIdentificationDetails.expiryDate,
                issuingAuthority: minorIdentificationDetails.issuingAuthority,
                issuingCountryId: minorIdentificationDetails.nationalityCountryId,
              },

              minorPickUpOption: minorPickup.pickupType,

              guardianInfo: {
                fullName: `${minorPickup.guardian?.firstName} ${minorPickup.guardian?.lastName}`,
                relationshipId: minorPickup.guardian?.relationshipId,
                telephone: minorPickup.guardian?.telephone,
              },
            }
          : undefined,
      },
    }).unwrap();

    if (createdCustomer.customerId && createdCustomer.basketId) {
      saveCustomerId(createdCustomer.customerId);
      saveBasketId(createdCustomer.basketId);
      dispatch(basketSlice.actions.setBasketId(createdCustomer.basketId));
      return createdCustomer;
    }
  };

  const createOrder = async (customerId: string, basketId: string) => {
    const resp = await createOrders({
      createOrderInSessionRequest: {
        customerId: customerId ?? '',
      },
      'Basket-Id': basketId ?? '',
    });
    const domainId = 'data' in resp ? resp.data?.domainId : '';
    saveDomainId(domainId ?? '');
    return domainId;
  };

  const createOrderAcknowledgement = async (domainId: string) => {
    const request = convertOrderAcknowledgeBodyLegacy({ other: orderAcknowledgementDetails, person: personalDetails, personContact: contactDetails });
    return await createAcknowledgement({
      createOrderAcknowledgementRequest: request,
      orderDomainId: domainId ?? '',
    }).unwrap();
  };

  const startPayment = async (domainId: string, basketId: string) => {
    const { paymentRedirectUrl } = (await createPayment({
      orderId: domainId,
      correlationId: basketId,
    }).unwrap()) as CreatePaymentResponse;

    GoogleTagManager.addEvent({
      action: 'ReviewStepCompletion',
      Country: searchDetails.country?.id,
      event: 'VirtualPageview',
      virtualPageTitle: 'British Council - Book a new test - Review',
      virtualPageTitleLocal: document.title,
      virtualPageURL: '/book-test/review/',
    });

    GoogleTagManager.addEvent({
      action: 'Payment Submit',
      category: 'Payment',
      Country: searchDetails.country?.id,
      countryCode: searchDetails.country?.id,
      correlationId: domainId,
      currency: searchDetails?.testPackage?.currency,
      amount: searchDetails?.testPackage?.totalFee || '',
      event: 'VirtualPageview',
      virtualPageTitle: 'British Council - Book a new test - Review',
      virtualPageTitleLocal: document.title,
      virtualPageURL: '/book-test/review/',
    });

    return paymentRedirectUrl;
  };

  const initPayment = async () => {
    setIsProcessing(true);

    const session = basket.items ? basket.items[0] : undefined;

    if (!session || isProcessing || !reservationIds) {
      return;
    }

    /** Do not create customer if already created */
    const createdCustomer = savedCustomerId && savedBasketId ? { customerId: savedCustomerId, basketId: savedBasketId } : await createCustomer();

    if (createdCustomer?.customerId && createdCustomer?.basketId) {
      const domainId = savedDomainId || (await createOrder(createdCustomer.customerId ?? '', createdCustomer.basketId));
      await createOrderAcknowledgement(domainId ?? '');
      const paymentRedirectUrl = (await startPayment(domainId ?? '', createdCustomer.basketId ?? '')) ?? '';

      /** Save dataLayer to session storage to retrieve it on booking complete page */
      setValue(window.dataLayer);

      /** Navigate to Payment's provider paywall */
      paymentRedirectUrl && window.location.replace(paymentRedirectUrl);
    } else {
      showError();
    }
  };

  return (
    <div className="text-right w-full">
      <Button disabled={isProcessing || !examAndTermsAndConditionsConsent} onClick={initPayment}>
        {t('review.book-pay-now')}
        {isProcessing && <FiLoader className="text-[20px] relative top-[-2px] ml-[10px] animate-spin-slow inline-block"></FiLoader>}
      </Button>
      {errorMsg ? (
        <p className="p-[18px] bg-red-light text-left rounded-lg mt-[24px]">
          {(isCreatingCustomerError as any)?.data?.error[0].code === 'Customer.DuplicateEmailFound'
            ? 'This email is already registered. Please use a different email address to complete this booking.'
            : errorMsg}
        </p>
      ) : null}
    </div>
  );
};
