import { ChangeEvent, FC, HTMLAttributes, useCallback, useEffect, useState } from 'react';

import styled from '@emotion/styled';
import {
  ChildComponentProps,
  Choice,
  Columns,
  ComponentWrapper,
  ComponentWrapperProps,
  ConditionalRequiredProps,
  FormElementProps,
  FormValidateControl,
  LoadingProps,
  TextInput,
  TextInputChangeEvent,
  useErrorMessages,
  useRegisterFormControl,
} from '@/components';
import { breakpoints, noAction, useDebounce } from '@/core';

interface RadioGroupProps extends ComponentWrapperProps {
  addBorder?: boolean;
  autoFocus?: boolean;
  expandBorder?: boolean;
  name: string;
  inlineInput?: boolean;
  inlineLabel?: boolean;
  labelSpacing?: string;
  onChoiceChange: RadioGroupSelectionChange;
  radios: RadioGroupRadios;
  rowSpacing?: string;
  simplified?: boolean;
  value: string;
}

export const RadioGroup: FC<
  ChildComponentProps & ConditionalRequiredProps & FormElementProps & HTMLAttributes<HTMLElement> & LoadingProps & RadioGroupProps
> = ({
  'aria-label': ariaLabel = '',
  addBorder,
  autoFocus,
  className,
  debounceWait = 150,
  expandBorder,
  formErrorMessages,
  formErrorSimpleMessage,
  formPreventControlRegistration,
  id,
  inlineErrorMessages,
  inlineInput = false,
  inlineLabel = false,
  isLoading,
  label,
  labelIsHeading = false,
  labelSpacing,
  loadingText,
  onChange = noAction,
  onChoiceChange,
  name,
  radios,
  required = false,
  requiredText = '',
  rowSpacing,
  showValidationBorder = true,
  simplified = false,
  tabIndex = 0,
  value,
  ...props
}): JSX.Element => {
  const [valueForChange, setValueForChange] = useState(value);
  const debounceValue = useDebounce(valueForChange, debounceWait);
  const parentId = id;
  const { valuePassesRequiredCheck, internalErrorMessages } = useErrorMessages(!!required, requiredText, inlineErrorMessages);
  const validateByForm: FormValidateControl = useCallback(() => {
    return valuePassesRequiredCheck(valueForChange, true);
  }, [valueForChange, valuePassesRequiredCheck]);

  const { errorId, scrollIntoView, resetScrollIntoView } = useRegisterFormControl(id, !!formPreventControlRegistration, validateByForm);

  useEffect(() => {
    onChoiceChange(debounceValue);
  }, [debounceValue, onChoiceChange]);

  const handleBlur = useCallback(() => {
    valuePassesRequiredCheck(valueForChange);
  }, [valuePassesRequiredCheck, valueForChange]);

  const handleChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.type !== 'radio') {
        return;
      }
      const newValue = event.target.value;
      valuePassesRequiredCheck(newValue);
      setValueForChange(newValue);
      onChange(event);
    },
    [onChange, valuePassesRequiredCheck],
  );

  return (
    <ComponentWrapper
      aria-label={ariaLabel}
      className={className}
      {...(errorId && { 'data-errorid': errorId })}
      expandBorder={expandBorder}
      formErrorMessages={formErrorMessages}
      formErrorSimpleMessage={formErrorSimpleMessage}
      id={`radio-group-${id}`}
      inlineErrorMessages={internalErrorMessages}
      inputDirection={inlineInput ? 'row' : 'column'}
      internalBorder={addBorder}
      isLoading={isLoading}
      label={label}
      labelIsHeading={labelIsHeading}
      loadingText={loadingText}
      htmlFor={`error-wrapper-radio-group-${id}`}
      rowSpacing={rowSpacing ?? '--whitespace-basic-16'}
      onBlur={handleBlur}
      onChildChange={handleChanged}
      labelSpacing={labelSpacing ?? '--whitespace-basic-24'}
      labelLineHeight={inlineLabel ? '44px' : undefined}
      scrollIntoView={scrollIntoView}
      showValidationBorder={showValidationBorder}
      resetScrollIntoView={resetScrollIntoView}
      wrapperDirection={inlineLabel ? 'row' : 'column'}
      {...props}
    >
      {radios.map(({ id, label, onRadioTextChange, radioTextDefaultValue = '' }, index) => (
        <RadioColumns key={id}>
          <>
            <Choice
              autoFocus={index === 0 ? autoFocus : false}
              className={onRadioTextChange ? 'column-narrow' : ''}
              data-testid={id}
              {...(id === value && { defaultChecked: true })}
              formPreventControlRegistration
              id={`${parentId}-${id}`}
              inlineErrorMessages={inlineErrorMessages}
              key={id}
              label={label}
              name={name}
              simplified={simplified}
              {...(id === value && { tabIndex: tabIndex })}
              type="radio"
              value={id}
            />
            {onRadioTextChange && valueForChange === id && (
              <TextInput
                debounceWait={5}
                defaultText={radioTextDefaultValue}
                id={`${parentId}-${id}-text`}
                inputType="text"
                onValueChange={onRadioTextChange}
              />
            )}
          </>
        </RadioColumns>
      ))}
    </ComponentWrapper>
  );
};

export type RadioGroupRadio = {
  id: string;
  label: string;
  onRadioTextChange?: void;
  radioTextDefaultValue?: void;
  required: boolean;
};
export type RadioGroupRadioExtended = {
  id: string;
  label: string;
  onRadioTextChange: TextInputChangeEvent;
  radioTextDefaultValue: string;
  required: boolean;
};
export type RadioGroupRadios = Array<RadioGroupRadio | RadioGroupRadioExtended>;
export type RadioGroupSelectionChange = (newValue: string) => void;

const RadioColumns = styled(Columns)`
  ${() => breakpoints.isLaptop.mediaMinWidth} {
    .column-narrow {
      width: 50%;
    }
    margin-top: inherit;
  }
`;
