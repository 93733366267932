import { Well } from '@britishcouncil/react-registration-platform';

import { Calendar, MonthsScroll } from '@/components';

export const CalendarPlaceholder = () => {
  return (
    <div className="relative">
      <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-[24px] z-10 rounded-sm shadow-md w-4/5 md:w-2/3">
        <p>Please select the city, package and venue to see the calendar</p>
      </div>
      <div className="opacity-20 pointer-events-none cursor-not-allowed">
        <MonthsScroll availableDates={[]} setDatesRange={() => null} setCurrentMonth={() => null} />
        <Well className="my-[32px]">
          <Calendar id="calendar-placeholder" enabledDays={[]} onDateSelect={() => null} />
        </Well>
      </div>
    </div>
  );
};
