import styled from '@emotion/styled';
import { breakpoints } from '@/core';

export const FlowIndicatorAllign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-bottom: var(--whitespace-24-to-40);
`;

export const FlowIndicatorCircle = styled.div`
  border-radius: 16px;
  background: rgba(var(--rgb-light-grey-half));
  height: 16px;
  margin: 0 3px;
  width: 16px;

  &.active {
    background: var(--purple);
  }

  &:last-of-type {
    margin-right: 0;
  }

  ${() => breakpoints.isTablet.mediaMinWidth} {
    border-radius: 24px;
    height: 24px;
    margin: 0px 12px;
    width: 24px;
  }
`;

export const FlowIndicatorLine = styled.div`
  background: rgba(var(--rgb-light-grey-half));
  flex-grow: 1;
  height: 3px;
  position: relative;

  &:first-of-type {
    border-radius: 5px 0 0 5px;
    &.active {
      &:before {
        border-radius: 5px 0 0 5px;
      }
    }
    &.last {
      border-radius: 5px 5px 5px 5px;
    }
  }

  &:last-of-type,
  &.last {
    border-radius: 0 5px 5px 0;
  }

  &:last-of-type.active {
    &:before {
      border-radius: 0 5px 5px 0;
    }
  }

  &.active {
    background: rgba(178, 94, 255, 1);
    &:before {
      content: '';
      background: rgba(var(--rgb-light-grey-half));
      display: block;
      width: 100%;
      height: 100%;
      z-index: -1;
      position: absolute;
    }
  }

  ${() => breakpoints.isTablet.mediaMinWidth} {
    height: 6px;
  }
`;

export const FlowIndicatorLineSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin: 0px 12px 0 12px;
  padding-top: 8px;
  ${() => breakpoints.isTablet.mediaMinWidth} {
    width: 160px;
  }
`;

export const FlowIndicatorSection = styled.div`
  display: flex;
  flex-direction: row;
  ${() => breakpoints.isTablet.mediaMinWidth} {
    flex-grow: 1;
  }
`;

export const FlowIndicatorStar = styled.div`
  border-radius: 16px;
  background: rgba(var(--rgb-light-grey-half));
  height: 16px;
  margin: 0 5.5px;
  width: 16px;
  position: relative;

  svg {
    height: 10px;
    position: absolute;
    left: 0;
    top: 3px;
    transform: translateX(-17%);
  }
  ${() => breakpoints.isTablet.mediaMinWidth} {
    border-radius: 24px;
    height: 24px;
    margin: 0px 12px;
    width: 24px;
    svg {
      height: 15px;
      top: 4px;
      transform: none;
    }
  }
`;

export const FlowIndicatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;
  justify-content: right;
  width: 100%;

  ${() => breakpoints.isTablet.mediaMinWidth} {
    width: inherit;
  }
`;
