import { useCallback } from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Label, RadioGroupItem, WellReg } from '@britishcouncil/react-registration-platform';

import { ClearForm } from '@/components/component-wrapper/ClearForm';
import { FormDate } from '@/components/forms/FormDate';
import { FormInput } from '@/components/forms/FormInput';
import { FormRadioGroup } from '@/components/forms/FormRadioGroup';
import { getFirstFormError, getFormError, getServerError } from '@/core/errors/FormError';
import { dobToDate, isAdult } from '@/pages/book-test';

import { PersonalDetailsFormType } from '../hooks/usePersonalDetailsForm';
import { NAME_REGEX } from '@/core/consts/regular-expressions/common';

export const CandidatePersonalDetails = () => {
  const { t } = useTranslation('');

  const {
    register,
    formState: { errors },
    setValue,
    control,
    watch,
    setError,
    clearErrors,
    trigger,
  } = useFormContext<PersonalDetailsFormType>();

  const isOtherGenderChoosen = watch('gender')?.toString() === '0';
  const dob = watch('dateOfBirth');

  const onChangeDoB = useCallback(() => {
    if (!dob) return;

    const today = new Date();
    const dateOfBirth = dobToDate(dob);

    if (dateOfBirth > new Date(today.getFullYear(), today.getMonth(), today.getDay())) {
      setError('dateOfBirth', {
        type: 'futureDob',
        message: t('personal-details.about-you.future-dob'),
      });
    } else if (dateOfBirth < new Date(1900, 0, 1)) {
      setError('dateOfBirth', {
        type: 'dobTooEarly',
        message: t('personal-details.about-you.dob-too-early'),
      });
    } else if (!isAdult(dob)) {
      setError('dateOfBirth', {
        type: 'minDob',
        message: t('personal-details.about-you.dob-minor'),
      });
    } else clearErrors('dateOfBirth');

    trigger('dateOfBirth');
  }, [clearErrors, dob, setError, trigger, t]);

  const onFormClear = useCallback(() => {
    setValue('firstName', '');
    setValue('lastName', '');
    setValue('gender', undefined);
    setValue('otherGenders', '');
    setValue('dateOfBirth', undefined);
  }, [setValue]);

  return (
    <ClearForm onClear={onFormClear}>
      <WellReg>
        <div key={'candidate-personal-details'} className="[&>*]:my-3">
          <FormInput
            required
            data-testid="first-name"
            {...register('firstName', { required: t('common.required'), pattern: { value: NAME_REGEX, message: t('common.format') } })}
            errorInline={getFormError(errors.firstName)}
            errorSubmittion={getServerError(errors.firstName)}
            label={t('common.name-dob-sex.first-name')}
            helpText={t('common.name-dob-sex.first-name-sublabel')}
          />
          <FormInput
            required
            data-testid="last-name"
            {...register('lastName', { required: t('common.required'), pattern: { value: NAME_REGEX, message: t('common.format') } })}
            errorInline={getFormError(errors.lastName)}
            errorSubmittion={getServerError(errors.lastName)}
            label={t('common.name-dob-sex.last-name')}
          />
          <FormDate<'dateOfBirth', PersonalDetailsFormType>
            rootPropertyName="dateOfBirth"
            control={control}
            label={t('common.name-dob-sex.date-of-birth')}
            errorInline={getFirstFormError(errors.dateOfBirth?.day, errors.dateOfBirth?.month, errors.dateOfBirth?.year, errors.dateOfBirth)}
            errorSubmittion={getServerError(errors.dateOfBirth)}
            onChange={onChangeDoB}
          />
          <FormRadioGroup
            data-testid="gender-choice"
            name="gender"
            control={control}
            label={t('common.name-dob-sex.sex.title')}
            required
            items={[
              { id: 'gender-2', value: '2', label: t('common.name-dob-sex.sex.female') },
              { id: 'gender-1', value: '1', label: t('common.name-dob-sex.sex.male') },
              {
                id: 'gender-0',
                value: '0',
                owerideItem: (
                  <label key="gender-0" className={isOtherGenderChoosen ? 'flex items-center' : ''}>
                    <div className="flex items-center space-x-3 sm:border-none sm:px-0 sm:py-0 sm:my-0 border border-grey-dark px-4 py-2 rounded-lg my-1">
                      <RadioGroupItem id="gender-0" placeholder="" value="0" />
                      <Label placeholder="" className="[&>*]:font-light" htmlFor="gender-0">
                        Other
                      </Label>
                    </div>
                    {isOtherGenderChoosen && (
                      <div className="w-8/12  ml-auto">
                        <FormInput className="w-full " data-testid="other-genders" {...register('otherGenders')} />
                      </div>
                    )}
                  </label>
                ),
              },
            ]}
          />
        </div>
      </WellReg>
    </ClearForm>
  );
};
