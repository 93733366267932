import { HTMLAttributes, PropsWithChildren } from 'react';

import { Button } from '@britishcouncil/react-registration-platform';

type Props = PropsWithChildren<HTMLAttributes<HTMLDivElement>> & { onClear: () => void };

export const ClearForm = ({ children, onClear, ...rest }: Props) => {
  return (
    <div {...rest} className={rest.className}>
      <div>{children}</div>
      <Button type="button" className="bg-transparent text-red_dark pl-0 hover:bg-white active:bg-white" onClick={onClear}>
        Clear form
      </Button>
    </div>
  );
};
