import { FiEdit } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, WellReg } from '@britishcouncil/react-registration-platform';

type Text = string | number | undefined | null;

type Props = {
  title?: string;
  editUrl?: string;
  rows?: { label: string; value?: Text | Text[] }[];
};

export const ReviewCard = ({ title, editUrl, rows }: Props) => {
  const { t } = useTranslation('');

  return (
    <div>
      <div className="flex justify-between my-6">
        <h5>{title}</h5>
        {editUrl && (
          <Button asChild variant="tetriary" className="p-0 min-w-0 hover:bg-transparent text-blue_dark">
            <Link to={`${editUrl}?edit=true`} className="no-underline p-0">
              <FiEdit className="relative mr-2" size={16} />
              {t('common.edit')}
            </Link>
          </Button>
        )}
      </div>
      <WellReg className="bg-grey-50 p-4">
        <div className="space-y-8">
          {rows?.map(({ label, value }) => {
            return (
              <div key={label}>
                <p className="pb-2 text-base">{label}</p>
                <p className="font-bold">{Array.isArray(value) ? value.map((v, i) => <div key={`${v}-${i}`}>{v}</div>) : value}</p>
              </div>
            );
          })}
        </div>
      </WellReg>
    </div>
  );
};
