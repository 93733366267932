import { CheckCircle } from '@/icons/CheckCircle';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { ErrorMessages, TextInputType, createErrorMessage } from '@/components';
import { TimesCircle } from '@/icons/TimesCircle';
import { TypedDictionary, countParam, createGuid, lengthParam, minimumPasswordLength, minimumRequiredValidations, validatePassword } from '@/core';

import { ValidateMethod, ValidateMethods } from '..';

export type PasswordRequirementText = { id: string; value: string };
export type PasswordRequirementTexts = TypedDictionary<string, string>;

const getIcon = (valid: boolean): EmotionJSX.Element => (valid ? <CheckCircle index={createGuid()} /> : <TimesCircle index={createGuid()} />);

export const addPasswordValidation = (inputType: TextInputType, validationMethods: ValidateMethods) => {
  const validateText: ValidateMethod = (text: string, validationErrors: ErrorMessages, { passwordRequirementTexts }): ErrorMessages => {
    if (!passwordRequirementTexts) return validationErrors;
    const passwordValidation = validatePassword(text);
    if (passwordValidation.isValid) return validationErrors;
    let result = [...validationErrors];
    result = [...result, createErrorMessage({ isNotError: true, text: passwordRequirementTexts.mustHaveMessage, type: 'internal' })];
    result = [
      ...result,
      createErrorMessage({
        icon: getIcon(passwordValidation.isMinimumLength),
        isSuccess: passwordValidation.isMinimumLength,
        text: passwordRequirementTexts.lengthOfCharacters.replace(lengthParam, minimumPasswordLength.toString()),
        type: 'internal',
      }),
    ];
    result = [
      ...result,
      createErrorMessage({
        isNotError: true,
        text: passwordRequirementTexts.mustMeetMessage.replace(countParam, minimumRequiredValidations.toString()),
        type: 'internal',
      }),
    ];
    result = passwordValidation.passwordRequirements.reduce<ErrorMessages>((_result, validation) => {
      _result = [
        ..._result,
        createErrorMessage({ icon: getIcon(validation.valid), isSuccess: validation.valid, text: passwordRequirementTexts[validation.id], type: 'internal' }),
      ];
      return _result;
    }, result);
    return result;
  };

  if (inputType === 'password') validationMethods.push(validateText);
};
