import { useForm } from 'react-hook-form';

import { DateInputs } from '@/components/forms/FormDate';
import { IdentityDocument } from '@/store/webApi';
import { useAppSelector } from '@/core';
import { webApi } from '@/store/webApi/enhancedWebApi';

export const IdentificationType = {
  Passport: '1',
  IdCard: '2',
};

export type IdentificationFormTypes = {
  typeId?: number;
  documentNumber?: string;
  issuingAuthority?: string;
  issuingCountryId?: number;
  idCardNoExpiry?: boolean;
} & DateInputs<'expiryDate'>;

export const useIdentificationForm = () => {
  const [triggerGetCustomerDetails, getCustomerDetailsQuery] = webApi.useLazyCustomerGetCustomerDetailsQuery();
  const [triggerGetMinorDetails, getMinorDetailsQuery] = webApi.useLazyCustomerGetMinorDetailsQuery();
  const { minorId, isChild } = useAppSelector((s) => s.journey);

  const methods = useForm<IdentificationFormTypes>({
    mode: 'onTouched',
    criteriaMode: 'all',
    defaultValues: async () => {
      let identificationData = {} as IdentityDocument | null | undefined;
      let expiryDate;

      if (isChild) {
        const minorData = await triggerGetMinorDetails({ id: minorId ?? '' });
        identificationData = minorData?.data?.identityDocument;
        expiryDate = minorData?.data?.identityDocument?.expiryDate;
      } else {
        const customerData = await triggerGetCustomerDetails();
        identificationData = customerData?.data?.identityDocument;
        expiryDate = customerData?.data?.identityDocument?.expiryDate;
      }

      const date = expiryDate ? new Date(expiryDate) : null;

      return {
        idCardNoExpiry: identificationData?.typeId?.toString() === IdentificationType.IdCard && !date,
        typeId: identificationData?.typeId ?? IdentificationType.Passport,
        documentNumber: identificationData?.documentNumber,
        expiryDate: expiryDate ? { day: date?.getDate(), month: date?.getMonth(), year: date?.getFullYear() } : undefined,
        issuingCountryId: identificationData?.issuingCountryId,
        issuingAuthority: identificationData?.issuingAuthority,
      } as IdentificationFormTypes;
    },
  });

  return { ...methods, isLoading: getCustomerDetailsQuery.isLoading || getMinorDetailsQuery.isLoading };
};
