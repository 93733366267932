import { FC } from 'react';

import { IconProps } from '@/icons/IconProps';

export const CheckCircle: FC<IconProps> = ({ index = 0 }) => {
  return (
    <svg
      aria-hidden="true"
      aria-labelledby={`check-circle-icon-title-${index}`}
      className="check-circle-icon"
      data-testid="check-circle-icon"
      fill="none"
      focusable="false"
      role="img"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`check-circle-icon-title-${index}`}></title>
      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
      <polyline points="22 4 12 14.01 9 11.01"></polyline>
    </svg>
  );
};
