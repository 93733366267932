import { FC, HTMLAttributes, useRef, useState } from 'react';

import { Loading } from '@/components';
import { SetState, focusableButNoTabStop, noAction, useControlScrollIntoView } from '@/core';

import * as S from './ComponentWrapper.style';
import { ErrorMessageList, ErrorMessages } from './error-messages';

export interface AutoScrollProps {
  activeElementForScroll?: React.RefObject<HTMLElement>;
  preventScrollToElement?: boolean;
  resetScrollIntoView?: SetState;
  scrollIntoView?: boolean;
}

export interface ChildComponentProps {
  debounceWait?: number;
}

export const ComponentWrapper: FC<
  AutoScrollProps &
    ComponentWrapperProps &
    ComponentWrapperOnlyProps &
    ConditionalRequiredProps &
    FormElementProps &
    HTMLAttributes<HTMLElement> &
    LoadingProps &
    SpacingProps
> = ({
  activeElementForScroll,
  addBackground = false,
  'aria-label': ariaLabel = '',
  backgroundOn,
  bolded = true,
  children,
  className = '',
  customBackground,
  customPadding,
  dangersouslySetLabel,
  expandBorder,
  fontColor,
  fontSize,
  formErrorMessages,
  formErrorSimpleMessage,
  hide = false,
  hideOn,
  htmlFor,
  id,
  inlineErrorMessages,
  inputDirection,
  internalBorder,
  isLoading = false,
  label,
  labelIsHeading = false,
  labelLineHeight,
  labelSpacing = '--whitespace-12-to-20',
  loadingText,
  noPadding,
  onBlur = noAction,
  onChildChange = noAction,
  preventScrollToElement,
  required = false,
  rowSpacing = '--whitespace-basic-24',
  resetScrollIntoView,
  scrollIntoView,
  showValidationBorder,
  sublabel,
  tabIndex = focusableButNoTabStop,
  whiteBackground,
  wrapperDirection,
  ...props
}): JSX.Element => {
  const [showFormError, setShowFormError] = useState(false);
  const wrapperRef = useRef<HTMLElement>(null);
  useControlScrollIntoView({ alignToTop: true, activeElementForScroll, controlToScrollIntoView: wrapperRef, resetScrollIntoView, scrollIntoView });

  if (isLoading) return <Loading text={loadingText} />;

  const toggleFormError = () => {
    setShowFormError(!showFormError);
  };

  return (
    <S.ComponentWrapper
      addBackground={addBackground}
      {...(ariaLabel && !label && { 'aria-label': ariaLabel })}
      backgroundOn={backgroundOn}
      {...(className && { className: className })}
      customBackground={customBackground}
      customPadding={customPadding}
      hide={hide}
      hideOn={hideOn}
      id={id}
      labelSpacing={labelIsHeading ? '--whitespace-16-to-24' : labelSpacing}
      noPadding={noPadding}
      onBlur={onBlur}
      {...(!preventScrollToElement && label && { ref: wrapperRef })}
      {...(showValidationBorder && (formErrorMessages?.length || inlineErrorMessages?.length) && { showValidationBorder: true })}
      tabIndex={tabIndex}
      whiteBackground={whiteBackground}
      wrapperDirection={wrapperDirection}
      {...props}
    >
      {label && (
        <S.ComponentLabel
          data-testid="label"
          bolded={bolded}
          fontColor={fontColor}
          fontSize={fontSize}
          {...(dangersouslySetLabel && { dangerouslySetInnerHTML: { __html: label } })}
          htmlFor={htmlFor}
          id={`label-${id}`}
          labelIsHeading={labelIsHeading}
          labelLineHeight={labelLineHeight}
          {...(required && { required: true })}
        >
          {!dangersouslySetLabel && label}
          {sublabel && <S.Sublabel>{sublabel}</S.Sublabel>}
        </S.ComponentLabel>
      )}
      <S.ErrorWrapper
        {...(formErrorMessages && formErrorMessages.length > 0 && { className: 'isFormErrored' })}
        expandBorder={expandBorder}
        inputDirection={inputDirection}
        id={`error-wrapper-${id}`}
        internalBorder={internalBorder}
        onChange={onChildChange}
        rowSpacing={rowSpacing}
      >
        {formErrorMessages && formErrorMessages.length > 0 && (
          <>
            <S.ErrorMessageLabel className="clickable" data-testid="form-error-simple" onClick={toggleFormError} role="alert">
              {formErrorSimpleMessage}
            </S.ErrorMessageLabel>
            <S.ErrorMessageLabel className={showFormError ? 'animated slideInDown' : 'hide'} data-testid="form-error" role="alert">
              <ErrorMessageList errorMessages={formErrorMessages} />
            </S.ErrorMessageLabel>
          </>
        )}
        {children}
        {inlineErrorMessages && inlineErrorMessages.length > 0 && (
          <S.ErrorMessageLabelInline className="animated verticalReveal" data-testid="inline-error" role="alert">
            <ErrorMessageList errorMessages={inlineErrorMessages} />
          </S.ErrorMessageLabelInline>
        )}
      </S.ErrorWrapper>
    </S.ComponentWrapper>
  );
};

export interface ComponentWrapperOnlyProps {
  htmlFor?: string;
  labelSpacing?: string;
  onChildChange?: React.FormEventHandler<HTMLElement>;
}

export interface ComponentWrapperProps {
  addBackground?: boolean;
  backgroundOn?: 'mobile' | 'tablet';
  bolded?: boolean;
  customBackground?: string;
  customPadding?: string;
  dangersouslySetLabel?: boolean;
  expandBorder?: boolean;
  fontColor?: string;
  fontSize?: string;
  hide?: boolean;
  hideOn?: 'mobile' | 'tablet';
  id: string;
  inputDirection?: string;
  internalBorder?: boolean;
  label?: string;
  labelIsHeading?: boolean;
  labelLineHeight?: string;
  sublabel?: string;
  whiteBackground?: boolean;
  wrapperDirection?: string;
}

export type ConditionalRequiredProps = NotRequiredProps | RequiredProps;

export interface FormElementProps {
  formErrorMessages?: ErrorMessages;
  formErrorSimpleMessage?: string;
  formPreventControlRegistration?: boolean;
  inlineErrorMessages?: ErrorMessages;
  showValidationBorder?: boolean;
}

export interface LoadingProps {
  isLoading?: boolean;
  loadingText?: string;
}

export type NoPadding = 'column' | 'none' | 'row';

interface NotRequiredProps {
  required?: never;
  requiredText?: never;
}

interface RequiredProps {
  required: true;
  requiredText: string;
}

export interface SpacingProps {
  noPadding?: NoPadding;
  rowSpacing?: string;
}
