import { FC } from 'react';

import * as S from './Loading.style';

interface LoadingProps {
  leftAlign?: boolean;
  text?: string;
}

export const Loading: FC<LoadingProps> = ({ leftAlign = false, text = '' }): JSX.Element => {
  return <S.Loading leftAlign={leftAlign}>{text ? text : 'Loading...'}</S.Loading>;
};
