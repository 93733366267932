import { useTranslation } from 'react-i18next';

import { Clock } from '@/icons/Clock';
import { SessionWithVenueResponse } from '@/store/webApi';

interface InformationCardProps {
  examData: SessionWithVenueResponse;
}

export const InformationCard = ({ examData }: InformationCardProps) => {
  const { t } = useTranslation();

  return (
    <div className="border rounded-3xl border-grey-300 p-6 md:max-w-xs md:w-full">
      <section className="mb-6">
        <p className="text-indigo font-bold">{examData.sessionName}</p>
      </section>
      <section className="flex justify-between my-6">
        <div className="flex flex-col gap-2">
          <p className="text-xs font-normal border-grey-800">{t('common.duration')}</p>
          <div className="flex justify-center items-center gap-2">
            <div className="w-[20px]">
              <Clock />
            </div>
            <p className="font-bold leading-6">{`${examData.durationInMinutes} ${t('common.minutes')}`}</p>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-xs font-normal border-grey-800">{t('common.price')}</p>
          <p className="font-bold">{`${examData.currency} ${examData.price}`}</p>
        </div>
      </section>
      <section className="flex flex-col">
        <p className="text-xs font-normal border-grey-800 mb-4">{t('common.components')}</p>
        <div className="flex flex-wrap gap-y-4 gap-x-2 mt-6">
          {examData.skills?.map((skill, key) => (
            <div key={key} className="bg-grey-100 font-medium rounded-3xl px-4 py-1">
              <p>{skill}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};
