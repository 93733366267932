import styled from '@emotion/styled';
import { breakpoints } from '@/core';

export const Step = styled.section<{
  bottomMargin?: string;
}>`
  color: var(--indigo);
  font-size: var(--smaller-font-size);
  font-weight: 400;
  margin-bottom: ${({ bottomMargin }) => bottomMargin ?? 'var(--whitespace-basic-08)'};
  ${() => breakpoints.isTablet.mediaMinWidth} {
    font-weight: 700;
  }
`;
