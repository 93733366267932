import { SkipLink } from '@/components';

import * as S from './Footer.style';
import { Copy } from './copy';
import { Links } from './links';

interface FooterProps {
  copy: Array<string>;
  skipId?: string;
  skipText?: string;
  skipTo?: string;
}

export const accessibilityRoute = {
  path: 'https://www.britishcouncil.org/accessibility',
  text: 'Accessibility',
};

const dataProtectionRoute = {
  path: 'https://www.britishcouncil.org/privacy-cookies/data-protection',
  text: 'Data protection',
};

export const privacyAndCookiesRoute = {
  path: 'https://www.britishcouncil.org/privacy-cookies',
  text: 'Privacy and cookies',
};

export const termsOfUseRoute = {
  path: 'https://www.britishcouncil.org/terms',
  text: 'Terms of use',
};

export const footerMenuRoutes = [termsOfUseRoute, accessibilityRoute, privacyAndCookiesRoute, dataProtectionRoute];

export const Footer = ({ copy, skipId, skipText, skipTo }: FooterProps) => {
  return (
    <S.Footer>
      <S.InnerMainContent>
        {skipId && skipText && skipTo && <SkipLink id={skipId} skipText={skipText} skipTo={skipTo} />}
        <Links routes={footerMenuRoutes} />
        <Copy copy={copy} />
      </S.InnerMainContent>
    </S.Footer>
  );
};
