interface Breakpoint {
  breakpoint: number;
  mediaMinWidth: string;
  mediaMaxWidth?: string;
}

export type BreakpointSize = 'isBase' | 'isMobile' | 'isXMobile' | 'isTablet' | 'isLaptop' | 'isXLaptop' | 'isDesktop' | 'isXDesktop';

export const breakpoints: Record<BreakpointSize, Breakpoint> = {
  isBase: { breakpoint: 0, mediaMinWidth: '@media (min-width: 0px)' },
  isMobile: { breakpoint: 480, mediaMinWidth: '@media (min-width: 480px)' },
  isXMobile: { breakpoint: 640, mediaMinWidth: '@media (min-width: 640px)' },
  isTablet: { breakpoint: 768, mediaMinWidth: '@media (min-width: 768px)', mediaMaxWidth: '@media (max-width: 767px)' }, //edit hard coded values in global styles if this value changes
  isLaptop: { breakpoint: 1024, mediaMinWidth: '@media (min-width: 14px)' },
  isXLaptop: { breakpoint: 1280, mediaMinWidth: '@media (min-width: 1280px)' },
  isDesktop: { breakpoint: 1440, mediaMinWidth: '@media (min-width: 1440px)' },
  isXDesktop: { breakpoint: 1920, mediaMinWidth: '@media (min-width: 1920px)' },
};
