import { useCallback } from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { WellReg } from '@britishcouncil/react-registration-platform';

import { ClearForm } from '@/components/component-wrapper/ClearForm';
import { FormInput } from '@/components/forms/FormInput';
import { FormPhoneInput } from '@/components/forms/FormPhoneInput';

import { PersonalDetailsFormType } from '../legacy/usePersonalDetailsForm';

export const ContactDetails = () => {
  const { t } = useTranslation('');

  const formContext = useFormContext<PersonalDetailsFormType>();

  const onFormClear = useCallback(() => {
    formContext.setValue('mobileNumber', '');
  }, [formContext]);

  return (
    <ClearForm onClear={onFormClear}>
      <WellReg>
        <div className="[&>*]:my-3">
          <FormInput disabled data-testid="email" {...formContext.register('email')} label={t('personal-details.create-account.email-password.your-email')} />
          <FormPhoneInput data-testid="telephone-number" label={t('personal-details.contact-details.mobile-number')} formContext={formContext} />
        </div>
      </WellReg>
    </ClearForm>
  );
};
