import { SessionWithVenueResponse } from '@/store/webApi';
import { InformationCard } from './InformationCard';
import { TabItem, Tabs } from '@/components';

export interface ExamRowProps {
  enrolments: SessionWithVenueResponse[];
  getTabs: (exam: SessionWithVenueResponse) => Array<TabItem>;
}

export const ExamRow = ({ enrolments, getTabs }: ExamRowProps) => {
  return enrolments.map((exam) => (
    <div key={exam.enrolmentId} className="flex flex-col gap-6 py-12 md:flex-row md:py-0">
      <InformationCard examData={exam} />
      <Tabs defaultKey={0} tabs={getTabs(exam)} />
    </div>
  ));
};
