import { FC } from 'react';

import { IconProps } from '@/icons/IconProps';

export const Edit: FC<IconProps> = ({ index = 0 }) => {
  return (
    <svg
      aria-hidden="true"
      aria-labelledby={`edit-icon-title-${index}`}
      className="edit-icon feather feather-edit"
      data-testid={`edit-icon-${index}`}
      fill="none"
      focusable="false"
      role="img"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`edit-icon-title-${index}`}></title>
      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
    </svg>
  );
};
