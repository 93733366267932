import { css } from '@emotion/react';

import { breakpoints } from '../utils';

export const iconDimensions = css`
  .themed {
    --icon-34-to-58: 34px;
    --icon-68-to-90: 68px;

    ${breakpoints.isTablet.mediaMinWidth} {
      --icon-34-to-58: 58px;
      --icon-68-to-90: 90px;
    }
  }
`;
