import { useCallback } from 'react';

import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { WellReg } from '@britishcouncil/react-registration-platform';

import { AlertReg } from '@/components/presentation/alerts/AlertReg';
import { ClearForm } from '@/components/component-wrapper/ClearForm';
import { FlowIndicator, Form, FormSection, FormSectionTitle, Loading, PageFormWrapper, PageTitle, Step } from '@/components';
import { FlowSteps, getTotalSteps, isMobileTrack, useAppSelector, useBasket, useDocumentTitle } from '@/core';
import { FormCheckbox } from '@/components/forms/FormCheckbox';
import { FormDate } from '@/components/forms/FormDate';
import { FormInput } from '@/components/forms/FormInput';
import { FormRadioGroup } from '@/components/forms/FormRadioGroup';
import { FormSelect } from '@/components/forms/FormSelect';
import { ServerValidationError, getFirstFormError, getFormError, getServerError } from '@/core/errors/FormError';
import { StepButtonsForm } from '@/pages/book-test/StepButtonsForm';
import { useCountryGetAllQuery } from '@/store/webApi';
import { useFormError } from '@/core/errors/useFormError';

import { IdentificationFormTypes, IdentificationType, useIdentificationForm } from './components/useIdentificationForm';
import { useAnalytics } from './components/useAnalytics';
import { useIdentificationMutation } from './components/useIdentificationMutation';

export const IdentificationPage = () => {
  const isChild = useAppSelector((s) => s.journey.isChild);
  const { data: countries = [], error: countriesError, isLoading: isLoadingCountries } = useCountryGetAllQuery();
  const { createOrUpdate, isLoading: isProcessData, error: processDataError } = useIdentificationMutation();
  const { t } = useTranslation('');
  const { t: tRoutes } = useTranslation('routes');
  const navigate = useNavigate();
  const { getEarliestExpiryDate } = useBasket();
  const { submitEvents } = useAnalytics();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    isLoading: isLoadingForm,
    control,
    watch,
    setError,
    clearErrors,
    trigger,
  } = useIdentificationForm();

  useFormError({
    error: processDataError as ServerValidationError,
    setError,
    propertyMapping: [
      { serverProperty: 'identificationDocument.expiryDate', formProperty: 'expiryDate' },
      { serverProperty: 'identificationDocument.typeId', formProperty: 'typeId' },
      { serverProperty: 'identificationDocument.documentNumber', formProperty: 'documentNumber' },
      { serverProperty: 'IssuingAuthority', formProperty: 'issuingAuthority' },
      { serverProperty: 'identificationDocument.issuingCountryId', formProperty: 'issuingCountryId' },
    ],
  });

  const options = countries.map((country) => ({
    value: country.id ?? -1,
    label: country.name ?? '',
  }));
  const totalSteps = getTotalSteps(window.innerWidth, isChild);
  const activeFlowStep = isMobileTrack(window.innerWidth) ? 5 : 2;
  const mobileTrack = isMobileTrack(window.innerWidth);
  const selectedIdCard = watch('typeId')?.toString() === IdentificationType.IdCard;
  const idCardNoExpiry = watch('idCardNoExpiry');
  const expiryDate = watch('expiryDate');

  useDocumentTitle(`${tRoutes('book-new-test.title')} - ${tRoutes('book-new-test.identification')}`);

  const onSubmit: SubmitHandler<IdentificationFormTypes> = (data: IdentificationFormTypes) => {
    submitEvents;
    createOrUpdate(data);
  };

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onFormClear = useCallback(() => {
    setValue('documentNumber', '');
    setValue('issuingAuthority', '');
    setValue('issuingCountryId', undefined);
    setValue('idCardNoExpiry', false);
    setValue('expiryDate', undefined);
  }, [setValue]);

  const isDateBeforeEarliestExpiryDate = useCallback(
    (day: number, month: number, year: number) => {
      const earliestExpiryDate = getEarliestExpiryDate();
      const dateToCompare = earliestExpiryDate ? earliestExpiryDate : new Date();
      return dateToCompare >= new Date(year, month, day);
    },
    [getEarliestExpiryDate],
  );

  const onChangeExpiryDate = useCallback(() => {
    if (expiryDate && (!selectedIdCard || !idCardNoExpiry)) {
      if (isDateBeforeEarliestExpiryDate(expiryDate.day, expiryDate.month, expiryDate.year)) {
        setError('expiryDate', {
          type: 'dateBeforeEarliestExpiryDate',
          message: t('identification.expiry-date-too-early'),
        });
      } else clearErrors();
      trigger('expiryDate');
    }
  }, [clearErrors, trigger, expiryDate, idCardNoExpiry, isDateBeforeEarliestExpiryDate, selectedIdCard, setError, t]);

  if (isLoadingForm) return <Loading />;

  return (
    <>
      <header>
        <FlowIndicator
          currentFormStep={activeFlowStep}
          currentStep={FlowSteps.sessionSelectionFinished}
          formSteps={totalSteps}
          steps={FlowSteps.totalStepsForSessionSelection}
        />
        {mobileTrack && (
          <Step bottomMargin={'var(--whitespace-basic-08)'}>
            {t('general-step', {
              stepNumber: `${activeFlowStep}`,
              stepsTotal: `${totalSteps}`,
            })}
          </Step>
        )}
        <PageTitle
          hideOn="mobile"
          marginBottom={'var(--whitespace-32-to-08)'}
          text={tRoutes(isChild ? 'book-new-test.minor-identification' : 'book-new-test.identification')}
        />
        {!mobileTrack && (
          <Step bottomMargin={'var(--whitespace-32-to-72)'}>
            {t('general-step', {
              stepNumber: `${activeFlowStep}`,
              stepsTotal: `${totalSteps}`,
            })}
          </Step>
        )}
      </header>
      <PageFormWrapper>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <FormSection>
            <FormSectionTitle>{t('identification.type.title')}</FormSectionTitle>

            <AlertReg
              variant="information"
              title={t('common.please-note')}
              visible={isChild}
              content={
                <div className="flex flex-col gap-y-3">
                  <div>{t('minor-identification.note')}</div>
                  <div>{t('minor-identification.note2')}</div>
                </div>
              }
            />
            <AlertReg variant="information" title={t('common.please-note')} visible={!isChild} content={t('identification.note')} />

            <ClearForm onClear={onFormClear}>
              <WellReg>
                <div className="[&>*]:my-3">
                  <FormRadioGroup
                    data-testid="identification-type-choice"
                    name="typeId"
                    control={control}
                    required
                    items={[
                      { id: 'identification-type-choice-passport', value: IdentificationType.Passport, label: t('identification.type.passport') },
                      {
                        id: 'identification-type-choice-id-card',
                        value: IdentificationType.IdCard,
                        label: t('identification.type.id-card'),
                      },
                    ]}
                  />
                  <FormInput
                    label={selectedIdCard ? t('identification.details.id-card.number') : t('identification.details.passport.number')}
                    required
                    data-testid="id-number"
                    {...register('documentNumber', { required: t('common.required') })}
                    errorInline={getFormError(errors.documentNumber)}
                    errorSubmittion={getServerError(errors.documentNumber)}
                  />
                  <div className="md:flex md:space-x-2.5">
                    <FormDate<'expiryDate'>
                      rootPropertyName="expiryDate"
                      label={selectedIdCard ? t('identification.details.id-card.expiry-date') : t('identification.details.passport.expiry-date')}
                      control={control}
                      errorInline={getFirstFormError(errors.expiryDate?.day, errors.expiryDate?.month, errors.expiryDate?.year, errors.expiryDate)}
                      errorSubmittion={getServerError(errors.expiryDate)}
                      required={!selectedIdCard || !idCardNoExpiry}
                      disabled={selectedIdCard && idCardNoExpiry}
                      onChange={onChangeExpiryDate}
                    />
                    {selectedIdCard && (
                      <div className="md:w-[170px] md:mt-0 mt-2 ">
                        <FormCheckbox
                          label={t('identification.details.id-card.no-expiry')}
                          control={control}
                          id="no-expiry-checkbox"
                          {...register('idCardNoExpiry')}
                        />
                      </div>
                    )}
                  </div>

                  <FormInput
                    label={t('identification.details.issuing-authority')}
                    data-testid="issuing-authority"
                    {...register('issuingAuthority', { required: t('common.required') })}
                    errorInline={getFormError(errors.issuingAuthority)}
                    errorSubmittion={getServerError(errors.issuingAuthority)}
                  />
                  <FormSelect
                    label={t('identification.nationality')}
                    data-testid="country-of-nationality"
                    name={'issuingCountryId'}
                    control={control}
                    required
                    options={options}
                    placeholder={t('personal-details.contact-details.search-by-country')}
                    errorSubmittion={typeof countriesError === 'string' ? countriesError : getServerError(errors.issuingCountryId)}
                    errorInline={getFormError(errors.issuingCountryId)}
                    disabled={isLoadingCountries}
                  />
                </div>
              </WellReg>
            </ClearForm>
          </FormSection>

          <div className="flex flex-col gap-4">
            <StepButtonsForm
              submitButton={{
                isLoading: isProcessData,
              }}
              backButton={{ onClick: goBack }}
            />
            <AlertReg
              variant="error"
              title={t('personal-details.save-error-title')}
              content={t('personal-details.save-error-message')}
              visible={processDataError !== undefined}
            />
          </div>
        </Form>
      </PageFormWrapper>
    </>
  );
};
