import { GenderType } from '@/store/webApi';
import { MarketingPreference } from '@/core';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface PersonalDetailsState {
  confirmEmail: string;
  dateOfBirth: string;
  email: string;
  firstName: string;
  gender?: GenderType; // { other: 0, male: 1, female: 2 }
  genderOther?: string;
  lastName: string;
  marketingPreference: MarketingPreference;
  noLastName: boolean;
  termsAndConditionsConsent: boolean;
}

const initialState: PersonalDetailsState = {
  //To delete after identity release
  confirmEmail: '',
  dateOfBirth: '',
  email: '',
  firstName: '',
  lastName: '',
  marketingPreference: 'none',
  noLastName: false,
  termsAndConditionsConsent: false,
};

export const personalDetailsSlice = createSlice({
  name: 'personalDetails',
  initialState,
  reducers: {
    clearEmail: (state): void => {
      state.confirmEmail = '';
      state.email = '';
    },
    clearPersonalDetails: (state): void => {
      delete state.gender;
      state.firstName = '';
      state.lastName = '';
      state.dateOfBirth = '';
      state.marketingPreference = 'none';
      state.noLastName = false;
      state.termsAndConditionsConsent = false;
    },
    setConfirmEmail: (state, { payload }: PayloadAction<string>): void => {
      state.confirmEmail = payload;
    },
    setDateOfBirth: (state, { payload }: PayloadAction<string>): void => {
      state.dateOfBirth = payload;
    },
    setEmail: (state, { payload }: PayloadAction<string>): void => {
      state.email = payload;
    },
    setFirstName: (state, { payload }: PayloadAction<string>): void => {
      state.firstName = payload;
    },
    setGender: (state, { payload }: PayloadAction<GenderType>): void => {
      state.gender = payload;
      if (state.gender !== 0) {
        state.genderOther = undefined;
      }
    },
    setGenderOther: (state, { payload }: PayloadAction<string>): void => {
      state.genderOther = payload;
    },
    setIsChild: (state, { payload }: PayloadAction<boolean>): void => {
      state.isChild = payload;
    },
    setLastName: (state, { payload }: PayloadAction<string>): void => {
      state.lastName = payload;
    },
    setMarketingPreference: (state, { payload }: PayloadAction<MarketingPreference>): void => {
      state.marketingPreference = payload;
    },
    setNoLastName: (state, { payload }: PayloadAction<boolean>): void => {
      state.noLastName = payload;
    },
    setPersonalDetails: (state, { payload }: PayloadAction<Partial<PersonalDetailsState>>): PersonalDetailsState => {
      return { ...state, ...payload };
    },
    setTermsAndConditionsConsent: (state, { payload }: PayloadAction<boolean>): void => {
      state.termsAndConditionsConsent = payload;
    },
  },
});
