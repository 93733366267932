import { ReactNode } from 'react';

import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Label, LabelProps, ValidatedField } from '@britishcouncil/react-registration-platform';

import { getMonthsArray } from '@/utils/getMonthsNames';

import { FormInput } from './FormInput';
import { FormSelect } from './FormSelect';

export type DobInputs = {
  dateOfBirth?: {
    day: number;
    month: number;
    year: number;
  };
};

type Props<T extends DobInputs> = {
  label?: ReactNode;
  control: Control<T>;
  errorInline?: string;
  errorSubmittion?: string;
  disabled?: boolean;
} & Pick<LabelProps, 'helpText'>;

export const FormDob = <T extends DobInputs = DobInputs>({ errorInline, errorSubmittion, label, helpText, disabled, ...rest }: Props<T>) => {
  const { t } = useTranslation();
  const dayRange = { min: 1, max: 31 };
  const yearRange = { min: 1900, max: new Date().getFullYear() };

  const options = getMonthsArray().map((month, index) => ({ value: index, label: month }));

  /* I do not know how to type it properly using generic props, so I cast it to Control<DobInputs>.
  Component consuming <FormDob /> is forced to use proper typings, so it should works fine. */
  const control = rest.control as unknown as Control<DobInputs>;

  return (
    <div>
      {label ? (
        <Label placeholder="" helpText={helpText}>
          {label}
        </Label>
      ) : null}
      <ValidatedField errorInline={errorInline} errorSubmittion={errorSubmittion}>
        <div className="flex gap-2 items-center">
          <FormInput
            label="Day"
            type="number"
            {...dayRange}
            {...control.register('dateOfBirth.day', { required: t('common.required'), ...dayRange })}
            disabled={disabled}
          />
          <div className="grow">
            <FormSelect label="Month" options={options} name="dateOfBirth.month" control={control} disabled={disabled} />
          </div>
          <FormInput
            label="Year"
            type="number"
            disabled={disabled}
            {...yearRange}
            {...control.register('dateOfBirth.year', { required: t('common.required'), ...yearRange })}
          />
        </div>
      </ValidatedField>
    </div>
  );
};
