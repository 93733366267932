import { PropsWithChildren } from 'react';

import { Choice, ChoiceChangeEvent, ComponentWrapper } from '@/components';

interface ExamAndCancellationPolicyProps {
  alert: JSX.Element;
  label: string;
  onChange: ChoiceChangeEvent;
  requiredText: string;
  termsAndConditionsConsent: boolean;
}

export const ExamAndCancellationPolicy = ({
  alert,
  children,
  label,
  onChange,
  requiredText,
  termsAndConditionsConsent,
}: PropsWithChildren<ExamAndCancellationPolicyProps>) => {
  return (
    <ComponentWrapper internalBorder id="marketing-preferences">
      {alert}

      <Choice
        dangersouslySetLabel
        defaultChecked={termsAndConditionsConsent}
        id="terms-and-conditions-choice"
        label={label}
        name="terms-and-conditions-choice"
        onValueChange={onChange}
        required
        requiredText={requiredText}
        type="checkbox"
      >
        {children}
      </Choice>
    </ComponentWrapper>
  );
};
