import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AlertReg } from '@/components/presentation/alerts/AlertReg';
import { FormRadioGroup } from '@/components/forms/FormRadioGroup';

import { MarketingPreferenceType, PersonalDetailsFormType } from '../hooks/usePersonalDetailsForm';

export const MarketingPreferencesDetails = () => {
  const { t } = useTranslation('');

  const { control } = useFormContext<PersonalDetailsFormType>();

  return (
    <>
      <AlertReg
        variant="information"
        title={t('common.please-note')}
        content={
          <div className="flex flex-col gap-y-3">
            <div>{t('personal-details.marketing-preferences.note')}</div>
            <div>{t('personal-details.marketing-preferences.how-we-process')}</div>
          </div>
        }
      />
      <div className="md:border md:border-grey_light md:rounded-xl md:p-6">
        <FormRadioGroup
          name="marketingPreference"
          control={control}
          defaultValue={MarketingPreferenceType.DoNotSend.toString()}
          required
          items={[
            {
              id: 'marketingPreference-3',
              value: MarketingPreferenceType.BcAndThirdParties.toString(),
              label: t('personal-details.marketing-preferences.all'),
            },
            {
              id: 'marketingPreference-2',
              value: MarketingPreferenceType.BcOnly.toString(),
              label: t('personal-details.marketing-preferences.british-council'),
            },
            { id: 'marketingPreference-1', value: MarketingPreferenceType.DoNotSend.toString(), label: t('personal-details.marketing-preferences.none') },
          ]}
        />
      </div>
    </>
  );
};
