import { css } from '@emotion/react';

export const RowsMixin = css`
  --row-gap: var(--whitespace-08-to-16);

  align-items: flex-start;
  display: flex;
  flex-direction: column;
  row-gap: var(--whitespace-08-to-16);
`;
