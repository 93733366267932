import { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ContactDetailsState } from '@/store';
import { ReviewContactDetails } from '@/components';
import { useCountryOptions } from '@/core/hooks/useCountryOptions';

interface AppReviewContactDetailsProps extends ContactDetailsState {
  email?: string;
  hideAddress?: boolean;
  hideEmail?: boolean;
  hideTelephone?: boolean;
}

export const AppReviewContactDetails: FC<AppReviewContactDetailsProps> = ({
  countryId,
  email,
  hideAddress,
  hideEmail,
  hideTelephone,
  mobileNumber,
  postalLine01,
  postalLine02,
  postalTownCity,
  postalZipCode,
}): JSX.Element => {
  const { countries } = useCountryOptions();
  const [countryName, setCountryName] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (countryId && !countryName && countries?.length) {
      const country = countries.find((country) => +country.value === countryId);
      if (country) setCountryName(country.text);
    }
  }, [countryId, countries, countryName]);

  return (
    <ReviewContactDetails
      addressFirstLine={postalLine01}
      addressLabel={t('review.contact-details.address')}
      addressSecondLine={postalLine02}
      countryName={countryName}
      email={email}
      emailLabel={t('review.email-address')}
      hideAddress={hideAddress}
      hideEmail={hideEmail}
      hideTelephone={hideTelephone}
      mobileNumberLabel={t('review.contact-details.mobile')}
      mobileNumber={mobileNumber}
      townCity={postalTownCity}
      zipCode={postalZipCode}
    />
  );
};
