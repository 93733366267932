import { PropsWithChildren, useEffect } from 'react';

import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { useAppSettingsContext } from '@/core/app-settings';

import { maskSensitiveData } from './pii';

export const SentryWrapper = ({ children }: PropsWithChildren) => {
  const appSettings = useAppSettingsContext();

  useEffect(() => {
    const client = Sentry.getCurrentHub().getClient();
    const enable = !client && process.env.NODE_ENV === 'production';

    enable &&
      Sentry.init({
        dsn: 'https://2115cb944685906dd671eff62d73327c@o505679.ingest.sentry.io/4506478384250880',
        release: appSettings.appVersion,
        environment: appSettings.sentryEnvironment,

        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes),
          }),

          new Sentry.Replay({
            blockAllMedia: false,
            maskAllText: false,
            networkDetailAllowUrls: [window.location.origin],

            beforeAddRecordingEvent: (event) => {
              if (event.data.tag === 'performanceSpan' && (event.data.payload.op === 'resource.fetch' || event.data.payload.op === 'resource.xhr')) {
                const reqData = event.data.payload.data?.request.body;
                const resData = event.data.payload.data?.response.body;

                const censoredReqBody = reqData ? maskSensitiveData(reqData) : reqData;
                const censoredResBody = resData ? maskSensitiveData(resData) : resData;

                if (event.data.payload?.data?.request?.body) {
                  event.data.payload.data.request.body = censoredReqBody;
                }

                if (event.data.payload?.data?.response?.body) {
                  event.data.payload.data.response.body = censoredResBody;
                }

                return event;
              }

              return event;
            },
          }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
        tracesSampleRate: 0.5,

        // Setting this option to true will print useful information to the console while you're setting up Sentry.
        debug: false,

        // Capture Replay for 10% of all sessions, plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,

        normalizeDepth: 10,
      });
  }, [appSettings]);

  return <>{children}</>;
};
