import { useEffect } from 'react';

import { GoogleTagManager, getTotalSteps, isMobileTrack, useAppSelector } from '@/core';

/** Utilities and functions used in both identity and legacy view. */
export const useUtils = () => {
  const journey = useAppSelector((s) => s.journey);

  const activeFlowStep = isMobileTrack(window.innerWidth) ? (journey.isChild ? 7 : 6) : journey.isChild ? 5 : 3;
  const totalSteps = getTotalSteps(window.innerWidth, journey.isChild);

  useEffect(() => {
    GoogleTagManager.addEvent({
      action: 'Review',
      event: 'ReviewStep',
      virtualPageTitle: 'British Council - Book a new test - Review',
      virtualPageURL: document.title,
    });
  }, []);

  return { activeFlowStep, totalSteps };
};
