import { ReactNode, forwardRef } from 'react';

import { FieldValues, UseControllerProps, useController } from 'react-hook-form';

import { CheckboxReg, CheckboxRegProps, Label, RegCheckedState, ValidatedField, ValidatedFieldProps } from '@britishcouncil/react-registration-platform';

type FormCheckboxRegProps<T extends FieldValues> = CheckboxRegProps &
  UseControllerProps<T> &
  Pick<ValidatedFieldProps, 'errorInline' | 'errorSubmittion'> & {
    label?: ReactNode;
  };

export const FormCheckbox = forwardRef<React.ElementRef<typeof CheckboxReg>, FormCheckboxRegProps<FieldValues>>(
  ({ id, name, control, rules, defaultValue, shouldUnregister, label, errorInline, errorSubmittion, ...props }, ref) => {
    const { field } = useController({
      name,
      control,
      rules: { ...rules },
      defaultValue,
      shouldUnregister,
    });

    return (
      <ValidatedField errorInline={errorInline} errorSubmittion={errorSubmittion}>
        <label htmlFor={id}>
          <div className="flex space-x-2.5 sm:border-none sm:px-0 sm:py-0 sm:my-0 border border-grey-dark px-4 py-2 rounded-lg">
            <div className="grid place-content-center">
              <CheckboxReg
                name={field.name}
                checked={field.value as RegCheckedState}
                onCheckedChange={(checked: RegCheckedState) => {
                  return field.onChange(checked);
                }}
                onBlur={field.onBlur}
                placeholder=""
                id={id}
                ref={ref}
                {...props}
              />
            </div>
            <div className="grid place-content-center">
              <Label placeholder="" htmlFor={id} className="[&>*]:font-light">
                {label}
              </Label>
            </div>
          </div>
        </label>
      </ValidatedField>
    );
  },
);

FormCheckbox.displayName = 'FormCheckbox';
