import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SelectField } from '@britishcouncil/react-registration-platform';

import { Alert } from '@/components';
import { searchDetailsSlice } from '@/store';
import { useAppSelector } from '@/core';
import { useProductConfig } from '@/core/hooks/useProductConfig';
import { useSessionGetAllTestPackagesQuery } from '@/store/webApi';

export const ChooseSessionTestPackage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [alreadyChosenExam, setAlreadyChosenExam] = useState<string | undefined | null>();
  const { productConfig } = useProductConfig();

  const searchDetails = useAppSelector((s) => s.searchDetails);
  const basket = useAppSelector((s) => s.basket);

  const {
    data: testPackages = [],
    error: testPackagesError,
    isLoading: isLoadingTestPackages,
  } = useSessionGetAllTestPackagesQuery(
    {
      countryId: searchDetails?.country?.id ?? 0,
      productVariantId: searchDetails?.productVariant?.id ?? '',
      productCode: productConfig?.productCode ?? '',
    },
    { skip: !searchDetails?.productVariant?.id || !searchDetails?.country?.id },
  );

  const options =
    testPackages?.map((pkg) => ({
      value: pkg?.id ?? '',
      label: pkg?.description ?? '',
    })) ?? [];

  const handlePackageChange = (pkgOption: (typeof options)[0] | null) => {
    const selectedPackage = testPackages?.find((pkg) => pkg.id === pkgOption?.value);

    if (!selectedPackage) {
      return;
    }

    if (basket?.items.some((session) => session.packageId === selectedPackage.id)) {
      dispatch(searchDetailsSlice.actions.setTestPackage(undefined));
      return setAlreadyChosenExam(selectedPackage.id);
    }

    setAlreadyChosenExam(undefined);
    dispatch(searchDetailsSlice.actions.setTestPackage(selectedPackage));
  };

  useEffect(() => {
    setAlreadyChosenExam(undefined);
  }, [searchDetails.country?.id, searchDetails.productVariant?.id]);

  // Needs to use any as Select from reg. platform is using incorrect types and not allow to use null.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value: any = options.find((el) => el.value === searchDetails?.testPackage?.id) || null;

  return (
    <div>
      <SelectField
        errorMessage={testPackagesError ? 'Cannot load exams' : undefined}
        headerMessage={t('book-test.choose-exam')}
        selectProps={{
          value,
          options,
          isMulti: false,
          onChange: handlePackageChange,
          placeholder: isLoadingTestPackages ? 'Loading...' : t('book-test.search-by-exam'),
          isDisabled: isLoadingTestPackages || !searchDetails.country?.id || !searchDetails.productVariant?.id,
        }}
        className="mb-[16px]"
        testId="exam"
      />
      {alreadyChosenExam && (
        <div className="my-[16px]">
          <Alert show={!!alreadyChosenExam} text={t('book-test.choose-exam-alert')} alertType="warning" showIcon={false} />
        </div>
      )}
    </div>
  );
};
