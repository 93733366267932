import styled from '@emotion/styled';
import { breakpoints } from '@/core';

export const OuterWrapper = styled.section<{ rowSpacing?: string }>`
  display: flex;
  flex-direction: column;
  position: relative;

  ${() => breakpoints.isTablet.mediaMaxWidth} {
    & > section {
      padding: 0;
    }
  }
  ${() => breakpoints.isTablet.mediaMinWidth} {
    gap: ${({ rowSpacing }) => (rowSpacing ? rowSpacing : 'var(--whitespace-12-to-20)')};
    background: white;
    padding: var(--whitespace-16-to-24);
    background-color: var(--component-wrapper-background);
    border-radius: var(--wrapper-border-radius);
  }
`;
