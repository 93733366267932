import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';

import { AppNameDobGender } from '@/components';
import { MinorDetailsState, clearMinorDetails } from '@/store';
import { useAppSelector } from '@/core';

export const MinorNameDOBGender: FC = (): JSX.Element => {
  const minorDetails = useAppSelector((s) => s.minorDetails);

  const [details, setDetails] = useState(minorDetails);
  const preSelectedMinorDetails = useRef<MinorDetailsState>();

  useEffect(() => {
    if (!preSelectedMinorDetails.current) {
      preSelectedMinorDetails.current = minorDetails;
      setDetails(preSelectedMinorDetails.current);
    }
  }, [minorDetails]);
  const dispatch = useDispatch();

  const onFormClear = useCallback(() => {
    dispatch(clearMinorDetails());
    setDetails(minorDetails);
    preSelectedMinorDetails.current = undefined;
  }, [dispatch, minorDetails]);

  return (
    <AppNameDobGender
      defaultDateOfBirth={details?.dateOfBirth}
      defaultFirstName={details?.firstName}
      defaultGenderOther={details?.genderOther}
      defaultLastName={details?.lastName}
      gender={details.gender}
      id="personal-details-metrics"
      isMinor
      noLastName={minorDetails.noLastName}
      onFormClear={onFormClear}
      showDob={!!preSelectedMinorDetails.current}
      showGender={!!preSelectedMinorDetails.current}
    />
  );
};
