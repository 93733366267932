/* istanbul ignore file */
import { FC, createContext } from 'react';

import { GUID } from '@/core';
import { mockProductId } from '@/tests/mock-data';

type SetMockApi = (newValue: boolean) => void;
type SetMockProductId = (newValue: GUID) => void;

interface IMockApiContext {
  mockCity: boolean;
  mockCountry: boolean;
  mockCustomer: boolean;
  mockGuardiansSetup: boolean;
  mockProductVariant: boolean;
  mockProductId: GUID;
  mockTestPackage: boolean;
  mockSession: boolean;

  setMockCity: SetMockApi;
  setMockCountry: SetMockApi;
  setMockCustomer: SetMockApi;
  setMockGuardians: SetMockApi;
  setMockProductId: SetMockProductId;
  setMockProductVariant: SetMockApi;
  setMockTestPackage: SetMockApi;
  setMockSession: SetMockApi;
}

const defaultMockApiContext: IMockApiContext = {
  mockCity: true,
  mockCountry: true,
  mockCustomer: true,
  mockGuardiansSetup: true,
  mockProductVariant: true,
  mockProductId: mockProductId,
  mockTestPackage: true,
  mockSession: true,

  setMockCity: () => undefined,
  setMockCountry: () => undefined,
  setMockCustomer: () => undefined,
  setMockGuardians: () => undefined,
  setMockProductVariant: () => undefined,
  setMockProductId: () => undefined,
  setMockTestPackage: () => undefined,
  setMockSession: () => undefined,
};

const MockApiContext = createContext<IMockApiContext>(defaultMockApiContext);

interface MockApiContextProviderProps {
  children: React.ReactNode;
  value: IMockApiContext;
}

const MockApiContextProvider: FC<MockApiContextProviderProps> = ({ children, value }) => {
  return <MockApiContext.Provider value={value}>{children}</MockApiContext.Provider>;
};

const defaultMockApiContextForTests: IMockApiContext = {
  mockCity: false,
  mockCountry: false,
  mockCustomer: false,
  mockGuardiansSetup: false,
  mockProductId: mockProductId,
  mockProductVariant: false,
  mockTestPackage: false,
  mockSession: false,

  setMockCity: () => undefined,
  setMockCountry: () => undefined,
  setMockCustomer: () => undefined,
  setMockGuardians: () => undefined,
  setMockProductId: () => undefined,
  setMockProductVariant: () => undefined,
  setMockTestPackage: () => undefined,
  setMockSession: () => undefined,
};

export type { IMockApiContext };
export { MockApiContextProvider, defaultMockApiContextForTests };
