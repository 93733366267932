import { FieldError, FieldErrorsImpl, FieldValues, Merge } from 'react-hook-form';

export const ErrorType = {
  Server: 'server-error',
};

export type ServerValidationError = {
  status?: string;
  data: {
    error?: {
      code?: string;
      message?: string;
      propertyName: {
        index: string;
        value?: string;
      };
    }[];
    isFailure?: boolean;
    isSuccess?: boolean;
  };
};

type FormField<T extends FieldValues> = FieldError | Merge<FieldError, FieldErrorsImpl<T>> | undefined;

export const getFormError = <T extends FieldValues>(field: FormField<T>): string | undefined => {
  return field?.type !== ErrorType.Server ? (field?.message as string) : undefined;
};

export const getFirstFormError = <T extends FieldValues>(...fields: FormField<T>[]): string | undefined => {
  return fields.filter((field) => field?.type !== ErrorType.Server && field?.message).map((field) => field?.message as string)[0];
};

export const getServerError = <T extends FieldValues>(field: FormField<T>): string | undefined => {
  return field?.type === ErrorType.Server ? (field?.message as string) : undefined;
};
