import React, { FC, InputHTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ChevronDown } from '@/icons/ChevronDown';
import { ChevronUp } from '@/icons/ChevronUp';
import {
  ChildComponentProps,
  Choice,
  ComponentWrapper,
  ComponentWrapperProps,
  ConditionalRequiredProps,
  DisabledChangeEvent,
  FormElementProps,
  FormValidateControl,
  LoadingProps,
  createErrorMessage,
  pasteErrorId,
  useErrorMessages,
  useRegisterFormControl,
} from '@/components';
import { Eye } from '@/icons/Eye';
import { EyeOff } from '@/icons/EyeOff';
import { decodeEncodedString, noAction, useDebounce } from '@/core';

import * as S from './TextInput.style';
import { PasswordRequirementTexts, ValidateOptions, useTextInputValidation } from './hooks';

interface TextInputProps extends ComponentWrapperProps {
  clearText?: boolean;
  'data-testid'?: string;
  onValueChange?: TextInputChangeEvent;
  onEnterPressed?: (event: React.KeyboardEvent<HTMLInputElement>, newValue: string) => void;
  onValidate?: TextInputValidated;
  placeholder?: string;
  preventPaste?: boolean;
  preventPasteMessage?: string;
  setText?: string;
}

interface TextInputAllProps {
  defaultText?: string;
  disabledChoiceText?: never;
  emailToMatch?: never;
  inputType: TextInputTextType;
  invalidConfirmEmailMessage?: never;
  invalidEmailMessage?: never;
  max?: never;
  maxCount?: number;
  min?: never;
  onDisabledChange?: never;
  passwordRequirementTexts?: never;
  showDisabledChoice?: never;
  step?: never;
}

interface TextInputConfirmEmailProps extends Omit<TextInputAllProps, 'emailToMatch' | 'inputType' | 'invalidConfirmEmailMessage'> {
  emailToMatch: string;
  inputType: TextInputConfirmEmailType;
  invalidConfirmEmailMessage: string;
  preventPasteMessage: string;
}

interface TextInputEmailProps extends Omit<TextInputAllProps, 'inputType' | 'invalidEmailMessage'> {
  inputType: TextInputEmailType;
  invalidEmailMessage: string;
}

interface TextInputDisabledChoiceProps extends Omit<TextInputAllProps, 'disabledChoiceText' | 'onDisabledChange' | 'showDisabledChoice'> {
  disabledChoiceText: string;
  onDisabledChange?: DisabledChangeEvent;
  showDisabledChoice: true;
}

interface TextInputNumberProps extends Omit<TextInputAllProps, 'inputType' | 'max' | 'min' | 'step'> {
  inputType: TextInputNumberType;
  max?: number;
  min?: number;
  step?: number;
}

interface TextInputPasswordProps extends Omit<TextInputAllProps, 'inputType' | 'passwordRequirementTexts'> {
  inputType: TextInputPasswordType;
  passwordRequirementTexts: PasswordRequirementTexts;
}

interface TextInputTelProps extends Omit<TextInputAllProps, 'inputType'> {
  inputType: TextInputTelType;
}

type ConditionalProps =
  | TextInputAllProps
  | TextInputConfirmEmailProps
  | TextInputEmailProps
  | TextInputDisabledChoiceProps
  | TextInputNumberProps
  | TextInputPasswordProps
  | TextInputTelProps;

export const TextInput: FC<
  ChildComponentProps & ConditionalProps & ConditionalRequiredProps & FormElementProps & InputHTMLAttributes<HTMLInputElement> & LoadingProps & TextInputProps
> = ({
  addBackground,
  'aria-label': ariaLabel = '',
  autoComplete,
  autoFocus,
  className,
  clearText,
  'data-testid': dataTestId,
  debounceWait = 500,
  defaultText = '',
  disabled,
  disabledChoiceText = '',
  emailToMatch,
  invalidConfirmEmailMessage,
  invalidEmailMessage,
  formErrorMessages,
  formErrorSimpleMessage,
  formPreventControlRegistration,
  id,
  inlineErrorMessages,
  isLoading,
  inputType,
  label,
  labelIsHeading,
  loadingText,
  max,
  maxCount = -1, //TODO: ui styling
  min,
  onBlur = noAction,
  onChange = noAction,
  onDisabledChange = noAction,
  onEnterPressed = noAction,
  onValidate = noAction,
  onValueChange = noAction,
  passwordRequirementTexts,
  placeholder = '',
  preventPaste,
  preventPasteMessage = '',
  readOnly,
  required,
  requiredText = '',
  setText = '',
  showDisabledChoice,
  step,
  sublabel,
  ...props
}): JSX.Element => {
  const [characterCount, setCharacterCount] = useState(0);
  const [isRequired, setIsRequired] = useState(!!required);
  const { valuePassesRequiredCheck, internalErrorMessages, internalErrorMessageCount, setInternalErrorMessages } = useErrorMessages(
    isRequired,
    requiredText,
    inlineErrorMessages,
  );
  const [hideDefaultIcon, setHideDefaultIcon] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');
  const debounceInputValue = useDebounce(inputValue, debounceWait);
  const [isDisabled, setIsDisabled] = useState(!!disabled);
  const [numberDecreaseMouseDown, setNumberDecreaseMouseDown] = useState(false);
  const debounceNumberDecreaseMouseDown = useDebounce(numberDecreaseMouseDown, 350);
  const numberDecreaseTimer = useRef<ReturnType<typeof setTimeout>>();
  const [numberIncreaseMouseDown, setNumberIncreaseMouseDown] = useState(false);
  const debounceNumberIncreaseMouseDown = useDebounce(numberIncreaseMouseDown, 350);
  const numberIncreaseTimer = useRef<ReturnType<typeof setTimeout>>();
  const preventPasteError = useMemo(() => createErrorMessage({ id: pasteErrorId, text: preventPasteMessage, type: 'internal' }), [preventPasteMessage]);
  const testIdValue = dataTestId ?? id;
  const validateOptions: ValidateOptions = useMemo(
    () => ({ emailToMatch, passwordRequirementTexts, errorMessages: { invalidConfirmEmailMessage, invalidEmailMessage } }),
    [emailToMatch, invalidConfirmEmailMessage, invalidEmailMessage, passwordRequirementTexts],
  );
  const validateText = useTextInputValidation(inputType, validateOptions);

  const htmlInputType = useMemo(
    () => (inputType === 'confirm-email' ? 'email' : inputType === 'password' && hideDefaultIcon ? 'text' : inputType),
    [hideDefaultIcon, inputType],
  );

  useEffect(() => {
    setInputValue(defaultText);
    setCharacterCount(defaultText.length);
  }, [defaultText]);

  useEffect(() => {
    onValueChange(debounceInputValue);
  }, [debounceInputValue, onValueChange]);

  useEffect(() => {
    if (clearText) setInputValue('');
  }, [clearText]);

  useEffect(() => {
    setIsDisabled(!!disabled);
  }, [disabled]);

  useEffect(() => {
    setIsRequired(!!required);
  }, [required]);

  useEffect(() => {
    if (required) setIsRequired(!isDisabled);
  }, [isDisabled, required]);

  useEffect(() => {
    if (setText) setInputValue(setText);
  }, [setText]);

  useEffect(() => {
    if (['password', 'text'].includes(htmlInputType) && inputType === 'password' && inputRef.current && inputRef.current === document.activeElement) {
      const input = inputRef.current;
      const theEnd = input.value.length;
      input.selectionEnd = theEnd;
      input.selectionStart = theEnd;
    }
  }, [htmlInputType, inputType]);

  const validate = useCallback(
    (newValue: string, ignoreRequiredSet?: boolean): boolean => {
      let result = valuePassesRequiredCheck(newValue, ignoreRequiredSet);
      setInternalErrorMessages((errorMessages) =>
        errorMessages.filter((errorMessage) => errorMessage.type !== 'internal' || (!newValue && errorMessage.id === pasteErrorId)),
      );
      const validationErrorMessages = validateText(newValue);
      if (result && validationErrorMessages.length) result = false;
      (newValue || inputType === 'password') && setInternalErrorMessages((errorMessages) => [...errorMessages, ...validationErrorMessages]);
      onValidate(newValue, result);
      return result;
    },
    [valuePassesRequiredCheck, inputType, onValidate, setInternalErrorMessages, validateText],
  );

  const validateByForm: FormValidateControl = useCallback((): boolean => {
    return validate(inputValue, true);
  }, [inputValue, validate]);

  const { errorId, scrollIntoView, resetScrollIntoView } = useRegisterFormControl(id, !!formPreventControlRegistration, validateByForm);

  useEffect(() => {
    if (internalErrorMessageCount) validate(debounceInputValue);
  }, [debounceInputValue, internalErrorMessageCount, validate]);

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      validate(event.target.value);
      onBlur(event);
    },
    [onBlur, validate],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isDisabled) return;
      const {
        target: { value },
      } = event;
      const currentCount = value.length;
      if (maxCount > -1 && currentCount > maxCount) setInputValue(value.slice(0, maxCount));
      else {
        if (internalErrorMessageCount || inputType === 'password') validate(value);
        setCharacterCount(currentCount);
        setInputValue(value);
        onChange(event);
      }
    },
    [inputType, internalErrorMessageCount, isDisabled, maxCount, onChange, validate],
  );

  const handleDisabledChanged = useCallback(
    (newValue: boolean) => {
      setIsDisabled(newValue);
      if (newValue) setInputValue('');
      onDisabledChange(newValue);
    },
    [onDisabledChange],
  );

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (inputType === 'password') {
        validate(event.target.value);
      }
    },
    [inputType, validate],
  );

  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (!isDisabled && event.key.toUpperCase() === 'ENTER') onEnterPressed(event, inputValue);
    },
    [inputValue, isDisabled, onEnterPressed],
  );

  const handleNumberStep = useCallback(
    (up: boolean) => {
      const _step = step ? (up ? step : 0 - step) : up ? 1 : -1;
      if (up && +inputValue === max) return;
      if (!up && +inputValue === min) return;
      if (min && +inputValue < min) return setInputValue(min.toString());
      if (max && +inputValue > max) return setInputValue(max.toString());
      setInputValue((+inputValue + _step).toString());
    },
    [inputValue, max, min, step],
  );

  useEffect(() => {
    if (debounceNumberDecreaseMouseDown && numberDecreaseMouseDown)
      numberDecreaseTimer.current = setTimeout(() => {
        handleNumberStep(false);
      }, 25);
  }, [handleNumberStep, debounceNumberDecreaseMouseDown, numberDecreaseMouseDown]);

  useEffect(() => {
    if (debounceNumberIncreaseMouseDown && numberIncreaseMouseDown)
      numberIncreaseTimer.current = setTimeout(() => {
        handleNumberStep(true);
      }, 25);
  }, [handleNumberStep, debounceNumberIncreaseMouseDown, numberIncreaseMouseDown]);

  const handleNumberMouseDown = useCallback(
    (chevronUp: boolean) => {
      if (isDisabled) return;
      if (chevronUp) setNumberIncreaseMouseDown(true);
      else setNumberDecreaseMouseDown(true);
    },
    [isDisabled],
  );

  const handleClick = useCallback(
    (chevronUp: boolean) => {
      if (isDisabled) return;
      if (chevronUp) handleNumberStep(true);
      else handleNumberStep(false);
    },
    [handleNumberStep, isDisabled],
  );

  const handleNumberMouseUp = useCallback(
    (chevronUp: boolean) => {
      if (isDisabled) return;
      if (chevronUp) {
        clearTimeout(numberIncreaseTimer.current);
        setNumberIncreaseMouseDown(false);
      } else {
        clearTimeout(numberDecreaseTimer.current);
        setNumberDecreaseMouseDown(false);
      }
    },
    [isDisabled],
  );

  const handlePaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      if (inputType === 'confirm-email' || preventPaste) event.preventDefault();
      if (!isDisabled) setInternalErrorMessages((errorMessages) => [...errorMessages, preventPasteError]);
    },
    [inputType, isDisabled, preventPaste, preventPasteError, setInternalErrorMessages],
  );

  const toggleHidePassword = useCallback(() => {
    inputRef.current?.focus();
    setHideDefaultIcon(!hideDefaultIcon);
  }, [hideDefaultIcon]);

  return (
    <ComponentWrapper
      activeElementForScroll={inputRef}
      addBackground={addBackground}
      className={className}
      {...(errorId && { 'data-errorid': errorId })}
      formErrorMessages={formErrorMessages}
      formErrorSimpleMessage={formErrorSimpleMessage}
      id={`wrapper-${id}`}
      inlineErrorMessages={internalErrorMessages}
      label={label}
      htmlFor={id}
      labelIsHeading={labelIsHeading}
      labelSpacing="--whitespace-04-to-08"
      rowSpacing="--whitespace-04-to-08"
      resetScrollIntoView={resetScrollIntoView}
      scrollIntoView={scrollIntoView}
      sublabel={sublabel}
    >
      <S.TextInputContainer {...(hideDefaultIcon && { className: 'hide-default-icon' })}>
        <S.TextInput
          {...(ariaLabel && !label && { 'aria-label': ariaLabel })}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          {...((formErrorMessages?.length || internalErrorMessages.length) && { className: 'error' })}
          data-testid={testIdValue}
          disabled={isDisabled}
          id={id}
          max={max}
          min={min}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyUp={handleKeyUp}
          onPaste={handlePaste}
          {...(placeholder && { placeholder: placeholder })}
          readOnly={readOnly}
          ref={inputRef}
          step={step}
          type={htmlInputType}
          value={isLoading && loadingText ? loadingText : decodeEncodedString(inputValue)}
          {...props}
        />
        {inputType === 'password' && (
          <>
            <Eye onClick={toggleHidePassword} /> <EyeOff onClick={toggleHidePassword} />
          </>
        )}
        {inputType === 'number' && (
          <>
            <ChevronUp
              index={`${id}-chevron-up`}
              onClick={() => handleClick(true)}
              onMouseDown={() => handleNumberMouseDown(true)}
              onMouseOut={() => handleNumberMouseUp(true)}
              onMouseUp={() => handleNumberMouseUp(true)}
            />{' '}
            <ChevronDown
              index={`${id}-chevron-down`}
              onClick={() => handleClick(false)}
              onMouseDown={() => handleNumberMouseDown(false)}
              onMouseOut={() => handleNumberMouseUp(false)}
              onMouseUp={() => handleNumberMouseUp(false)}
            />
          </>
        )}
        {showDisabledChoice && (
          <Choice
            defaultChecked={disabled}
            formPreventControlRegistration
            id={`${id}-disable`}
            label={disabledChoiceText}
            name={`${id}-disable`}
            onValueChange={handleDisabledChanged}
            type="checkbox"
          />
        )}
      </S.TextInputContainer>
      {maxCount > -1 && <span id={`count-${id}`}>{maxCount > 0 ? `${characterCount}/${maxCount}` : ''}</span>}
    </ComponentWrapper>
  );
};

export type TextInputAllTextType = TextInputConfirmEmailType | TextInputEmailType | TextInputPasswordType | TextInputTextType;
export type TextInputChangeEvent = (newValue: string) => void;
export type TextInputConfirmEmailType = 'confirm-email';
export type TextInputEmailType = 'email';
export type TextInputNumberType = 'number';
export type TextInputPasswordType = 'password';
export type TextInputTelType = 'tel';
export type TextInputTextType = 'text';
export type TextInputType = TextInputAllTextType | TextInputNumberType | TextInputTelType;
export type TextInputValidated = (newValue: string, valid: boolean) => boolean;
