import { css } from '@emotion/react';

import { breakpoints } from '../utils';

export const componentColors = css`
  .themed {
    --a-link-color: var(--dark-blue);

    --alert-color: var(--indigo);
    --alert-error: rgba(var(--rgb-light-red), 0.5);
    --alert-information: rgba(var(--rgb-light-blue), 0.5);
    --alert-success: rgba(var(--rgb-dark-green), 0.2);
    --alert-warning: rgba(var(--rgb-light-yellow), 0.5);

    --alert-popup-error: rgba(var(--rgb-light-red));
    --alert-popup-information: var(--white);
    --alert-popup-success: rgba(var(--rgb-dark-green));
    --alert-popup-warning: rgba(var(--rgb-light-yellow));

    --box-shadow-responsive: none;

    --button-primary-background: var(--dark-blue);
    --button-primary-color: var(--white);
    --button-primary-background-hover: var(--darker-blue);
    --button-secondary-background: var(--white);
    --button-secondary-color: var(--dark-blue);
    --button-secondary-background-hover: var(--lightest-grey);

    --calendar-accent-color: rgb(var(--rgb-dark-blue), 0.7);
    --calendar-background-color: var(--white);
    --calendar-border-color: var(--light-grey);
    --calendar-border-color-hover: var(--dark-blue);
    --calendar-disabled-color: var(--light-grey);
    --calendar-nav-button-color: var(--dark-blue);
    --calendar-non-session-color: var(--core-grey);
    --calendar-selected-background-hover: rgba(var(--rgb-dark-blue), 0.6);
    --calendar-selected-color: var(--white);
    --calendar-session-color: var(--black);
    --calendar-start-end-background-hover: var(--darker-blue);
    --calendar-start-end-color: var(--dark-blue);
    --calendar-today-background: rgba(var(--rgb-light-grey), 0.32);
    --calendar-today-color: var(--background-color-inverted);

    --card-background: var(--background-color);
    --card-title-background: var(--lighter-grey);
    --card-title-color: var(--heading-color);

    --choice-center-background: var(--background-color);
    --choice-center-error-background: var(--dark-red);
    --choice-error-background: rgba(var(--rgb-dark-red), 0.1);
    --choice-error-border: var(--dark-red);
    --choice-normal-background: rgba(var(--rgb-light-grey), 0.1);
    --choice-normal-border: var(--border-color);
    --choice-checked-background: rgba(var(--rgb-dark-blue), 0.1);
    --choice-checked-border: var(--dark-blue);
    --choice-checked-color: var(--dark-blue);

    --drill-card-cta-icon: var(--dark-blue);

    --crumb-color: var(--dark-blue);

    --component-background: var(--background-color);
    --component-wrapper-background: var(--lighter-grey);
    --component-wrapper-background-for-desktop: var(--white);
    --component-wrapper-color: var(--black-text);

    --error-message-color: var(--dark-red);

    --heading-color: var(--indigo);

    --menu-color-hover: var(--light-blue);
    --menu-item-focus-color: var(--indigo);

    --select-close-background: var(--dark-blue);
    --select-option-list-background: var(--white);
    --select-option-list-item-active-background: var(--dark-blue);

    --skip-background: var(--background-color-inverted);
    --skip-background-inverted: var(--background-color);
    --skip-color: var(--color-inverted);
    --skip-color-inverted: var(--color);

    --tab-link-color: var(--dark-grey);
    --tab-link-color-active: var(--dark-blue);
    --tab-link-border-color: var(--light-grey);
    --tab-link-border-color-active: var(--dark-blue);
    ${breakpoints.isTablet.mediaMinWidth} {
      --box-shadow-responsive: 0px 1px 4px rgba(84, 86, 90, 0.24);
      --component-wrapper-background-for-desktop: var(--component-wrapper-background);
    }
  }
`;
