import { FC } from 'react';

import { ReviewGrid, ReviewGridRow } from '@/components';

interface ReviewContactDetailsProps {
  addressFirstLine: string;
  addressLabel?: string;
  addressSecondLine: string;
  countryName: string;
  email?: string;
  emailLabel?: string;
  hideAddress?: boolean;
  hideEmail?: boolean;
  hideTelephone?: boolean;
  mobileNumber: string;
  mobileNumberLabel?: string;
  townCity: string;
  zipCode: string;
}

export const ReviewContactDetails: FC<ReviewContactDetailsProps> = ({
  addressFirstLine,
  addressLabel,
  addressSecondLine,
  countryName,
  email,
  emailLabel,
  hideAddress,
  hideEmail,
  hideTelephone,
  mobileNumber,
  mobileNumberLabel,
  townCity,
  zipCode,
}): JSX.Element => {
  return (
    <ReviewGrid>
      {emailLabel && !hideEmail && (
        <ReviewGridRow id="review-email-label" label={emailLabel}>
          {email}
        </ReviewGridRow>
      )}
      {mobileNumberLabel && !hideTelephone && (
        <ReviewGridRow id="mobile-number-label" label={mobileNumberLabel}>
          {mobileNumber}
        </ReviewGridRow>
      )}
      {addressLabel && !hideAddress && (
        <ReviewGridRow id="address-label" label={addressLabel}>
          {addressFirstLine}
          <br />
          {addressSecondLine && addressSecondLine}
          {addressSecondLine && <br />}
          {townCity}
          <br />
          {countryName}
          <br />
          {zipCode}
        </ReviewGridRow>
      )}
    </ReviewGrid>
  );
};
