import { useCallback, useEffect } from 'react';

import { FieldPath, FieldValues, UseFormSetError } from 'react-hook-form';

import { ErrorType, ServerValidationError } from './FormError';

export type ValidationPropertyMapping<T extends FieldValues> = {
  serverProperty: string;
  formProperty: FieldPath<T>;
};

type FormErrorProps<T extends FieldValues> = {
  error: ServerValidationError;
  setError: UseFormSetError<T>;
  propertyMapping?: ValidationPropertyMapping<T>[];
};

export const useFormError = <T extends FieldValues>({ error, setError, propertyMapping }: FormErrorProps<T>) => {
  const mapPropertyName = useCallback(
    (serverPropertyName: string): FieldPath<T> => {
      const formProp = propertyMapping?.find((p) => p.serverProperty === serverPropertyName)?.formProperty;
      return (formProp as FieldPath<T>) ?? serverPropertyName;
    },
    [propertyMapping],
  );

  useEffect(() => {
    const validationError = error as ServerValidationError;
    if (validationError && validationError?.data?.error !== undefined && validationError?.data?.error?.length > 0) {
      validationError.data.error?.map((data) => {
        const message = data.message;
        const property = data.propertyName.value;
        if (property && message) {
          setError(mapPropertyName(property), {
            type: ErrorType.Server,
            message: message,
          });
        }
      });
    }
  }, [error, mapPropertyName, setError]);
};
