import { FC } from 'react';

import { IconProps } from '@/icons/IconProps';
import { noAction } from '@/core';

export const Eye: FC<IconProps> = ({ index = 0, onClick = noAction }) => {
  return (
    <svg
      aria-hidden="true"
      aria-labelledby={`eye-icon-title-${index}`}
      className="eye eye-icon"
      data-testid={`eye-icon-${index}`}
      fill="none"
      focusable="false"
      onClick={onClick}
      role="img"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`eye-icon-title-${index}`}></title>
      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
      <circle cx="12" cy="12" r="3" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></circle>
    </svg>
  );
};
