import { PropsWithChildren, useEffect, useState } from 'react';

import { ErrorPage } from '@/pages/errors/ErrorPage';
import { AppSettings, AppSettingsContext, localAppSettings } from './AppSettingsContext';

export enum AppSettingsStatus {
  loading = 'loading',
  loaded = 'loaded',
  error = 'error',
}

export const loadAppSettings = async (): Promise<AppSettings> => {
  if (process.env.NODE_ENV === 'production') {
    const response = await fetch(`/appsettings.json`);
    const json = await response.json();
    const appSettings = json as unknown as AppSettings;
    return appSettings;
  }

  return localAppSettings;
};

export const AppSettingsProvider = ({ children }: PropsWithChildren) => {
  const [appSettings, setAppSettings] = useState({
    status: AppSettingsStatus.loading,
    settings: localAppSettings,
  });

  const loadRemote = async () => {
    try {
      const settings = await loadAppSettings();
      setAppSettings({
        status: AppSettingsStatus.loaded,
        settings: settings,
      });
    } catch (error) {
      console.log('error', error);
      setAppSettings({
        status: AppSettingsStatus.error,
        settings: localAppSettings,
      });
    }
  };

  useEffect(() => {
    loadRemote();
  }, []);

  return (
    <AppSettingsContext.Provider value={appSettings.settings}>
      {appSettings.status == AppSettingsStatus.loading && <div className="flex items-center h-full justify-center"> Loading ...</div>}
      {appSettings.status == AppSettingsStatus.loaded && children}
      {appSettings.status == AppSettingsStatus.error && <ErrorPage />}
    </AppSettingsContext.Provider>
  );
};
