import { useCallback, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { SelectBaseOptionReg, SelectReg } from '@britishcouncil/react-registration-platform';

import { useCustomerGetCustomerMinorsQuery, useIdentityGetLoggedInCustomerDetailsQuery } from '@/store/webApi';
import { CustomerSelectPayload as SelectedCustomer } from '@/components';

interface CustomerSelectProps {
  title: string;
  customerChange: (personData: CustomerSelectPayload) => void;
  isDisabled?: boolean;
  selectCustomer: SelectedCustomer | null | undefined
}

export type CustomerSelectPayload = SelectBaseOptionReg & {
  value: string;
  customerType: 'minor' | 'adult';
};

export const CustomerSelect = ({ title, customerChange, isDisabled, selectCustomer }: CustomerSelectProps) => {
  const { t } = useTranslation();

  const { data: customerData, isSuccess: isCustomerFetched, isLoading: isCustomerLoading } = useIdentityGetLoggedInCustomerDetailsQuery();
  const {
    data: minorsData,
    isSuccess: areMinorsFetched,
    isLoading: areMinorsLoading,
    isUninitialized: areMinorsUninitialized,
  } = useCustomerGetCustomerMinorsQuery();

  const selectOptions = useMemo((): Array<CustomerSelectPayload> => {
    const customer: CustomerSelectPayload | null = customerData
      ? {
          label: `${customerData?.name} ${customerData?.lastName}`,
          value: minorsData?.customerId ?? '',
          customerType: 'adult',
        }
      : null;

    const minors: Array<CustomerSelectPayload> =
      minorsData?.minors?.map((x) => ({
        label: `${x.firstName} ${x.lastName}`,
        value: x.customerId ?? '',
        customerType: 'minor',
      })) ?? [];

    return customer ? [customer, ...minors] : [...minors];
  }, [customerData, minorsData]);

  const handlePersonChange = useCallback(
    (customer: CustomerSelectPayload | null) => {
      if (customer) {
        customerChange(customer);
      }
    },
    [customerChange],
  );

  useEffect(() => {
    if (selectOptions && isCustomerFetched && areMinorsFetched && selectOptions.length > 0) {
      customerChange(selectOptions[0]);
    }
  }, [isCustomerFetched, areMinorsFetched, selectOptions, customerChange]);

  return (
    <section className="lg:w-1/3">
      <p>{title}</p>
      <SelectReg
        name="person-select"
        onChange={handlePersonChange}
        value={selectCustomer}
        options={selectOptions}
        disabled={isDisabled}
        isLoading={isCustomerLoading || areMinorsLoading || areMinorsUninitialized}
        loadingMessage={() => t('common.loading')}
        id="dashboard-person-select"
      />
    </section>
  );
};
