import { AuthGuard, Loading } from '@/components';
import { useFeatureFlags } from '@/core';

import { IdentificationLegacy } from './legacy/IdentificationLegacy';
import { IdentificationPage } from './IdentificationPage';

export const Identification = () => {
  const { data, isLoading } = useFeatureFlags();

  if (isLoading) {
    return <Loading />;
  }

  // Temporary solution while we are working on introducing identity authentication, this will allow us to backward compatibility until full implementation.
  return data?.identityIntegrationFeature ? (
    <AuthGuard>
      <IdentificationPage />
    </AuthGuard>
  ) : (
    <IdentificationLegacy />
  );
};
