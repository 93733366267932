import { useMemo } from 'react';

import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { WellReg } from '@britishcouncil/react-registration-platform';

import { AlertReg } from '@/components/presentation/alerts/AlertReg';
import { Country, getDateStringLong } from '@/core';
import { CustomerSelectPayload, Loading } from '@/components';
import { IdentityDocument } from '@/store/webApi';
import { webApi } from '@/store/webApi/enhancedWebApi';

type CustomerData = {
  customerType: 'adult' | 'minor';
  fullName: string;
  dateOfBirth: string;
  email?: string;
  mobilePhone?: string;
  address?: {
    firstLine?: string;
    secondLine?: string;
    city?: string;
    country?: string;
  };
  identityDocument?: {
    documentNumber?: string;
    expiryDate?: string;
    issuingAuthority?: string;
    issuingCountry?: string;
  };
};

const formatDate = (d?: string) => (d ? getDateStringLong(new Date(d)) : '');
const formatFullName = (firstName?: string | null, middleName?: string | null, lastName?: string | null) =>
  [firstName, middleName, lastName].filter((n) => !!n).join(' ');
const formatIdentityDocument = (doc?: IdentityDocument | null, countries?: Country[]) =>
  doc && !isEmpty(doc)
    ? {
        documentNumber: doc.documentNumber ?? undefined,
        expiryDate: formatDate(doc.expiryDate ?? undefined),
        issuingAuthority: doc.issuingAuthority ?? undefined,
        issuingCountry: countries?.find((c) => c.id === doc.issuingCountryId)?.name ?? undefined,
      }
    : undefined;
const isEmpty = <T,>(obj: { [s: string]: T } | ArrayLike<T>) => Object.values(obj).every((x) => x === null || x === '');

const Field = ({ labelKey, value, isOptional = true }: { labelKey: string; value?: string; isOptional?: boolean }) => {
  const { t } = useTranslation();

  return (
    (value || !isOptional) && (
      <div key={labelKey} className="flex flex-col gap-2">
        <p className="text-xs font-normal">{t(`dashboard.customer-data-fields.${labelKey}`)}</p>
        <p className="font-bold">{value ? value : '-'}</p>
      </div>
    )
  );
};

export const CustomerData = () => {
  const { t } = useTranslation();
  const selectedCustomer = useOutletContext<CustomerSelectPayload>();

  const { data: countriesData, isLoading: isCountriesDataLoading } = webApi.useCountryGetAllQuery();
  const { data: adultData, isLoading: isAdultDataLoading } = webApi.useCustomerGetCustomerDetailsQuery(undefined, {
    skip: selectedCustomer?.customerType !== 'adult',
  });
  const { data: minorsData, isLoading: isMinorsDataLoading } = webApi.useCustomerGetMinorDetailsQuery(
    { id: selectedCustomer?.value ?? '' },
    { skip: selectedCustomer?.customerType !== 'minor' },
  );
  const isLoading = useMemo(
    () => isAdultDataLoading || isMinorsDataLoading || isCountriesDataLoading,
    [isAdultDataLoading, isMinorsDataLoading, isCountriesDataLoading],
  );

  const data: CustomerData | undefined = useMemo(() => {
    if (selectedCustomer?.customerType === 'minor' && minorsData) {
      return {
        customerType: 'minor',
        fullName: formatFullName(minorsData.firstName, minorsData.middleName, minorsData.lastName),
        dateOfBirth: formatDate(minorsData.dateOfBirth),
        identityDocument: formatIdentityDocument(minorsData.identityDocument, countriesData as Country[]),
      };
    }

    if (selectedCustomer?.customerType === 'adult' && adultData) {
      return {
        customerType: 'adult',
        fullName: formatFullName(adultData.firstName, adultData.middleName, adultData.lastName),
        dateOfBirth: formatDate(adultData.dateOfBirth),
        email: adultData.email ?? undefined,
        mobilePhone: adultData.mobileNumber ?? undefined,
        address:
          adultData?.address && !isEmpty(adultData.address)
            ? {
                firstLine: adultData.address.addressLine1 ?? undefined,
                secondLine: adultData.address.addressLine2 ?? undefined,
                city: [adultData.address.postCode, adultData.address.city].filter((x) => !!x).join(' '),
                country: countriesData?.find((c) => c.id === adultData.address?.residenceCountryId)?.name ?? undefined,
              }
            : undefined,
        identityDocument: formatIdentityDocument(adultData.identityDocument, countriesData as Country[]),
      };
    }
    return undefined;
  }, [adultData, minorsData, countriesData, selectedCustomer]);

  if(isLoading){
    return (
      <WellReg className="mt-6">
        <Loading />
      </WellReg>
    )
  }

  return (
    <WellReg className="mt-6">
      {!selectedCustomer ? (
        <p className="font-bold text-lg text-center">{t('dashboard.no-data')}</p>
      ) : data ? (
        <>
          <div className="flex flex-col md:flex-row gap-2">
            <div className="md:w-1/2">
              <section className="text-indigo text-xl font-bold">
                {t(`dashboard.customer-data-fields.${data.customerType === 'adult' ? 'your-details' : 'your-childs-details'}`)}
              </section>

              <section className="flex flex-col gap-4 justify-between my-4">
                <Field labelKey="full-name" value={data.fullName} isOptional={false} />
                <Field labelKey="date-of-birth" value={data.dateOfBirth} isOptional={false} />
                <Field labelKey="email" value={data.email} />
                <Field labelKey="mobile-phone" value={data.mobilePhone} />
                {data.address && (
                  <div className="flex flex-col gap-2">
                    <p className="text-xs font-normal">{t('dashboard.customer-data-fields.address')}</p>
                    <p className="font-bold">{data.address.firstLine ?? '-'}</p>
                    {data.address.secondLine && <p className="font-bold">{data.address.secondLine}</p>}
                    {data.address.city && <p className="font-bold">{data.address.city}</p>}
                    {data.address.country && <p className="font-bold">{data.address.country}</p>}
                  </div>
                )}
              </section>
            </div>
            {data.identityDocument && (
              <div className="md:w-1/2">
                <section className="text-indigo text-xl font-bold">
                  {t(`dashboard.customer-data-fields.${data.customerType === 'adult' ? 'your-identification' : 'your-childs-identification'}`)}
                </section>

                <section className="flex flex-col gap-4 justify-between my-4">
                  <Field labelKey="passport-number" value={data.identityDocument.documentNumber} />
                  <Field labelKey="passport-expiry-date" value={data.identityDocument.expiryDate} />
                  <Field labelKey="issuing-authority" value={data.identityDocument.issuingAuthority} />
                  <Field labelKey="issuing-country" value={data.identityDocument.issuingCountry} />
                </section>
              </div>
            )}
          </div>
          {data.customerType === 'minor' && (
            <AlertReg variant="warning" visible title={t('common.please-note')} content={t('dashboard.alerts.parent-consent')} />
          )}
        </>
      ) : null}
    </WellReg>
  );
};
