import { useTranslation } from 'react-i18next';

import { SelectField } from '@britishcouncil/react-registration-platform';

import { searchDetailsSlice } from '@/store';
import { useAppDispatch, useAppSelector } from '@/core';
import { useProductConfig } from '@/core/hooks/useProductConfig';
import { useSessionGetByCountryForSessionsQuery } from '@/store/webApi';

export const ChooseSessionCity = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { productConfig } = useProductConfig();

  const searchDetails = useAppSelector((s) => s.searchDetails);

  const {
    data = [],
    error,
    isLoading,
  } = useSessionGetByCountryForSessionsQuery(
    {
      countryId: searchDetails?.country?.id ?? -1,
      productCode: productConfig?.productCode ?? '',
      productVariantId: searchDetails?.productVariant?.id ?? '',
      testPackageId: searchDetails?.testPackage?.id ?? '',
    },
    { skip: !searchDetails?.testPackage?.id },
  );

  const options = data.map((city) => ({
    value: city.id ?? 0,
    label: city.name ?? '',
    countryId: city.countryId ?? 0,
  }));

  const setSelectedCity = (city: (typeof options)[0] | null) => {
    city &&
      dispatch(
        searchDetailsSlice.actions.setCity({
          countryId: city.countryId ?? 0,
          id: city.value ?? 0,
          name: city.label ?? '',
        }),
      );
  };

  // Needs to use any as Select from reg. platform is using incorrect types and not allow to use null.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value: any = options.find((el) => el.value === searchDetails?.city?.id) || null;

  return (
    <div>
      <SelectField
        errorMessage={error ? 'Cannot load cities' : undefined}
        headerMessage={t('book-test.choose-city')}
        selectProps={{
          value,
          options,
          isMulti: false,
          onChange: setSelectedCity,
          placeholder: isLoading ? 'Loading...' : t('book-test.search-by-city'),
          isDisabled: isLoading || !searchDetails.testPackage?.id,
        }}
        className="mb-[16px]"
        testId="cities"
      />
    </div>
  );
};
