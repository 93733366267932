/* istanbul ignore file */
import { Venue } from '@/core';

type venueName = 'acoruna01' | 'barcelona01' | 'barcelona02' | 'madrid01' | 'madrid02' | 'seville01' | 'seville02' | 'seville03' | 'valencia01';

export const venues: Record<venueName, Venue> = {
  barcelona01: {
    id: '1',
    address: 'Placa de la Rosa dels Vents, 1 Final Passeig de Joan de Borbo, 08039',
    city: 'Barcelona',
    name: 'W Barcelona',
  },
  barcelona02: {
    id: '2',
    address: 'Passeig del Taulat, 278, 08019',
    city: 'Barcelona',
    name: 'AC Barcelona Forum',
  },
  madrid01: {
    id: '3',
    address: 'Calle de Echegaray, 6, 28014',
    city: 'Madrid',
    name: 'Training center CINPAA',
  },
  madrid02: {
    id: '4',
    address: 'C/ de Bárbara de Braganza, 11, 2º, 28004',
    city: 'Madrid',
    name: 'Alquiler Aulas Madrid',
  },
  seville01: {
    id: '5',
    address: 'Av. Álvaro Alonso Barba, s/n, 41092',
    city: 'Sevilla',
    name: 'Barceló Sevilla Renacimiento',
  },
  seville02: {
    id: '6',
    address: 'Av. la Aeronáutica, 41020',
    city: 'Sevilla',
    name: 'Hotel Vértice Sevilla',
  },
  seville03: {
    id: '7',
    address: 'Torneo Parque Empresarial, Av Ingeniería, 11, 41015',
    city: 'Sevilla',
    name: 'Hilton Garden Inn Sevilla',
  },
  valencia01: {
    id: '8',
    address: 'Carrer de Agustí Alaman i Rodrigo, 12, 46022',
    city: 'Valencia',
    name: 'HEAd',
  },
  acoruna01: {
    id: '9',
    address: 'Avda. Pedro Barrie de la Maza, 1, 15003',
    city: 'A Coruña',
    name: 'Melia Maria Pita',
  },
};
