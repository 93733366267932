import { FC, useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, ButtonStyle, ButtonsWrapper, DateInputValidated, NameDobGender, OuterWrapper } from '@/components';
import { GenderType } from '@/store/webApi';
import {
  personalDetailsSlice,
  setMinorDateOfBirth,
  setMinorFirstName,
  setMinorGender,
  setMinorGenderOther,
  setMinorLastName,
  setMinorNoLastName,
} from '@/store';

interface AppNameDobGenderProps {
  defaultDateOfBirth?: string;
  defaultFirstName?: string;
  defaultGenderOther?: string;
  defaultLastName?: string;
  gender?: GenderType;
  id: string;
  isMinor?: boolean;
  noLastName: boolean;
  onFormClear?: () => void;
  showDob: boolean;
  showGender: boolean;
}

export const AppNameDobGender: FC<AppNameDobGenderProps> = ({
  defaultDateOfBirth,
  defaultFirstName,
  defaultGenderOther,
  defaultLastName,
  gender,
  id,
  isMinor,
  noLastName,
  onFormClear,
  showDob,
  showGender,
}): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation('');

  const onDateOfBirthChange = useCallback(
    (newValue: Date) => {
      const dob = `${newValue.getFullYear()}-${(newValue.getMonth() + 1).toString().padStart(2, '0')}-${newValue.getDate().toString().padStart(2, '0')}`;
      dispatch(isMinor ? setMinorDateOfBirth(dob) : personalDetailsSlice.actions.setDateOfBirth(dob));
    },
    [dispatch, isMinor],
  );

  const onDateOfBirthValidate: DateInputValidated = useCallback(
    ({ valid }) => {
      if (!valid) dispatch(isMinor ? setMinorDateOfBirth('') : personalDetailsSlice.actions.setDateOfBirth(''));
    },
    [dispatch, isMinor],
  );

  const onFirstNameChange = useCallback(
    (newValue: string) => {
      dispatch(isMinor ? setMinorFirstName(newValue) : personalDetailsSlice.actions.setFirstName(newValue));
    },
    [dispatch, isMinor],
  );

  const onGenderOtherChange = useCallback(
    (newValue: string) => {
      dispatch(isMinor ? setMinorGenderOther(newValue) : personalDetailsSlice.actions.setGenderOther(newValue));
    },
    [dispatch, isMinor],
  );

  const onGenderChange = useCallback(
    (newValue: string) => {
      dispatch(isMinor ? setMinorGender(Number(newValue) as GenderType) : personalDetailsSlice.actions.setGender(Number(newValue) as GenderType));
    },
    [dispatch, isMinor],
  );

  const onNoLastNameChange = useCallback(
    (newValue: boolean) => {
      dispatch(isMinor ? setMinorNoLastName(newValue) : personalDetailsSlice.actions.setNoLastName(newValue));
    },
    [dispatch, isMinor],
  );

  const onLastNameChange = useCallback(
    (newValue: string) => {
      dispatch(isMinor ? setMinorLastName(newValue) : personalDetailsSlice.actions.setLastName(newValue));
    },
    [dispatch, isMinor],
  );

  return (
    <OuterWrapper>
      <NameDobGender
        addBackground
        dateOfBirthTooOldMessage={isMinor ? t('minor-details.about-your-child.dob-adult') : t('personal-details.about-you.dob-too-early')}
        dateOfBirthTooYoungMessage={isMinor ? t('minor-details.about-your-child.dob-child') : t('personal-details.about-you.dob-minor')}
        dateOfBirthLabel={t('common.name-dob-sex.date-of-birth')}
        defaultDateOfBirth={defaultDateOfBirth ? new Date(defaultDateOfBirth) : undefined}
        defaultFirstName={defaultFirstName}
        defaultGenderOther={defaultGenderOther}
        defaultLastName={defaultLastName}
        femaleText={t('common.name-dob-sex.sex.female')}
        firstNameLabel={t('common.name-dob-sex.first-name')}
        firstNameRequiredText={t('common.required')}
        firstNameSubLabel={t('common.name-dob-sex.first-name-sublabel')}
        gender={gender}
        genderLabel={t('common.name-dob-sex.sex.title')}
        genderRequiredText={t('common.required')}
        invalidDateMessage={t('components.date-input.invalid-date-message')}
        id={id}
        isMinor={!!isMinor}
        lastNameDisabled={noLastName}
        lastNameLabel={t('common.name-dob-sex.last-name')}
        lastNameRequiredText={t('common.required')}
        maleText={t('common.name-dob-sex.sex.male')}
        noLastNameText={isMinor ? t('common.name-dob-sex.they-no-last-name') : t('common.name-dob-sex.no-last-name')}
        onDateOfBirthChange={onDateOfBirthChange}
        onDateOfBirthValidate={onDateOfBirthValidate}
        onGenderOtherChange={onGenderOtherChange}
        onFirstNameChange={onFirstNameChange}
        onGenderChange={onGenderChange}
        onLastNameChange={onLastNameChange}
        onNoLastNameChange={onNoLastNameChange}
        placeholderDay={t('components.date-input.day-placeholder')}
        placeholderMonth={t('components.date-input.month-placeholder')}
        placeholderYear={t('components.date-input.year-placeholder')}
        showDob={showDob}
        showGender={showGender}
        whiteBackground
      />
      {onFormClear && (
        <ButtonsWrapper>
          <Button onClick={onFormClear} buttonStyle={ButtonStyle.DiscreteRed} text={t('common.clear-form')} />
        </ButtonsWrapper>
      )}
    </OuterWrapper>
  );
};
