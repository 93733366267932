import { useEffect } from 'react';

import { FiChevronLeft, FiChevronRight, FiLoader } from 'react-icons/fi';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from '@britishcouncil/react-registration-platform';

import { AlertReg } from '@/components/presentation/alerts/AlertReg';
import { ButtonsWrapper, FlowIndicator, FormSection, FormSectionTitle, PageFormWrapper, PageTitle, Step } from '@/components';
import { FlowSteps, isMobileTrack, useAppDispatch } from '@/core';
import { journeySlice } from '@/store';
import { webApi } from '@/store/webApi/enhancedWebApi';

import { MinorPickupForm } from './components/MinorPickupForm';
import { PersonalDetails, PersonalDetailsInputs } from './components/PersonalDetails';
import { useNextStep } from './components/useNextStep';

export const MinorDetailsPage = () => {
  const { t } = useTranslation('');
  const { t: tRoutes } = useTranslation('routes');

  const dispatch = useAppDispatch();
  const [createMinor, createMutation] = webApi.useCustomerCreateMinorMutation();
  const [updateMinor, updateMutation] = webApi.useCustomerUpdateMinorPersonalDataMutation();

  const navigate = useNavigate();

  const { displayCurrentStep, goToNextStep, currentStep, returnToReviewFrom, totalSteps } = useNextStep();

  const methods = useForm<PersonalDetailsInputs>({
    mode: 'onTouched',
    criteriaMode: 'all',
  });

  const onSubmit: SubmitHandler<PersonalDetailsInputs> = async ({ dateOfBirth: dob, ...rest }) => {
    const dateOfBirth = new Date(Date.UTC(dob?.year ?? 0, dob?.month, dob?.day)).toISOString().split('T')[0];

    if (!rest.id || rest.id === 'new') {
      return createMinor({
        createMinorRequest: {
          ...rest,
          dateOfBirth,
        },
      });
    }

    updateMinor({
      id: rest.id,
      updateMinorPersonalDetailsRequest: {
        ...rest,
        dateOfBirth,
      },
    });
  };

  useEffect(() => {
    if (createMutation.isSuccess || updateMutation.isSuccess) {
      dispatch(journeySlice.actions.setMinor(createMutation.data || methods.getValues('id')));
      goToNextStep({});
    }
  }, [createMutation.data, createMutation.isSuccess, dispatch, goToNextStep, methods, updateMutation.isSuccess]);

  return (
    <div className="minor-details">
      <header className="header">
        <FlowIndicator
          currentFormStep={displayCurrentStep}
          currentStep={FlowSteps.sessionSelectionFinished}
          formSteps={totalSteps}
          steps={FlowSteps.totalStepsForSessionSelection}
        ></FlowIndicator>
        {isMobileTrack(window.innerWidth) && (
          <Step bottomMargin={'var(--whitespace-basic-08)'}>
            {t('general-step', {
              stepNumber: `${displayCurrentStep}`,
              stepsTotal: `${totalSteps}`,
            })}
          </Step>
        )}
        <PageTitle hideOn="mobile" marginBottom={'var(--whitespace-32-to-08)'} text={tRoutes('book-new-test.minor-details')} />
        {!isMobileTrack(window.innerWidth) && currentStep === '0' && (
          <Step bottomMargin={'var(--whitespace-32-to-72)'}>
            {t('general-step', {
              stepNumber: `${displayCurrentStep}`,
              stepsTotal: `${totalSteps}`,
            })}
          </Step>
        )}
      </header>
      <PageFormWrapper>
        <FormProvider {...methods}>
          <form className="flex flex-col gap-12" onSubmit={methods.handleSubmit(onSubmit)}>
            {(currentStep === FlowSteps.flowStepForDesktopPath || currentStep === FlowSteps.flowStepForMobilePath) && (
              <FormSection>
                <FormSectionTitle>{t('minor-details.about-your-child.title')}</FormSectionTitle>
                <AlertReg variant="information" title={t('minor-details.about-your-child.note-clarify')} content={t('minor-details.about-your-child.note')} />
                <PersonalDetails />
              </FormSection>
            )}
            {(currentStep === FlowSteps.flowStepForDesktopPath || currentStep === '2') && (
              <FormSection>
                <FormSectionTitle>{t('minor-details.pickup.title')}</FormSectionTitle>
                <AlertReg variant="information" title={t('minor-details.pickup.warning-title')} content={t('minor-details.pickup.warning')} />
                <MinorPickupForm />
              </FormSection>
            )}
            <ButtonsWrapper>
              <Button variant="secondary" onClick={() => navigate(-1)}>
                <FiChevronLeft className="text-[20px] relative top-[-1px] mr-[10px] hidden md:block" /> {t('components.back-button')}
              </Button>
              <Button type="submit" disabled={methods.formState.isSubmitting} className="w-full md:w-40 ">
                {t(returnToReviewFrom ? 'tests-and-results.upcoming.save-and-review' : 'minor-details.cta')}
                {methods.formState.isSubmitting ? (
                  <FiLoader className="text-[20px] relative top-[-2px] ml-[10px] animate-spin-slow inline-block"></FiLoader>
                ) : (
                  <FiChevronRight className="text-[20px] relative top-[-1px] ml-[10px] hidden md:block" />
                )}
              </Button>
            </ButtonsWrapper>
          </form>

          <AlertReg visible={createMutation.isError || updateMutation.isError} variant="error" title={'Error'} content={t('minor-details.errors.save')} />
        </FormProvider>
      </PageFormWrapper>
    </div>
  );
};
