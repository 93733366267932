import { atSign, optional, or, zeroOrMore } from './common';

const disallowedCharacters = '<>()[\\].,;:\\s@£"';
const disallowedCharacterSet = `[^${disallowedCharacters}]`;

const unquotedLocalPart = `(${disallowedCharacterSet}+(\\.${disallowedCharacterSet}+)*)`;
const quotedLocalPart = '(".+")';
const localPart = `(${unquotedLocalPart}${or}${quotedLocalPart})`;

const domainAsIPAddress = '([[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])';
const disallowedDomainCharacters = `!$&*=\\\\^\`|~#%‘+/?_{}${disallowedCharacters}`;
const disallowedDomainCharacterSet = `[^${disallowedDomainCharacters}]`;
const disallowedDomainStartingCharacters = `\\-${disallowedDomainCharacters}`;
const disallowedDomainStartEndingCharacterSet = `[^${disallowedDomainStartingCharacters}]`;
const secondLevelDomain = `${disallowedDomainStartEndingCharacterSet}(${disallowedDomainCharacterSet}${zeroOrMore}${disallowedDomainStartEndingCharacterSet})${optional}`;
const multipleSecondLevelDomain = `\\.${secondLevelDomain}`;
const secondLevelDomains = `(${secondLevelDomain})(${multipleSecondLevelDomain})${zeroOrMore}`;
const topLevelDomain = `(\\.${disallowedDomainStartEndingCharacterSet}${disallowedDomainCharacterSet}${zeroOrMore}${disallowedDomainStartEndingCharacterSet})`;

const domain = `(${domainAsIPAddress}${or}(${secondLevelDomains}${topLevelDomain}))`;

export const emailRegEx = new RegExp(`^${localPart}${atSign}${domain}$`);
