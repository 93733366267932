import { css } from '@emotion/react';

import { breakpoints } from '../utils';

export const attributeTypography = css`
  .themed {
    --base-font-size: 16px;
    --small-font-size: 15px;
    --smaller-font-size: 12px;

    --h1-base-font-size: 28px;
    --h2-base-font-size: 28px;
    --h3-base-font-size: 24px;
    --h4-base-font-size: 20px;
    --h5-base-font-size: 18px;
    --h5-h6-base-font-size: 16px;
    --h6-base-font-size: 14px;
    --h7-base-font-size: 12px;

    --h1-font-size: var(--h1-base-font-size);
    --h2-font-size: var(--h2-base-font-size);
    --h3-font-size: var(--h3-base-font-size);
    --h4-font-size: var(--h4-base-font-size);
    --h5-font-size: var(--h5-base-font-size);
    --h6-font-size: var(--h6-base-font-size);

    --label-1: 14px;

    --link-text-decoration: underline var(--button-secondary-color) 2px;

    ${breakpoints.isTablet.mediaMinWidth} {
      --base-font-size: 18px;
      --small-font-size: 15px;
      --smaller-font-size: 14px;

      --h1-font-size: 48px;
      --h2-font-size: 40px;
      --h3-font-size: 32px;
      --h4-font-size: 24px;
      --h5-font-size: 20px;
      --h6-font-size: 16px;

      --label-1: 20px;
    }
  }
`;
