import styled from '@emotion/styled';
import { breakpoints } from '@/core';
import { css } from '@emotion/react';

const DrillCardMixin = css`
  background-color: var(--card-background);
  border: 1px solid rgba(var(--rgb-dark-grey) 0.2);
  border-radius: var(--wrapped-border-radius);
  box-shadow: 0px 1px 4px rgba(84, 86, 90, 0.24);

  a {
    --focus-spread: 4px;
    padding: 0;
  }
`;

export const DrillCardColumnListItem = styled.li<{ active?: boolean }>`
  background-color: var(--card-background);
  border: 1px solid var(--light-grey);
  border-radius: var(--wrapped-border-radius);
  box-shadow: 0px 1px 4px rgba(84, 86, 90, 0.24);
  color: var(--indigo);
  width: 100%;

  a {
    --focus-spread: 4px;
    padding: 0;
  }
  &:hover .hover-color {
    background: rgba(var(--rgb-light-blue), 0.5);
    border-radius: var(--wrapped-border-radius) var(--wrapped-border-radius) 0 0;
  }
  ${({ active }) => (active ? 'border: 2px solid var(--dark-blue);' : '')};

  &.simplified {
    border: none;
    box-shadow: none;
  }
`;

export const DrillCardDetailTitle = styled.h3<{ hideOn?: 'mobile' | 'tablet' }>`
  color: var(--card-title-color);
  font-size: var(--h5-h6-base-font-size);
  font-weight: 700;

  ${({ hideOn }) => (hideOn === 'mobile' ? 'display: none;' : '')};

  ${() => breakpoints.isTablet.mediaMinWidth} {
    font-size: var(--h4-font-size);
    ${({ hideOn }) => (hideOn === 'mobile' ? 'display: block;' : '')};
    ${({ hideOn }) => (hideOn === 'tablet' ? 'display: none;' : '')};
  }
`;

export const DrillCardListItem = styled.li<{ active?: boolean }>`
  ${DrillCardMixin}
  ${({ active }) => (active ? 'border: 2px solid var(--dark-blue);' : '')};
`;

export const DrillCard = styled.section<{ simplified?: boolean }>`
  ${DrillCardMixin}
  ${({ simplified }) => (simplified ? 'border: none; box-shadow: none;' : '')};
`;

export const DrillCardSection = styled.div<{
  background?: 'top';
  border?: 'top';
  customPadding?: string;
  gap?: string;
  hideOn?: 'mobile' | 'tablet';
  direction?: 'row' | 'column';
  iconSize?: string;
  justify?: string;
}>`
  display: flex;
  ${({ gap }) => (gap ? `gap: ${gap};` : '')};

  ${({ justify }) => (justify ? `  justify-content: ${justify};` : '  justify-content: space-between;')};
  ${({ direction }) => (direction ? `flex-direction: ${direction};` : 'flex-direction: row;')};
  ${({ customPadding }) => (customPadding ? `padding: ${customPadding};` : 'padding: var(--whitespace-basic-24)')};
  ${({ background }) => (background ? 'background-color: var(--lightest-grey);' : '')};
  ${({ background }) => (background === 'top' ? 'border-radius: var(--wrapped-border-radius) var(--wrapped-border-radius) 0 0;' : '')};
  ${({ border }) => (border === 'top' ? '  border-top: 3px solid var(--lightest-grey);' : '')};

  ${({ hideOn }) => (hideOn === 'mobile' ? 'display: none;' : '')};

  ${() => breakpoints.isTablet.mediaMinWidth} {
    ${({ hideOn }) => (hideOn === 'mobile' ? 'display: flex;' : '')};
    ${({ hideOn }) => (hideOn === 'tablet' ? 'display: none;' : '')};
  }

  .from {
    color: var(--black-text);
    line-height: 1.5em;
    font-size: var(--h6-font-size);
  }
  .price {
    color: var(--black-text);
    font-size: var(--h4-font-size);
    margin-left: 10px;
    font-weight: 700;
  }

  svg {
    &.product-icon {
      height: ${({ iconSize }) => (iconSize ? iconSize : 'var(--icon-34-to-58)')};
      width: ${({ iconSize }) => (iconSize ? iconSize : 'var(--icon-34-to-58)')};
    }
    &.chevron-right-icon {
      color: var(--white);
      flex-shrink: 0;
      height: 14px;
      transform: translateY(10%);
      width: 5px;
    }
    &.chevron-right-icon {
      color: var(--drill-card-cta-icon);
      flex-shrink: 0;
      height: 28px;
      width: 17px;
    }
  }

  &.border {
    color: var(--black-text);
    padding: var(--whitespace-16-to-24) 0;
    width: 100%;
    ${() => breakpoints.isTablet.mediaMinWidth} {
      border: 1px solid var(--light-grey);
      border-radius: var(--component-border-radius);
      padding: var(--whitespace-16-to-24);
    }
  }
  &.simplified {
    padding: 0;
  }
`;

export const DrillCardSectionIcon = styled.div<{ hideOn?: 'mobile' | 'tablet' }>`
  svg {
    color: var(--alert-color);
    flex-shrink: 0;
    height: var(--icon-68-to-90);
    margin-right: 0.2em;
    margin-top: 1em;
    width: var(--icon-68-to-90);
  }
`;

export const DrillColumnCardDetailBottom = styled.div<{ border?: 'always' | 'mobile' | 'tablet'; responsive?: boolean }>`
  color: var(--calendar-non-session-color);
  padding: var(--whitespace-basic-16);

  ${({ border }) => (border === 'always' || border === 'mobile' ? 'border-top: 3px solid var(--lightest-grey);' : '')};

  svg {
    color: var(--alert-color);
    flex-shrink: 0;
    height: 1.5em;
    margin-right: 0.5em;
    transform: translateY(35%);
    width: 1.5em;
  }
  .line {
    font-size: 30px;
    line-height: 10px;
  }
  ${() => breakpoints.isTablet.mediaMaxWidth} {
    ${({ responsive }) =>
      responsive &&
      `
      display: flex;
      flex-direction: row;
      gap: var(--whitespace-basic-16);
      padding: var(--whitespace-basic-16);

    `}
  }
  ${() => breakpoints.isTablet.mediaMinWidth} {
    ${({ border }) => (border === 'tablet' ? 'border-top: 3px solid var(--lightest-grey);' : border === 'mobile' ? 'border:none' : '')};
  }
`;

export const DrillColumnCardDetailBottomColumn = styled.div`
  display: flex;
  justify-content: space-between;
  &.active {
    color: var(--black);
    svg {
      color: #6ab651;
    }
  }
`;

export const DrillColumnCardDetailBottomRow = styled.div`
  color: var(--core-grey);
  display: flex;
  font-size: 0.78em;
  font-weight: 700;
  justify-content: space-between;
  padding: 4px 0;
  width: 100%;
  ${() => breakpoints.isTablet.mediaMaxWidth} {
    width: auto;
  }

  &.small-font {
    color: var(--black-text);
    font-weight: 400;
    svg {
      height: 1.4em;
      width: 1.4em;
      transform: translateY(-3%);
    }
  }

  &.split {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--whitespace-basic-16);
    row-gap: var(--whitespace-basic-24);
    ${() => breakpoints.isMobile.mediaMinWidth} {
      grid-template-columns: 1fr;
    }
    ${() => breakpoints.isTablet.mediaMinWidth} {
      grid-template-columns: 1fr 1fr;
    }
  }

  &.active {
    color: var(--black);
    svg {
      color: #6ab651;
    }
  }
  svg {
    height: 1em;
    margin-right: 0;
    width: 1em;
    transform: translateY(-5%);
  }

  .line {
    position: relative;
    &:after {
      content: '';
      width: 8px;
      border-bottom: 2px solid;
      position: absolute;
      bottom: 50%;
      right: 4px;
    }
  }
`;

export const DrillColumnCardDetailContent = styled.div<{ customBackground?: string; customPadding?: string; hideOn?: 'mobile' | 'tablet'; onlyRow?: boolean }>`
  background-color: ${({ customBackground }) => (customBackground ? customBackground : 'var(--lightest-grey)')};

  ${({ customPadding }) => (customPadding ? `padding: ${customPadding};` : 'padding: var(--whitespace-basic-24)')};
  ${({ hideOn }) => (hideOn === 'mobile' ? 'display: none;' : 'display: flex;')};

  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  ${() => breakpoints.isMobile.mediaMinWidth} {
    ${({ onlyRow }) => !onlyRow && 'flex-direction: column;'};
  }

  ${() => breakpoints.isTablet.mediaMinWidth} {
    flex-direction: row;
    ${({ hideOn }) => (hideOn === 'mobile' ? 'display: flex;' : '')};
    ${({ hideOn }) => (hideOn === 'tablet' ? 'display: none;' : '')};
  }

  svg {
    color: var(--alert-color);
    flex-shrink: 0;
    height: 0.9em;
    margin-right: 0.2em;
    transform: translateY(5%);
    width: 0.9em;
  }
`;

export const DrillColumnCardDetailDurationTitle = styled.div`
  font-weight: bold;
  width: 60%;

  ${() => breakpoints.isMobile.mediaMinWidth} {
    width: 100%;
  }

  ${() => breakpoints.isTablet.mediaMinWidth} {
    width: 60%;
  }
`;

export const DrillColumnCardDetailSubtitle = styled.div<{ customPadding?: string; width?: string }>`
  color: var(--core-grey);
  font-size: var(--h7-base-font-size);
  font-weight: 400;
  line-height: 130%;
  padding-bottom: 4px;
  ${({ customPadding }) => customPadding && `padding: ${customPadding};`};
  ${({ width }) => width && `width: ${width};`};

  &.core-theme {
    color: var(--core-grey);
  }
  &.black-theme {
    color: var(--core-grey);

    ${() => breakpoints.isTablet.mediaMinWidth} {
      color: var(--black);
    }
  }
`;

export const DrillColumnCardDetailTitle = styled.div`
  display: flex;
  flex-direction: row;
  font-size: var(--h5-h6-base-font-size);
  font-weight: bold;
  justify-content: space-between;
  padding: var(--whitespace-basic-16);
  width: 100%;

  svg,
  svg.chevron-right-icon {
    height: 1em;
    width: 1em;
    color: var(--indigo);
  }
`;

export const DrillColumnCardDetailValue = styled.div<{ customMargin?: string }>`
  color: var(--black-text);
  font-weight: 700;
  font-size: 0.78em;
  ${({ customMargin }) => customMargin && `margin: ${customMargin}`};

  h5 {
    color: var(--black-text);
  }
  p {
    font-weight: 400;
    font-size: 16px;
    margin-top: 8px;
  }

  &.price {
    font-size: var(--h3-base-font-size);
  }
  ${() => breakpoints.isTablet.mediaMinWidth} {
    margin-bottom: 0.5em;
  }
`;
