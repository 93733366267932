import { ChangeEvent, FC, FocusEvent, InputHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';

import {
  ChildComponentProps,
  ComponentWrapper,
  ComponentWrapperProps,
  ConditionalRequiredProps,
  FormElementProps,
  FormValidateControl,
  useErrorMessages,
  useRegisterFormControl,
} from '@/components';
import { noAction, useDebounce } from '@/core';

import * as S from './Choice.style';

interface ChoiceProps extends ComponentWrapperProps {
  dangersouslySetLabel?: boolean;
  'data-testid'?: string;
  simplified?: boolean;
  type: ChoiceType;
  onValueChange?: ChoiceChangeEvent;
}

export const Choice: FC<ChildComponentProps & ChoiceProps & ConditionalRequiredProps & FormElementProps & InputHTMLAttributes<HTMLInputElement>> = ({
  autoFocus,
  children,
  className,
  debounceWait = 150,
  dangersouslySetLabel,
  'data-testid': dataTestId,
  defaultChecked = false,
  formErrorMessages,
  formErrorSimpleMessage,
  formPreventControlRegistration,
  id,
  inlineErrorMessages,
  label,
  name,
  onBlur = noAction,
  onChange = noAction,
  onValueChange = noAction,
  required = false,
  requiredText = '',
  simplified,
  tabIndex,
  type,
  value,
  ...props
}): JSX.Element => {
  const [checkedForChange, setCheckedForChange] = useState(defaultChecked);
  const choiceRef = useRef<HTMLInputElement>(null);
  const debounceChecked = useDebounce(checkedForChange, debounceWait);
  const { valuePassesRequiredCheck, internalErrorMessages } = useErrorMessages(!!required, requiredText, inlineErrorMessages);

  const validateByForm: FormValidateControl = useCallback(() => {
    return valuePassesRequiredCheck(checkedForChange, true);
  }, [checkedForChange, valuePassesRequiredCheck]);
  const testIdValue = dataTestId ?? id;
  const { errorId, scrollIntoView, resetScrollIntoView } = useRegisterFormControl(id, !!formPreventControlRegistration, validateByForm);

  useEffect(() => {
    onValueChange(debounceChecked);
  }, [debounceChecked, onValueChange]);

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      valuePassesRequiredCheck(checkedForChange);
      onBlur(event);
    },
    [valuePassesRequiredCheck, checkedForChange, onBlur],
  );

  const handleChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.checked;
      valuePassesRequiredCheck(newValue);
      setCheckedForChange(newValue);
      onChange(event);
    },
    [onChange, valuePassesRequiredCheck],
  );

  return (
    <ComponentWrapper
      activeElementForScroll={choiceRef}
      className={className}
      {...(errorId && { 'data-errorid': errorId })}
      formErrorMessages={formErrorMessages}
      formErrorSimpleMessage={formErrorSimpleMessage}
      id={`wrapper-${id}`}
      inlineErrorMessages={internalErrorMessages}
      scrollIntoView={scrollIntoView}
      resetScrollIntoView={resetScrollIntoView}
    >
      <S.ChoiceLabel htmlFor={id}>
        <S.Choice
          autoFocus={autoFocus}
          {...(internalErrorMessages.length && { className: 'error' })}
          data-testid={testIdValue}
          defaultChecked={defaultChecked}
          defaultValue={value}
          id={id}
          name={name}
          onBlur={handleBlur}
          onChange={handleChanged}
          ref={choiceRef}
          tabIndex={tabIndex}
          type={type}
          simplified={simplified}
          {...props}
        />
        <span>
          {dangersouslySetLabel && label ? <span dangerouslySetInnerHTML={{ __html: label }}></span> : <span>{label}</span>}
          {children}
        </span>
      </S.ChoiceLabel>
    </ComponentWrapper>
  );
};

export type ChoiceChangeEvent = (newValue: boolean) => void;
export type ChoiceType = 'checkbox' | 'radio';
