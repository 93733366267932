import { FiAlertCircle, FiCheckCircle } from 'react-icons/fi';

import { useAppSelector } from '@/core';

export const PaymentStatus = () => {
  const basket = useAppSelector((s) => s.basket);

  const iconMapper = (status: string) => {
    switch (status) {
      case 'processing':
        return <FiCheckCircle />;
      case 'success':
        return <FiCheckCircle />;
      case 'failure':
        return <FiAlertCircle />;
      default:
        return null;
    }
  };

  const colorMapper = (status?: string) => {
    switch (status) {
      case 'processing':
        return 'text-indigo';
      case 'success':
        return 'text-teal-dark';
      case 'failure':
        return 'text-red-dark';
      default:
        return null;
    }
  };

  const paymentIcon = basket.paymentStatus?.status && iconMapper(basket.paymentStatus?.status);

  return (
    <div className={`text-center font-bold mt-[24px] pt-[24px] border-t border-grey-light text-[24px] ${colorMapper(basket.paymentStatus?.status)}`}>
      <div className="justify-center flex gap-[10px] items-center	">
        {paymentIcon}
        {basket.paymentStatus?.basketStatus ?? '-'}
      </div>
    </div>
  );
};
