import { useCallback, useEffect, useMemo } from 'react';

import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { WellReg } from '@britishcouncil/react-registration-platform';

import { CustomerSelectPayload, Loading, TabItem } from '@/components';
import { PagedResultOfSessionWithVenueResponse, SessionWithVenueResponse } from '@/store/webApi';
import { webApi } from '@/store/webApi/enhancedWebApi';

import { ExamDetails, ExamRow, ResultsAndCertificates } from './components';

export const CustomerTests = () => {
  const { t } = useTranslation();
  const selectedCustomer = useOutletContext<CustomerSelectPayload>();

  const [getCustomerEnrolments, { data: customerEnrolments, isLoading: isCustomerLoading }] = webApi.useSessionGetCustomerSessionWithVenueMutation();
  const [getMinorEnrolments, { data: minorEnrolments, isLoading: isMinorLoading }] = webApi.useSessionGetMinorSessionWithVenueMutation();

  const { data: productConfigs } = webApi.useProductsGetConfigurationsQuery();
  const getTabs = useCallback(
    (exam: SessionWithVenueResponse): Array<TabItem> => {
      const supportedFeatures = productConfigs?.find((x) => x.productCode?.toLowerCase() === exam.productCode?.toLowerCase())?.supportedFeatures;

      const tabs = [
        {
          index: 0,
          component: <ExamDetails examData={exam} />,
          title: 'Exam details',
        },
      ];
      if (supportedFeatures?.includes('ResultAndCertificate') && exam.sessionStats === 'Completed') {
        tabs.push({
          index: 1,
          component: (
            <ResultsAndCertificates enrolmentId={exam.enrolmentId!} minorId={selectedCustomer?.customerType === 'minor' ? selectedCustomer.value : undefined} />
          ),
          title: 'Results and certificate',
        });
      }

      return tabs;
    },
    [productConfigs, selectedCustomer],
  );

  const filterUpcomingAndPreviousTest = useCallback((sessionList?: PagedResultOfSessionWithVenueResponse) => {
    const upcoming: SessionWithVenueResponse[] = [] ;
    const past: SessionWithVenueResponse[] = [];
    sessionList?.results?.forEach(item => {
      if (item.sessionStats && (item.sessionStats === 'Completed' || item.sessionStats === 'Canceled')) {
        past.push(item);
      } else {
        upcoming.push(item);
      }
    })
    return {
      upcoming,
      past
    }
  }, [selectedCustomer, customerEnrolments, minorEnrolments])

  useEffect(() => {
    if (selectedCustomer?.customerType === 'adult') {
      getCustomerEnrolments({ paginationAndSortingParameters: { pagination: { page: 1, pageSize: 100 }, isPaginationSpecified: true } });
    } else {
      if (selectedCustomer && selectedCustomer.value) {
        getMinorEnrolments({
          minorId: selectedCustomer.value,
          paginationAndSortingParameters: { pagination: { page: 1, pageSize: 100 }, isPaginationSpecified: true },
        });
      }
    }
  }, [selectedCustomer, getCustomerEnrolments, getMinorEnrolments]);

  const enrolments = useMemo(
    () => (selectedCustomer?.customerType === 'adult' ? filterUpcomingAndPreviousTest(customerEnrolments) : filterUpcomingAndPreviousTest(minorEnrolments)),
    [selectedCustomer, customerEnrolments, minorEnrolments],
  );

  if (isCustomerLoading || isMinorLoading) {
    return (
      <WellReg className="mt-6">
        <Loading />
      </WellReg>
    );
  }

  return (
    <WellReg className="mt-6">
      <div className="flex flex-col divide-y-2 divide-grey-300 md:gap-6 md:divide-y-0">
        {!selectedCustomer ? (
          <p className="font-bold text-lg mx-auto">{t('dashboard.no-tests')}</p>
        ) : (
          <>
            <h4 className="font-bold">{t('dashboard.upcoming-tests')}</h4>
            <ExamRow enrolments={enrolments.upcoming} getTabs={getTabs} />
            <h4 className="mt-5 font-bold">{t('dashboard.past-tests')}</h4>
            <ExamRow enrolments={enrolments.past} getTabs={getTabs} />
          </>
        )}
      </div>
    </WellReg>
  );
};
