import qs from 'query-string';
import { AxiosError, AxiosRequestConfig } from 'axios';

import * as Sentry from '@sentry/react';
import { BaseQueryFn } from '@reduxjs/toolkit/query';

import { downloadFile } from './downloadFile';
import { webApiClient } from './webApiClient';

export const axiosBaseQuery =
  (): BaseQueryFn<
    Omit<AxiosRequestConfig, 'headers'> & {
      body?: AxiosRequestConfig['data'];
      headers?: Record<string, string | number | boolean | undefined | null>;
    },
    unknown,
    { status?: number; data?: unknown },
    { downloadFile?: boolean }
  > =>
  async ({ url, body, headers, ...config }, _, extraOptions) => {
    try {
      const result = await webApiClient({
        url,
        data: body,
        headers: mapToAxiosHeaders(headers),
        responseType: extraOptions?.downloadFile ? 'blob' : 'text',
        ...config,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'none' }),
      });

      if (result.headers['content-disposition']?.includes('attachment')) {
        downloadFile(result);
      }

      return { data: result.data, meta: { headers: result.headers } };
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      Sentry.withScope((scope) => {
        /* Group errors together based on their method and URL */
        scope.setFingerprint([config.method ?? 'GET', url ?? '/']);
        scope.setTransactionName(`${config.method ?? 'GET'} ${url}`);
        Sentry.captureException(err);
      });

      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };

const mapToAxiosHeaders = (originalHeaders?: Record<string, string | number | boolean | undefined | null>) => {
  const headers: AxiosRequestConfig['headers'] = {};
  if (originalHeaders) {
    Object.keys(originalHeaders).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (originalHeaders[key] !== undefined && originalHeaders[key] !== null) headers[key] = originalHeaders[key] as any;
    });
  }
  return headers;
};
