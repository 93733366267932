import { css } from '@emotion/react';

import { LinkMixin } from './mixins';

import {
  animations,
  attributeColors,
  attributeColumns,
  attributeDimensions,
  attributeGrid,
  attributeTypography,
  componentColors,
  componentDimensions,
  componentTypography,
  iconDimensions,
  themeColors,
} from '.';

export const globalStyles = css`
  ${animations}
  ${themeColors}
  ${attributeColors}
  ${attributeColumns}
  ${attributeDimensions}
  ${attributeGrid}
  ${attributeTypography}
  ${componentColors}
  ${componentDimensions}
  ${componentTypography}
  ${iconDimensions}
  @font-face {
    font-family: BCSans;
    src:
      url(https://sui.britishcouncil.org/0.1.7/fonts/BritishCouncilSansW08-Lt.woff2) format('woff2'),
      url(https://sui.britishcouncil.org/0.1.7/fonts/BritishCouncilSansW08-Lt.woff) format('woff');
    font-weight: 100;
  }

  @font-face {
    font-family: BCSans;
    src:
      url(https://sui.britishcouncil.org/0.1.7/fonts/BritishCouncilSansW08-Rg.woff2) format('woff2'),
      url(https://sui.britishcouncil.org/0.1.7/fonts/BritishCouncilSansW08-Rg.woff) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: BCSans;
    src:
      url(https://sui.britishcouncil.org/0.1.7/fonts/BritishCouncilSansW08-Bd.woff2) format('woff2'),
      url(https://sui.britishcouncil.org/0.1.7/fonts/BritishCouncilSansW08-Bd.woff) format('woff');
    font-weight: 600;
  }

  @font-face {
    font-family: BCSansHeading;
    src:
      url(https://sui.britishcouncil.org/0.1.7/fonts/BritishCouncilSansW05-Headline.woff2) format('woff2'),
      url(https://sui.britishcouncil.org/0.1.7/fonts/BritishCouncilSansW05-Headline.woff) format('woff');
    font-weight: 300;
  }

  *:focus:not(.reg-react-select__input) {
    box-shadow:
      0 0 0 var(--focus-spread) var(--focus-input-color),
      0 0 0 var(--focus-spread) var(--focus-input-color);
    outline: none;
  }

  *:focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
  }

  *:focus-visible:not(.reg-react-select__input) {
    box-shadow:
      0 0 0 var(--focus-spread) var(--focus-input-color),
      0 0 0 var(--focus-spread) var(--focus-input-color);
    outline: none;
  }

  a {
    ${LinkMixin}
  }

  body {
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: Arial, Helvetica, sans-serif;
    font-size: var(--base-font-size);
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    scroll-behavior: smooth;
  }

  .visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .regPlatform-select__placeholder {
    line-height: 1;
  }
  .customScroll::-webkit-scrollbar {
    width: 5px;
    cursor: pointer;
  }
  .customScroll::-webkit-scrollbar-track {
    background-color: #fafafa;
  }
  .customScroll::-webkit-scrollbar-thumb {
    background-color: darkgray;
  }

  .customScroll2::-webkit-scrollbar {
    width: 0px;
    visibility: hidden;
    height: 0;
    cursor: pointer;
  }
  .customScroll2::-webkit-scrollbar-track {
    background-color: #fff;
  }
  .customScroll2::-webkit-scrollbar-thumb {
    background-color: #fff;
  }
`;
