import { CustomerDataResponse } from '@/store/webApi';
import { OrderAcknowledgementDetailsState } from '@/store';

export const convertOrderAcknowledgeBody = (content: { other: OrderAcknowledgementDetailsState; personDetails: CustomerDataResponse }) => {
  const acknowledgementType = { not_specified: 0, self: 1, person: 2, company: 3 }[content.other.orderAcknowledgement ?? 'not_specified'];
  const usePersonal = acknowledgementType === 1;

  const includeAddress = {
    addressLine1: usePersonal ? content.personDetails.address?.addressLine1 : content.other.postalLine01,
    addressLine2: usePersonal ? content.personDetails.address?.addressLine2 : content.other.postalLine02,
    city: usePersonal ? content.personDetails.address?.city : content.other.postalTownCity,
    postCode: usePersonal ? content.personDetails.address?.postCode : content.other.postalZipCode,
    residenceCountryId: usePersonal ? content.personDetails.address?.residenceCountryId : content.other.countryId,
  };
  const personalDetailsToPost = {
    address: includeAddress,
    contactNumber: content.personDetails.mobileNumber,
    email: content.personDetails.email,
    firstName: content.personDetails.firstName,
    lastName: content.personDetails.lastName,
  };

  const otherPersonDetails = {
    address: includeAddress,
    contactNumber: content.other.telephoneNumber,
    email: content.other.email,
    firstName: content.other.firstName,
    lastName: content.other.lastName,
  };

  const companyDetails = {
    address: includeAddress,
    companyName: content.other.companyName,
    contactNumber: content.other.telephoneNumber,
    email: content.other.email,
    taxNumber: content.other.vatNipNumber,
  };

  const bodyTypes = [
    { acknowledgementReceiverType: acknowledgementType, ...personalDetailsToPost },
    { acknowledgementReceiverType: acknowledgementType, ...personalDetailsToPost },
    { acknowledgementReceiverType: acknowledgementType, ...otherPersonDetails },
    { acknowledgementReceiverType: acknowledgementType, ...companyDetails },
  ];
  return bodyTypes[acknowledgementType];
};
