import { AlertType } from '@/components';
import { CreateReservationResponse, SessionResponse } from '@/store/webApi';
import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/store';

type ItemFormat = {
  type: string;
  desc?: string;
  startDate?: string;
  endDate?: string;
};

export type PaymentStatusFormat = {
  status: string;
  alertType: AlertType;
  title: string;
  text: string;
  basketStatus: string;
};

export type BasketItem = {
  id?: string;
  name?: string;
  address?: string;
  formats?: ItemFormat[];
  startDateTimeUtc?: string;
  endDateTimeUtc?: string;
  price?: number;
  tax?: number;
  currency?: string;
  productCode?: string;
  venuName?: string;
  timeZone?: string;
  packageId?: string;
};

export type BasketState = {
  // TODO: remove SessionResponse and keep in basket items only required fields. Do not tie Basket to API models.
  items: BasketItem[];
  reservations: CreateReservationResponse[];
  basketId?: string;
  paymentStatus?: PaymentStatusFormat;
};

export type BasketPriceSummary = {
  fee: number;
  tax: number;
  total: number;
  currency: string;
};

const initialState: BasketState = {
  items: [],
  reservations: [],
};

export const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    setBasket: (state, { payload }: PayloadAction<BasketState>): void => {
      state.items = payload.items;
      state.basketId = payload.basketId;
      state.reservations = payload.reservations;
      state.paymentStatus = payload.paymentStatus;
    },
    setItems: (state, { payload }: PayloadAction<BasketState['items']>): void => {
      state.items = payload;
    },
    addItem: (state, { payload }: PayloadAction<BasketItem & SessionResponse>): void => {
      state.items = [...state.items, payload];
    },
    setBasketId: (state, { payload }: PayloadAction<string>): void => {
      state.basketId = payload;
    },
    setPaymentStatus: (state, { payload }: PayloadAction<PaymentStatusFormat>): void => {
      state.paymentStatus = payload;
    },
    removeItem: (state, { payload }: PayloadAction<string>): void => {
      state.items = state.items.filter((item) => item.id !== payload);
      state.reservations = state.reservations?.filter((reservation) => reservation.sessionId !== payload);
    },
    setReservations: (state, { payload }: PayloadAction<CreateReservationResponse[]>): void => {
      state.reservations = payload;
    },
    addReservation: (state, { payload }: PayloadAction<CreateReservationResponse>): void => {
      /* Remove old reservation from state if present */
      const filtered = state?.reservations?.filter((reservation) => reservation.sessionId !== payload.sessionId);

      state.reservations = [...filtered, payload];
    },
    reset: (): BasketState => initialState,
  },
});

export const selectBasketPriceSummary = createSelector(
  (state: RootState) => state.basket,
  (state) => {
    const initialValue: BasketPriceSummary = {
      fee: 0,
      tax: 0,
      total: 0,
      currency: state.items[0]?.currency ?? 'EUR',
    };

    return state.items.reduce((result, current) => {
      const total = current.price ?? 0;
      const tax = current.tax ?? 0;

      result.tax += tax;
      result.fee += total - tax;
      result.total += total;

      return result;
    }, initialValue);
  },
);
