import { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { WellReg } from '@britishcouncil/react-registration-platform';

import { ClearForm } from '@/components/component-wrapper/ClearForm';
import { FormPhoneInput } from '@/components/forms/FormPhoneInput';
import { TextInput } from '@/components';
import { personalDetailsSlice } from '@/store';
import { useAppSelector } from '@/core';

import { PersonalDetailsFormType } from './usePersonalDetailsForm';

//To delete after identity release
export const ContactDetails = () => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const personalDetails = useAppSelector((s) => s.personalDetails);

  const formContext = useFormContext<PersonalDetailsFormType>();

  const onConfirmEmailChange = useCallback(
    (newValue: string) => {
      if (newValue) dispatch(personalDetailsSlice.actions.setConfirmEmail(newValue));
    },
    [dispatch],
  );

  const onEmailChange = useCallback(
    (newValue: string) => {
      if (newValue) dispatch(personalDetailsSlice.actions.setEmail(newValue));
    },
    [dispatch],
  );

  const onFormClear = useCallback(() => {
    dispatch(personalDetailsSlice.actions.clearEmail());
    formContext.setValue('mobileNumber', '');
  }, [dispatch, formContext]);

  return (
    <ClearForm onClear={onFormClear}>
      <WellReg>
        <div className="[&>*]:my-3">
          <TextInput
            defaultText={personalDetails?.email}
            id="email"
            inputType="email"
            invalidEmailMessage={t('components.text-input.errors.invalid-email')}
            label={t('personal-details.create-account.email-password.your-email')}
            onValueChange={onEmailChange}
            required
            requiredText={t('common.required')}
          ></TextInput>
          <TextInput
            defaultText={personalDetails?.confirmEmail}
            emailToMatch={personalDetails.email}
            id="confirm-email"
            inputType="confirm-email"
            invalidConfirmEmailMessage={t('components.text-input.errors.invalid-confirm-email')}
            label={t('personal-details.create-account.email-password.confirm-email')}
            onValueChange={onConfirmEmailChange}
            preventPasteMessage={t('common.prevent-paste')}
            required
            requiredText={t('common.required')}
          ></TextInput>
          <FormPhoneInput id="mobileNumber" label={t('personal-details.contact-details.mobile-number')} formContext={formContext} />
        </div>
      </WellReg>
    </ClearForm>
  );
};
