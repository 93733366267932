import { FlowSteps } from '@/core/consts';
import { breakpoints } from '@/core/styles';

export const getTotalSteps = (width: number, ischild: boolean): number => {
  const isMobile = width < breakpoints.isXMobile.breakpoint;
  return isMobile
    ? ischild
      ? FlowSteps.totalStepsForFormInMobileWithChild
      : FlowSteps.totalStepsForFormInMobileAndNoChild
    : ischild
      ? FlowSteps.totalStepsForFormInDesktopWithChild
      : FlowSteps.totalStepsForFormInDesktopAndNoChild;
};

export const isMobileTrack = (width: number): boolean => {
  const isMobile = width < breakpoints.isTablet.breakpoint;
  return isMobile;
};

export const isMobileOrTabletTrack = (width: number): boolean => {
  const isMobile = width < breakpoints.isLaptop.breakpoint;
  return isMobile;
};
