import { useCallback, useEffect, useState } from 'react';

import { addMonths, endOfMonth } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { WellReg } from '@britishcouncil/react-registration-platform';

import { Calendar, createErrorMessage } from '@/components';
import { DateWithTimeZone } from '@/components/presentation/calendar/UtcWithLocalDate';
import { MONTHS_CHUNKS, MonthsScroll } from '@/components/presentation/calendar';
import { UtcWithLocalDateResponse } from '@/store/webApi';
import { searchDetailsSlice } from '@/store';
import { useAppDispatch, useAppSelector } from '@/core';

import { CalendarPlaceholder } from './CalendarPlaceholder';
import { useSearchAvailableDates } from './useSearchAvailableDates';

const initRange = {
  from: new Date(),
  to: endOfMonth(addMonths(new Date(), MONTHS_CHUNKS)),
};

export const ChooseDates = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const searchDetails = useAppSelector((s) => s.searchDetails);

  const [datesRange, setDatesRange] = useState(initRange);
  const [currentMonthVisible, setCurrentMonth] = useState<Date>(new Date());
  const [isCalendarDisabled, setIsCalendarDisabled] = useState(false);
  const [activeDates, setActiveDates] = useState<UtcWithLocalDateResponse[]>([]);

  const {
    availableDates = [],
    availableDatesError,
    isLoadingAvailableDates,
  } = useSearchAvailableDates(datesRange.from.toISOString(), datesRange.to.toISOString());

  useEffect(() => {
    // Reset active dates when city is changed
    setActiveDates([]);
  }, [searchDetails.city]);

  useEffect(() => {
    setActiveDates((d) => {
      // Check if new available dates array contains dates that are not present in active dates array
      const hasNewItems = availableDates.some((date) => !d.includes(date));

      return hasNewItems ? Array.from(new Set([...d, ...availableDates])) : d;
    });
  }, [availableDates]);

  useEffect(() => {
    if (availableDates.length === 0) return;

    const sorted = [...availableDates].sort()[0];
    setCurrentMonth(new Date(sorted.localDate ?? ''));
  }, [availableDates]);

  const onDateSelect = useCallback(
    (selectedDate?: DateWithTimeZone) => {
      dispatch(searchDetailsSlice.actions.setSelectedDate(selectedDate));
    },
    [dispatch],
  );

  useEffect(() => {
    const disabled = !searchDetails.city || isLoadingAvailableDates;

    if (disabled) {
      setDatesRange(initRange);
      setCurrentMonth(new Date());
      setIsCalendarDisabled(true);
      return;
    }

    setIsCalendarDisabled(false);
  }, [isLoadingAvailableDates, searchDetails.city]);

  const mapDateTimeZone = (dates: UtcWithLocalDateResponse): DateWithTimeZone => {
    const dateWithTimeZone: DateWithTimeZone = {
      localDate: new Date(dates.localDate ?? ''),
      utcDate: new Date(dates.utcDate ?? ''),
      dateOffset: dates?.dateOffset ?? 0
    };
    return dateWithTimeZone;
  };

  return (
    <>
      {isCalendarDisabled ? (
        <CalendarPlaceholder />
      ) : (
        <>
          <MonthsScroll availableDates={activeDates.map((d) => d.localDate) as string[]} setDatesRange={setDatesRange} setCurrentMonth={setCurrentMonth} />
          <WellReg className="my-8">
            <Calendar
              id="choose-dates-calendar"
              enabledDays={activeDates?.map((date) => mapDateTimeZone(date))}
              month={currentMonthVisible}
              onDateSelect={onDateSelect}
              formErrorMessages={availableDatesError && [createErrorMessage({ isFormError: true, text: availableDatesError.response, type: 'form' })]}
              formErrorSimpleMessage={t('common.server-error')}
              isLoading={isLoadingAvailableDates}
            />
          </WellReg>
        </>
      )}
    </>
  );
};
