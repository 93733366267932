import { useCallback, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Label, RadioGroupItem, WellReg } from '@britishcouncil/react-registration-platform';

import { ClearForm } from '@/components/component-wrapper/ClearForm';
import { DateInputs, FormDate } from '@/components/forms/FormDate';
import { FormInput } from '@/components/forms/FormInput';
import { FormRadioGroup } from '@/components/forms/FormRadioGroup';
import { GenderType } from '@/store/webApi';
import { Loading } from '@/components';
import { getFirstFormError, getFormError, getServerError } from '@/core/errors/FormError';
import { dobToDate, isAdult } from '@/pages/book-test';
import { webApi } from '@/store/webApi/enhancedWebApi';

import { ChooseMinor } from './ChooseMinor';
import { NAME_REGEX } from '@/core/consts/regular-expressions/common';

export type PersonalDetailsInputs = {
  id?: string;
  firstName?: string;
  lastName?: string;
  gender?: GenderType;
  otherGenders?: string;
} & DateInputs<'dateOfBirth'>;

export const PersonalDetails = () => {
  const { t } = useTranslation('');

  const { data, isLoading } = webApi.useCustomerGetCustomerMinorsQuery();
  const [getMinorDetails, minorDetailsQuery] = webApi.useLazyCustomerGetMinorDetailsQuery();

  const {
    register,
    formState: { errors },
    control,
    watch,
    setError,
    clearErrors,
    reset,
    setValue,
    getValues,
    trigger,
  } = useFormContext<PersonalDetailsInputs>();

  const isOtherGenderChosen = watch('gender')?.toString() === '0';
  const dob = watch('dateOfBirth');

  const onChangeDoB = useCallback(() => {
    if (!dob) return;

    const today = new Date();
    const dateOfBirth = dobToDate(dob);

    if (dateOfBirth > new Date(today.getFullYear(), today.getMonth(), today.getDay())) {
      setError('dateOfBirth', {
        type: 'futureDob',
        message: t('personal-details.about-you.future-dob'),
      });
    } else if (dateOfBirth < new Date(1900, 0, 1)) {
      setError('dateOfBirth', {
        type: 'dobTooEarly',
        message: t('personal-details.about-you.dob-too-early'),
      });
    } else if (isAdult(dob)) {
      setError('dateOfBirth', {
        type: 'minDob',
        message: t('personal-details.about-you.dob-adult-minor'),
      });
    } else clearErrors('dateOfBirth');

    trigger('dateOfBirth');
  }, [clearErrors, dob, setError, trigger, t]);

  /** Prefill form inputs using data from API */
  useEffect(() => {
    const minorData = minorDetailsQuery.data;

    if (!minorData) return;

    const dob = minorData.dateOfBirth ? new Date(minorData.dateOfBirth) : undefined;

    setValue('firstName', minorData.firstName ?? '');
    setValue('lastName', minorData.lastName ?? '');
    setValue('gender', minorData.gender ?? undefined);
    setValue('otherGenders', minorData.otherGenders ?? '');
    if (dob && !isNaN(dob.getTime())) {
      setValue('dateOfBirth.year', dob.getFullYear());
      setValue('dateOfBirth.month', dob.getMonth());
      setValue('dateOfBirth.day', dob.getDate());
    }
  }, [minorDetailsQuery.data, setValue]);

  const handleMinorChange = useCallback(
    (id: string) => {
      reset();
      setValue('id', id);

      id !== 'new' && getMinorDetails({ id });
    },
    [getMinorDetails, reset, setValue],
  );

  if (isLoading) {
    return (
      <WellReg className="h-[600px] [&>*]:h-full">
        <Loading />
      </WellReg>
    );
  }

  /** Disable form until user chooses a minor or "add new" option */
  const isFormDisabled = data?.minors?.length === 0 ? false : !getValues().id || minorDetailsQuery.isFetching;

  return (
    <ClearForm onClear={reset}>
      <WellReg>
        <div className="[&>*]:my-3">
          <ChooseMinor onMinorChange={handleMinorChange} minors={data?.minors ?? []} />
          <FormInput
            data-testid="first-name"
            {...register('firstName', { required: t('common.required'), pattern: { value: NAME_REGEX, message: t('common.format') } })}
            errorInline={getFormError(errors.firstName)}
            errorSubmittion={getServerError(errors.firstName)}
            label={t('common.name-dob-sex.first-name')}
            helpText={t('common.name-dob-sex.first-name-sublabel')}
            disabled={isFormDisabled}
          />
          <FormInput
            data-testid="last-name"
            {...register('lastName', { required: t('common.required'), pattern: { value: NAME_REGEX, message: t('common.format') } })}
            errorInline={getFormError(errors.lastName)}
            errorSubmittion={getServerError(errors.lastName)}
            label={t('common.name-dob-sex.last-name')}
            disabled={isFormDisabled}
          />
          <FormDate<'dateOfBirth', PersonalDetailsInputs>
            rootPropertyName="dateOfBirth"
            control={control}
            label={t('common.name-dob-sex.date-of-birth')}
            errorInline={getFirstFormError(errors.dateOfBirth?.day, errors.dateOfBirth?.month, errors.dateOfBirth?.year, errors.dateOfBirth)}
            errorSubmittion={getServerError(errors.dateOfBirth)}
            onChange={onChangeDoB}
            disabled={isFormDisabled}
          />
          <FormRadioGroup
            data-testid="gender-choice"
            name="gender"
            control={control}
            label={t('common.name-dob-sex.sex.title')}
            required
            disabled={isFormDisabled}
            items={[
              { id: 'gender-2', value: '2', label: t('common.name-dob-sex.sex.female') },
              { id: 'gender-1', value: '1', label: t('common.name-dob-sex.sex.male') },
              {
                id: 'gender-0',
                value: '0',
                owerideItem: (
                  <label key="gender-0" className={isOtherGenderChosen ? 'flex items-center' : ''}>
                    <div className="flex items-center space-x-3 sm:border-none sm:px-0 sm:py-0 sm:my-0 border border-grey-dark px-4 py-2 rounded-lg my-1">
                      <RadioGroupItem id="gender-0" placeholder="" value="0" />
                      <Label placeholder="" className="[&>*]:font-light" htmlFor="gender-0">
                        Other
                      </Label>
                    </div>
                    {isOtherGenderChosen && (
                      <div className="w-8/12  ml-auto">
                        <FormInput className="w-full " data-testid="other-genders" {...register('otherGenders')} />
                      </div>
                    )}
                  </label>
                ),
              },
            ]}
          />
        </div>
      </WellReg>
    </ClearForm>
  );
};
