import { FC } from 'react';

import { ReviewGrid, ReviewGridRow, ReviewGridWrapper } from '@/components';

interface ReviewPaymentProps {
  fee: string;
  feeLabel: string;
  tax: string;
  taxLabel: string;
  total: string;
  totalLabel: string;
}

export const ReviewPayment: FC<ReviewPaymentProps> = ({ fee, feeLabel, tax, taxLabel, total, totalLabel }): JSX.Element => {
  return (
    <ReviewGridWrapper>
      <ReviewGrid>
        <ReviewGridRow id="fee-label" label={feeLabel}>
          {fee}
        </ReviewGridRow>
        <ReviewGridRow id="tax-label" label={taxLabel}>
          {tax}
        </ReviewGridRow>
        <ReviewGridRow bold id="total-label" label={totalLabel}>
          {total}
        </ReviewGridRow>
      </ReviewGrid>
    </ReviewGridWrapper>
  );
};
