import { GroupedSessions } from '@/core';
import { SessionResponse } from '@/store/webApi';

// Copied from old codebase (manual API clients declarations), but I have no idea what it does and why it is here.
// TODO: remove this file if below logic make no sense.
export const transformResponse = (sessions: SessionResponse[]): GroupedSessions =>
  groupSessions(sessions.slice().sort((a, b) => a.startDateTimeUtc?.localeCompare(b.endDateTimeUtc ?? '') ?? 0));

const groupSessions = (sessions: SessionResponse[]): GroupedSessions => {
  let venueId: string | undefined | null;

  return sessions.reduce<GroupedSessions>((result, session) => {
    if (venueId !== session.venue?.id) {
      result.push([]);
      venueId = session.venue?.id;
    }
    result[result.length - 1].push(session); //TODO: transform this from the shape the BE sends it to the shape the FE expects it
    return result;
  }, []);
};
