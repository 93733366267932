import { FC } from 'react';

import { ReviewGrid, ReviewGridRow, ReviewGridWrapper } from '@/components';

interface ReviewIdentificationProps {
  countryOfNationality: string;
  countryOfNationalityLabel: string;
  expiryDate: string;
  expiryDateLabel: string;
  idNumber: string;
  idNumberLabel: string;
  issuingAuthority: string;
  issuingAuthorityLabel: string;
}

export const ReviewIdentification: FC<ReviewIdentificationProps> = ({
  countryOfNationality,
  countryOfNationalityLabel,
  expiryDate,
  expiryDateLabel,
  idNumber,
  idNumberLabel,
  issuingAuthority,
  issuingAuthorityLabel,
}): JSX.Element => {
  return (
    <ReviewGridWrapper>
      <ReviewGrid>
        <ReviewGridRow id="id-number-label" label={idNumberLabel}>
          {idNumber}
        </ReviewGridRow>
        {expiryDate && (
          <ReviewGridRow id="expiry-date-label" label={expiryDateLabel}>
            {expiryDate}
          </ReviewGridRow>
        )}
        <ReviewGridRow id="issuing-authority-label" label={issuingAuthorityLabel}>
          {issuingAuthority}
        </ReviewGridRow>
        <ReviewGridRow id="country-of-nationality-label" label={countryOfNationalityLabel}>
          {countryOfNationality}
        </ReviewGridRow>
      </ReviewGrid>
    </ReviewGridWrapper>
  );
};
