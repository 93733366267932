import { FC, useCallback, useState } from 'react';

import { SelectSingleEventHandler } from 'react-day-picker';
import { eachDayOfInterval, endOfMonth, isSameDay, startOfMonth } from 'date-fns';

import { ComponentWrapper, FormElementProps } from '@/components';
import { Global } from '@emotion/react';

import * as S from './Calendar.style';
import { DateWithTimeZone } from './UtcWithLocalDate';

interface CalendarProps {
  id: string;
  enabledDays?: Array<DateWithTimeZone>;
  month?: Date;
  onDateSelect: (date?: DateWithTimeZone) => void;
  isLoading?: boolean;
}

export const Calendar: FC<CalendarProps & FormElementProps> = ({
  id,
  enabledDays,
  month,
  onDateSelect,
  formErrorMessages,
  formErrorSimpleMessage,
  inlineErrorMessages,
}): JSX.Element => {
  const [selectedDate, setSelectedDate] = useState<Date>();

  const getDateObject = useCallback(
    (date: Date) => enabledDays?.find((e) => e.localDate.toLocaleDateString() === date.toLocaleDateString()),
    [enabledDays],
  );

  const setSelectedDay: SelectSingleEventHandler = useCallback(
    (selectedDate) => {
      if (!selectedDate) return;

      setSelectedDate(selectedDate);
      onDateSelect && onDateSelect(getDateObject(selectedDate));
    },
    [getDateObject, onDateSelect],
  );

  const allDaysInSelectedMonth = month ? eachDayOfInterval({ start: startOfMonth(month), end: endOfMonth(month) }) : [];
  const disabledDays = allDaysInSelectedMonth.filter((d) => !enabledDays?.find((e) => isSameDay(d, e.localDate)));
  const getUtcDate = (date: Date) => enabledDays?.find(e => e.localDate.toLocaleDateString() === date.toLocaleDateString())?.utcDate;



  return (
    <>
      {((formErrorMessages && formErrorMessages.length > 0) || (inlineErrorMessages && inlineErrorMessages.length > 0)) && (
        <Global styles={S.CalendarValidationBorder} />
      )}
      <Global styles={S.CalendarWrapper} />
      <ComponentWrapper
        formErrorMessages={formErrorMessages}
        formErrorSimpleMessage={formErrorSimpleMessage}
        inlineErrorMessages={inlineErrorMessages}
        id={id}
        className="[box-shadow:none!important]"
      >
        <S.Calendar
          disabled={disabledDays}
          disableNavigation
          fixedWeeks
          fromDate={new Date()}
          month={month}
          mode="single"
          onSelect={setSelectedDay}
          pagedNavigation
          selected={selectedDate}
          weekStartsOn={1}
        ></S.Calendar>
      </ComponentWrapper>
    </>
  );
};
