import { FC } from 'react';

import { IconProps } from '@/icons/IconProps';

export const TimesCircle: FC<IconProps> = ({ index = 0 }) => {
  return (
    <svg
      aria-hidden="true"
      aria-labelledby={`times-circle-icon-title-${index}`}
      className="times-circle-icon"
      data-testid="times-circle-icon"
      fill="none"
      focusable="false"
      role="img"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`times-circle-icon-title-${index}`}></title>
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="15" y1="9" x2="9" y2="15"></line>
      <line x1="9" y1="9" x2="15" y2="15"></line>
    </svg>
  );
};
