import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BCLogoSVG } from '@/icons';

export const Logo = () => {
  const { t } = useTranslation();
  const { productCode } = useParams();

  return (
    <NavLink to={productCode ? `/${productCode}/book-test` : '/'} className="flex">
      <img alt={t('common.logo-alt-text')} height={30} src={BCLogoSVG} width={105} />
    </NavLink>
  );
};
