import { css } from '@emotion/react';

export const ColumnsMixin = css`
  --column-gap: var(--whitespace-16-to-24);

  align-items: center;
  column-gap: var(--column-gap);
  display: flex;
  flex-direction: row;
  justify-content: var(--columns-justify-content);
  width: 100%;
`;
