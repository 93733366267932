import styled from '@emotion/styled';
import { breakpoints } from '@/core';

export const PageTitle = styled.div<{ hideOn?: 'tablet' | 'mobile'; main?: boolean; marginBottom?: string }>`
  color: var(--heading-color);
  font-family: BCSans;

  font-weight: bold;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? 'var(--whitespace-32-to-72)'};
  max-width: var(--main-content-width);
  padding-bottom: var(--whitespace-16-to-24);
  position: relative;
  h1 {
    font-size: ${({ main }) => main && 'var(--h3-font-size)'};
  }

  &:after {
    background-color: var(--heading-color);
    border-radius: var(--line-round-end-border-radius);
    bottom: 0;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    width: 38px;
  }

  ${() => breakpoints.isMobile.mediaMaxWidth} {
    h1 {
      font-size: 'var(--h1-font-size)';
    }

    &:after {
      height: 4px;
      width: 58px;
    }
  }
  ${({ hideOn }) => (hideOn === 'mobile' ? 'display: none;' : '')};

  ${() => breakpoints.isTablet.mediaMinWidth} {
    ${({ hideOn }) => (hideOn === 'mobile' ? 'display: block;' : '')};
    ${({ hideOn }) => (hideOn === 'tablet' ? 'display: none;' : '')};
  }
`;
