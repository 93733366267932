import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ComponentWrapper } from '@/components/component-wrapper';
import { FormInput } from '@/components/forms/FormInput';
import { Select } from '@/components/forms/Select';
import { setGuardian } from '@/store';
import { useAppDispatch, useAppSelector } from '@/core';
import { useDictionariesGetAllMinorPickupPersonsQuery } from '@/store/webApi';

type Inputs = {
  firstName: string;
  lastName: string;
  telephone: string;
  relationshipId?: number;
};

export const GuardianForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { guardian } = useAppSelector((s) => s.minorPickup);

  const {
    register,
    formState: { errors },
  } = useForm<Inputs>({
    mode: 'onTouched',
    criteriaMode: 'all',
    defaultValues: {
      firstName: guardian?.firstName,
      lastName: guardian?.lastName,
      telephone: guardian?.telephone ?? '',
      relationshipId: guardian?.relationshipId,
    },
  });

  const { data: minorPickupPersons = [] } = useDictionariesGetAllMinorPickupPersonsQuery();

  const updateGuardian = useCallback(
    ({ target }: ChangeEvent<{ name: string; value: string }>) => {
      dispatch(setGuardian({ [target.name]: target.value }));
    },
    [dispatch],
  );

  const minorPickupPersonsOptions = useMemo(
    () =>
      minorPickupPersons.map((d) => ({
        label: d.name ?? '',
        value: d.id ?? '',
      })),
    [minorPickupPersons],
  );

  useEffect(() => {
    if (!guardian?.relationshipId && minorPickupPersons.length > 0) {
      dispatch(setGuardian({ ['relationshipId']: minorPickupPersons[0].id }));
    }
  }, [guardian, dispatch, minorPickupPersons]);

  return (
    <ComponentWrapper addBackground whiteBackground className="animated slideInDown" id="minor-pickup-other">
      <FormInput
        data-testid="first-name"
        {...register('firstName', { required: t('common.required') })}
        errorInline={errors.firstName?.message}
        label={t('common.name-dob-sex.first-name')}
        helpText={t('common.name-dob-sex.first-name-sublabel')}
        onChange={updateGuardian}
        required
      />
      <FormInput
        data-testid="last-name"
        {...register('lastName', { required: t('common.required') })}
        errorInline={errors.lastName?.message}
        label={t('common.name-dob-sex.last-name')}
        onChange={updateGuardian}
        required
      />
      <Select
        label={t('minor-details.pickup.other-pickup.relationship')}
        {...register('relationshipId', { required: t('common.required') })}
        onChange={updateGuardian}
        options={minorPickupPersonsOptions}
        required
      />
      <FormInput
        data-testid="telephone-number"
        {...register('telephone', { required: t('common.required') })}
        errorInline={errors.telephone?.message}
        label={t('minor-details.pickup.other-pickup.telephone-number')}
        onChange={updateGuardian}
        required
      />
    </ComponentWrapper>
  );
};
