import { Dispatch, FC, SetStateAction, createContext, useCallback, useContext, useEffect, useState } from 'react';

import { GUID, SetState, TypedDictionary, createGuid, noAction } from '@/core';

type FormValidateControl = () => boolean;
type FormValidate = () => boolean;
type RegisterFormControl = (control: FormControl) => void;
type DecrementCount = () => number;
type DeregisterFormControl = (controlId: GUID) => void;

type FormControlDictionary = TypedDictionary<string, FormControl>;
type FormControlArray = Array<GUID>;

class FormControl {
  public active: boolean;
  id: GUID;
  name: string;
  setErrorId: Dispatch<SetStateAction<GUID | undefined>>;
  setScrollIntoView: Dispatch<SetStateAction<boolean>>;
  validate: FormValidateControl;

  constructor(
    name: string,
    setErrorId: Dispatch<SetStateAction<GUID | undefined>>,
    setScrollIntoView: Dispatch<SetStateAction<boolean>>,
    validate: FormValidateControl,
  ) {
    this.active = true;
    this.id = createGuid();
    this.name = name;
    this.setErrorId = setErrorId;
    this.setScrollIntoView = setScrollIntoView;
    this.validate = validate;
  }
}

interface IFormContext {
  checkForm: FormValidate;
  decrementCount: DecrementCount;
  deregisterFormControl: DeregisterFormControl;
  registerFormControl: RegisterFormControl;
  scrollToFirstError: () => void;
}

const defaultFormContext: IFormContext = {
  checkForm: () => true,
  decrementCount: () => 0,
  deregisterFormControl: () => undefined,
  registerFormControl: () => undefined,
  scrollToFirstError: noAction,
};

class FormContextClass implements IFormContext {
  private failureCount = 0;
  private formControlDictionary: FormControlDictionary = {};
  private formControlArray: FormControlArray = [];

  checkForm = (): boolean => {
    this.failureCount = 0;
    let controlToScrollIntoView: FormControl;

    this.formControlArray.forEach((id) => {
      if (this.formControlDictionary[id].active && !this.formControlDictionary[id].validate()) {
        this.failureCount++;
        this.formControlDictionary[id].setErrorId(id);
      }
      controlToScrollIntoView && controlToScrollIntoView.setScrollIntoView(true);
    });
    return this.failureCount === 0;
  };

  decrementCount = () => {
    return --this.failureCount;
  };

  deregisterFormControl = (controlId: GUID) => {
    if (this.formControlDictionary[controlId]) this.formControlDictionary[controlId].active = false;
  };

  registerFormControl = (control: FormControl) => {
    control.active = true;
    if (!this.formControlDictionary[control.id]) this.formControlArray.push(control.id);
    this.formControlDictionary[control.id] = control;
  };

  scrollToFirstError = () => {
    const firstErroredElement = document.querySelector('[data-errorid]') as HTMLElement | null;
    const controlId = firstErroredElement?.dataset.errorid;
    controlId && this.formControlDictionary[controlId].setScrollIntoView(true);
    this.formControlArray.forEach((id) => {
      this.formControlDictionary[id].setErrorId(undefined);
    });
  };
}

const FormContext = createContext<IFormContext>(defaultFormContext);

interface FormContextProviderProps {
  children: React.ReactNode;
  value: IFormContext;
}

const FormContextProvider: FC<FormContextProviderProps> = ({ children, value }) => <FormContext.Provider value={value}>{children}</FormContext.Provider>;

const useFormContext = () => {
  return useContext(FormContext);
};

const useRegisterFormControl = (name: string, preventRegistration: boolean, validate: FormValidateControl) => {
  const { decrementCount, deregisterFormControl, registerFormControl, scrollToFirstError } = useFormContext();
  const [errorId, setErrorId] = useState<GUID>();
  const [scrollIntoView, setScrollIntoView] = useState(false);

  const [formControl] = useState(new FormControl(name, setErrorId, setScrollIntoView, validate));

  const resetScrollIntoView: SetState = useCallback(() => {
    if (setScrollIntoView) setScrollIntoView(false);
  }, []);

  useEffect(() => {
    if (errorId && !decrementCount()) {
      scrollToFirstError();
    }
  }, [decrementCount, errorId, scrollToFirstError]);

  useEffect(() => {
    if (!preventRegistration) registerFormControl({ ...formControl, validate });
    return () => deregisterFormControl(formControl.id);
  }, [deregisterFormControl, formControl, preventRegistration, registerFormControl, validate]);

  return { errorId, scrollIntoView, resetScrollIntoView };
};

export { FormContextClass, FormContextProvider, useFormContext, useRegisterFormControl };
export type { FormValidateControl };
