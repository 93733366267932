import { useRouteError } from 'react-router-dom';

export const ErrorPage = () => {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div data-testid="error-page" className="h-screen flex flex-col items-center justify-center gap-4">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p className="text-grey-400">
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
};
