import { enhancedApi } from './webApi';

// TODO:(BRP-609) use below "webApi" object across the app instead of hooks importing directly from './webApi'.
export const webApi = enhancedApi.enhanceEndpoints({
  endpoints: {
    customerGetMinorDetails: {
      providesTags: ['Minor'],
    },
    customerGetCustomerMinors: {
      providesTags: ['Minors'],
    },
    customerGetCustomerDetails: {
      providesTags: ['Customer'],
    },
    enrolmentGetEnrolmentPayments: {
      providesTags: ['EnrolmentPayments'],
    },
    certificateGetCertificate: {
      providesTags: ['Certificates'],
      extraOptions: { downloadFile: true },
    },
    certificateGetCertificateForMinor: {
      providesTags: ['MinorCertificates'],
      extraOptions: { downloadFile: true },
    },
    certificateIsCertificateAvailable: {},
    certificateIsCertificateAvailableForMinor: {},
    customerCreateOrUpdateCustomerIdentificationDocument: {
      invalidatesTags: ['Customer'],
    },
    customerCreateOrUpdateMinorIdentificationDocument: {
      invalidatesTags: ['Minor'],
    },
    customerCreateMinor: {
      invalidatesTags: ['Minors'],
    },
    customerUpdateMinorPersonalData: {
      invalidatesTags: ['Minors'],
    },
    enrolmentEnrolCustomer: {
      invalidatesTags: ['Customer'],
    },
    enrolmentEnrolMinor: {
      invalidatesTags: ['Minors'],
    },
    sessionGetCustomerSessionWithVenue: {},
    sessionGetMinorSessionWithVenue: {},
  },
});
