import styled from '@emotion/styled';

import { ColumnsMixin } from '../columns';

export const ReviewTitle = styled.section`
  ${ColumnsMixin}

  .change-button {
    flex-shrink: 1;
  }
  .section-title {
    flex-grow: 1;
    flex-shrink: 0;
  }
`;
