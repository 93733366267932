import { GoogleTagManager, useAppSelector } from '@/core';

export const useAnalytics = () => {
  const { searchDetails } = useAppSelector((s) => s);

  const startPayment = (domainId: string) => {
    GoogleTagManager.addEvent({
      action: 'ReviewStepCompletion',
      Country: searchDetails.country?.id,
      event: 'VirtualPageview',
      virtualPageTitle: 'British Council - Book a new test - Review',
      virtualPageTitleLocal: document.title,
      virtualPageURL: '/book-test/review/',
    });

    GoogleTagManager.addEvent({
      action: 'Payment Submit',
      category: 'Payment',
      Country: searchDetails.country?.id,
      countryCode: searchDetails.country?.id,
      correlationId: domainId,
      currency: searchDetails?.testPackage?.currency,
      amount: searchDetails?.testPackage?.totalFee || '',
      event: 'VirtualPageview',
      virtualPageTitle: 'British Council - Book a new test - Review',
      virtualPageTitleLocal: document.title,
      virtualPageURL: '/book-test/review/',
    });
  };

  return { startPayment };
};
