import { Navigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { useTranslation } from 'react-i18next';

import { Button, WellReg } from '@britishcouncil/react-registration-platform';

import { PageTitle } from '@/components';
import { useAppLocation } from '@/core/hooks/router';

export const Create = () => {
  const auth = useAuth();
  const { state } = useAppLocation();
  const { t } = useTranslation();
  if (auth.isAuthenticated) {
    return <Navigate to={state?.productCode ? `/${state.productCode}/book-test/personal-details/0` : '/dashboard'} replace />;
  }

  const accountBaseFeatures = [t('account.create.features.check-order'), t('account.create.features.verify-data')];
  const accountExtraFeatures: { [key: string]: string[] } = {
    aptis: [t('account.create.features.find-results')],
  };

  const accountFeatures = state?.productCode ? [...accountBaseFeatures, ...(accountExtraFeatures[state.productCode.toLowerCase()] ?? [])] : accountBaseFeatures;

  return (
    <div className="p-4 mb-24 lg:p-0">
      <PageTitle main text="Create an account" />
      <WellReg className="p-0 md:p-6">
        <div className="grid grid-cols-1 grid-rows-[auto_auto_auto] lg:grid-rows-none lg:grid-cols-[50%_1px_50%]">
          <div className="row-start-1 lg:row-auto lg:col-start-1 lg:col-end-2 lg:pr-6">
            <h4 className="lg:mb-16 mb-8 after:w-11 after:h-1 after:bg-purple after:block after:rounded after:mt-4">{t('account.create.title')}</h4>
          </div>
          <div className="row-start-3 lg:row-auto lg:col-start-1 lg:col-end-2 lg:pr-6">
            <p className="mb-8 lg:mb-12">{t('account.create.cta.note')}</p>
            <div>
              <Button className="w-full h-12" onClick={() => auth.signinRedirect({ state, extraQueryParams: { flow: 'register' } })}>
                {t('account.create.cta.register')}
              </Button>
              <div className="flex justify-center items-center my-5">
                <span className="grow h-[1px] bg-grey-light" />
                <span className="px-6">{t('account.create.cta.or')}</span>
                <span className="grow h-[1px] bg-grey-light" />
              </div>
              <Button variant="secondary" className="w-full h-12" onClick={() => auth.signinRedirect({ state })}>
                {t('account.create.cta.login')}
              </Button>
            </div>
          </div>
          <div className="hidden row-start-1 row-end-3 lg:block lg:col-start-2 lg:col-end-3 bg-grey-light w-[1px]" />
          <div className="row-start-2 lg:row-start-1 lg:row-end-3 lg:col-start-3 lg:col-end-4">
            <div className="mb-8 lg:mb-0 lg:pl-6">
              <h5>{t('account.create.features.title')}</h5>
              <ul className="mt-6 list-disc pl-6 marker:text-purple text-grey-dark">
                {accountFeatures.map((feature) => (
                  <li className="py-2" key={feature}>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </WellReg>
    </div>
  );
};
