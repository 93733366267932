import { ReactNode, forwardRef } from 'react';

import { Alert, AlertDescription, AlertProps, AlertTitle } from '@britishcouncil/react-registration-platform';

import { AlertIconVariants } from './AlerIconVariants';

export type AlertRegProps = Omit<AlertProps, 'content'> & { content: ReactNode; title: ReactNode; visible?: boolean };

//Rename to Alert after remove legacy Alert component
export const AlertReg = forwardRef<HTMLDivElement, AlertRegProps>(({ variant, content, visible = true, title, ...props }, ref) => {
  return (
    visible && (
      <Alert variant={variant} {...props} ref={ref}>
        {variant && AlertIconVariants[variant]}
        <AlertTitle>{title}</AlertTitle>
        <AlertDescription>{content}</AlertDescription>
      </Alert>
    )
  );
});

AlertReg.displayName = 'AlertReg';
