import { FiBookOpen, FiFileText, FiMic, FiPenTool, FiRadio } from 'react-icons/fi';

export type ExamFormat = {
  format: { type: string; desc?: string; startDate?: string; endDate?: string };
};

export const Format = ({ format }: ExamFormat) => {
  const { text, Ico } = typeMapper(format.type.toLowerCase());
  return (
    <div className="flex flex-col gap-[4px] mb-[16px]">
      <div className="flex items-center text-purple-dark text-[16px]">
        <Ico className="w-[24px] h-[24px] mr-[12px]" />
        <p>{text}</p>
      </div>
      {format.desc && <p>{format.desc}</p>}
    </div>
  );
};

const typeMapper = (type: string) => {
  switch (type) {
    case 'writing':
      return { text: 'Written exam', Ico: FiFileText };
    case 'speaking':
      return { text: 'Speaking exam', Ico: FiMic };
    case 'listening':
      return { text: 'Listening exam', Ico: FiRadio };
    case 'reading':
      return { text: 'Reading exam', Ico: FiBookOpen };
    case 'grammar and vocabulary':
      return { text: 'Grammar and vocabulary', Ico: FiPenTool };
    default:
      return { text: type, Ico: FiFileText };
  }
};
