import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

const initialize = (gtmCode: string, gtmAuth?: string, previewEnv?: string) => {
  TagManager.initialize({
    gtmId: gtmCode,
    auth: gtmAuth,
    preview: previewEnv,
  });
  ReactGA.initialize(gtmCode);
  ReactGA.pageview(window.location.pathname);
};

const updateDataLayer = (dataLayer: object) => {
  TagManager.dataLayer({
    dataLayer,
    dataLayerName: 'dataLayer',
  });
};

export const GoogleTagManagerUtils = { initialize, updateDataLayer };
