export const getMonthsArray = (locale = 'en-GB') => {
  const formatter = new Intl.DateTimeFormat(locale, { month: 'long' });
  return Array.from({ length: 12 }, (_, i) => formatter.format(new Date(2000, i)));
};

export const getMonthsObject = (locale = 'en-GB') => {
  const arr = getMonthsArray(locale);
  return arr.reduce(
    (acc, curr) => {
      acc[curr.toLowerCase()] = curr;
      return acc;
    },
    {} as { [key: string]: string },
  );
};
