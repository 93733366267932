import { FC, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AlertExamAndCancellationPolicy, ExamAndCancellationPolicy, ExternalLink } from '@/components';
import { getExamAndTermsAndConditionsConsent, journeySlice } from '@/store';
import { useProductConfig } from '@/core/hooks/useProductConfig';

export const AppExamAndCancellationPolicy: FC = (): JSX.Element => {
  const consent = useSelector(getExamAndTermsAndConditionsConsent);
  const { productConfig } = useProductConfig();
  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const onConsentChange = useCallback(
    (newValue: boolean) => {
      dispatch(journeySlice.actions.setExamAndTermsAndConditionsConsent(newValue));
    },
    [dispatch],
  );

  return (
    <ExamAndCancellationPolicy
      alert={<AlertExamAndCancellationPolicy />}
      label={t('review.consent.agree-to')}
      onChange={onConsentChange}
      requiredText={t('common.required')}
      termsAndConditionsConsent={consent}
    >
      <ExternalLink
        href={`/terms-and-conditions?product=${productConfig?.productCode?.toLowerCase()}`}
        id="product-terms-and-conditions"
        target="_blank"
        text={t('review.consent.terms-and-conditions')}
      />
    </ExamAndCancellationPolicy>
  );
};
