import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { SelectField } from '@britishcouncil/react-registration-platform';

import { searchDetailsSlice } from '@/store';
import { useAppDispatch, useAppParams, useAppSelector } from '@/core';
import { useSessionGetAllVariantsQuery } from '@/store/webApi';

export const ChooseVariant = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { productCode } = useAppParams();

  const { country, productVariant } = useAppSelector((s) => s.searchDetails);

  const {
    data = [],
    error,
    isLoading,
  } = useSessionGetAllVariantsQuery({ countryId: Number(country?.id ?? ''), productCode: productCode ?? '' }, { skip: !country || !productCode });

  useEffect(() => {
    /** Autoselect variant if only one available */
    country?.id && data.length === 1 && dispatch(searchDetailsSlice.actions.setProductVariant(data[0]));
  }, [data, dispatch, country?.id, productVariant?.id]);

  const options = data?.map((productVariant) => ({
    label: productVariant.title ?? '',
    value: productVariant.id ?? '',
  }));

  const handleExamTypeChange = (selected: (typeof options)[0] | null) => {
    const selectedVariant = data?.find((variant) => variant.id === selected?.value);
    selectedVariant && dispatch(searchDetailsSlice.actions.setProductVariant(selectedVariant));
  };

  // Need to use any as Select from reg. platform is using incorrect types and not allow to use null.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value: any = options.find((el) => el.value === productVariant?.id) || null;

  return (
    <>
      {country && !isLoading && data.length > 1 ? (
        <SelectField
          errorMessage={error ? 'Cannot load exam type' : undefined}
          headerMessage={t('book-test.choose-exam-type')}
          selectProps={{
            value,
            options,
            isMulti: false,
            onChange: handleExamTypeChange,
            placeholder: t('book-test.search-by-exam-type'),
            isDisabled: isLoading,
          }}
          className="mb-[16px]"
          testId="exam-type"
        />
      ) : null}
    </>
  );
};
