import { createContext, useContext } from 'react';

export type AppSettings = {
  appVersion: string;
  api: {
    url: string;
  };
  sentryEnvironment: string;
  oidc: {
    authority: string;
    client_id: string;
  };
};

export const localAppSettings: AppSettings = {
  appVersion: 'local-dev',
  api: {
    url: 'https://tango-my-dev.dev.britishcouncil.org/api/',
  },
  sentryEnvironment: 'development',
  oidc: {
    authority: 'https://dalet-connect-dev.dev.britishcouncil.org',
    client_id: 'bc.registration-platform-b2c.app',
  },
};

export const AppSettingsContext = createContext<AppSettings>(localAppSettings);

export const useAppSettingsContext = () => {
  return useContext(AppSettingsContext);
};
