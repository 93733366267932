import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { IdentityDocument, useCountryGetAllQuery, useDictionariesGetAllMinorPickupPersonsQuery } from '@/store/webApi';
import { formatPrice, getDateStringLong, useAppSelector } from '@/core';
import { selectBasketPriceSummary } from '@/store';
import { webApi } from '@/store/webApi/enhancedWebApi';

export const useReviewData = () => {
  const { t } = useTranslation('');
  const { minorId } = useAppSelector((s) => s.journey);
  const basketTotals = useAppSelector(selectBasketPriceSummary);

  const { data } = webApi.useCustomerGetCustomerDetailsQuery();
  const { data: minorData } = webApi.useCustomerGetMinorDetailsQuery({ id: minorId ?? '' });
  const { data: countries = [] } = useCountryGetAllQuery();

  const formatDate = (d?: string) => (d ? getDateStringLong(new Date(d)) : '');

  const { guardian, pickupType } = useAppSelector((state) => state.minorPickup);

  const { data: dics = [] } = useDictionariesGetAllMinorPickupPersonsQuery();

  const relationShipMatched = dics.filter((relation) => relation.id === Number(guardian?.relationshipId));
  const relationTranslation = t('review.child-details.pickup-relationship');
  const pickupStatementTranslation = t('review.child-details.pickup-statement');
  const telephoneTranslation = t('review.child-details.pickup-telephone-information');
  const parentPickUpStatementTranslation = t('review.child-details.parent-pickup-statement');
  const noPickupStatementTranslation = t('review.child-details.no-pickup-statement');

  let pickup = [''];

  const buildIdentificationDataRows = useCallback(
    (identityDocument?: IdentityDocument | null) => [
      {
        label: t(`${identityDocument?.typeId === 1 ? 'identification.details.passport.number' : 'identification.details.id-card.number'}`),
        value: identityDocument?.documentNumber,
      },
      {
        label: t('identification.details.expiry-date'),
        value: identityDocument?.expiryDate ? formatDate(identityDocument?.expiryDate) : '-',
      },
      {
        label: t('identification.details.issuing-authority'),
        value: identityDocument?.issuingAuthority,
      },
      {
        label: t('review.identification.nationality'),
        value: countries.find((c) => c.id === identityDocument?.issuingCountryId)?.name,
      },
    ],
    [countries, t],
  );

  if (pickupType === 1) {
    pickup = [noPickupStatementTranslation];
  }

  if (pickupType === 2) {
    pickup = [parentPickUpStatementTranslation];
  }

  if (pickupType === 3) {
    pickup = [
      `${guardian?.firstName} ${guardian?.lastName} (${relationTranslation} ${relationShipMatched[0] ? relationShipMatched[0].name : ''})`,
      pickupStatementTranslation,
      `${telephoneTranslation} ${guardian?.telephone}`,
    ];
  }

  const rows = {
    personal: {
      details: [
        {
          label: t('review.personal-details.name'),
          value: `${data?.firstName ?? ''} ${data?.middleName ?? ''} ${data?.lastName ?? ''}`,
        },
        { label: t('review.personal-details.dob'), value: formatDate(data?.dateOfBirth) },
        { label: t('review.email-address'), value: data?.email },
        { label: t('review.contact-details.mobile'), value: data?.mobileNumber },
        {
          label: t('review.contact-details.address'),
          value: [data?.address?.addressLine1, data?.address?.addressLine2, `${data?.address?.postCode} ${data?.address?.city}`],
        },
      ],
      identification: buildIdentificationDataRows(data?.identityDocument),
    },
    minor: {
      details: [
        {
          label: t('review.personal-details.name'),
          value: `${minorData?.firstName ?? ''} ${minorData?.middleName ?? ''} ${minorData?.lastName ?? ''}`,
        },
        { label: t('review.personal-details.dob'), value: formatDate(minorData?.dateOfBirth) },
        { label: t('review.child-details.pickup-label'), value: pickup },
      ],
      identification: buildIdentificationDataRows(minorData?.identityDocument),
    },
    price: [
      {
        label: t('review.payment.fee'),
        value: formatPrice(basketTotals.fee, basketTotals.currency),
      },
      {
        label: t('review.payment.tax'),
        value: formatPrice(basketTotals.tax, basketTotals.currency),
      },
      {
        label: t('review.payment.total'),
        value: formatPrice(basketTotals.total, basketTotals.currency),
      },
    ],
  };

  return { rows };
};
