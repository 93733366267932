import { useEffect } from 'react';

import { BasketState } from '@/store';
import { GetOrderResponse } from '@/store/webApi';
import { GoogleTagManager, OrderStatus, useSessionStorage } from '@/core';

const getPaymentStatus = (orderData?: GetOrderResponse) => {
  switch (true) {
    case orderData?.status === OrderStatus.Completed:
      return 'success';
    case orderData?.status === OrderStatus.AwaitingPayment:
      return 'processing';
    case orderData?.status === OrderStatus.PaymentFailed:
      return 'failed';
    default:
      return 'unknown';
  }
};

export const useAnalytics = ({ orderData, basket }: { orderData?: GetOrderResponse; basket?: BasketState }) => {
  const { storedValue, removeValue } = useSessionStorage<[]>('dataLayer');

  // Get dataLayer saved before redirection to NPP
  if (storedValue?.length > 0) {
    window.dataLayer = storedValue;
    removeValue();
  }

  useEffect(() => {
    orderData &&
      GoogleTagManager.addEvent({
        action: 'BookingComplete',
        country: orderData?.countryIsoCode,
        event: 'VirtualPageview',
        virtualPageTitle: 'Booking complete',
        virtualPageURL: window.location.pathname,
      });
  }, [orderData]);

  useEffect(() => {
    basket?.items.length &&
      orderData &&
      GoogleTagManager.addEvent({
        event: 'purchase',
        payment_status: getPaymentStatus(orderData),
        value: orderData?.orderItems?.reduce((acc, item) => acc + (item.gross ?? 0), 0),
        currency: orderData.currencyIsoCode,
        transaction_id: orderData.correlationId,
        items: basket.items.map((i) => ({
          item_name: i.name,
          item_id: i.id,
          price: i.price,
          item_brand: i.productCode,
          quantity: 1,
          // custom_variable: 'Albania', // feel free to add as many custom variables as you like
        })),
      });
  }, [basket?.items, orderData]);

  const paymentRepetitionAction = () =>
    GoogleTagManager.addEvent({
      action: 'Payment_repetition',
      country: orderData?.countryIsoCode,
      event: 'VirtualPageview',
      virtualPageTitle: 'Booking complete',
      virtualPageURL: window.location.pathname,
    });

  const paymentAttemptFailedAction = () =>
    GoogleTagManager.addEvent({
      action: 'Payment attempt failed',
      category: 'Payment',
      country: orderData?.countryIsoCode,
      correlationId: orderData?.correlationId,
      status: orderData?.status,
      currency: orderData?.currencyIsoCode,
      amount: orderData?.orderItems?.reduce((acc, item) => acc + (item.gross ?? 0), 0),
      event: 'VirtualPageview',
      virtualPageTitle: 'Booking complete',
      virtualPageURL: window.location.pathname,
    });

  return { paymentRepetitionAction, paymentAttemptFailedAction };
};
