import { DayPicker } from 'react-day-picker';

import styled from '@emotion/styled';
import { breakpoints } from '@/core';
import { css } from '@emotion/react';

import 'react-day-picker/dist/style.css';

export const Calendar = styled(DayPicker)`
  .rdp-caption {
    display: none;
  }
  --rdp-accent-color: var(--calendar-accent-color);
  --rdp-background-color: transparent;
  --calendar-disabled-color: #54565a;
  --rdp-cell-size: 35px;

  @media only screen and (min-width: 320px) {
    --calendar-border-spacing: var(--whitespace-basic-06);
    --rdp-cell-size: 35px;
  }

  @media only screen and (min-width: 375px) {
    --calendar-border-spacing: var(--whitespace-basic-06);
    --rdp-cell-size: 40px;
  }

  @media only screen and (min-width: 425px) {
    --calendar-border-spacing: var(--whitespace-basic-08);
    --rdp-cell-size: 45px;
  }

  @media only screen and (min-width: 768px) {
    --calendar-border-spacing: var(--whitespace-basic-16);
    --rdp-cell-size: 60px;
  }


  button.rdp-day span:first-of-type {
    box-shadow: 0px 1px 4px 0px rgba(84, 86, 90, 0.24);
  }

  button.rdp-day:not([disabled]) {
    span:first-of-type {
      border: 2px solid #7716bd;
      color: var(--calendar-session-color);
      background: #f4edff;
      text-decoration-line: none;
      color: #7716bd;
      position: relative;

      &:hover {
        background-color: #7716bd;
        color: #fff;
      }
      &:after {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        background: #7716bd;
        border-radius: 12px;
        position: absolute;
        top: 0px;
        transform: translate3d(50%, -50%, 0);
        right: 0px;
      }
    }
  }

  .rdp-button:focus:not([disabled]) {
    border: none;
    span:first-of-type {
      border: 2px solid var(--focus-color);
    }
  }
  .rdp-day_selected.rdp-day:not([disabled]) {
    background: #7716bd;
    span:first-of-type {
      background: #7716bd;
      color: #fff;
    }
  }

  .rdp-button:focus:not([disabled]):not(:focus-visible) {
    border: none;
    span:first-of-type {
      border: none;
    }
  }

  .rdp-button:focus-visible:not([disabled]) {
    border: none;
    span:first-of-type {
      border: 2px solid var(--focus-color);
    }
  }

  .rdp-day {
    --focus-spread: 0;
    border-radius: var(--component-border-radius);
    border-width: 0;
    max-width: calc(var(--calendar-border-spacing) + var(--rdp-cell-size));
    width: calc(var(--calendar-border-spacing) + var(--rdp-cell-size));
    height: calc(var(--calendar-border-spacing) + var(--rdp-cell-size));
    & > div {
      box-shadow: 0px 1px 4px 0px rgba(84, 86, 90, 0.24);
    }

    &:active {
      border: none;
    }

    &:focus {
      span:first-of-type {
        border-color: var(--focus-color);
      }
    }

    &:focus:not(:focus-visible) {
      border: none;
      span:first-of-type {
        border: none;
      }
    }

    &:focus-visible {
      outline: none;
      span:first-of-type {
        border-color: var(--focus-color);
      }
    }

    span:first-of-type {
      border-radius: var(--component-border-radius);
      color: var(--calendar-non-session-color);
      display: flex;
      flex-direction: column;
      font-weight: normal;
      justify-content: center;
      height: calc(var(--rdp-cell-size) * 0.8);
      max-height: var(--rdp-cell-size);
      text-decoration-color: var(--calendar-non-session-color);
      text-decoration-thickness: 1px;
      width: calc(var(--rdp-cell-size) * 0.8);
      font-size: 16px;
    }

    &.rdp-day_disabled {
      opacity: 1;

      &:focus {
        span:first-of-type {
          border-color: var(--focus-color);
        }
      }

      &:focus:not(:focus-visible) {
        span:first-of-type {
          border: none;
        }
      }

      &:focus-visible {
        span:first-of-type {
          border-color: var(--focus-color);
        }
      }

      span:first-of-type {
        border: none;
        color: var(--calendar-disabled-color);
        font-weight: normal;
        text-decoration-line: none;
      }
    }

    &.rdp-day_today {
      span:first-of-type {
        border: 2px solid #005cb9;
        border-radius: var(--component-border-radius);
        color: var(--calendar-today-color);
        font-weight: 400;
        text-decoration-line: none;
      }
    }
  }

  .rdp-head_cell {
    font-size: var(--h6-font-size);
    font-weight: normal;
    text-transform: capitalize;
  }

  .rdp-nav_button,
  .rdp-nav_button:focus {
    color: var(--calendar-nav-button-color);
  }

  .rdp-table {
    border-collapse: separate;
    border-spacing: 0 var(--calendar-border-spacing);
    color: var(--color);
    font-size: var(--base-font-size);
  }

  h2.rdp-caption_label {
    color: var(--color);
    font-size: var(--h5-font-size);
    font-weight: bold;
  }

  .rdp-months {
    display: flex;
    justify-content: center;
  }
`;

export const CalendarValidationBorder = css`
  #choose-dates-calendar {
    border: solid 2px var(--border-color-error);
  }
`;

export const CalendarWrapper = css`
  #choose-dates-calendar {
    box-shadow: var(--box-shadow-responsive);
    --component-wrapper-background: var(--background-color);
    --slidedown-percentage: -10%;

    margin: auto auto;
  }
  @media only screen and (max-width: 480px) {
    #choose-dates-calendar {
      margin: 0;
      max-width: 100%;
    }
  }
`;
