import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { WellReg } from '@britishcouncil/react-registration-platform';

import { AlertPopup, createErrorMessage } from '@/components';
import { ErrorResponse, popupShowTimeInMiliseconds, useBasket } from '@/core';
import { useAppSelector } from '@/core';

import { SessionsList } from './SessionsList';
import { transformResponse } from './utils';
import { useSearchAvailableSessions } from './useSearchAvailableSessions';

export const ChooseSession = () => {
  const { t } = useTranslation();

  const [popupMsg, setPopupMsg] = useState('');

  const { addSession } = useBasket();

  const { testPackage, selectedDate } = useAppSelector((s) => s.searchDetails);

  const { data: sessions = [], error, isLoading } = useSearchAvailableSessions();
  const groupedSessions = transformResponse(sessions);

  const showError = useCallback((error: string) => {
    setPopupMsg(error);
    setTimeout(() => {
      setPopupMsg('');
    }, popupShowTimeInMiliseconds);
  }, []);

  const handleSessionChange = useCallback(
    (sessionId?: string) => {
      if (!sessionId) {
        return;
      }

      const sessionObject = groupedSessions.map((sessionGroup) => sessionGroup.filter((el) => el.id === sessionId)).filter((el) => el.length > 0);

      if (sessionObject[0] && sessionObject[0][0]) {
        const { error } = addSession(sessionObject[0][0]);

        if (error) {
          showError(error);
        }
      }
    },
    [addSession, groupedSessions, showError],
  );

  return (
    <WellReg>
      {selectedDate ? (
        <>
          <AlertPopup alertType="error" show={!!popupMsg} text={''} title={popupMsg}></AlertPopup>
          <SessionsList
            formErrorMessages={error ? [createErrorMessage({ isFormError: true, text: (error as ErrorResponse)?.response ?? '', type: 'form' })] : undefined}
            groupedSessions={groupedSessions}
            handleClick={handleSessionChange}
            id="session-list"
            isLoading={isLoading}
            testPackage={testPackage}
          />
        </>
      ) : (
        <p>{t('common.calendar.pick-first-date')}</p>
      )}
    </WellReg>
  );
};
