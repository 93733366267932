import { FC } from 'react';

import {
  CompanyVatNip,
  ComponentWrapper,
  DisabledChangeEvent,
  NameDobGender,
  OuterWrapper,
  RadioGroup,
  RadioGroupSelectionChange,
  SelectSelectionChangeEvent,
  TextInput,
  TextInputChangeEvent,
} from '@/components';
import { Country } from '@/store/webApi';
import { ErrorResponse, Options, OrderAcknowledgement } from '@/core';

import { ContactDetails } from './ContactDetails';

interface OrderAcknowledgementProps {
  acknowledgement: OrderAcknowledgement;
  anotherPersonChoiceLabel: string;
  companyChoiceLabel: string;
  companyNameLabel: string;
  countries: Options<Country>;
  countriesError?: ErrorResponse;
  countriesIsLoading: boolean;
  countryLabel: string;
  defaultCountryId?: number;
  defaultCompanyName?: string;
  defaultEmail?: string;
  defaultFirstName?: string;
  defaultLastName?: string;
  defaultPostalOne?: string;
  defaultPostalTwo?: string;
  defaultTelephoneNumber?: string;
  defaultTownCity?: string;
  defaultVatNipNumber?: string;
  defaultZip?: string;
  descriptiveLabel: string;
  emailLabel: string;
  firstNameLabel: string;
  invalidEmailMessage: string;
  lastNameLabel: string;
  myselfChoiceLabel: string;
  noLastName?: boolean;
  noLastNameText: string;
  onCountryChange: SelectSelectionChangeEvent<Country>;
  onCompanyNameChange: TextInputChangeEvent;
  onEmailChange: TextInputChangeEvent;
  onFirstNameChange: TextInputChangeEvent;
  onLastNameChange: TextInputChangeEvent;
  onNoLastNameChange: DisabledChangeEvent;
  onPostalOneChange: TextInputChangeEvent;
  onPostalTwoChange: TextInputChangeEvent;
  onTelephoneNumberChange: (newValue?: string) => void;
  onTownCityChange: TextInputChangeEvent;
  onTypeChange: RadioGroupSelectionChange;
  onVatNipNumberChange: TextInputChangeEvent;
  onZipChange: TextInputChangeEvent;
  postalOneLabel: string;
  postalTwoAriaLabel: string;
  requiredText: string;
  telephoneNumberLabel: string;
  townCityLabel: string;
  vatNipNumberLabel: string;
  zipCodeLabel: string;
}

export const OrderAcknowledgementDetails: FC<OrderAcknowledgementProps> = ({
  acknowledgement,
  anotherPersonChoiceLabel,
  companyChoiceLabel,
  companyNameLabel,
  countryLabel,
  countries,
  countriesError,
  countriesIsLoading,
  defaultCountryId,
  defaultCompanyName,
  defaultEmail,
  defaultFirstName,
  defaultLastName,
  defaultPostalOne,
  defaultPostalTwo,
  defaultTelephoneNumber,
  defaultTownCity,
  defaultVatNipNumber,
  defaultZip,
  descriptiveLabel,
  emailLabel,
  firstNameLabel,
  invalidEmailMessage,
  lastNameLabel,
  myselfChoiceLabel,
  noLastName,
  noLastNameText,
  onCountryChange,
  onCompanyNameChange,
  onEmailChange,
  onFirstNameChange,
  onLastNameChange,
  onNoLastNameChange,
  onPostalOneChange,
  onPostalTwoChange,
  onTelephoneNumberChange,
  onTownCityChange,
  onTypeChange,
  onVatNipNumberChange,
  onZipChange,
  requiredText,
  postalOneLabel,
  postalTwoAriaLabel,
  telephoneNumberLabel,
  townCityLabel,
  vatNipNumberLabel,
  zipCodeLabel,
}): JSX.Element => {
  return (
    <>
      <OuterWrapper>
        <ComponentWrapper addBackground whiteBackground id="test-taker" label={descriptiveLabel}>
          <RadioGroup
            id="order-acknowledgement-choice"
            name="order-acknowledgement-choice"
            onChoiceChange={onTypeChange}
            radios={[
              { id: 'self', label: myselfChoiceLabel, required: true },
              { id: 'person', label: anotherPersonChoiceLabel, required: true },
              { id: 'company', label: companyChoiceLabel, required: true },
            ]}
            value={acknowledgement}
          />
        </ComponentWrapper>
      </OuterWrapper>
      {['company', 'person'].includes(acknowledgement) && (
        <div className="my-6">
          <OuterWrapper>
            <ComponentWrapper addBackground whiteBackground className="animated slideInDown" id="test-taker">
              {acknowledgement === 'person' && (
                <NameDobGender
                  addBackground={false}
                  defaultFirstName={defaultFirstName}
                  defaultLastName={defaultLastName}
                  firstNameLabel={firstNameLabel}
                  firstNameRequiredText={requiredText}
                  id={acknowledgement}
                  lastNameDisabled={!!noLastName}
                  lastNameLabel={lastNameLabel}
                  lastNameRequiredText={requiredText}
                  noLastNameText={noLastNameText}
                  noPadding="none"
                  onFirstNameChange={onFirstNameChange}
                  onLastNameChange={onLastNameChange}
                  onNoLastNameChange={onNoLastNameChange}
                  showDob={false}
                  showGender={false}
                />
              )}
              {acknowledgement === 'company' && (
                <CompanyVatNip
                  addBackground={false}
                  companyNameLabel={companyNameLabel}
                  companyNameRequiredText={requiredText}
                  defaultCompanyName={defaultCompanyName}
                  defaultVatNipNumber={defaultVatNipNumber}
                  onCompanyNameChange={onCompanyNameChange}
                  onVatNipNumberChange={onVatNipNumberChange}
                  vatNipNumberLabel={vatNipNumberLabel}
                  vatNipNumberRequiredText={requiredText}
                />
              )}
              <ComponentWrapper id="email-wrapper" noPadding="none">
                <TextInput
                  defaultText={defaultEmail}
                  id="email"
                  inputType="email"
                  invalidEmailMessage={invalidEmailMessage}
                  label={emailLabel}
                  onValueChange={onEmailChange}
                  required
                  requiredText={requiredText}
                />
              </ComponentWrapper>
              <ContactDetails
                addBackground={false}
                countries={countries}
                countriesIsLoading={countriesIsLoading}
                countriesError={countriesError}
                countryLabel={countryLabel}
                defaultCountryId={defaultCountryId}
                defaultTelephoneNumber={defaultTelephoneNumber}
                defaultPostalOne={defaultPostalOne}
                defaultPostalTwo={defaultPostalTwo}
                defaultTownCity={defaultTownCity}
                defaultZip={defaultZip}
                onCountryChange={onCountryChange}
                onPostalOneChange={onPostalOneChange}
                onPostalTwoChange={onPostalTwoChange}
                onTelephoneNumberChange={onTelephoneNumberChange}
                onTownCityChange={onTownCityChange}
                onZipChange={onZipChange}
                postalOneLabel={postalOneLabel}
                postalTwoAriaLabel={postalTwoAriaLabel}
                requiredText={requiredText}
                telephoneNumberLabel={telephoneNumberLabel}
                townCityLabel={townCityLabel}
                zipLabel={zipCodeLabel}
              />
            </ComponentWrapper>
          </OuterWrapper>
        </div>
      )}
    </>
  );
};
