import { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ComponentWrapper, RadioGroup } from '@/components';
import { identificationDetailsSlice, minorIdentificationDetailsSlice } from '@/store';

interface IdentificationTypeChoiceProps {
  identificationType: number;
  isChild?: boolean;
}

export const IdentificationTypeChoice = ({ identificationType, isChild }: IdentificationTypeChoiceProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onChange: (newValue: string) => void = useCallback(
    (newValue) => {
      const slice = isChild ? minorIdentificationDetailsSlice : identificationDetailsSlice;
      dispatch(slice.actions.setIdentificationType(Number(newValue)));
    },
    [dispatch, isChild],
  );

  return (
    <ComponentWrapper id="identification-type">
      <RadioGroup
        id="identification-type-choice"
        name="identification-type-choice"
        onChoiceChange={onChange}
        radios={[
          { id: '0', label: t('identification.type.passport'), required: true },
          { id: '1', label: t('identification.type.id-card'), required: true },
        ]}
        value={String(identificationType)}
      />
    </ComponentWrapper>
  );
};
