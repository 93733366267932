import { FC } from 'react';

import { IconProps } from '@/icons/IconProps';

export const Card: FC<IconProps> = ({ index = 0 }) => {
  return (
    <svg
      aria-hidden="true"
      aria-labelledby={`card-icon-title-${index}`}
      className="card-icon"
      data-testid={`card-icon-${index}`}
      fill="none"
      focusable="false"
      role="img"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`card-icon-title-${index}`}></title>
      <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
      <line x1="1" y1="10" x2="23" y2="10"></line>
    </svg>
  );
};

<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>;
