import { BasketItemDto, BasketItem as BasketItemResponse, SessionResponse } from '@/store/webApi';

import { BasketItem } from './basketSlice';

export const basketAdapter = {
  /** From APTIS Session to BasketItem */
  fromSession: (s: SessionResponse): BasketItem => ({
    id: s.id,
    name: s.name ?? '',
    address: s.venue?.address ?? '',
    formats:
      s.sessionComponents?.map((c) => ({
        type: c,
        description: c,
        startDate: s.startDateTimeUtc,
        endDate: s.endDateTimeUtc,
      })) ?? [],
    startDateTimeUtc: s.startDateTimeUtc,
    endDateTimeUtc: s.endDateTimeUtc,
    timeZone: s.venue?.timezone ?? '',
    venuName: s.venue?.name ?? '',
    productCode: s.productCode,
    packageId: s.packageId ?? '',
  }),

  fromBasketItemDto: (b: BasketItemDto): BasketItem => ({
    id: b.id?.toString(),
    name: b.name ?? '',
    address: b.address ?? '',
    formats:
      b.skills?.map((c) => ({
        type: c,
        description: c,
        startDate: b.startDate,
        endDate: b.endDate,
      })) ?? [],
    startDateTimeUtc: b.startDate,
    endDateTimeUtc: b.endDate,
    timeZone: b.timeZone ?? '',
    venuName: b.name ?? '',
    productCode: b.productCode ?? '',
    price: b?.totalFee ?? 0,
    tax: b?.tax ?? 0,
    currency: b?.currency ?? '',
  }),

  fromBasketItem: (b: BasketItemResponse): BasketItem => ({
    id: b.id?.toString(),
    name: b.name ?? '',
    address: b.address ?? '',
    formats:
      b.skills?.map((c) => ({
        type: c,
        description: c,
        startDate: b.startDate,
        endDate: b.endDate,
      })) ?? [],
    startDateTimeUtc: b.startDate,
    endDateTimeUtc: b.endDate,
    timeZone: b.timeZone ?? '',
    venuName: b.name ?? '',
    productCode: b.productCode ?? '',
    price: b?.totalFee ?? 0,
    tax: b?.tax ?? 0,
    currency: b?.currency ?? '',
  }),
};
