import { useState } from 'react';

import { FiChevronUp } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router';

import { basketSlice } from '@/store';
import { cn } from '@/core/helpers/cn';
import { mainMenuBookTest, params, useAppDispatch, useAppSelector } from '@/core';

import { Actions } from './Actions';
import { Empty } from './Empty';
import { Exam } from './Exam';
import { MobileOpener } from './MobileOpener';

export const Basket = () => {
  const { items } = useAppSelector((s) => s.basket);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showBasket, setShowBasket] = useState(false);
  const { productCode } = useParams();

  if (items.some((item) => item.productCode?.toLowerCase() !== productCode?.toLowerCase())) {
    /* Basket contains items from another product, so clear the whole basket. */
    dispatch(basketSlice.actions.reset());
  }

  const hideBasket = () => {
    setShowBasket(false);
    document.body.style.overflow = 'auto';
  };

  const showBasketHandler = () => {
    setShowBasket(true);
    /* Prevent scroll of the body when basket is visible on mobile */
    document.body.style.overflow = 'hidden';
  };

  const redirectToSelection = () => {
    productCode && navigate(mainMenuBookTest.absolutePath.replace(params.productCode, productCode));
  };

  return (
    <>
      <MobileOpener openFn={showBasketHandler} exams={items} />
      <div
        className={cn(
          'fixed lg:relative bg-white lg:bg-transparent translate-y-[-120vh] px-[36px] pt-[16px] lg:p-0 left-0 top-0 z-30 w-full h-screen lg:h-auto transition-all duration-500 lg:translate-y-0 overflow-auto',
          { 'translate-y-0': showBasket },
          { 'translate-y-[-120vh]': !showBasket },
        )}
      >
        <section className="lg:max-w-[419px] xl:max-w-[427px] 2xl:max-w-[435px] pb-[24px] bg-white lg:bg-transparent">
          <h3 className="mb-[32px] !text-[16px] lg:!text-[32px]">
            Order Summary{' '}
            <span>
              {`(${items.length}`}&nbsp;{`item${items.length !== 1 ? 's' : ''})`}
            </span>
          </h3>
          {items?.length === 0 && <Empty onClick={() => redirectToSelection()} />}
          {items?.length > 0 && (
            <div>
              <ol className="overflow-auto max-h-screen customScroll pr-[24px] lg:pr-[8px] xl:pr-[16px] 2xl:pr-[24px]">
                {items.map((exam, i) => (
                  <Exam key={exam.id} exam={exam} i={i} />
                ))}
              </ol>
            </div>
          )}
          <Actions hideBasket={hideBasket} />
        </section>
        <div className="lg:hidden text-center text-blue-dark font-bold pb-[24px]">
          <button onClick={hideBasket} className="inline-flex items-center gap-[4px] mt-[12px]">
            <span>Show less</span>
            <FiChevronUp />
          </button>
        </div>
      </div>
    </>
  );
};
