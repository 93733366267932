import { useMemo } from 'react';

import { FiFileText, FiMapPin } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

import { SessionWithVenueResponse } from '@/store/webApi';
import { getDateInTimezone, getZonedTime } from '@/core';

export interface ExamDetailsProps {
  examData: SessionWithVenueResponse;
}

export const ExamDetails = ({ examData }: ExamDetailsProps) => {
  const { t } = useTranslation();

  const dateTime = useMemo(
    () =>
      `${getDateInTimezone(examData.startDateUtc, examData.timezone)} ${getZonedTime(examData.startDateUtc, examData.timezone)} - ${getZonedTime(examData.endDateUtc, examData.timezone)}`,
    [examData],
  );

  return (
    <div className="flex grow gap-6 flex-col md:flex-row">
      <section className="flex flex-col gap-4 w-full">
        <div className="flex flex-row items-center justify-start gap-3 text-purple-dark">
          <FiFileText className="w-6 h-6" />
          <p className="text-lg">{t('dashboard.exam-details.exam')}</p>
        </div>

        <p className="font-bold">{dateTime}</p>
        <p>{examData.deliveryMode}</p>
      </section>
      <section className="flex flex-col gap-4 w-full ">
        <div className="flex flex-row items-center justify-start gap-3 text-purple-dark">
          <FiMapPin className="w-6 h-6" />
          <p className="text-lg">{t('dashboard.exam-details.location')}</p>
        </div>
        <p className="font-bold">{examData.venueName}</p>
        <p>{`${examData.buildingStreet} ${examData.postCode} ${examData.city}`}</p>
        <p>{examData.country}</p>
      </section>
    </div>
  );
};
