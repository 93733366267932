import { FC } from 'react';

import { ReviewGrid, ReviewGridRow } from '@/components';

interface ReviewNameDobProps {
  dateOfBirth: string;
  dateOfBirthLabel: string;
  name: string;
  nameLabel: string;
}

export const ReviewNameDob: FC<ReviewNameDobProps> = ({ dateOfBirth, dateOfBirthLabel, name, nameLabel }): JSX.Element => {
  return (
    <ReviewGrid>
      <ReviewGridRow id="name-label" label={nameLabel}>
        {name}
      </ReviewGridRow>
      <ReviewGridRow id="dob-label" label={dateOfBirthLabel}>
        {dateOfBirth}
      </ReviewGridRow>
    </ReviewGrid>
  );
};
